import React from 'react';
import { useIntl } from 'react-intl';
import { func, number, oneOfType, string } from 'prop-types';
import Button from '../../../../atoms/button/button';
import ArrowRight from '../../../../../../resources/images/chevron-right-16.svg';

const StoreSelector = ({ availableStores, selectStores, className, changeStoreRef, productName }) => {
    const intl = useIntl();

    const getStoresLabel = availableStores => {
        if (availableStores && parseInt(availableStores) > 1) {
            return `${availableStores + ' ' + intl.formatMessage({ id: 'stores-text' })}`;
        } else {
            return `${availableStores + ' ' + intl.formatMessage({ id: 'store-text' })}`;
        }
    };
    return (
        <>
            <Button
                btnRef={changeStoreRef}
                className={`button button-block producttile__storeselectore producttile__action ${className}`}
                onClick={selectStores}
                dataTestid="change_store_cta" buttonAriaLabel={`${intl.formatMessage({ id: 'product-availability-store-text' })} ${getStoresLabel(availableStores)} ${intl.formatMessage({ id: 'change-store' })} for ${productName}`}>
                <span className="producttile__abailable">
                    {intl.formatMessage({ id: 'product-availability-store-text' })}{' '}
                    <strong>{getStoresLabel(availableStores)}</strong>
                </span>

                <span className="producttile__changestore">
                    {intl.formatMessage({ id: 'change-store' })} <ArrowRight />
                </span>
            </Button>
        </>
    );
};
export default React.memo(StoreSelector);

StoreSelector.defaultProps = {
    availableStores: '',
    selectStores: () => {},
    className: '',
    changeStoreRef: () => {},
    productName: ''
};

StoreSelector.propTypes = {
    availableStores: oneOfType([number, string]),
    selectStores: func,
    className: string,
    changeStoreRef: func,
    productName: string
};
