import { bool, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useFavourites from '../../../../../../aem-core-components/components/Minicart/hooks/useFavourites';
import { useUserContext } from '../../../../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../../../../config/GoogleTagManagerEvents';
import { useCheckUser } from '../../../../../../hooks/useCheckUser';
import Button from '../../../../atoms/button/button';
import StarIcon from '../../../../../../resources/images/star.svg';
import StarSolidIcon from '../../../../../../resources/images/star-solid.svg';
import { logError } from '../../../../utils/logger';
import config from '../../../../../App/config';
import { VARIABLE_CONFIG } from '../../../../../../constants/analyticsConstants/Variables';
import { USER_TYPE } from '../../../../../../constants/userDetailsConstants';
import { pdpDataLocators } from '../../../../../pdp/dataLocators';
import { STORAGE_CONFIG } from '../../../../../../constants/storageConfig';
export default function Favorites({ sku, qty, accountNumber, showFavoriteTitle, productName, favoriteClasses }) {
    const intl = useIntl();
    const userType = useCheckUser();
    const { addToFavouritesWishlist, removeFavouritesWishlist } = useFavourites();
    const [{ wishlist }, { dispatch }] = useUserContext();
    const [favoriteProduct, setFavoriteProduct] = useState({});
    const [isFav, setIsFav] = useState(false);
    const { sendEventsForClick } = useAnalyticsContext();
    const isPunchoutUser = sessionStorage.getItem('punchoutUserData')
        ? JSON.parse(sessionStorage.getItem('punchoutUserData'))?.isPunchoutUser || false
        : false;

    const isInWishlist = () => {
        const productInListIndex = wishlist?.items?.findIndex(item => item?.product?.sku === sku);
        if (productInListIndex > -1) {
            setFavoriteProduct({ product: wishlist?.items[productInListIndex], productIndex: productInListIndex });
            return true;
        } else {
            setFavoriteProduct({});
            return false;
        }
    };

    useEffect(() => {
        setIsFav(isInWishlist());
    }, [wishlist]);

    const sendEvents = productName => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.FAVOURITE_PRODUCTS,
                VARIABLE_CONFIG.EVENT_ACTION.ADD_TO_FAVOURITES,
                productName
            );
        } catch (error) {
            logError(error, false, 'sendEvents');
        }
    };

    const clickHandler = async e => {
        if (userType === USER_TYPE.CREDIT) {
            let wishListCopy = { ...wishlist };
            if (!isFav) {
                const addedResponse = await addToFavouritesWishlist(accountNumber, sku);
                if (addedResponse?.status && addedResponse?.favoriteID) {
                    setIsFav(true);
                    wishListCopy?.items?.push({ favoriteID: addedResponse?.favoriteID, product: { sku: sku } });
                    dispatch({ type: 'wishlist', wishListData: wishListCopy });
                }
            } else if (isFav) {
                const { data } = await removeFavouritesWishlist(accountNumber, favoriteProduct?.product?.favoriteID);
                if (data?.status) {
                    setIsFav(false);
                    wishListCopy?.items?.splice(favoriteProduct.productIndex, 1);
                    dispatch({ type: 'wishlist', wishListData: wishListCopy });
                }
            }
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.LOCALWISHLISTDATA);
            sendEvents(productName);
        } else {
            sendEvents(productName);
            window.location.href = config.pagePaths.signInHandler();
        }
    };

    return (
        <>
            {!isPunchoutUser && userType === USER_TYPE.CREDIT && (
                <Button
                    className={`button button-outline-primary button-pb0 block__pdp-hero-actions ${favoriteClasses}`}
                    onClick={e => clickHandler(e)}
                    tabIndex={0}
                    dataTestid={pdpDataLocators.pdp_favoriteLink_testid}
                    customButtonAriaLabel={`${
                        isFav
                            ? intl.formatMessage({ id: 'saved-favorite-aria-label' })
                            : intl.formatMessage({ id: 'save-favorite-aria-label' })
                    } ${productName}`}
                    aria-pressed={isFav}>
                    {!isFav ? (
                        <StarIcon className="button_icon" aria-hidden={true} tabIndex={'-1'} />
                    ) : (
                        <StarSolidIcon className="button_icon" aria-hidden={true} tabIndex={'-1'} />
                    )}
                    {showFavoriteTitle &&
                        (isFav
                            ? intl.formatMessage({ id: 'pdp:saved-favorite' })
                            : intl.formatMessage({ id: 'pdp:save-favorite' }))}
                </Button>
            )}
        </>
    );
}

Favorites.propTypes = {
    showFavoriteTitle: bool,
    favoriteClasses: string
};

Favorites.defaultProps = {
    showFavoriteTitle: true,
    favoriteClasses: ''
};
