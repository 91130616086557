import React, { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { bool, func, instanceOf, object, oneOfType, string } from 'prop-types';
import { useFilterState } from '../filterContext';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import useMedia from '../../../hooks/useMedia';
import ReadOnlyPersistantCapHeader from './ReadOnlyPersistantHeader';
import EditOnlyPersistantCapHeader from './EditOnlyPersistantCapHeader/EditOnlyPersistantCapHeader';
import AlertCircle from '../../../resources/images/alert-circle-black.svg';
import { EDIT_VIEW_CLOSE, EDIT_VIEW_DETAILS, EDIT_VIEW_OPEN, SET_IS_SUGGESTIONS_LOADING } from '../constants';
import { SET_START_CLICK } from '../../../aem-core-components/actions/constants';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';

const PersistantHeader = props => {
    const {
        renderRentalDetailsView,
        renderRentalDetailsDrawerContent,
        renderUpdateBtn,
        isCapInteracted,
        setIsCapInteracted,
        isCapDetailsSaved,
        setIsCapDetailsSaved,
        setShowPickupStoreAlert,
        showPickupStoreAlert,
        setNoLocationPC,
        setShowAlertBanner,
        showAlertBanner,
        updateRentalViewDetails,
        addressText,
        filterStartDate,
        filterEndDate,
        selectedPickupStore,
        jobsiteDetails,
        fulfillmentValue,
        isJobsiteSelected,
        resetCapFields,
        formInteractionStartedEventAnalytics,
        pickupstoreContainerRef,
        locationContainerRef,
        sendDeliveryInteractedEvent,
        sendAnalyticsForNoStoreNearBy,
        isStoreLocationFetching
    } = props;
    const intl = useIntl();
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

    const [{ viewCart, isEditView, projectDetails, editViewSource, startDate, endDate }, dispatch] = useFilterState();
    const [{ isOpen: isMiniCartOpen }, cartDispatch] = useCartState();
    const { isSelectedLocationEmpty } = useCheckLocationEmpty();
    const mediaType = useMedia();

    useEffect(() => {
        const getHeader = ENV_CONFIG.HEADER;
        getHeader?.classList?.add('header--no-shadow');
        const breadcrumbPaddingTop = ENV_CONFIG.BREADCRUMB_SECTION;
        if (showAlertBanner) {
            breadcrumbPaddingTop?.classList?.add('breadcrumb-padding-top');
        } else {
            breadcrumbPaddingTop?.classList?.remove('breadcrumb-padding-top');
        }
    }, [showAlertBanner]);

    useEffect(() => {
        // open condition to check that the click is not done from minicart
        if (!isMiniCartOpen && editViewSource == VARIABLE_CONFIG.CAP_VIEW_MODE.DATE_VIEW) {
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCAPDETAILSSAVED, true);
            setIsCapInteracted(true);
            dispatch({ type: EDIT_VIEW_CLOSE });
        }
    }, [editViewSource]);

    useEffect(() => {
        const isCapDetailsSaved = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCAPDETAILSSAVED) || false;
        if (mediaType !== MEDIA_TYPE.DESKTOP) {
            setIsCapInteracted(false);
        } else if (isCapDetailsSaved && JSON.parse(isCapDetailsSaved)) {
            setIsCapInteracted(false);
        } else if (!isSelectedLocationEmpty() || startDate || endDate) {
            setIsCapInteracted(false);
        } else {
            setIsCapInteracted(true);
        }
    }, [isCapDetailsSaved, viewCart?.lat, projectDetails?.selectedProjectLatititude]);

    const toggleViewMode = () => {
        setIsCapInteracted(false);
        dispatch({ type: EDIT_VIEW_OPEN });
        dispatch({ type: EDIT_VIEW_DETAILS, editViewSource: '' });
    };

    const toggleEditMode = () => {
        dispatch({ type: EDIT_VIEW_CLOSE });
        setIsCapInteracted(false);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCAPDETAILSSAVED, true);
        setIsCapDetailsSaved(true);
        resetCapFields();
    };

    const handleDateOverlay = () => {
        setIsCapInteracted(true);
        dispatch({ type: EDIT_VIEW_DETAILS, editViewSource: VARIABLE_CONFIG.CAP_VIEW_MODE.DATE_VIEW });
        cartDispatch({ type: SET_START_CLICK });
    };

    const handleLocationOverlay = () => {
        dispatch({ type: EDIT_VIEW_OPEN });
        dispatch({ type: EDIT_VIEW_DETAILS, editViewSource: VARIABLE_CONFIG.CAP_VIEW_MODE.LOCATION_VIEW });
        formInteractionStartedEventAnalytics(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LOCATION);
    };
    const renderAlertBanner = () => {
        if (showAlertBanner && !overridePC?.pc && (mediaType === MEDIA_TYPE.DESKTOP || !isEditView)) {
            return (
                <div className={isEditView ? 'edit__pickupStore__alert' : 'view__pickupStore__alert'}>
                    <div
                        className={
                            isEditView
                                ? 'edit__pickupStore__alert__container'
                                : 'view__pickupStore__alert__container container'
                        }>
                        <AlertCircle className="alertCircle__icon" />
                        <div>
                            {intl.formatMessage({ id: 'cap:pickup-no-store-warning-text-prefix' })}
                            <a
                                href={intl.formatMessage({ id: 'cap:no-address-helpline-ref' })}
                                className="telephone__link"
                                title={intl.formatMessage({ id: 'cap:no-store-help-text' })}
                                aria-label={`${intl.formatMessage({
                                    id: 'cap:pickup-no-store-warning-text-prefix'
                                })} ${intl.formatMessage({ id: 'cap:no-address-helpline' })} ${intl.formatMessage({
                                    id: 'cap:pickup-no-store-warning-text-sufix'
                                })}`}>
                                {intl.formatMessage({ id: 'cap:no-address-helpline' })}
                            </a>
                            {intl.formatMessage({ id: 'cap:pickup-no-store-warning-text-sufix' })}
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <EditOnlyPersistantCapHeader
                renderUpdateBtn={renderUpdateBtn}
                renderRentalDetailsView={renderRentalDetailsView}
                renderRentalDetailsDrawerContent={renderRentalDetailsDrawerContent}
                toggleEditMode={toggleEditMode}
                isCapInteracted={isCapInteracted}
                isEditView={isEditView}
                setShowPickupStoreAlert={setShowPickupStoreAlert}
                showPickupStoreAlert={showPickupStoreAlert}
                setNoLocationPC={setNoLocationPC}
                setShowAlertBanner={setShowAlertBanner}
                showAlertBanner={showAlertBanner}
                updateRentalViewDetails={updateRentalViewDetails}
                sendAnalyticsForNoStoreNearBy={sendAnalyticsForNoStoreNearBy}
            />
            <ReadOnlyPersistantCapHeader
                toggleViewMode={toggleViewMode}
                selectedStoreDetails={selectedPickupStore}
                handleDateOverlay={handleDateOverlay}
                handleLocationOverlay={handleLocationOverlay}
                startDate={filterStartDate}
                endDate={filterEndDate}
                addressText={addressText}
                jobsiteDetails={jobsiteDetails}
                fulfillmentValue={fulfillmentValue}
                isJobsiteSelected={isJobsiteSelected}
                isEditView={!isEditView && !isCapInteracted}
                formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                sendDeliveryInteractedEvent={sendDeliveryInteractedEvent}
                pickupstoreContainerRef={pickupstoreContainerRef}
                locationContainerRef={locationContainerRef}
                isStoreLocationFetching={isStoreLocationFetching}
            />
            {renderAlertBanner()}
        </>
    );
};

PersistantHeader.defaultProps = {
    setIsCapInteracted: () => {},
    isCapInteracted: false,
    setIsCapDetailsSaved: () => {},
    isCapDetailsSaved: false,
    renderRentalDetailsView: () => {},
    renderRentalDetailsDrawerContent: () => {},
    renderUpdateBtn: () => {},
    showPickupStoreAlert: false,
    setShowPickupStoreAlert: () => {},
    setNoLocationPC: () => {},
    setShowAlertBanner: () => {},
    showAlertBanner: false,
    updateRentalViewDetails: () => {},
    addressText: '',
    filterStartDate: '',
    filterEndDate: '',
    selectedPickupStore: {},
    jobsiteDetails: {},
    fulfillmentValue: '',
    isJobsiteSelected: false,
    resetCapFields: () => {},
    formInteractionStartedEventAnalytics: () => {},
    sendDeliveryInteractedEvent: () => {},
    sendAnalyticsForNoStoreNearBy: () => {},
    isStoreLocationFetching: false
};

PersistantHeader.propTypes = {
    setIsCapInteracted: func,
    setIsCapDetailsSaved: func,
    isCapDetailsSaved: bool,
    isCapInteracted: bool,
    renderRentalDetailsView: func,
    renderRentalDetailsDrawerContent: func,
    renderUpdateBtn: func,
    showPickupStoreAlert: bool,
    setShowPickupStoreAlert: func,
    setNoLocationPC: func,
    setShowAlertBanner: func,
    showAlertBanner: bool,
    updateRentalViewDetails: func,
    addressText: string,
    filterStartDate: oneOfType([instanceOf(Date), string]),
    filterEndDate: oneOfType([instanceOf(Date), string]),
    selectedPickupStore: object,
    jobsiteDetails: object,
    fulfillmentValue: string,
    isJobsiteSelected: bool,
    resetCapFields: func,
    formInteractionStartedEventAnalytics: func,
    sendDeliveryInteractedEvent: func,
    sendAnalyticsForNoStoreNearBy: func,
    isStoreLocationFetching: bool
};

export default memo(PersistantHeader);
