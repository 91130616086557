var otheLocationOne = document.querySelector('.other__location-one');
var otheLocationTwo = document.querySelector('.other__location-two');
var selectedStore = JSON.parse(localStorage.getItem('selectedStoreDetails'));
var addressCompanyId = parseInt(localStorage.getItem('companyID')) || 1;
var locationBranchDistanceDetail = document.querySelector('.location__branch-distance');
var blockOtherLocation = document.querySelector('.block--other-location');

if (window.location.href.indexOf('/location') > -1 && selectedStore) {
    if (selectedStore?.distance) {
        var getLocationDetail =
            Math.floor(addressCompanyId) === 2
                ? `${Math.floor(selectedStore.distance)} km`
                : `${Math.floor(selectedStore.distance)} mi`;
        if (locationBranchDistanceDetail) {
            locationBranchDistanceDetail.innerHTML = getLocationDetail;
        }
    }
    var getNearStore = (key, value) => {
        if (localStorage.getItem(key)) {
            var { name, phone, distance, companyId, hours, street, zip, state, city, pc, specialtyUrl, branchText } =
                JSON.parse(localStorage.getItem(key))[value];
            return otherLocationDiv(
                name,
                phone,
                distance,
                companyId,
                hours,
                street,
                zip,
                state,
                city,
                pc,
                addressCompanyId,
                specialtyUrl,
                branchText
            );
        }
        return '';
    };
    var convertPhone = phoneNo => {
        if (phoneNo) {
            return phoneNo?.substr(1, 3) + '-' + phoneNo?.substr(6, 8);
        }
        return '';
    };

    var formatUrl = text => {
        if (text) {
            let formattedText = text?.replace(/\s+/g, '-');
            formattedText = formattedText?.toLowerCase();
            return formattedText;
        }
        return '';
    };
    var checkSpecialtiesTitle = (pc, name) => {
        if (pc >= 5000 && pc <= 5099) {
            return 'Sunbelt Rentals Tool Rental';
        } else return name;
    };

    var otherLocationDiv = (
        name,
        phone,
        distance,
        companyId,
        hours,
        street,
        zip,
        state,
        city,
        pc,
        addressCompanyId,
        specialtyUrl,
        branchText
    ) => {
        var currentLocation = companyId === 2 ? '/ca' : '';
        return `<div class="other__location-wrap">
      <div class="other__location-top">
        <div class="other__location-title" data-testid="locationDetailsPage_otherStoreDetails_storeName">${checkSpecialtiesTitle(
            pc,
            name
        )}</div>
        <div class="other__location-distance" data-testid="locationDetailsPage_otherStoreDetails_distance">
        ${
            distance ? (parseInt(addressCompanyId) == 2 ? `${parseInt(distance)} km` : `${parseInt(distance)} mi`) : ''
        }     
        </div>
      </div>
      <small class="other_location-branchid">${branchText}</small>
      <div class="other__location-hours" data-testid="locationDetailsPage_otherStoreDetails_staticText">${hours}</div>
      <div class="other__location-address">
        <button class="other__location-addresslink" role="link" data-redirect="${street},${city ? city : ''},${
            state ? state : ''
        },${zip ? zip : ''}" data-testid="locationDetailsPage_otherStoreDetails_getDirectionsCTA">
        <i class="icon icon-external-link-green"></i>
        </button>
        <address data-testid="locationDetailsPage_otherStoreDetails_storeAddress">
            <span>${street?.toLowerCase() || ''}</span>
            <span>${city?.toLowerCase() || ''}, ${state || ''} ${zip || ''}</span>
        </address>
      </div>
        <div class="">
            <div class="other__location-actions">
            <a  class="other__location-link other__location-phone" href="tel:${convertPhone(
                phone
            )}" data-testid="locationDetailsPage_otherStoreDetails_storeContactNumber">${convertPhone(phone)}</a>
            <a href="/location${currentLocation}/${formatUrl(state)}/${formatUrl(city)}/${specialtyUrl}/${pc}/" 
            class="other__location-link other__location-redirect" 
            data-testid="locationDetailsPage_otherStoreDetails_storeDetailsCTA">Store details</a>
                </div>
            <div></div>
            </div>
        </div></div>`;
    };

    if (otheLocationOne) {
        otheLocationOne.innerHTML = getNearStore('nearbyStores', 'closestStore')?.trim();
    }
    if (otheLocationTwo) {
        otheLocationTwo.innerHTML = getNearStore('nearbyStores', 'secondClosest')?.trim();
    }
    var otherLocationRedirect = document.querySelectorAll('.other__location-redirect');

    otherLocationRedirect.forEach(item =>
        item?.addEventListener('click', function (e) {
            e.preventDefault();
            var setLocationData = {
                closestStore: JSON.parse(localStorage.getItem('nearbyStores'))?.closestStore,
                secondClosest: JSON.parse(localStorage.getItem('nearbyStores'))?.secondClosest
            };
            var newSelectdStore = {};
            if (e.currentTarget.closest('.other__location-in').classList.contains('other__location-one')) {
                newSelectdStore = setLocationData?.closestStore;
                setLocationData.closestStore = selectedStore;
                setLocationData.secondClosest = JSON.parse(localStorage.getItem('nearbyStores'))?.secondClosest;
            } else {
                newSelectdStore = setLocationData.secondClosest;
                setLocationData.closestStore = JSON.parse(localStorage.getItem('nearbyStores'))?.closestStore;
                setLocationData.secondClosest = selectedStore;
            }
            localStorage.setItem('selectedStoreDetails', JSON.stringify(newSelectdStore));
            localStorage.setItem('nearbyStores', JSON.stringify(setLocationData));
            window.location.href = e.currentTarget.href;
        })
    );

    var addresslinks = document.querySelectorAll('.other__location-addresslink');
    addresslinks.forEach(function (link) {
        link?.addEventListener('click', function (e) {
            var getSetLocation = localStorage.getItem('startingLocationForGetDirections') || '';
            var newGetSetLocation = getSetLocation.replace(/[^A-Z0-9]+/gi, ' ');
            var newLink = `https://www.google.com/maps/dir/${newGetSetLocation}/${e.currentTarget.dataset.redirect}`;
            window.open(newLink, '_blank');
        });
    });

    var secondClosestIsEmpty = JSON.parse(localStorage.getItem('nearbyStores'))?.secondClosest;
    var closestStoreIsEmpty = JSON.parse(localStorage.getItem('nearbyStores'))?.closestStore;

    if (Object.keys(secondClosestIsEmpty || closestStoreIsEmpty).length === 0) {
        if (blockOtherLocation) {
            blockOtherLocation.classList.add('block--other-locationhide');
        }
    }
}
