/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2021 Adobe
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
import { gql } from '@apollo/client';

const QUERY_CART_DETAILS = gql`
    query cartDetails(
        $cartId: String!
        $pc: Int
        $jobSiteAddr1: String
        $jobSiteAddr2: String
        $jobSiteCity: String
        $jobSiteState: String
        $jobSiteZip: String
        $dlSt: String
        $dlNumber: String
        $startDateAndTime: String
        $endDateAndTime: String
        $lat: String
        $long: String
        $distance: String
        $timeType: String
        $equipment: [CartEquipment]
        $salesEquipments: [SalesEquipments]
        $lastResort: Boolean
        $fulfillment: Int
        $pcLat: String
        $pcLong: String
        $projectId: String
        $account: Int
        $isDeliveryEstimate: Boolean
    ) {
        cart(
            cart_id: $cartId
            pc: $pc
            jobSiteAddr1: $jobSiteAddr1
            jobSiteAddr2: $jobSiteAddr2
            jobSiteCity: $jobSiteCity
            jobSiteState: $jobSiteState
            jobSiteZip: $jobSiteZip
            dlSt: $dlSt
            dlNumber: $dlNumber
            startDateAndTime: $startDateAndTime
            endDateAndTime: $endDateAndTime
            lat: $lat
            long: $long
            distance: $distance
            timeType: $timeType
            equipment: $equipment
            salesEquipments: $salesEquipments
            lastResort: $lastResort
            fulfillment: $fulfillment
            pcLat: $pcLat
            pcLong: $pcLong
            projectId: $projectId
            account: $account
            isDeliveryEstimate: $isDeliveryEstimate
        ) {
            id
            prices {
                grand_total {
                    currency
                    value
                }
            }
            updated_at
            created_at
            estimatesResponse {
                status {
                    code
                    desc
                }
                estimate {
                    itemizedCharges {
                        products {
                            catId
                            classId
                            unitRentalCost
                            rentalCost
                            rppCost
                            rppTax
                        }
                        salesItems {
                            itemNumber
                            stockClass
                            unitPrice
                            totalPrice
                            salesTax
                            quantity
                            productSku
                        }
                    }
                    miscCharges {
                        type
                        charge
                        tax
                        taxExempt
                        nonTaxable
                        itemTaxRate
                        productSku
                    }
                    totals {
                        rentalAmount
                        rppCharges
                        finalTotal
                        salesAmount
                        salesTax
                        miscCharges
                    }
                }
                estimatePickup {
                    totals {
                        rentalAmount
                        rppCharges
                        miscCharges
                        finalTotal
                        salesAmount
                        salesTax
                    }
                    miscCharges {
                        type
                        charge
                        tax
                        taxExempt
                        nonTaxable
                        itemTaxRate
                        productSku
                    }
                    itemizedCharges {
                        products {
                            catId
                            classId
                            rentalCost
                            unitRentalCost
                            quantity
                            rppCost
                            unitRPPCost
                            rppTax
                            isSerialized
                        }
                    }
                }
            }
            total_quantity
            items {
                __typename
                uid
                quantity
                prices {
                    price {
                        currency
                        value
                    }
                    row_total {
                        currency
                        value
                    }
                }
                product {
                    name
                    sku
                    product_page_url
                    ec_pc_inventory
                    isOverSize
                    isOverWeight
                    category_name
                    parent_category_name
                    category_path
                    productcatsortindex
                    disableaddtocartoption
                    showonlinecatalog
                    categories {
                        category_page_url
                        id
                        name
                    }
                    thumbnail {
                        url
                    }
                }
                ... on BundleCartItem {
                    bundle_options {
                        id
                        label
                        type
                        values {
                            id
                            label
                            price
                            quantity
                        }
                    }
                }
            }
        }
    }
`;
export default QUERY_CART_DETAILS;
