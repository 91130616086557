import { SET_LAT_LONG, SET_VIEW_CART_FIELDS } from '../aem-core-components/actions/constants';
import { useFilterState } from '../components/cap';
import { useCartState } from '../aem-core-components/components/Minicart';
import { VIEW_CART } from '../aem-core-components/components/Minicart/constants';
import { useUserContext } from '../aem-core-components/context/UserContext';
import { useCookieValue } from '../aem-core-components/utils/hooks';
import useReservation from '../components/checkout/hooks/useReservation';
import { isValidString } from '../components/global/utils/logger';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { useCheckUser } from './useCheckUser';
import { setFourHourRentals } from '../components/global/utils/commonUtils';
import { usePdpState } from '../contexts/pdp/pdpContext';

const useUserData = () => {
    const userType = useCheckUser();
    const [{ userProfile }] = useUserContext();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const [
        {
            userInfo,
            userAccount,
            guestUserDetails,
            cart,
            cartId,
            howToGetYourOrderDetails,
            homeAssetsRates
        }
    ] = useCartState();
    const [{ rates }] = usePdpState();
    const [settingsCookie, setSettingsCookie] = useCookieValue('Settings');

    const { estimatedTotal } = howToGetYourOrderDetails;
    const [{ getStartDate, getEndDate }] = useReservation();

    const ccCookies = [
        'SLLat',
        'SLLong',
        'SLA',
        'SLC',
        'SLS',
        'SLZ',
        'CurrentJobSite',
        'locationPC',
        'projectName',
        'projectAddress2',
        'primaryContactName',
        'phoneNumber',
        'accessNotes',
        'poNumber',
        'RMJobsiteId'
    ];

    const getRentalDuration = (startDate, endDate) => {
        return (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24);
    };

    const setUserDetails = () => {
        let userDetails = {};
        switch (userType) {
            case USER_TYPE.CASH:
            case USER_TYPE.CREDIT:
                userDetails = {
                    firstName: userProfile?.firstName || '',
                    lastName: userProfile?.lastName || '',
                    company: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1',
                    email: userProfile?.email || '',
                    phone: userProfile?.phone || '',
                    address: userProfile?.address1 || '',
                    city: userProfile?.city || '',
                    state: userProfile?.state || '',
                    country: userProfile?.country || '',
                    postalCode: userProfile?.postal_code || '' // check for canada also
                };

                break;
            case USER_TYPE.GUEST:
                userDetails = {
                    firstName: guestUserDetails?.firstName || '',
                    lastName: guestUserDetails?.lastName || '',
                    company: parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                    email: guestUserDetails?.email || '',
                    phone: guestUserDetails?.phoneNumber || '',
                    address: viewCart?.location || '',
                    city: viewCart?.jobSiteCity || '',
                    state: viewCart?.jobSiteState || '',
                    country: viewCart?.country || '',
                    postalCode: viewCart?.jobSiteState || '' // check for canada also
                };
                break;
            default:
                break;
        }
        return userDetails;
    };

    const setCartDetails = (cart, reservationNumber, isAddToCart) => {
        const itemsSKU = [];
        const itemsQuantity = {};
        const productName = {};
        const productsPayload = [];
        const conditionalPayload = isAddToCart
            ? {
                total: parseInt(estimatedTotal) || 0,
                reservationNumber: reservationNumber ? parseInt(reservationNumber) : 0,
                typeOfReservation: viewCart?.isInStorePickup ? 'instore' : 'delivery'
            }
            : {};
        cart?.items?.forEach(prod => {
            itemsSKU.push(prod.product.sku);
            itemsQuantity[prod.product.sku] = prod.quantity;
            productName[prod.product.sku] = prod.product.name;
            productsPayload.push({
                itemName: prod?.product?.name || '',
                requestID: 'qwerty123', // to do
                profileID: userProfile?.oktaUserId || '', // to do
                AccountId: userAccount?.accountNumber || '',
                cartID: cart?.id || '',
                catClassId: parseInt(prod?.product?.sku) || 0,
                catClassName: prod?.product?.name || '',
                categoryId: '', // to do
                categoryName: '', // to do
                orderDescription: '', // to do
                projectName: projectDetails?.projectName || '',
                quantityAdded: parseInt(prod?.quantity) || 0,
                rate: prod?.prices?.price?.value || '',
                rentalDuration: getRentalDuration(getStartDate(), getEndDate()),
                specialtyId: '', // to do
                userType: userType || '',
                ...conditionalPayload
            });
        });
        return productsPayload;
    };

    const getCartDetails = cartItems => {
        if (userInfo?.isAuthenticatedUser) {
            return {
                cartId,
                projectName: projectDetails?.projectName ? projectDetails?.projectName : undefined,
                oktaId: userProfile?.oktaUserId || '',
                items: cartItems?.map(item => {
                    return {
                        productId: item?.product?.sku,
                        productName: item?.product?.name,
                        quantityAdded: item?.quantity
                    };
                })
            };
        }
    };

    const getAssets = (cartData) => {
        const items = cartData ? cartData?.items : cart?.items;
        let assets = [];
        if (!items?.length) {
            //if cart is not available, for eg, on page load since cart call doesnt happen, use this storage to fetch cartdetails
            return JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA) || "[]") || []
        }
        items?.forEach((item, index) => {
            const { sku = '' } = item?.product;
            const quantity = item?.quantity;
            assets.push({
                productId: sku,
                quantity,
                sequenceNumber: index
            });
        });
        return assets;
    };

    //TODO : to check for rmJobsiteId
    const getProjectsFromCookie = () => {
        const projectObj = {};
        const projectsInfo = settingsCookie?.split('&');
        for (let i in projectsInfo) {
            let cookiePair = projectsInfo[i].split('=');
            projectObj[cookiePair[0]] = decodeURIComponent(cookiePair[1]);
        }
        return projectObj;
    };

    const clearProjectDetailCookies = () => {
        let settingsKey = [];
        let settingData = (settingsCookie && settingsCookie.split('&')) || [];
        let settingCookieData = '';
        for (let i in settingData) {
            let cookieKey = settingData[i].split('=');
            if (ccCookies.indexOf(cookieKey[0]) === -1) {
                settingsKey.push(settingData[i]);
            }
        }
        settingsKey.push(`CurrentJobSite=${encodeURIComponent('')}`);
        settingsKey.push(`locationPC=${encodeURIComponent('')}`);
        settingsKey.push(`projectName=${encodeURIComponent('')}`);
        settingsKey.push(`projectAddress2=${encodeURIComponent('')}`);
        settingsKey.push(`primaryContactName=${encodeURIComponent('')}`);
        settingsKey.push(`phoneNumber=${encodeURIComponent('')}`);
        settingsKey.push(`accessNotes=${encodeURIComponent('')}`);
        settingsKey.push(`poNumber=${encodeURIComponent('')}`);
        settingsKey.push(`SLLat=${encodeURIComponent('')}`);
        settingsKey.push(`SLLong=${encodeURIComponent('')}`);
        settingsKey.push(`SLA=${encodeURIComponent('')}`);
        settingsKey.push(`SLC=${encodeURIComponent('')}`);
        settingsKey.push(`SLS=${encodeURIComponent('')}`);
        settingsKey.push(`SLZ=${encodeURIComponent('')}`);
        settingsKey.push(`RMJobsiteId=${encodeURIComponent('')}`);
        settingCookieData = settingsKey.join('&');
        setSettingsCookie(settingCookieData);
    };

    const updateGeography = (pc, pcLat, pcLong, distance, specialtyTypes = [], localLat = '', localLong = '', assets = []) => {
        const NewAddress = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
        let browsePCObj =
            startDate &&
                endDate &&
                (isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0 || assets?.length > 0)
                ? STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ
                : STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ;
        sessionStorage.setItem(
            browsePCObj,
            JSON.stringify({
                pc: pc,
                lat: pcLat,
                long: pcLong,
                localLat:
                    userType == USER_TYPE.CREDIT && !NewAddress
                        ? projectDetails?.selectedProjectLatititude
                        : localLat || viewCart?.lat,
                localLong:
                    userType == USER_TYPE.CREDIT && !NewAddress
                        ? projectDetails?.selectedProjectLongitude
                        : localLong || viewCart?.long,
                distance: distance,
                specialtyTypes
            })
        );
        if (localLat && localLong && !(userType == USER_TYPE.CREDIT && !NewAddress)) {
            filterDispatch({ type: SET_LAT_LONG, lat: localLat, long: localLong });
        }
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.PC, value: pc });
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.PCLAT, value: pcLat });
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.PCLONG, value: pcLong });
        const viewCartFromStorage = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART)
            ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART))
            : viewCart;
        localStorage.setItem(
            STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART,
            JSON.stringify({
                ...viewCartFromStorage,
                [VIEW_CART.PC]: pc,
                [VIEW_CART.PCLAT]: pcLat,
                [VIEW_CART.PCLONG]: pcLong,
                [VIEW_CART.LAT]: userType == USER_TYPE.CREDIT && !NewAddress ? '' : localLat || viewCart?.lat,
                [VIEW_CART.LONG]: userType == USER_TYPE.CREDIT && !NewAddress ? '' : localLong || viewCart?.long
            })
        );
    };

    const updateFourHourRentals = sku => {
        const isPDP = document.querySelector('.block--pdp-hero')?.dataset;
        const pdpRatesObj = rates?.[0] || {};
        if (isPDP && pdpRatesObj?.productId == sku) {
            setFourHourRentals(pdpRatesObj?.rates?.suggestedRates, sku);
        } else if (Object.keys(homeAssetsRates).length > 0) {
            setFourHourRentals(homeAssetsRates[sku], sku);
        }
    };

    return [
        {
            setUserDetails,
            setCartDetails,
            getCartDetails,
            getAssets,
            getProjectsFromCookie,
            clearProjectDetailCookies,
            updateGeography,
            updateFourHourRentals,
        }
    ];
};

export default useUserData;
