import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { googleApiKey } from '../../utils/commonUtils';
import MapPinIcon from '../../../../resources/images/map-pin.svg';
import CurrentLocationMarker from '../../../../resources/images/current_location_green.svg';
import { MAP_RADIUS, MILES_TO_METER_CONVERSION_FACTOR } from '../../../../constants/screenConstants';
import './MapLocationMarker.scss';
import { GOOGLE_MAP_STYLES } from '../../../location/constants';

const Marker = props => {
    return (
        <>
            {props.mapIcon ? (
                <MapPinIcon className="mapIcon" />
            ) : (
                <button
                    tabIndex={'0'}
                    onClick={() => {
                        props.onClickMarker(props.pc);
                        props.saveStoreDetails(props.itemData);
                    }}
                    className={props.pc == props.selectedStoreValue ? 'markerSelected' : 'markerUnselected'}>
                    <p className={props.pc == props.selectedStoreValue ? 'itemTextSelected' : 'itemTextUnselected'}>
                        {props.itemNumber}
                    </p>
                </button>
            )}
        </>
    );
};

const MapLocationMarker = props => {
    const {
        selectedLatLong,
        storesData,
        selectedStoreValue,
        onClickMarker,
        defaultCenter,
        onStoreDetailsClick,
        saveStoreDetails,
        currentLocation
    } = props;
    const [zoomLevel, setZoomLevel] = useState(8);
    const [strictBounds, setStrictBounds] = useState('');

    const center = {
        lat: selectedLatLong.lat,
        lng: selectedLatLong.long
    };
    const defaultcenter = {
        lat: defaultCenter.lat,
        lng: defaultCenter.long
    };

    const getMapBounds = map => {
        let mapCenter = map.center;
        let circle = new google.maps.Circle({
            radius: MAP_RADIUS * MILES_TO_METER_CONVERSION_FACTOR,
            center: mapCenter
        });

        setStrictBounds(
            new google.maps.LatLngBounds(circle.getBounds().getSouthWest(), circle.getBounds().getNorthEast())
        );
    };

    const checkAllowedRadius = map => {
        if (!strictBounds) {
            getMapBounds(map);
        }

        if (strictBounds.contains(map.getCenter())) return;

        let c = map.getCenter(),
            x = c.lng(),
            y = c.lat(),
            maxX = strictBounds.getNorthEast().lng(),
            maxY = strictBounds.getNorthEast().lat(),
            minX = strictBounds.getSouthWest().lng(),
            minY = strictBounds.getSouthWest().lat();

        if (x < minX) x = minX;
        if (x > maxX) x = maxX;
        if (y < minY) y = minY;
        if (y > maxY) y = maxY;

        map.setCenter(new google.maps.LatLng(y, x));
    };

    const getMapOptions = maps => {
        if (props?.showMonochromaticMap) {
            return {
                styles: GOOGLE_MAP_STYLES,
                maxZoom: 15,
                minZoom: 7,
                fullscreenControl: false,
                zoomControlOptions: {
                    style: maps.ZoomControlStyle.SMALL,
                }
            };
        } else {
            return {
                maxZoom: 15,
                minZoom: 7,
                fullscreenControl: false,
                zoomControlOptions: {
                    style: maps.ZoomControlStyle.SMALL
                }
            };
        }
    };

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: googleApiKey }}
            // bootstrapURLKeys={{ key: 'AIzaSyDY6u8g5tPsfyZ_NST8Ne08OcA7IPlhJlw' }}
            defaultCenter={defaultcenter}
            center={center}
            zoom={zoomLevel}
            onGoogleApiLoaded={({ map }) => getMapBounds(map)}
            onDrag={map => checkAllowedRadius(map)}
            options={maps => getMapOptions(maps)}
            yesIWantToUseGoogleMapApiInternals
            // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            defaultZoom={12}>
            {!onStoreDetailsClick ? (
                storesData?.data.map((item, index) => {
                    return (
                        <Marker
                            key={index}
                            mapIcon={false}
                            lat={item.latitude}
                            itemNumber={index + 1}
                            itemData={item}
                            pc={item.pc}
                            onClickMarker={item => {
                                onClickMarker(item);
                            }}
                            selectedStoreValue={selectedStoreValue}
                            lng={item.longitude}
                            saveStoreDetails={saveStoreDetails}
                        />
                    );
                })
            ) : (
                <Marker
                    mapIcon={true}
                    lat={storesData.latitude}
                    itemData={storesData}
                    pc={storesData.pc}
                    onClickMarker={storesData => {
                        onClickMarker(storesData);
                    }}
                    selectedStoreValue={selectedStoreValue}
                    lng={storesData.longitude}
                />
            )}

            {currentLocation?.lat && currentLocation?.long && (
                <div lat={currentLocation?.lat} lng={currentLocation?.long} data-testid="current-location-marker">
                    <CurrentLocationMarker className="mapIcon" />
                </div>
            )}
        </GoogleMapReact>
    );
};

export default MapLocationMarker;
