import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Location from './overlays/Location';
import {
    POP_CART_STACK,
    SET_CART_OVERLAY,
    SET_IS_CART_LOADING,
    SET_IS_BULK_ATC_MODAL_OPEN,
    SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
    SET_OPTIONAL_PLANS_CHECKED,
    SET_SOURCES_LOADING_FLAG,
    SET_STATIC_TILE_PRODUCT_DETAILS,
    SET_VIEW_CART_FIELDS,
    UPDATE_PROJECT
} from '../../actions/constants';
import MUTATION_ADD_BUNDLE_TO_CART from '../../queries/mutation_add_bundle_to_cart.graphql';
import MUTATION_ADD_SIMPLE_AND_VIRTUAL_TO_CART from '../../queries/mutation_add_simple_and_virtual_to_cart.graphql';
import MUTATION_ADD_TO_CART from '../../queries/mutation_add_to_cart.graphql';
import MUTATION_ADD_VIRTUAL_TO_CART from '../../queries/mutation_add_virtual_to_cart.graphql';
import MUTATION_CREATE_CART from '../../queries/mutation_create_guest_cart.graphql';
import CART_DETAILS_QUERY from '../../queries/query_cart_details.graphql';
import { useAwaitQuery, useCookieValue, useEventListener } from '../../utils/hooks';
import { useCheckoutState } from '../Checkout/checkoutContext';
import Mask from '../Mask';
import { useCartState } from '../Minicart/cartContext';
import { CART_OVERLAYS, VIEW_CART } from './constants';
import Accounts from './overlays/Accounts';
import Cart from './overlays/Cart';
import Container from './overlays/Container';
import Estimates from './overlays/Estimates';
import RoundTripEstimate from './overlays/RoundTripEstimate';
import ViewCart from './overlays/ViewCart';
import useMinicart from './useMinicart';
import {
    checkIsInstorePickup,
    getProductAssetsList,
    getProjectsFromCookie,
    getURLParams,
    checkIsEditQuoteFlow,
    isGlobalRates,
    trapFocus
} from '../../../components/global/utils/commonUtils';
import { useDidMount } from '../../../hooks/useDidMount';
import { useUserContext } from '../../context/UserContext';
import useCartEstimate from './hooks/useCartEstimate';
import useComputeLocation from '../../../hooks/useComputeLocation';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import useAnalytics from '../../../hooks/useAnalytics';
import useMarketo from '../../../hooks/useMarketo';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import useUserData from '../../../hooks/useUserData';
import { useCapUtils } from '../../../components/cap/hooks/useCapUtils';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { HOME_PAGE } from '../../../constants/screenConstants';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { mktoLeadCreation } from '../../../../api/mktoLeadCreation';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { getCartDetails } from '../../actions/cart';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useAtp } from '../../../components/cap/hooks/useATP';
import { isValidString, logError, logWarning } from '../../../components/global/utils/logger';
import config from '../../../components/App/config';
import { usePricing } from '../../../hooks/usePricing';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import QUERY_PRODUCT_LIST from '../../queries/query_product_list';
import QUERY_PRODUCT_LIST_STATIC from '../../queries/query_product_list_static';
import { getProductList } from '../../actions/product/actions';
import { usePageType } from '../../../hooks/usePageType';
import { useFilterState } from '../../../components/cap';

const MiniCart = () => {
    const intl = useIntl();
    const [
        {
            cartStack,
            isCreditNewAddress,
            isEstimateOnCartOpenRequired,
            cartId,
            cashProfileLatLongUpdated,
            isCombinedTabLoading,
            selectedCombinedTabData,
            cidPcList,
            isCartLoading,
            isBulkAddToCartModalOpen,
            isItemPCAdding,
            isRatesLoading
        },
        cartDispatch
    ] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate, selectedStoreDetails }, filterDispatch] = useFilterState();
    const didMount = useDidMount();
    const [{ isProfileLoaded, isUserProfileLoading }, { resetUser, logoutUser }] = useUserContext();
    const [{ getEstimates, triggerEstimates, isSourcePCCallCriteria }] = useCartEstimate();
    const { getPC, fetchandUpdatePricingPCs } = useComputeLocation();
    const { sendEventsForEcommercePageData, sendEventsForViewItemList } = useAnalyticsContext();
    const [{ payloadEcommercePageData, payloadEcommerceViewItemList }] = useAnalytics();
    const [{ getCartDetailsForMarketo }] = useMarketo();
    const [{ updateFourHourRentals, getAssets }] = useUserData();
    const { getProductPrices } = usePricing();
    const productListQuery = useAwaitQuery(QUERY_PRODUCT_LIST);
    const staticProductsListQuery = useAwaitQuery(QUERY_PRODUCT_LIST_STATIC);
    const [createCartMutation] = useMutation(MUTATION_CREATE_CART);
    const [addToCartMutation] = useMutation(MUTATION_ADD_TO_CART);
    const [addVirtualItemMutation] = useMutation(MUTATION_ADD_VIRTUAL_TO_CART);
    const [addSimpleAndVirtualItemMutation] = useMutation(MUTATION_ADD_SIMPLE_AND_VIRTUAL_TO_CART);
    const [addBundleItemMutation] = useMutation(MUTATION_ADD_BUNDLE_TO_CART);
    const [{ flowState }] = useCheckoutState();
    const [showWarning, setShowWarning] = useState(false);
    const [disablCheckoutOnApiError, setDisablCheckoutOnApiError] = useState('');
    const [addProduct, setAddProduct] = useCookieValue('addProduct');
    const [seasonalProducts, setSeasonalProducts] = useState([]);
    const [popularProducts, setPopularProducts] = useState([]);
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const cookieAccount = getProjectsFromCookie();
    const params = getURLParams();
    const pageType = usePageType();
    const userType = useCheckUser();
    const { isRentalDetailsAvailable, isSelectedLocationEmpty } = useCheckLocationEmpty();
    const isCapFilled = isRentalDetailsAvailable();
    const { updateBsrPricingPCs } = useCapUtils();
    const isEditQuoteFlow = checkIsEditQuoteFlow();
    const { handleATPCart } = useAtp();
    const isFirstCartLoad = isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_FIRST_CART_LOAD));

    const [{ cart, isOpen, isLoading, isEditing, errorMessage }, { addItem, dispatch }] = useMinicart({
        queries: {
            createCartMutation,
            addToCartMutation,
            cartDetailsQuery,
            addVirtualItemMutation,
            addBundleItemMutation,
            addSimpleAndVirtualItemMutation
        }
    });
    const liveChatButton = document.querySelector('.chat-button');
    let aemProducts = document.querySelectorAll(config.mountingPoints.addToCartTile);

    useEffect(() => {
        /* First time loader true and minicart open based on flag */
        const ifItemsInCart = isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0;
        if (cartId && ifItemsInCart) {
            dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
            if (!isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA))) {
                getCartItems();
            }
        }
        if (JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISOPEN) || false)) {
            dispatch({ type: 'open' });
            sessionStorage.removeItem('isOpen');
        }
        return () => {
            logWarning(
                'isProfileLoad' +
                    isProfileLoaded +
                    'isCartLoading' +
                    isCartLoading +
                    'isEstimateOnOpenCartRequired' +
                    isEstimateOnCartOpenRequired
            );
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('overflow-hidden');
            trapFocus(document.querySelector('.minicart__body'));
            if (liveChatButton) {
                liveChatButton.ariaHidden = 'true';
                liveChatButton.tabIndex = '-1';
            }
        } else {
            if (liveChatButton) {
                liveChatButton.ariaHidden = 'false';
                liveChatButton.tabIndex = '0';
            }
        }
    }, [isOpen]);

    useEffect(() => {
        filterDispatch({
            type: SET_VIEW_CART_FIELDS,
            key: 'showDeliveryEstimate',
            value: !viewCart?.isInStorePickup
        });
    }, [viewCart?.isInStorePickup]);

    useEventListener(document, 'aem.cif.open-cart', () => {
        dispatch({ type: 'open' });
    });
    useEventListener(document, 'aem.cif.add-to-cart', addItem);

    const isEmpty = cart && Object.entries(cart)?.length > 0 ? cart?.items?.length === 0 : true;
    const showFooter = !(isLoading || isEmpty || isEditing || errorMessage || isCartLoading) || flowState === 'receipt';

    const onClickHandler = () => {
        document.body.classList.remove('overflow-hidden');
        dispatch({ type: 'close' });
        if (cartStack[cartStack.length - 1] === CART_OVERLAYS.LOCATION) {
            dispatch({ type: POP_CART_STACK });
        }
    };

    const setPcInContext = pcVal => {
        const isEnterAddress = JSON.parse(localStorage.getItem('isCreditNewAddress'));
        if (cookieAccount?.CurrentWynneAccount && !isEnterAddress && userType === USER_TYPE.CREDIT) {
            filterDispatch({
                type: UPDATE_PROJECT,
                ...projectDetails,
                locationPC: pcVal
            });
        } else {
            filterDispatch({ type: SET_VIEW_CART_FIELDS, key: 'pc', value: pcVal });
        }
    };

    const sourcePCAndEstimateCall = async () => {
        dispatch({ type: 'beginLoading' });
        if (isSourcePCCallCriteria()) {
            cartDispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: true
            });
            const { pcVal, pcLat, pcLong } = await getPC();
            await upateSourcePcInContext(pcVal, pcLat, pcLong);
        }
        dispatch({ type: 'endLoading' });
    };

    const upateSourcePcInContext = async (pcVal, pcLat, pcLong) => {
        if (pcVal) {
            setPcInContext(pcVal);
            setDisablCheckoutOnApiError('');
            dispatch({
                type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                key: 'isEstimateOnCartOpenRequired',
                value: true
            });
            if (isOpen) {
                await getEstimates(undefined, pcVal, pcLat, pcLong);
            }
            //to handle checkout source call if not called
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: false
            });
        } else {
            setPcInContext(0);
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: false
            });
            setDisablCheckoutOnApiError(intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-2' }));
        }
    };

    const initialiseAuthenticatedCart = async () => {
        if (
            userType !== USER_TYPE.GUEST &&
            !cart?.id &&
            !sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_FIRST_CART_LOAD)
        ) {
            let estimatesResponse = await getCartDetails({
                cartDetailsQuery,
                dispatch,
                cartId,
                filterDispatch,
                handleATPCart
            });
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_FIRST_CART_LOAD, true);
            /* call sources API on inital login */
            if (startDate && endDate && estimatesResponse?.items?.length > 0) {
                const { pcVal, pcLat, pcLong } = await getPC('', '', getAssets(estimatesResponse));
                await upateSourcePcInContext(pcVal, pcLat, pcLong);
            }
            dispatch({ type: 'endLoading' });
        }
    };

    useEffect(() => {
        if (!isUserProfileLoading) {
            if (viewCart?.showDeliveryEstimate) {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, false);
                filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.IN_STORE, value: false });
            } else {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, checkIsInstorePickup());
                filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.IN_STORE, value: checkIsInstorePickup() });
            }
        }
    }, [userType, isUserProfileLoading]);

    useEffect(() => {
        let isCombinedTabs = true;
        if (!ENV_CONFIG.COMBINED_TABS) {
            isCombinedTabs = false;
        }
        if (
            (!isCombinedTabLoading || !isCombinedTabs) &&
            isGlobalRates() &&
            pageType.toLowerCase() === HOME_PAGE.toLowerCase()
        ) {
            let assetsOnHomePage = getProductAssetsList() || [];
            let combinedSelectedTabAssets = selectedCombinedTabData || [];
            let totalItems = [...new Set([...assetsOnHomePage, ...combinedSelectedTabAssets])];
            sendEventsForEcommercePageData(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATA,
                payloadEcommercePageData([], totalItems?.length)
            );
        }
    }, [isCombinedTabLoading]);

    useEffect(() => {
        updateBsrPricingPCs();
    }, [startDate, projectDetails?.selectedProjectLatititude, viewCart?.lat, viewCart?.isInStorePickup]);

    useEffect(() => {
        const fn = async () => {
            if (
                !disablCheckoutOnApiError &&
                ((viewCart?.lat && viewCart?.long) ||
                    (projectDetails?.selectedProjectLatititude && projectDetails?.selectedProjectLongitude)) &&
                isProfileLoaded &&
                cartId
            ) {
                sourcePCAndEstimateCall();
            }
        };
        fn();
    }, [
        startDate,
        endDate,
        isProfileLoaded,
        isCreditNewAddress,
        projectDetails?.selectedProjectLatititude,
        viewCart?.lat,
        cashProfileLatLongUpdated,
        selectedStoreDetails?.pc,
        viewCart?.isInStorePickup
    ]);

    useEffect(() => {
        const loadCart = async () => {
            if (!cart?.id) {
                getCartItems();
            }
        };
        if (userType === USER_TYPE.GUEST || isFirstCartLoad) {
            if (isOpen && cartId && isProfileLoaded) {
                loadCart();
            }
        }
    }, [isOpen, cartId, isProfileLoaded]);

    const isCartOpenOrIsEditQuote = (ifItemsInCart) => {
        return (isOpen && ifItemsInCart) || (isEditQuoteFlow)
    }

    useEffect(() => {
        async function fn() {
            if (!addProduct && isEstimateOnCartOpenRequired) {
                cartDispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
                if (isSelectedLocationEmpty()) {
                    await getCartDetails({
                        cartDetailsQuery,
                        dispatch,
                        cartId,
                        filterDispatch,
                        handleATPCart
                    });
                    cartDispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
                    dispatch({
                        type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                        key: 'isEstimateOnCartOpenRequired',
                        value: false
                    });
                } else {
                    getEstimates();
                }
            }
        }
        const ifItemsInCart = isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0;
        if (isProfileLoaded) {
            if (cart?.id && isCartOpenOrIsEditQuote(ifItemsInCart)) {
                fn();
            } else if (!cartId && ifItemsInCart) {
                if (userType === USER_TYPE.GUEST) {
                    resetUser();
                    window.location.reload();
                } else {
                    logoutUser();
                }
            }
        }
    }, [isOpen, cart?.id, isEstimateOnCartOpenRequired, isProfileLoaded]);

    useEffect(() => {
        if (!isUserProfileLoading) {
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY, isCreditNewAddress);
        }
    }, [isCreditNewAddress, isUserProfileLoading]);

    useEffect(() => {
        if (
            isProfileLoaded &&
            (pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE ||
                pageType === VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE)
        ) {
            if (isRentalDetailsAvailable()) {
                if (cidPcList?.length > 0) {
                    callRatesForStaticItems();
                }
            } else {
                let products = [...aemProducts];
                const { seasonalList, popularList } = extractListsFromProducts(products);
                setPopularProducts(popularList);
                setSeasonalProducts(seasonalList);
            }
        }
    }, [isProfileLoaded, cidPcList]);

    useEffect(() => {
        if (pageType === VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE && (!isRatesLoading || !isCapFilled)) {
            if (popularProducts?.length > 0) {
                sendEventsForViewItemList(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_VIEW_ITEM_LIST,
                    payloadEcommerceViewItemList(popularProducts)
                );
            }
            if (seasonalProducts?.length > 0) {
                sendEventsForViewItemList(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_VIEW_ITEM_LIST,
                    payloadEcommerceViewItemList(seasonalProducts)
                );
            }
        }
    }, [pageType, isRatesLoading, isCapFilled, popularProducts, seasonalProducts]);

    const getCartItems = async () => {
        await getCartDetails({
            cartDetailsQuery,
            dispatch,
            cartId,
            filterDispatch,
            handleATPCart
        });
        if (!isEstimateOnCartOpenRequired) {
            cartDispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
        }
    };

    const updateSeasonalAndPopularList = (productDetails, seasonalList, popularList) => {
        try {
            productDetails?.forEach(detail => {
                const seasonalMatchIndex = seasonalList?.findIndex(product => product?.catclass === detail?.catclass);
                const popularMatchIndex = popularList?.findIndex(product => product?.catclass === detail?.catclass);

                if (seasonalMatchIndex !== -1) {
                    seasonalList[seasonalMatchIndex].ec_pc_inventory = detail?.ec_pc_inventory;
                    seasonalList[seasonalMatchIndex].pc_availability = detail?.pc_availability;
                } else if (popularMatchIndex !== -1) {
                    popularList[popularMatchIndex].ec_pc_inventory = detail?.ec_pc_inventory;
                    popularList[popularMatchIndex].pc_availability = detail?.pc_availability;
                }
            });
        } catch (error) {
            logError(error, false, 'updateSeasonalAndPopularList');
        }
    };
    /* To do - need to refrator the function later by removing old rates call if not required in global check*/

    const extractListsFromProducts = products => {
        var skuList = [];
        let seasonalList = [];
        let popularList = [];

        for (let i = 0; i < products?.length; i++) {
            if (products[i]?.dataset?.productdata) {
                var productData = JSON.parse(products[i]?.dataset?.productdata);
                skuList.push(productData.catclass);
                const containerAncestor = products[i]?.closest('.container');
                if (containerAncestor) {
                    const firstChild = containerAncestor?.children[0]?.firstElementChild;
                    if (firstChild?.textContent?.trim() === VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME) {
                        productData.listName = VARIABLE_CONFIG.PRODUCT_CARD.POPULAR_LIST_NAME;
                        popularList.push(productData);
                    } else {
                        productData.listName = VARIABLE_CONFIG.PRODUCT_CARD.SEASONAL_LIST_NAME;
                        seasonalList.push(productData);
                    }
                }
            }
        }
        return { skuList, seasonalList, popularList };
    };

    const callRatesForStaticItems = async () => {
        try {
            let products = [...aemProducts];

            const { skuList, seasonalList, popularList } = extractListsFromProducts(products);

            const midpoint = Math.ceil(products?.length / 2);
            if (skuList?.length) {
                const skuListFirstHalf = skuList?.slice(0, midpoint);
                const skuListSecondHalf = skuList?.slice(midpoint);

                const [firstResponse, secondResponse] = await Promise.allSettled([
                    getProductList({
                        productListQuery: staticProductsListQuery,
                        skuList: skuListFirstHalf,
                        pageSize: skuListFirstHalf?.length,
                        handleATPCart
                    }),
                    getProductList({
                        productListQuery: staticProductsListQuery,
                        skuList: skuListSecondHalf,
                        pageSize: skuListSecondHalf?.length,
                        handleATPCart
                    })
                ]);
                const productDetails = firstResponse?.value?.productDetails.concat(
                    secondResponse?.value?.productDetails
                );
                cartDispatch({ type: SET_STATIC_TILE_PRODUCT_DETAILS, staticTileProductDetails: productDetails });
                if (productDetails?.length > 0) {
                    getProductPrices(productDetails, pageType);
                    updateSeasonalAndPopularList(productDetails, seasonalList, popularList);
                    if (popularList?.length > 0) {
                        setPopularProducts(popularList);
                    }
                    if (seasonalList?.length > 0) {
                        setSeasonalProducts(seasonalList);
                    }
                }
            }
        } catch (error) {
            logError(error, false, 'callRatesForStaticItems');
        }
    };

    useEffect(() => {
        const updatePricingPCs = async () => {
            if (projectDetails?.selectedProjectLatititude || viewCart?.lat) {
                await fetchandUpdatePricingPCs();
                if (isValidString(ENV_CONFIG.IS_ATP_DISABLED) || !startDate || !endDate) {
                    triggerEstimates();
                }
                dispatch({ type: SET_CART_OVERLAY, cartOverlay: CART_OVERLAYS.VIEW_CART });
            }
        };

        if (isProfileLoaded) {
            /* To identify for merge cart scenario */
            initialiseAuthenticatedCart();
            updatePricingPCs();
        }
    }, [isProfileLoaded, projectDetails?.selectedProjectLatititude, viewCart?.lat]);

    useEffect(() => {
        /* call estimates for added product */
        const callEstimates = async () => {
            try {
                if (didMount && isProfileLoaded) {
                    if (cart?.total_quantity > 0) {
                        //default value is undefined on page load therefore explicit false check, dont change to !isItemPCAdding
                        if (isItemPCAdding == false) {
                            //to handle addtocart in AEM
                            updateFourHourRentals(cart?.items[0]?.product?.sku);
                            getEstimates();
                            // sourcePCAndEstimateOutsideCart()
                        } else if (addProduct) {
                            //to handle command center addtocart
                            if (isSelectedLocationEmpty()) {
                                cartDispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
                                dispatch({
                                    type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                                    key: 'isEstimateOnCartOpenRequired',
                                    value: false
                                });
                            } else {
                                if (startDate && endDate) {
                                    sourcePCAndEstimateCall();
                                } else {
                                    triggerEstimates();
                                }
                            }
                            setAddProduct('', 0);
                        }
                    }
                }
            } catch (error) {
                logError(error, false, 'getEstimates');
            }
        };
        callEstimates();
    }, [cart?.total_quantity, isItemPCAdding]);

    useEffect(() => {
        if (isBulkAddToCartModalOpen) {
            getEstimates();
            dispatch({
                type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                key: 'isEstimateOnCartOpenRequired',
                value: false
            });
            dispatch({ type: SET_IS_BULK_ATC_MODAL_OPEN, isBulkAddToCartModalOpen: false });
        }
    }, [isBulkAddToCartModalOpen]);

    useEffect(() => {
        const isCartUpdatedWithPrice = JSON.parse(
            sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CART_UPDATED_WITH_PRICE) || false
        );
        if (cart?.id && !isLoading && isCartUpdatedWithPrice) {
            mktoLeadCreation(getCartDetailsForMarketo());
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CART_UPDATED_WITH_PRICE);
        }
    }, [isLoading, cart?.id]);

    useEffect(() => {
        dispatch({
            type: SET_OPTIONAL_PLANS_CHECKED,
            isRPPChecked: userType === USER_TYPE.CREDIT ? false : true,
            isFuelChargeChecked: false
        });
    }, [userType]);

    useEffect(() => {
        async function getCartDetailsFunction() {
            if (addProduct) {
                cartDispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
                dispatch({
                    type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                    key: 'isEstimateOnCartOpenRequired',
                    value: true
                });
                await getCartDetails({ cartDetailsQuery, cartId, dispatch, filterDispatch, handleATPCart });
            }
        }
        getCartDetailsFunction();
    }, [addProduct]);

    const renderViewScreen = () => {
        return (
            <Container isOpen={isOpen} showWarning={showWarning} setShowWarning={setShowWarning}>
                <ViewCart
                    showFooter={showFooter}
                    disablCheckoutOnApiError={disablCheckoutOnApiError}
                    setDisablCheckoutOnApiError={setDisablCheckoutOnApiError}
                />
            </Container>
        );
    };
    const renderCartView = () => {
        switch (cartStack[cartStack.length - 1]) {
            case CART_OVERLAYS.VIEW_CART:
                return renderViewScreen();
            case CART_OVERLAYS.ACCOUNT:
                return (
                    <Container isOpen={isOpen}>
                        <Accounts />
                    </Container>
                );
            case CART_OVERLAYS.LOCATION:
                return (
                    <Container isOpen={isOpen}>
                        <Location />
                    </Container>
                );
            case CART_OVERLAYS.ROUND_TRIP:
                return (
                    <Container isOpen={isOpen}>
                        <RoundTripEstimate />
                    </Container>
                );
            case CART_OVERLAYS.CART:
                return (
                    <Container isOpen={isOpen}>
                        <Cart />
                    </Container>
                );
            case CART_OVERLAYS.ESTIMATE:
                return (
                    <Container isOpen={isOpen}>
                        <Estimates />
                    </Container>
                );
            default:
                return renderViewScreen();
        }
    };
    return (
        <>
            <Mask
                isOpen={isOpen}
                onClickHandler={onClickHandler}
                ariaLabel={intl.formatMessage({ id: 'itemAvailability-modal:clickToClose' })}
            />
            {renderCartView()}
        </>
    );
};

export default MiniCart;
