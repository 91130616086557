import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { bool, number, object, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import config from '../../../components/App/config';
import { useFilterState } from '../../../components/cap';
import Button from '../../../components/global/atoms/button/button';
import HigherFeeWarningAlert from '../../../components/global/atoms/higherFeeWarningAlert/HigherFeeWarningAlert';
import MaxQtyErrorComponent from '../../../components/global/atoms/maxQtyErrorComponent';
import ProductImage from '../../../components/global/atoms/productImage/ProductImage';
import { AUTHORITY_TYPE } from '../../../components/global/constants';
import { PRODUCT_CHANGE_STATUS } from '../../../components/global/modules/rentalChangeConfirmationModal/constants';
import ProductStatusChangeAlert from '../../../components/global/modules/rentalChangeConfirmationModal/productStatusChangeAlert/ProductStatusChangeAlert';
import { getRentalDuration } from '../../../components/global/utils/commonUtils';
import { logError } from '../../../components/global/utils/logger';
import { QTY_LIMIT } from '../../../components/pdp/constants';
import QuantitySelector from '../../../components/pdp/quantitySelector/QuantitySelector';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import useUserData from '../../../hooks/useUserData';
import { SET_IS_CART_LOADING, SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED } from '../../actions/constants';
import { useUserContext } from '../../context/UserContext';
import MUTATION_UPDATE_CART_ITEM from '../../queries/mutation_update_cart_item.graphql';
import CART_DETAILS_QUERY from '../../queries/query_cart_details.graphql';
import { useAwaitQuery } from '../../utils/hooks';
import Price from '../Price';
import { useCartState } from './cartContext';
import { miniCartDatalocator } from './dataLocators';
import useCartEstimate from './hooks/useCartEstimate';
import classes from './product.css';
import useCartOptions from './useCartOptions';
import useProduct from './useProduct';

const imageWidth = 56;
const imageHeight = 56;

const Product = props => {
    const intl = useIntl();
    const { item, index, cartLength, pcAvaibility, categories, isAvailableWithWarning } = props;
    const [updateCartItemMutation] = useMutation(MUTATION_UPDATE_CART_ITEM);
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const [{}, { dispatch, updateCartFromMinicart }] = useCartOptions({
        updateCartItemMutation,
        cartDetailsQuery
    });
    const { product = {}, uid = '', prices } = item;
    const { thumbnail, name, product_page_url } = product;
    const [, { removeItem }] = useProduct({ item });
    const [parentLink, setParentLink] = useState('');
    let { row_total } = prices;
    const [{ cart }] = useCartState();
    const [{ viewCart, startDate, endDate }] = useFilterState();
    const [{ updateFourHourRentals }] = useUserData();
    const { isSelectedLocationEmpty } = useCheckLocationEmpty();
    const authorityType = useCheckAuthorityType();
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const userType = useCheckUser();
    const [{ userProfile }, { dispatch: userDispatch }] = useUserContext();
    const {
        sendEventsForEcommerceRemove,
        sendEventsForEcommerceAdd,
        sendEventsForEcommerceProductClick,
        sendEventsForAlternateInventoryView
    } = useAnalyticsContext();
    const [{ isEstimatesWithOwnedPc }] = useCartEstimate();
    const isP2P = authorityType === AUTHORITY_TYPE.P2P;

    let alertStatus = '';
    if (!pcAvaibility) {
        alertStatus = PRODUCT_CHANGE_STATUS.UNAVAILABLE;
    }

    const cartitem = orderEstimates?.itemizedCharges?.products.find(product => {
        return `${product?.catId}${product?.classId}` === item.product.sku;
    });

    useEffect(() => {
        let link = categories ? categories[categories?.length - 2]?.category_page_url : '';
        link = link ? config.pagePaths.originURL + link : '';
        setParentLink(link);
    }, [categories]);

    const getProductPayload = quantity => {
        return [
            {
                id: item?.product?.sku,
                name: item?.product?.name,
                price: parseFloat(item?.prices?.row_total?.value).toFixed(2),
                categories: item?.product?.category_path || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                category:
                    item?.product?.productcategoryname ||
                    item?.product?.parent_category_name ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                subcategory: item?.product?.category_name || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                dimension38: getRentalDuration(startDate, endDate) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                dimension31: item?.prices?.row_total?.value,
                dimension32: item?.prices?.row_total?.value * 7,
                dimension33: item?.prices?.row_total?.value * 31,
                sku: item?.product?.sku,
                dimension41: -1,
                list: VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                position: parseInt(item?.product?.position) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                quantity: quantity
            }
        ];
    };

    const handleRemoveMergeCartError = () => {
        if (userProfile?.skip_qty[item?.product?.sku]) {
            const skuQtyCopy = { ...userProfile?.skip_qty };
            delete skuQtyCopy[item?.product?.sku];
            userDispatch({ type: 'updateSkipQtyObject', skipQtyObj: skuQtyCopy });
        }
    };

    const handleRemoveItem = useCallback(() => {
        try {
            sendEventsForEcommerceRemove(VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART, getProductPayload(item?.quantity));
        } catch (error) {
            logError(error, false, 'handleRemoveItem');
        }
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
        removeItem(uid);
        handleRemoveMergeCartError();
    }, [uid, removeItem]);

    const navigateToPdp = () => {
        window.location.href = window.location.origin + product_page_url;
    };

    const handleOnChange = async newVal => {
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
        await updateCartFromMinicart([{ cart_item_uid: uid, quantity: newVal }]);
        handleRemoveMergeCartError();
        dispatch({
            type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
            key: 'isEstimateOnCartOpenRequired',
            value: true
        });
        if (newVal - item?.quantity < 0) {
            try {
                sendEventsForEcommerceRemove(
                    VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                    getProductPayload(Math.abs(newVal - item?.quantity))
                );
            } catch (error) {
                logError(error, false, 'handleOnChange');
            }
        } else {
            try {
                sendEventsForEcommerceAdd(
                    VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                    getProductPayload(newVal - item?.quantity)
                );
                updateFourHourRentals(item?.product?.sku);
            } catch (error) {
                logError(error, false, 'handleOnChange');
            }
        }
        // setQuantity(parseInt(newVal));
        // setTotal(newVal * row_total.value);
    };
    var mockQtys = [];
    for (var i = 1; i <= 25; i++) {
        mockQtys.push({ value: i });
    }

    const navigateToPdpOnProduct = () => {
        try {
            if (userType !== USER_TYPE.CREDIT) {
                sendEventsForEcommerceProductClick(
                    VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                    getProductPayload(item?.quantity)
                );
            }
        } catch (error) {
            logError(error, false, 'navigateToPdpOnProduct');
        }

        navigateToPdp();
    };

    const handleClickEventViewSimilarItems = () => {
        sendEventsForAlternateInventoryView(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
            intl.formatMessage({ id: 'cart:view-similar-items-text' }),
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_VIEW_SIMILAR_ITEMS_LINK_TYPE,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_VIEW_SIMILAR_ITEMS_LINK_LOCATION,
            parentLink
        );
    };

    return (
        <li className={`${classes.root} ${!pcAvaibility && classes.unavailableProduct}`} data-testid="cart-item">
            <div
                className={classes.productImage}
                onClick={navigateToPdpOnProduct}
                data-testid={miniCartDatalocator.product_image_testid}>
                <ProductImage
                    url={thumbnail?.url}
                    width={imageWidth}
                    height={imageHeight}
                    name={name}
                    className={classes.image}
                    defaultViewCardType="minicart"
                />
            </div>
            <div className={classes.productDesc}>
                <div
                    tabIndex="0"
                    className={`${classes.name} ${!pcAvaibility && classes.title}`}
                    onClick={navigateToPdpOnProduct}
                    aria-label={`${name} item ${index} of ${cartLength}`}
                    data-testid={miniCartDatalocator.product_name_testid}>
                    {name}
                </div>
                {pcAvaibility ? (
                    <>
                        <div className={classes.quantity}>
                            <QuantitySelector
                                field="quantity"
                                qty={item.quantity}
                                setQty={handleOnChange}
                                limit={QTY_LIMIT}
                                testid={miniCartDatalocator.quantity_dropdown_testid}
                                className={classes.quantitySelector}
                            />
                            <Button
                                buttonAriaLabel={intl.formatMessage({ id: 'cart:remove-text' })}
                                className={`${classes.removeItem} button button-outline-primary button-pb0`}
                                onMouseDown={handleRemoveItem}
                                dataTestid={miniCartDatalocator.remove_cta_testid}>
                                {intl.formatMessage({ id: 'cart:remove-text' })}
                            </Button>
                        </div>
                        {!isSelectedLocationEmpty() ? (
                            <div className={classes.rowTotalRow}>
                                <Price
                                    currencyCode={row_total.currency}
                                    value={
                                        isEstimatesWithOwnedPc()
                                            ? row_total.value
                                            : cartitem?.rentalCost || row_total.value
                                    }
                                />
                                {!(endDate && startDate) && (
                                    <span className={classes.perDay}>
                                        {intl.formatMessage({ id: 'cart:per-day-text' })}
                                    </span>
                                )}
                            </div>
                        ) : (
                            <small
                                className={classes.noLocationMessage}
                                data-testid={miniCartDatalocator.no_location_message_testid}>
                                {intl.formatMessage({ id: 'cart:enter-location-for-price' })}
                            </small>
                        )}
                        <MaxQtyErrorComponent
                            skipQtyObj={userProfile?.skip_qty}
                            sku={item?.product?.sku}
                            isMergeCart={true}
                        />
                    </>
                ) : (
                    <div className={classes.availability}>
                        <Button
                            buttonAriaLabel={intl.formatMessage({ id: 'cart:remove-text' })}
                            className={` button button-outline-primary button-pb0 ${classes.buttonMargin}`}
                            onMouseDown={handleRemoveItem}
                            dataTestid={miniCartDatalocator.remove_cta_testid}>
                            {intl.formatMessage({ id: 'cart:remove-text' })}
                        </Button>
                        <a
                            tabIndex={0}
                            className={`button button-outline-primary button-pb0`}
                            data-testid={miniCartDatalocator.view_similar_cta_testid}
                            onClick={handleClickEventViewSimilarItems}
                            href={parentLink}>
                            {intl.formatMessage({ id: 'cart:view-similar-items-text' })}
                        </a>
                    </div>
                )}
            </div>
            {isAvailableWithWarning && isP2P && <HigherFeeWarningAlert className={classes.productAlertWrap} productName={item?.product?.name}/>}
            {isP2P && !viewCart?.isInStorePickup
                ? null
                : alertStatus && (
                      <div className={classes.productAlertWrap}>
                          <ProductStatusChangeAlert
                              status={alertStatus}
                              isProductStatusChanged
                              className={alertStatus === PRODUCT_CHANGE_STATUS.UNAVAILABLE ? classes.productAlert : ''}
                          />
                      </div>
                  )}
        </li>
    );
};

Product.propTypes = {
    item: shape({
        uid: string.isRequired,
        quantity: number.isRequired,
        prices: shape({
            price: shape({
                value: number.isRequired,
                currency: string.isRequired
            }).isRequired,
            row_total: shape({
                value: number.isRequired,
                currency: string.isRequired
            }).isRequired
        }).isRequired,
        product: shape({
            name: string.isRequired,
            image: object
        })
    })
};

export default Product;
