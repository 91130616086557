import React from 'react';
import { oneOf, string, func, bool, oneOfType, number } from 'prop-types';
import { Field } from '../../../../aem-core-components';
import AlertIcon from '../../../../resources/images/alert-triangle-red.svg';

import './input.scss';
const Input = props => {
    const {
        label,
        name,
        type,
        handleInputChange,
        handleInputBlur,
        handleInputFocus,
        classes,
        value,
        errorMsg,
        placeholder,
        maxLength,
        pattern,
        dataTestId,
        lbltestid,
        inputAriaLabel,
        inputAriaRequired,
        handleKeyDown,
        errorId,
        disclaimerMsg,
        isCCAErrorIcon,
        required,
        inputRef,
        ...restProps
    } = props;

    return (
        <Field
            label={label}
            htmlFor={name}
            lbltestid={lbltestid}
            errorMsg={errorMsg}
            required={required}
            fieldCustomClass={restProps?.fieldcustomclass && restProps.fieldcustomclass}>
            <input
                id={name}
                className={`cmp-Field__field__input ${classes} ${errorMsg ? 'error_input__border' : ''}`}
                type={type}
                name={name}
                value={value}
                ref={inputRef}
                placeholder={placeholder}
                maxLength={maxLength}
                pattern={pattern}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyDown={handleKeyDown}
                onFocus={handleInputFocus}
                data-testid={dataTestId}
                autoComplete="off"
                aria-label={inputAriaLabel}
                aria-required={inputAriaRequired}
                aria-invalid={errorMsg ? true : false}
                aria-describedby={errorMsg ? errorId : ''}
                {...restProps}
            />
            {errorMsg ? (
                <span
                    className={`error_input ${restProps?.errorIconClass}`}
                    id={errorId}
                    role="alert"
                    tabIndex="0"
                    aria-atomic="true">
                    {isCCAErrorIcon ? <AlertIcon tabIndex="-1" aria-hidden={true} /> : ''}
                    {isCCAErrorIcon ? (
                        <span className="error_input__span" role="alert" tabIndex="0">
                            {errorMsg}
                        </span>
                    ) : (
                        errorMsg
                    )}
                </span>
            ) : (
                ''
            )}
            {disclaimerMsg && <span className="disclaimer_msg">{disclaimerMsg}</span>}
        </Field>
    );
};

Input.propTypes = {
    type: oneOf(['text', 'password', 'submit', 'button', 'reset', 'number']),
    errorMsg: string,
    label: string,
    name: string,
    placeholder: string,
    value: oneOfType([string, number]),
    pattern: string,
    handleInputChange: func,
    handleInputFocus: func,
    handleInputBlur: func,
    handleKeyDown: func,
    classes: string,
    inputAriaLabel: string,
    readOnly: bool,
    inputAriaRequired: bool,
    disclaimerMsg: string,
    isCCAErrorIcon: bool,
    required: bool
};

Input.defaultProps = {
    type: 'text',
    errorMsg: '',
    label: '',
    name: '',
    placeholder: '',
    value: '',
    pattern: null,
    classes: '',
    inputAriaLabel: '',
    handleInputChange: () => {},
    handleInputBlur: () => {},
    handleInputFocus: () => {},
    handleKeyDown: () => {},
    readOnly: false,
    inputAriaRequired: false,
    disclaimerMsg: '',
    isCCAErrorIcon: false,
    required: false
};
export default Input;
