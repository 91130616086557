import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import Button from '../global/atoms/button';
import Alert from '../global/atoms/alert/alert';
import CardRadioButton from '../global/atoms/cardRadioButton';
import useComputeLocation from '../../hooks/useComputeLocation';
import ToggleTabs from '../global/modules/ToggleTabs/ToggleTabs';
import AnimatedHeaderModal from '../global/modules/animatedHeaderModal';
import { useCartState } from '../../aem-core-components/components/Minicart';
import { useUserContext } from '../../aem-core-components/context/UserContext';
import ChooseAccount from '../../aem-core-components/components/Minicart/chooseAccount';
import NewAddress from '../../aem-core-components/components/Minicart/locations/newAddress';
import { getlatLongByAddress } from '../global/modules/location-autocomplete/api/getLocations';
import AccountDetails from '../../aem-core-components/components/Minicart/locations/accountDetails';
import ProjectListing from '../../aem-core-components/components/Minicart/locations/projectListing';
import config from '../App/config';
import { VIEW_CART } from '../../aem-core-components/components/Minicart/constants';
import useMedia from '../../hooks/useMedia';
import {
    SET_CREDIT_NEWADDR_FLAG,
    SET_LOCATION_DATA,
    SET_VIEW_CART_FIELDS,
    UPDATE_PROJECT,
    UPDATE_PUNCHOUT_USER_DETAILS
} from '../../aem-core-components/actions/constants';
import SBRLogoHorz from '../../resources/images/logo-horizontal.svg';
import classes from '../../aem-core-components/components/Minicart/locations/existingAccount.css';
import './punchoutUser.scss';
import { isAccountBlocked, isAccountClosed } from '../global/utils/commonUtils';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { useFilterState } from '../cap';

const PunchoutUser = () => {
    const intl = useIntl();
    const mediaType = useMedia();
    const [userState] = useUserContext();
    const [{ userAccount, isCreditNewAddress }, dispatch] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const punchoutUserData = sessionStorage.getItem('punchoutUserData')
        ? JSON.parse(sessionStorage.getItem('punchoutUserData'))
        : {};
    const { computeInstore, handleManualUpdatedInstoreAddress, updateManualLocationInContext } = useComputeLocation();
    const { userProfile, accountProjects, isProjectsLoading } = userState;
    const [manualAddress, setManualAddress] = useState('');
    const [selectedButton, setSelectedButton] = useState(true);
    const [searchErrorText, setSearchErrorText] = useState('');
    const [locationByPlaces, setLocationByPlaces] = useState('');
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [selectedProjectData, setSelectedProjectData] = useState({});
    const [autoCompleteErrorMsg, setAutoCompleteErrorMsg] = useState('');
    const [saveAndContinueError, setSaveAndContinueError] = useState('');
    const [projectList, setProjectList] = useState(accountProjects || []);
    const [autoCompleteAddressSelected, setAutoCompleteAddressSelected] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [isAccountSelected, setIsAccountSelected] = useState(userAccount?.accountNumber || false);
    const [addressChangedByGeocode, setAddressChangedByGeocode] = useState(false);
    const [globalLocation, setGlobalLocation] = useState({
        location: viewCart?.location || '',
        lat: viewCart?.lat || '',
        long: viewCart?.long || '',
        jobSiteAddr2: viewCart?.jobSiteAddr2 || '',
        jobSiteCity: viewCart?.jobSiteCity || '',
        jobSiteState: viewCart?.jobSiteState || '',
        jobSiteZip: viewCart?.jobSiteZip || '',
        pc: viewCart?.pc || '',
        pcLat: viewCart?.pcLat || '',
        pcLong: viewCart?.pcLong || ''
    });

    useEffect(() => {
        if (accountProjects) {
            setProjectList(accountProjects);
        }
    }, [accountProjects]);

    useEffect(() => {
        if (isAccountSelected) {
            setSaveAndContinueError('');
            setAutoCompleteErrorMsg('');
        }
    }, [isAccountSelected, manualAddress, selectedButton, userAccount?.accountNumber]);

    useEffect(() => {
        if (userAccount?.accountNumber) {
            setIsAccountSelected(true);
        }
        setSelectedValue('');
    }, [userAccount?.accountNumber]);

    useEffect(() => {
        if (isCreditNewAddress) {
            setViewCartFields(VIEW_CART.SHOW_CREATE_PROJECT, true);
            setViewCartFields(VIEW_CART.CREATE_PROJECT_SOURCE, 'minicart');
        }
    }, [isCreditNewAddress]);

    const setViewCartFields = (key, value) => {
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
    };

    const handleToggle = currentSelected => {
        setSelectedButton(currentSelected);
    };

    const handleAccountOverlay = closeModal => {
        setShowAccountModal(closeModal);
    };

    const setEnterAddressFlag = value => {
        dispatch({
            type: SET_CREDIT_NEWADDR_FLAG,
            isCreditNewAddress: value
        });
    };

    const setSelectedProjectId = data => {
        setSaveAndContinueError('');
        setSelectedProjectData(data);
    };

    const handleSearchBarPlaceholder = () => {
        return localStorage.getItem('companyID') == 2
            ? intl.formatMessage({ id: 'accounts:Projectplaceholder-canada' })
            : intl.formatMessage({ id: 'accounts:Projectplaceholder' });
    };

    const onSaveAndContinue = async () => {
        if (!userProfile || userProfile?.accounts?.length <= 0 || !isAccountSelected) {
            setSaveAndContinueError(intl.formatMessage({ id: 'punchout:save-and-continue-choose-account-error' }));
            return;
        }
        if (selectedButton && !selectedValue) {
            setSaveAndContinueError(intl.formatMessage({ id: 'punchout:save-and-continue-location-error' }));
            return;
        }
        if (!selectedButton && !autoCompleteAddressSelected && !manualAddress) {
            setSaveAndContinueError(intl.formatMessage({ id: 'punchout:save-and-continue-enter-address-error' }));
            return;
        }
        if (!selectedButton && autoCompleteAddressSelected === '') {
            if (manualAddress !== globalLocation?.location) {
                let manualAddressMultipleFields = `${viewCart?.location || ''}, ${viewCart?.jobSiteCity || ''}, ${
                    viewCart?.jobSiteState || ''
                }, ${viewCart?.jobSiteZip || ''}`;
                let manualAddr = manualAddress === true ? manualAddressMultipleFields : manualAddress;
                const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                const localLocation = await handleManualUpdatedInstoreAddress(manualAddr, true, companyID);
                if (!localLocation) {
                    setAutoCompleteErrorMsg(intl.formatMessage({ id: 'order-details:address-error-label' }));
                    return;
                } else {
                    setAutoCompleteErrorMsg('');
                    if (manualAddress.toLowerCase() !== localLocation?.location.toLowerCase()) {
                        setAddressChangedByGeocode(true);
                        setManualAddress(localLocation?.location);
                        setGlobalLocation({
                            location: localLocation?.location,
                            lat: localLocation?.lat,
                            long: localLocation?.long,
                            jobSiteAddr2: localLocation?.jobSiteAddr2,
                            jobSiteCity: localLocation?.jobSiteCity,
                            jobSiteState: localLocation?.jobSiteState,
                            jobSiteZip: localLocation?.jobSiteZip,
                            pc: localLocation?.pc,
                            pcLat: localLocation?.pcLat,
                            pcLong: localLocation?.pcLong
                        });
                        return;
                    } else {
                        setManualAddress(localLocation?.location);
                        await updateManualLocationInContext(localLocation);
                        setEnterAddressFlag(!selectedButton);
                    }
                }
            } else {
                setManualAddress(globalLocation?.location);
                await updateManualLocationInContext(globalLocation);
            }
        } else if (!selectedButton && autoCompleteAddressSelected) {
            const localLocation = await computeInstore(locationByPlaces, autoCompleteAddressSelected);
            if (!localLocation) {
                setAutoCompleteErrorMsg(intl.formatMessage({ id: 'order-details:address-error-label' }));
                return;
            } else {
                setEnterAddressFlag(!selectedButton);
                setAutoCompleteErrorMsg('');
                filterDispatch({
                    type: SET_LOCATION_DATA,
                    pc: localLocation?.pc,
                    location: localLocation?.location,
                    jobSiteCity: localLocation?.jobSiteCity,
                    jobSiteState: localLocation?.jobSiteState,
                    jobSiteZip: localLocation?.jobSiteZip,
                    lat: localLocation?.lat,
                    long: localLocation?.long,
                    jobSiteAddr2: localLocation?.jobSiteAddr2,
                    pcLat: localLocation?.pcLat,
                    pcLong: localLocation?.pcLong
                });
            }
        } else {
            const {
                address1,
                latitude,
                longitude,
                city,
                state,
                zip,
                locationPC,
                contactName,
                contactPhone,
                deliveryInstructions,
                customerPO
            } = selectedProjectData || '';
            const addressTosend = address1 || `${city}, ${state}`; // If no address in addressList
            let projectLat = '';
            let projectLong = '';
            if (!latitude || !longitude) {
                const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                const response = await getlatLongByAddress(companyID, `${address1}, ${city}, ${state}, ${zip}`);
                if (response?.data?.results?.length > 0) {
                    projectLat = response?.data?.results[0]?.geometry?.location.lat;
                    projectLong = response?.data?.results[0]?.geometry?.location.lng;
                }
            } else {
                projectLat = latitude;
                projectLong = longitude;
            }
            setEnterAddressFlag(!selectedButton);
            if (Object.keys(selectedProjectData).length) {
                filterDispatch({
                    type: UPDATE_PROJECT,
                    projectName: selectedProjectData?.jobName,
                    projectAddress1: addressTosend,
                    projectAddress2: selectedProjectData?.address2,
                    selectedProjectJobId: selectedProjectData?.jobNumber,
                    selectedRMJobId: selectedProjectData?.selectedRMJobId,
                    selectedProjectLatititude: projectLat,
                    selectedProjectLongitude: projectLong,
                    selectedProjectState: state,
                    selectedProjectCity: city,
                    selectedProjectZip: zip,
                    locationPC: locationPC,
                    primaryContactName: contactName,
                    phoneNumber: contactPhone,
                    accessNotes: deliveryInstructions,
                    poNumber: customerPO,
                    CJDLVY: selectedProjectData?.CJDLVY
                });
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, selectedProjectData?.CJDLVY);
            } else {
                filterDispatch({
                    type: UPDATE_PROJECT,
                    projectName: projectDetails?.projectName,
                    projectAddress1: projectDetails?.projectAddress1,
                    projectAddress2: projectDetails?.projectAddress2,
                    selectedProjectJobId: projectDetails?.selectedProjectJobId,
                    selectedRMJobId: projectDetails?.selectedRMJobId,
                    selectedProjectLatititude: projectLat,
                    selectedProjectLongitude: projectLong,
                    selectedProjectState: projectDetails?.selectedProjectState,
                    selectedProjectCity: projectDetails?.selectedProjectCity,
                    selectedProjectZip: projectDetails?.selectedProjectZip,
                    locationPC: projectDetails?.locationPC,
                    primaryContactName: projectDetails?.contactName,
                    phoneNumber: projectDetails?.contactPhone,
                    accessNotes: projectDetails?.accessNotes,
                    poNumber: projectDetails?.poNumber,
                    CJDLVY: projectDetails?.CJDLVY
                });
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, projectDetails?.CJDLVY);
            }
        }
        dispatch({ type: UPDATE_PUNCHOUT_USER_DETAILS, key: 'showSelectAccountscreen', value: false });
        sessionStorage.setItem(
            STORAGE_CONFIG.SESSION_STORAGE.PUNCHOUTUSERDATA,
            JSON.stringify({ ...punchoutUserData, showSelectAccountscreen: false })
        );
        if (!isAccountClosed(userAccount) && !isAccountBlocked(userAccount)) {
            window.location.href = config.pagePaths.productPage;
        }
    };

    const punchoutHeader = () => {
        return (
            <>
                <div className="punchout__header">
                    {!showAccountModal && (
                        <>
                            <div className="punchout__header-content">
                                <h5 className="punchout__header-heading">
                                    {intl.formatMessage({ id: 'punchout:welcome-message' })}
                                </h5>
                                {intl.formatMessage({ id: 'punchout:accurate-pricing-text' })}
                            </div>
                            <div className="punchout__header-account">
                                <h6 className="punchout__header-title">Account</h6>

                                <AccountDetails
                                    setShowAccountModal={setShowAccountModal}
                                    handleAccountOverlay={handleAccountOverlay}
                                    accountLength="10"
                                    accountName={userAccount.accountName}
                                    accountNumber={userAccount.accountNumber}
                                    accountSelectorTitle={!isAccountSelected ? 'Choose an account' : ''}
                                />
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    };

    const cardStyle = data => {
        return (
            <div className={classes.accountCardRoot}>
                <span className={classes.cardTitle}>{data?.jobName}</span>
                <small className={classes.cardContent}>{data?.address1}</small>
                <small className={classes.cardContent}>
                    {data?.city}, {data?.state}, {data?.zip}
                </small>
            </div>
        );
    };

    const displaySearchResults = data => {
        return (
            data?.jobKey && (
                <div key={data?.jobKey} className="card-radio-button-wrapper">
                    <CardRadioButton
                        item={data}
                        cardStyle={cardStyle}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        onPressRadioButton={setSelectedProjectId}
                        isAccount={false}
                        radioButtonAriaLabel={data?.jobName}
                    />
                </div>
            )
        );
    };

    const renderModalBody = () => {
        return (
            <>
                {showAccountModal && (
                    <ChooseAccount
                        handleAccountOverlay={handleAccountOverlay}
                        footerClass={'bottomFixed'}
                        ctaTitle={intl.formatMessage({ id: 'choose-account-label' })}
                        hideFooter={true}
                    />
                )}
                {!showAccountModal && !isAccountSelected && (
                    <div className={`no-account-location ${saveAndContinueError ? 'no-account-location--error' : ''}`}>
                        <h6 className="no-account-location-title">
                            {intl.formatMessage({ id: 'existing-account:choose-a-project' })}
                        </h6>
                        {intl.formatMessage({ id: 'punchout:choose-account-to-see-avail-loc' })}
                    </div>
                )}
                {isAccountSelected && !showAccountModal && (
                    <>
                        <div
                            className={`punchout__location ${saveAndContinueError ? 'punchout__location--error' : ''} ${
                                isProjectsLoading && 'projects-loading-state'
                            }`}>
                            <div className="punchout__location__header">
                                <h5 className="modal-content-heading-label">
                                    {intl.formatMessage({ id: 'existing-account:choose-a-project' })}
                                </h5>
                                <ToggleTabs
                                    toggle1Title={intl.formatMessage({
                                        id: 'location-overlay:existing-project'
                                    })}
                                    toggle2Title={intl.formatMessage({
                                        id: 'location-overlay:enter-address'
                                    })}
                                    isSelected={selectedButton}
                                    toggleHandler={handleToggle}
                                />
                            </div>

                            {selectedButton ? (
                                <div>
                                    <div className={'punchout__location-search-bar'}>
                                        <ProjectListing
                                            placeholder={handleSearchBarPlaceholder()}
                                            displaySearchResults={displaySearchResults}
                                            isSearchIconVisible={true}
                                            handleInputBlur={true}
                                            setSearchErrorText={setSearchErrorText}
                                            notFoundErrorMessage={intl.formatMessage({
                                                id: 'projects:project-not-found'
                                            })}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="punchout__location__newaddress">
                                        <NewAddress
                                            setManualAddress={setManualAddress}
                                            autoCompleteErrorMsg={autoCompleteErrorMsg}
                                            setAutoCompleteErrorMsg={setAutoCompleteErrorMsg}
                                            setAddressChangedByGeocode={setAddressChangedByGeocode}
                                            addressChangedByGeocode={addressChangedByGeocode}
                                            setAutoCompleteAddressSelected={setAutoCompleteAddressSelected}
                                            setLocationByPlaces={setLocationByPlaces}
                                            manualAddress={manualAddress}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
                {!showAccountModal && (
                    <div className="punchout__body-ctasection">
                        {saveAndContinueError && (
                            <Alert localStyle="alert-withbottommargin" type={'error'} message={saveAndContinueError} />
                        )}
                        <Button
                            type="button"
                            className={'button button-primary button-block'}
                            onClick={onSaveAndContinue}
                            buttonAriaLabel={intl.formatMessage({
                                id: 'location-overlay:set-location-cta'
                            })}>
                            {intl.formatMessage({ id: 'location-overlay:save-and-continue' })}
                        </Button>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {punchoutUserData?.showSelectAccountscreen && mediaType === 'MOBILE' ? (
                <Modal isOpen={punchoutUserData?.showSelectAccountscreen} className="punchout-mobile">
                    <div className={`punchout-mobile__header ${showAccountModal ? 'punchout-mobile__dnone' : ''}`}>
                        <SBRLogoHorz className="marketoform__header-logo" />
                        {/* <CloseGreen /> */}
                        {!userProfile}
                    </div>
                    <div
                        className={`punchout-mobile__body ${showAccountModal ? 'punchout-mobile__chooseaccount' : ''} ${
                            isAccountSelected && !showAccountModal ? 'punchout-mobile__location-main' : ''
                        } ${
                            !userProfile || userProfile?.accounts?.length <= 0 || isProjectsLoading
                                ? 'punchout-mobile__choosenodata'
                                : 'punchout__choosewithdata'
                        } ${!selectedButton ? 'punchout__address' : ''}`}>
                        {punchoutHeader()}
                        {renderModalBody()}
                    </div>
                </Modal>
            ) : (
                punchoutUserData?.showSelectAccountscreen && (
                    <AnimatedHeaderModal
                        isOpen={punchoutUserData?.showSelectAccountscreen}
                        renderModalClass={`punchout__wrap ${showAccountModal ? 'punchout__wrap--chooseaccount' : ''} ${
                            isAccountSelected && !showAccountModal ? 'punchout__location--wrap' : ''
                        } ${
                            !userProfile || userProfile?.accounts?.length <= 0 || isProjectsLoading
                                ? 'punchout__choosenodata'
                                : 'punchout__choosewithdata'
                        }`}
                        renderHeader={punchoutHeader()}
                        renderBody={renderModalBody()}
                        showHeader={!showAccountModal}
                        renderBodyClass={''}
                        showCloseIcon={false}
                    />
                )
            )}
        </>
    );
};

export default PunchoutUser;
