import React, { useState } from 'react';
import { func, oneOfType, string, number } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import { useFavouritesState } from '../../../favorites/context';
import FavouritesButton from '../../../favorites/modules/favouritesButton/FavouritesButton';
import HigherFeeWarningAlert from '../../atoms/higherFeeWarningAlert/HigherFeeWarningAlert';
import { AUTHORITY_TYPE } from '../../constants';
import Favorites from './atoms/favorites';
import { productTileDataLocator } from '../addToCartTile/dataLocators';
import { currencyToLocale, formatCatClass } from '../../utils/commonUtils';
import DefaultViewNoImage from './atoms/noImageView/NoImageView';
import { DOTCOM, P2P } from './constants';
import './baseproducttile.scss';

const BaseProductTile = ({
    productImg,
    catClass,
    productName,
    productUrl,
    dailyProductRate,
    accountNumber,
    footerContent,
    handleAnchorClick,
    handleRedirectClick,
    isAvailableWithWarning,
    variant,
    footerTileRef,
    footerTileClassName,
    nativeLazyLoad = 'loading' in window.HTMLImageElement.prototype
}) => {
    const intl = useIntl();
    const [{ favoriteProducts }] = useFavouritesState();
    const authorityType = useCheckAuthorityType();
    const isP2P = authorityType === AUTHORITY_TYPE.P2P;
    const isProductFavorite = productCatClass => {
        const favoriteProduct = favoriteProducts.find(product => product.sku === productCatClass);
        return favoriteProduct?.isFavorite;
    };
    const [brokenImage, setBrokenImage] = useState(false);

    return (
        <div className="producttile">
            <div
                data-testid={productTileDataLocator.productTile_productFavoriteIndicator}
                className="producttile__favourite">
                {variant === DOTCOM && (
                    <Favorites
                        sku={catClass}
                        accountNumber={accountNumber}
                        showFavoriteTitle={false}
                        productName={productName}
                    />
                )}
            </div>
            <div>
                <a className="producttile__top" href={productUrl} onClick={handleAnchorClick}>
                    {productImg && !brokenImage ? (
                        nativeLazyLoad ? (
                            <div className="producttile__img" onClick={handleRedirectClick}>
                                <img
                                    alt={productName}
                                    src={productImg}
                                    loading="lazy"
                                    height="156"
                                    onError={() => {
                                        setBrokenImage(true);
                                    }}
                                />
                            </div>
                        ) : (
                            <div className="producttile__img" onClick={handleRedirectClick}>
                                <img
                                    alt={productName}
                                    data-src={productImg}
                                    height="156"
                                    onError={() => {
                                        setBrokenImage(true);
                                    }}
                                />
                            </div>
                        )
                    ) : (
                        <DefaultViewNoImage />
                    )}
                    <div>
                        <h3 className="producttile__catclass eyebrow">{`${intl.formatMessage({
                            id: 'homepage:cat-class'
                        })} ${formatCatClass(catClass)}`}</h3>
                        <h4 className="producttile__productname">{productName}</h4>
                    </div>
                </a>
                {dailyProductRate ? (
                    <div className="producttile__price">
                        {currencyToLocale(dailyProductRate)} {intl.formatMessage({ id: 'cart:per-day-text' })}
                    </div>
                ) : null}
            </div>
            {isAvailableWithWarning && !!dailyProductRate && isP2P && <HigherFeeWarningAlert className="mb_1" productName={productName}/>}
            <div className={`${footerTileClassName} producttile__footer`} ref={footerTileRef}>
                {footerContent()}
                {variant === P2P && (
                    <FavouritesButton
                        isFavorite={isProductFavorite(catClass)}
                        sku={catClass}
                        qty={1}
                        favoriteClasses={'prod_tile_fav_btn'}
                        productName={productName}
                    />
                )}
            </div>
        </div>
    );
};

export default React.memo(BaseProductTile);

BaseProductTile.defaultProps = {
    productImg: '',
    catClass: '',
    productName: '',
    productUrl: '',
    accountNumber: '',
    dailyProductRate: '',
    variant: 'dotcom',
    handleAnchorClick: () => {},
    handleRedirectClick: () => {},
    footerContent: () => {},
    footerTileRef: () => {},
    footerTileClassName: ''
};

BaseProductTile.propTypes = {
    productImg: string,
    catClass: string,
    productName: string,
    productUrl: string,
    accountNumber: oneOfType([number, string]),
    dailyProductRate: oneOfType([number, string]),
    handleAnchorClick: func,
    handleRedirectClick: func,
    footerContent: func,
    footerTileRef: func,
    footerTileClassName: string
};
