import { gql } from '@apollo/client';

const MUTATION_GET_ALL_JOBSITES_AND_AUTOSUGGEST = gql`
    mutation (
        $accountId: String
        $search: String
        $searchString: String!
        $limit: Int
        $location: String
        $radius: String
        $includeActiveEquipment: String
        $invalidateCache: Boolean
    ) {
        getSbrJobSitesAndAutoSuggest(
            accountId: $accountId
            search: $search
            searchString: $searchString
            limit: $limit
            location: $location
            radius: $radius
            includeActiveEquipment: $includeActiveEquipment
            invalidateCache: $invalidateCache
        ) {
            jobSites {
                status
                message
                data {
                    equipmentCount
                    projectKey
                    salesRepId
                    projectId
                    jobsiteId
                    projectName
                    accountName
                    deliveryInstructions1
                    deliveryInstructions2
                    deliveryInstructions3
                    deliveryInstructions4
                    Location
                    companyId
                    isActive
                    customerPO
                    contact {
                        name
                        phone
                        id
                    }
                    address {
                        attn
                        city
                        line1
                        line2
                        line3
                        state
                        zip
                        latitude
                        longitude
                        locationPC
                        isGeoCoded
                    }
                }
            }
            autoSuggest {
                data {
                    predictions {
                        description
                        placeId
                        structuredFormatting {
                            mainText
                        }
                    }
                }
            }
        }
    }
`;

export default MUTATION_GET_ALL_JOBSITES_AND_AUTOSUGGEST;
