import React from 'react';
import ViewCartFooterDotCom from './ViewCartFooterDotCom';

/** Segrigated this component if any changes will come regarding P2P specific then we can render from here */
const ViewCartFooter = props => {
    const { handleCheckoutBtnClickModal } = props;

    return <ViewCartFooterDotCom handleCheckoutBtnClickModal={handleCheckoutBtnClickModal} />;
};

export default ViewCartFooter;
