import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useAwaitQuery } from '../../utils/hooks';
import Price from '../Price';
import Button from '../Button';
import classes from './cartOptions.css';
import CART_DETAILS_QUERY from '../../queries/query_cart_details.graphql';
import MUTATION_UPDATE_CART_ITEM from '../../queries/mutation_update_cart_item.graphql';
import useCartOptions from './useCartOptions';

const CartOptions = () => {
    const [updateCartItemMutation] = useMutation(MUTATION_UPDATE_CART_ITEM);
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const intl = useIntl();

    const [{ editItem }, { dispatch, updateCartItem }] = useCartOptions({
        updateCartItemMutation,
        cartDetailsQuery
    });

    const { product, quantity: initialQuantity, prices } = editItem;
    const { name } = product;
    const { value, currency } = prices.price;
    const [quantity] = useState(initialQuantity);

    const handleUpdateClick = async () => {
        await updateCartItem(quantity);
        dispatch({ type: 'endEditing' });
    };

    return (
        <form className={classes.root}>
            <div className={classes.focusItem}>
                <span className={classes.name}>{name}</span>
                <span className={classes.price}>
                    <Price currencyCode={currency} value={value} />
                </span>
            </div>
            <div className={classes.form}>
                <section className={classes.quantity}>
                    <h2 className={classes.quantityTitle}>
                        <span>{intl.formatMessage({ id: 'cart:quantity', defaultMessage: 'Quantity' })}</span>
                    </h2>
                </section>
            </div>
            <div className={classes.save}>
                <Button
                    onClick={() => {
                        dispatch({ type: 'endEditing' });
                    }}>
                    <span>{intl.formatMessage({ id: 'common:cancel', defaultMessage: 'Cancel' })}</span>
                </Button>
                <Button priority="high" onClick={handleUpdateClick}>
                    <span>{intl.formatMessage({ id: 'cart:update-cart', defaultMessage: 'Update Cart' })}</span>
                </Button>
            </div>
        </form>
    );
};

export default CartOptions;
