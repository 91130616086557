import React, { memo, useEffect, useRef, useState } from 'react';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useCartState } from '../../../../aem-core-components/components/Minicart/cartContext';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import { useCheckAuthorityType, useCheckUser } from '../../../../hooks/useCheckUser';
import { usePricing } from '../../../../hooks/usePricing';
import { useAtp } from '../../../cap/hooks/useATP';
import Carousel from '../alternateInventory/carousel/Carousel';
import AddToCartProductTileReact from '../productTile/addToCartProductTileReact/AddToCartProductTileReact';
import { getDataBricks, setAccessTokenSession } from './api/getDataBricks';
import { recommendationMobileResponsive } from '../../utils/commonUtils';
import '../alternateInventory/carousel/carousel.scss';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { isValidString, logError } from '../../utils/logger';
import { dataBricksConstant } from './dataBricksConstant';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { AUTHORITY_TYPE } from '../../constants';
import { TILE_STATES } from '../productTile/constants';

const DataBricks = () => {
    const userType = useCheckUser();
    const { handleATP } = useAtp();
    const authorityType = useCheckAuthorityType();
    const [{ userAccount, cidPcList }] = useCartState();
    const [productRecommmendationsData, setProductRecommmendationsData] = useState([]);
    const isDotcomCreditUser = userType === USER_TYPE.CREDIT && authorityType === AUTHORITY_TYPE.DOTCOM;
    const { getProductPrices } = usePricing();
    const [{ isProfileLoaded }] = useUserContext();
    const { isRentalDetailsAvailable } = useCheckLocationEmpty();
    const productRecommmendationsRef = useRef('null');
    const itemLength = productRecommmendationsData?.length || dataBricksConstant?.defaultItemLength;
    const productLength = productRecommmendationsData?.length || 0;
    const minProducts =
        Number(isValidString(ENV_CONFIG.PRODUCT_RECOMMENDATIONS_MINIMUM_PRODUCTS)) ||
        dataBricksConstant?.minproductLength;

    useEffect(() => {
        const fetchProductRecommendations = async () => {
            try {
                await setAccessTokenSession();
                const { data: { data: { productRecommmendations = [] } = {} } = {} } = await getDataBricks(
                    userAccount?.accountNumber
                );

                setProductRecommmendationsData(productRecommmendations);
            } catch (error) {
                logError(error, false, 'fetchProductRecommendations');
            }
        };

        if (isDotcomCreditUser) {
            fetchProductRecommendations();
        }
    }, [userAccount?.accountNumber, isDotcomCreditUser]);

    useEffect(() => {
        try {
            const fetchRates = async () => {
                await getProductPrices(
                    productRecommmendationsData,
                    VARIABLE_CONFIG?.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE
                );
            };

            if (isProfileLoaded) {
                if (isRentalDetailsAvailable() && cidPcList?.length > 0 && productRecommmendationsData?.length > 0) {
                    fetchRates();
                }
            }
        } catch (error) {
            logError(error, false, 'fetchProductRecommendations');
        }
    }, [cidPcList, isProfileLoaded, productRecommmendationsData]);

    useEffect(() => {
        if (productRecommmendationsRef) {
            if (productLength <= minProducts) {
                productRecommmendationsRef?.current
                    ?.closest('.productrecommendations')
                    ?.classList.remove('productrecommendations--show');
            } else {
                productRecommmendationsRef?.current
                    ?.closest('.productrecommendations')
                    ?.classList.add('productrecommendations--show');
            }
        }
    }, [productLength]);

    const renderCarouselItems = productRecommmendationsData => {
        return productRecommmendationsData?.reduce((result, singleRecommendation) => {
            const {
                catclass,
                productimageurl,
                producttitle,
                producturl,
                metadescription,
                metatitle,
                ec_pc_inventory,
                disableaddtocartoption,
                showonlinecatalog
            } = singleRecommendation;

            const cardData = {
                catclass,
                productimageurl,
                producttitle,
                producturl,
                metadescription,
                metatitle,
                catsku: catclass,
                inventory: ec_pc_inventory || '{}',
                disableaddtocartoption,
                showonlinecatalog
            };

            const tileStatus = handleATP({
                catsku: cardData?.catsku,
                inventoryDataObj: cardData?.inventory,
                showonlinecatalog: cardData?.showonlinecatalog,
                disableaddtocartoption: cardData?.disableaddtocartoption,
                pcAvailability: cardData?.pc_availability
            });

            // If tileStatus is valid, push the component into the result array
            if (tileStatus !== TILE_STATES.UNAVAILABLE) {
                result.push(<AddToCartProductTileReact key={catclass} cardData={cardData} />);
            }
            return result;
        }, []); // Initial value of result is an empty array
    };
    return (
        <div ref={productRecommmendationsRef}>
            {isDotcomCreditUser && (
                <Carousel
                    disableDotsControls={false}
                    totalItem={itemLength}
                    mobileResponsive={recommendationMobileResponsive()}
                    carouselWrapClasses={'carousel--home-test'}>
                    {renderCarouselItems(productRecommmendationsData)}
                </Carousel>
            )}
        </div>
    );
};
export default memo(DataBricks);
