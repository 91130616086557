import React from 'react';
import { func, number, object, string, bool } from 'prop-types';
import OrderSummaryLineItem from '../orderSummaryLineItem/OrderSummaryLineItem';
import Alert from '../../../../global/atoms/alert/alert';
import { checkoutOrderSummaryDataLocators } from '../../dataLocators';
import RoundTripFee from '../roundTripFee/RoundTripFee';
import { useCheckAuthorityType } from '../../../../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../../../../global/constants';
import '../../orderSummaryFactory.scss';

const CheckoutSummaryView = props => {
    const {
        intl,
        orderTotals,
        currentStep,
        formStep,
        source,
        deliveryChargeFlag,
        handleOpenTBDOverlay,
        handleRoundTripAndEstimateFlow,
        isRatesFromTier2Radius,
        isDeliveryFeeRequired,
        shouldShowTbdAlert,
        isInStorePickupFromSessionStorage
    } = props;
    const authorityType = useCheckAuthorityType();

    const {
        rentalSubtotal,
        purchaseSubtotal,
        rentalProtectionPlan,
        prepayFuelOption,
        deliveryPickUpCharges,
        estimatedSubtotal,
        envFees,
        otherFees,
        taxes,
        subtotalValue,
        showDeliveryEstimate,
        dispatch
    } = orderTotals;
    const itemLabel = authorityType === AUTHORITY_TYPE.P2P ? 'order-summary:total' : 'order-summary:estimated-total' ;

    return (
        <>
            <div className="ordersummary">
                <div className={'ordersummary-top-checkout'}>
                    {currentStep > formStep?.OPTIONAL_PLANS ? null : (
                        <div
                            data-testid={checkoutOrderSummaryDataLocators.miniCartOverlay_taxesFees_txt}
                            className="ordersummary-top-content">
                            {intl.formatMessage({ id: 'order-summary:tax-and-fees-warning-text' })}
                        </div>
                    )}
                </div>
                <div className={'ordersummary-items-checkout'}>
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:rental-subtotal' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_rentalSubtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={rentalSubtotal}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_rentalSubtotal_dynvalue}
                        dispatch={dispatch}
                    />
                    {
                        <OrderSummaryLineItem
                            itemLabel={intl.formatMessage({ id: 'order-summary:purchases-subtotal' })}
                            itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_purchase_subtotal_lbl}
                            isTooltipIcon={false}
                            itemValue={purchaseSubtotal}
                            itemValueDataTestId={
                                checkoutOrderSummaryDataLocators.miniCartOverlay_purchase_subtotal_dynValue
                            }
                            dispatch={dispatch}
                        />
                    }
                    {isInStorePickupFromSessionStorage ? (
                        <OrderSummaryLineItem // need to confirm styling while testing
                            itemLabel={intl.formatMessage({ id: 'order-summary:instore-pickup' })}
                            itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_instorePickup_lbl}
                            isTooltipIcon
                            tooltipIconMessage={intl.formatMessage({ id: 'order-summary:instore-pickup' })}
                            itemValue={intl.formatMessage({ id: 'order-summary:instore-pickup-value' })}
                            itemValueDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_instorePickupFree_txt}
                            dispatch={dispatch}
                        />
                    ) : (
                        <RoundTripFee
                            source={source}
                            deliveryChargeFlag={deliveryChargeFlag}
                            deliveryPickUpCharges={deliveryPickUpCharges}
                            showDeliveryEstimate={showDeliveryEstimate}
                            handleOpenTBDOverlay={handleOpenTBDOverlay}
                            handleRoundTripAndEstimateFlow={handleRoundTripAndEstimateFlow}
                            isRatesFromTier2Radius={isRatesFromTier2Radius}
                            isDeliveryFeeRequired={isDeliveryFeeRequired}
                            dispatch={dispatch}></RoundTripFee>
                    )}
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:rental-protection-plan' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_rpp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={intl.formatMessage({ id: 'order-summary:rental-protection-plan' })}
                        itemValue={rentalProtectionPlan}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.miniCartOVerlay_rppBlank_value}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:fuel-convenience-plan' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_fcp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={intl.formatMessage({ id: 'order-summary:fuel-convenience-plan' })}
                        itemValue={prepayFuelOption}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.miniCartOVerlay_fcpBlank_value}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:environment-fee' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_srp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={intl.formatMessage({ id: 'order-summary:environment-fee' })}
                        itemValue={envFees}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_srp_dynValue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:other-fee' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_srp_lbl}
                        isTooltipIcon={false}
                        itemValue={otherFees}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_srp_dynValue}
                        dispatch={dispatch}
                    />
                </div>
                <div className="ordersummary-checkout">
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:subtotal' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_subtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={subtotalValue}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_subtotal_dynValue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:taxes' })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_taxes_lbl}
                        isTooltipIcon={false}
                        itemValue={taxes}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.miniCartOVerlay_taxesBlank_value}
                        dispatch={dispatch}
                    />
                </div>
                <div className="ordersummary-total">
                    <OrderSummaryLineItem
                        isBoldLabel
                        itemLabel={intl.formatMessage({ id: itemLabel })}
                        itemLabelDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_estimated_total_lbl}
                        isTooltipIcon={false}
                        itemValue={estimatedSubtotal}
                        itemValueDataTestId={checkoutOrderSummaryDataLocators.miniCartOverlay_estimated_total_dynValue}
                        dispatch={dispatch}
                    />
                </div>
                {shouldShowTbdAlert() && (
                    <Alert
                        type={'warning'}
                        icon={'warning'}
                        message={intl.formatMessage({ id: 'checkout:tbd-order-summary-alert' })}
                    />
                )}
            </div>
        </>
    );
};

export default React.memo(CheckoutSummaryView);

CheckoutSummaryView.propTypes = {
    orderTotals: object,
    currentStep: number,
    formStep: object,
    source: string,
    deliveryChargeFlag: string,
    handleOpenTBDOverlay: func,
    handleRoundTripAndEstimateFlow: func,
    isRatesFromTier2Radius: bool,
    isDeliveryFeeRequired: func
};
CheckoutSummaryView.defaultProps = {
    orderTotals: {},
    currentStep: 1,
    formStep: {},
    source: '',
    deliveryChargeFlag: '',
    handleOpenTBDOverlay: () => {},
    handleRoundTripAndEstimateFlow: () => {},
    isRatesFromTier2Radius: false,
    isDeliveryFeeRequired: () => {}
};
