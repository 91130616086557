import React from 'react';
import { func, number, string } from 'prop-types';
import { useIntl } from 'react-intl';
import Select from '../../global/atoms/select';

const QuantitySelector = props => {
    const { qty, setQty, limit, testid, className,isOpenselect } = props;
    const intl = useIntl();
    const generateQty = () => {
        let qtyList = [];
        for (let i = 1; i <= limit; i++) {
            qtyList.push({ label: i, value: i });
        }
        return qtyList;
    };

    const handleOnChange = newVal => {
        setQty(newVal);
    };
    return (
        <>
            <Select
            isOpenselect={isOpenselect}
                field="quantity"
                selectedValue={qty}
                handleChange={handleOnChange}
                dropDownValues={generateQty()}
                dataTestId={testid}
                className={className}
                selectAriaLabel={intl.formatMessage({ id: 'cart:quantity-selector' }) + `${qty}`}
            />
        </>
    );
};

export default QuantitySelector;
QuantitySelector.propTypes = {
    qty: number,
    limit: number,
    setQty: func,
    testid: string,
    className: string
};

QuantitySelector.defaultProps = {
    qty: 1,
    limit: '25',
    className: '',
    testid: '',
    setQty: () => {}
};
