import React from 'react';
import { BackHeader } from '../headers';

export default function Cart() {
    return (
        <div>
            <BackHeader />
            <p>Cart</p>
        </div>
    );
}
