import moment from 'moment';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import {
    getDifferenceinHours,
    getTimeStops,
    makeTimeInterval,
    validateEmail,
    validateName,
    validatePhone
} from '../../../global/utils/commonUtils';
import { logWarning } from '../../../global/utils/logger';

export const getDirectionCTA = (
    storeDetailData,
    storesData,
    viewCart,
    userInfo,
    projectDetails,
    locationSearched = false,
    searchLocation
) => {
    const itemData = storeDetailData ? storeDetailData : storesData?.data[0];
    const storeAddress = `${itemData.street},${itemData.city},${itemData.state},${itemData.zip}`;
    const projectAddress = `${projectDetails?.projectAddress1},${projectDetails?.selectedProjectCity},${projectDetails?.selectedProjectState},${projectDetails?.selectedProjectZip}`;
    let cashCustomerAddress;
    if (locationSearched) {
        cashCustomerAddress = `${searchLocation}`;
    } else {
        cashCustomerAddress = `${viewCart.location ? viewCart.location + ', ' : ''}${
            viewCart.jobSiteCity ? viewCart.jobSiteCity + ', ' : ''
        }${viewCart.jobSiteState ? viewCart.jobSiteState : ''}${viewCart.jobSiteZip ? ', ' + viewCart.jobSiteZip : ''}`;
    }

    window.open(
        `https://www.google.com/maps/dir/${
            !userInfo?.isCreditCustomer ? cashCustomerAddress : projectAddress
        }/${storeAddress}`
    );
};

export const getAddress = (pc, bestMatchStoresData, closestStoresData) => {
    const findStore = storesData => storesData?.data?.find(item => item?.pc === pc);
    let store;
    store = findStore(bestMatchStoresData) || findStore(closestStoresData);
    return store ? `${store.name}, ${store.city}, ${store.state}, ${store.zip}` : null;
};

export const getSelectedlatLong = (storesData, selectedStoreValue) => {
    const data = storesData?.data?.find(item => {
        return item?.pc === selectedStoreValue;
    });
    const obj = {};
    obj.lat = data?.latitude || storesData?.data?.[0]?.latitude;
    obj.long = data?.longitude || storesData?.data?.[0]?.longitude;
    return obj;
};

export const getLeadTime = cart => {
    let leadTime = 0.5;
    cart?.items?.every(item => {
        if (item?.product?.isOverSize == 'Yes' || item?.product?.isOverWeight == 'Yes') {
            leadTime = 2;
            return false;
        }
        return true;
    });
    return leadTime;
};

export const tommorrowDate = () => {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    return new Date(today.setDate(today.getDate() + 1));
};

export const getTodayCondition = (
    storeClosingTime,
    storeOpeningTime,
    leadTime,
    setPickUpTimeSlots,
    setSelectedPickupTime,
    currentOffSet
) => {
    const currentTime = makeTimeInterval();
    const date1 = new Date(currentTime);
    const date2 = new Date(currentOffSet);
    let estOffSet = (Math.abs(date1 - date2) / 36e5) * 3600;
    if (date1 > date2) {
        estOffSet = '-' + estOffSet;
    }
    const startTimeWithOffset = currentTime.clone().add(estOffSet, 'seconds');
    const roundOff = makeTimeInterval(startTimeWithOffset);
    let startTimeWithLeadTime = '';
    if (roundOff < storeOpeningTime) {
        startTimeWithLeadTime = storeOpeningTime.clone().add(leadTime, 'hours');
    } else {
        startTimeWithLeadTime = roundOff.clone().add(leadTime, 'hours');
    }

    // const closeTime = storeClosingTime.subtract(leadTime, 'hours');
    const timeSlops = getTimeStops(startTimeWithLeadTime, storeClosingTime);
    setPickUpTimeSlots(timeSlops);
};

export const getTomorrowCondition = (
    storeOpeningTime,
    storeClosingTime,
    leadTime,
    setPickUpTimeSlots,
    setSelectedPickupTime,
    currentOffSet
) => {
    const currentTime = makeTimeInterval();
    const date1 = new Date(currentTime);
    const date2 = new Date(currentOffSet);
    let estOffSet = (Math.abs(date1 - date2) / 36e5) * 3600;
    if (date1 > date2) {
        estOffSet = '-' + estOffSet;
    }
    const currentBookingTime = moment().clone().add(estOffSet, 'seconds');
    let timeSlops = [];
    const startTimeWithOffset = storeOpeningTime.clone().add(estOffSet, 'seconds');
    const roundOff = makeTimeInterval(startTimeWithOffset);

    if (currentBookingTime < storeClosingTime) {
        if (roundOff > storeOpeningTime) {
            timeSlops = getTimeStops(roundOff, storeClosingTime);
        } else {
            timeSlops = getTimeStops(storeOpeningTime, storeClosingTime);
        }
    } else {
        if (roundOff > storeOpeningTime) {
            const startTimeWithLeadTimeAndOffset = roundOff.clone().add(leadTime, 'hours');
            timeSlops = getTimeStops(startTimeWithLeadTimeAndOffset, storeClosingTime);
        } else {
            const startTimeWithLeadTime = storeOpeningTime.clone().add(leadTime, 'hours');
            timeSlops = getTimeStops(startTimeWithLeadTime, storeClosingTime);
        }
    }
    setPickUpTimeSlots(timeSlops);
};

export const validateSmonElse = (someoneElse, setSmoneElseError, intl, checkoutErrorHandlingAnalytics) => {
    const { firstname, lastname, phone, email } = someoneElse;
    let isValid = true;
    const errors = {
        firstname: '',
        lastname: '',
        phone: '',
        email: ''
    };

    if (!firstname) {
        errors.firstname = intl.formatMessage({ id: 'common:required-input-error' });
        logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
        isValid = false;
        checkoutErrorHandlingAnalytics(
            intl.formatMessage({ id: 'common:required-input-error' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_FIRST_NAME
        );
    } else if (firstname) {
        if (!validateName(firstname?.trim())) {
            isValid = false;
            errors.firstname = intl.formatMessage({ id: 'common:input-format-error' });
            logWarning(intl.formatMessage({ id: 'common:input-format-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:input-format-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_FIRST_NAME
            );
        } else {
            errors.firstname = '';
        }
    }

    if (!lastname) {
        isValid = false;
        errors.lastname = intl.formatMessage({ id: 'common:required-input-error' });
        logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
        checkoutErrorHandlingAnalytics(
            intl.formatMessage({ id: 'common:required-input-error' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_LAST_NAME
        );
    } else if (lastname) {
        if (!validateName(lastname?.trim())) {
            isValid = false;
            errors.lastname = intl.formatMessage({ id: 'common:input-format-error' });
            logWarning(intl.formatMessage({ id: 'common:input-format-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:input-format-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_LAST_NAME
            );
        } else {
            errors.lastname = '';
        }
    }

    if (!phone) {
        errors.phone = intl.formatMessage({ id: 'common:required-input-error' });
        logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
        isValid = false;
        checkoutErrorHandlingAnalytics(
            intl.formatMessage({ id: 'common:required-input-error' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_PHONE
        );
    } else if (phone) {
        if (!validatePhone(phone)) {
            isValid = false;
            errors.phone = intl.formatMessage({ id: 'common:input-format-error' });
            logWarning(intl.formatMessage({ id: 'common:input-format-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:input-format-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_PHONE
            );
        } else {
            errors.phone = '';
        }
    }

    if (!email) {
        errors.email = intl.formatMessage({ id: 'common:required-input-error' });
        logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
        isValid = false;
        checkoutErrorHandlingAnalytics(
            intl.formatMessage({ id: 'common:required-input-error' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_EMAIL
        );
    } else if (email) {
        if (!validateEmail(email)) {
            isValid = false;
            errors.email = intl.formatMessage({ id: 'common:input-format-error' });
            logWarning(intl.formatMessage({ id: 'common:input-format-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:input-format-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_EMAIL
            );
        } else {
            errors.email = '';
        }
    }

    setSmoneElseError({ ...errors });
    return isValid;
};

export const validateSmonElseOnToggle = (someoneElse, setSmoneElseError, intl, checkoutErrorHandlingAnalytics) => {
    const { firstname, lastname, phone, email } = someoneElse;
    let isValid = true;
    const errors = {
        firstname: '',
        lastname: '',
        phone: '',
        email: ''
    };

    if (firstname) {
        if (!validateName(firstname?.trim())) {
            isValid = false;
            errors.firstname = intl.formatMessage({ id: 'common:input-format-error' });
            logWarning(intl.formatMessage({ id: 'common:input-format-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:input-format-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_FIRST_NAME
            );
        } else {
            errors.firstname = '';
        }
    }

    if (lastname) {
        if (!validateName(lastname?.trim())) {
            isValid = false;
            errors.lastname = intl.formatMessage({ id: 'common:input-format-error' });
            logWarning(intl.formatMessage({ id: 'common:input-format-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:input-format-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_LAST_NAME
            );
        } else {
            errors.lastname = '';
        }
    }

    if (phone) {
        if (!validatePhone(phone)) {
            isValid = false;
            errors.phone = intl.formatMessage({ id: 'common:input-format-error' });
            logWarning(intl.formatMessage({ id: 'common:input-format-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:input-format-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_PHONE
            );
        } else {
            errors.phone = '';
        }
    }

    if (email) {
        if (!validateEmail(email)) {
            isValid = false;
            errors.email = intl.formatMessage({ id: 'common:input-format-error' });
            logWarning(intl.formatMessage({ id: 'common:input-format-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:input-format-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.SOMEONE_ELSE_EMAIL
            );
        } else {
            errors.email = '';
        }
    }

    setSmoneElseError({ ...errors });
    return isValid;
};

export const continueHandler = (
    startDate,
    endDate,
    selectedPickupTime,
    selectedReturnTime,
    setIsReturnError,
    validateForm,
    consumablesData,
    intl,
    checkoutErrorHandlingAnalytics
) => {
    const start = moment(startDate);
    const startDateFormat = start.format('yyyy-MM-DD');
    const enddate = moment(endDate);
    const endDateFormat = enddate.format('yyyy-MM-DD');
    const sd = moment(`${startDateFormat} ${selectedPickupTime}`, 'yyyy-MM-DD hh:mm A');
    const ed = moment(`${endDateFormat} ${selectedReturnTime}`, 'yyyy-MM-DD hh:mm A');
    const difference = getDifferenceinHours(sd, ed);
    if (difference < 24) {
        setIsReturnError(true);
        checkoutErrorHandlingAnalytics(
            intl.formatMessage({ id: 'checkout:return-time-less-than-24hrs' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.RETURN_TIME
        );
        logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
    }

    //TODO check this logic
    if (difference >= 24) {
        validateForm(consumablesData);
    }
};
