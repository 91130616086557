import { node, string } from 'prop-types';
import React from 'react';
import './footer.scss';

const Footer = props => {
    const { children, footerClass } = props;
    return <div className={`minicart-footer ${footerClass}`}>{children}</div>;
};
export default Footer;

Footer.propsType = {
    children: node,
    footerClass: string
};

Footer.defaultProps = {
    footerClass: ''
};
