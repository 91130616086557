import React, { useState, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useUserContext } from '../../../context/UserContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { useCartState } from '../cartContext';
import useCartEstimate from '../hooks/useCartEstimate';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import { useFilterState } from '../../../../components/cap';
import useAnalytics from '../../../../hooks/useAnalytics';
import Body from '../minicartBody/body';
import Footer from '../viewcart/footer';
import ProductAvailability from '../../../../components/global/modules/ProductAvailability/ProductAvailability';
import LoadingIndicator from '../../LoadingIndicator';
import MiniCartHeader from '../miniCartHeader';
import Button from '../../../../components/global/atoms/button';
import AnimatedHeader from '../headers/animatedHeader';
import { logError } from '../../../../components/global/utils/logger';
import {
    currencyToLocale,
    isAccountClosed,
    isTier2Radius,
    isCCPage,
    checkIsEditQuoteFlow,
    redirectToCheckoutOrQuotePage
} from '../../../../components/global/utils/commonUtils';
import config from '../../../../components/App/config';
import { cartDataLocators } from './dataLocators';
import {
    SET_CART_OVERLAY,
    SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
    SET_VIEW_CART_FIELDS,
    SHOW_GUEST_CHECKOUT_LOGIN
} from '../../../actions/constants';
import { EMPLOYEE } from '../../../../components/checkout/constants';
import { CART_OVERLAYS, VIEW_CART } from '../constants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { EDIT_VIEW_DETAILS, EDIT_VIEW_OPEN, SET_END_DATE, SET_START_DATE } from '../../../../components/cap/constants';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import ViewCartFooter from './viewCartFooter/ViewCartFooter';

const ViewCart = props => {
    const intl = useIntl();
    const { disablCheckoutOnApiError, setDisablCheckoutOnApiError, showFooter } = props;
    const [userState, { isValidSession }] = useUserContext();
    const { userProfile } = userState;
    const { sendEventsForEcommerceAction } = useAnalyticsContext();
    const [{ payloadEcommerceActionAnalytics, payloadEcommerceLocationActionAnalytics }] = useAnalytics();

    const [
        {
            userInfo,
            cart,
            isCreditNewAddress,
            optionalPlan,
            showGuestCheckoutLogin,
            userAccount,
            isSourcesLoading,
            isOpen,
            locationPCLoading,
            isCartLoading
        },
        dispatch
    ] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const [{ overridePCList, isProfileLoaded }] = useUserContext();
    const userType = useCheckUser();
    const { isSelectedLocationEmpty } = useCheckLocationEmpty();
    const [{ isEstimatesWithOwnedPc }] = useCartEstimate();
    const isEmpty = cart && Object.entries(cart)?.length > 0 ? cart?.items?.length === 0 : true;
    const [isCheckoutEnable, setIsCheckoutEnable] = useState(false);
    const isRatesFromTier2Radius = isTier2Radius();
    const displayQuoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
    const isEditQuoteFlow = checkIsEditQuoteFlow();

    useEffect(() => {
        if (isOpen && !isCartLoading) {
            /* Trigger event when no cart is there or with updated cart on mincart open */
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_VIEWED,
                payloadEcommerceActionAnalytics(),
                {
                    unavailable_items: cart?.unavailableCartItems?.length > 0
                }
            );
        }
    }, [isOpen, isCartLoading]);

    useEffect(() => {
        if (
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) &&
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)
        ) {
            if (
                moment().isAfter(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE), 'day') ||
                moment().isAfter(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE), 'day')
            ) {
                localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE);
                localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE);
            }
        }
        if (!startDate || !endDate) {
            filterDispatch({
                type: SET_START_DATE,
                startDate: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) || ''
            });
            filterDispatch({
                type: SET_END_DATE,
                endDate: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) || ''
            });
        }
        if (isValidSession()) {
            checkIfStartDateElapsed();
        }
    }, []);

    useEffect(() => {
        if (userType === USER_TYPE.CREDIT && isCreditNewAddress) {
            handleChange(VIEW_CART.CREATE_PROJECT_SOURCE, 'minicart');
        }
    }, [isCreditNewAddress]);

    useEffect(() => {
        setDisablCheckoutOnApiError('');
    }, [viewCart.lat]);

    const checkIfStartDateElapsed = () => {
        if (!isNaN(new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime())) {
            if (
                new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime() <
                new Date().setHours(0, 0, 0, 0)
            ) {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, '');
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, '');

                filterDispatch({ type: SET_START_DATE, startDate: '' });
                filterDispatch({ type: SET_END_DATE, endDate: '' });
                return true;
            } else {
                return false;
            }
        } else return false;
    };
    const handleSetLocation = () => {
        if (isCCPage()) {
            dispatch({ type: SET_CART_OVERLAY, cartOverlay: CART_OVERLAYS.LOCATION });
        } else {
            dispatch({ type: 'close' });
            document.body.classList.remove('overflow-hidden');
            filterDispatch({ type: EDIT_VIEW_OPEN });
            filterDispatch({ type: EDIT_VIEW_DETAILS, editViewSource: VARIABLE_CONFIG.CAP_VIEW_MODE.LOCATION_VIEW });
        }
    };
    const handleChange = (key, value) => {
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
    };

    const orderEstimates = cart?.estimatesResponse?.estimate;
    const {
        rentalAmount = '-',
        salesTax,
        fuelCharges,
        deliveryPickUpCharges,
        allOtherCharges
    } = orderEstimates?.totals || {};
    const envFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('ENVIRONMENTAL') > -1);
    const { charge = 0 } = envFeeObj || {};
    const { showDeliveryEstimate } = viewCart;
    const [isPerDay, setIsPerDay] = useState(startDate?.toString() === endDate?.toString());

    useEffect(() => {
        if (startDate?.toString() === endDate?.toString()) {
            setIsPerDay(true);
        } else {
            setIsPerDay(false);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (!userInfo.isAuthenticatedUser) {
            dispatch({ type: SHOW_GUEST_CHECKOUT_LOGIN, value: true });
        } else {
            dispatch({ type: SHOW_GUEST_CHECKOUT_LOGIN, value: false });
        }
    }, [userInfo.isAuthenticatedUser]);

    useEffect(() => {
        if (!locationPCLoading && isProfileLoaded) {
            const userData = payloadEcommerceLocationActionAnalytics();
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USER_DATA_OBJ, JSON.stringify(userData));
        }
    }, [locationPCLoading, isProfileLoaded]);

    const onCheckoutHandler = () => {
        const localViewCart = { ...viewCart };
        if (!isEditQuoteFlow) {
            localViewCart.isInStorePickup = userInfo?.isCreditCustomer ? false : true;
        }
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART, JSON.stringify(localViewCart));
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.USERINFO, JSON.stringify(userInfo));
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.PROJECTDETAILS, JSON.stringify(projectDetails));
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE, JSON.stringify(userProfile));
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS, isCreditNewAddress);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.SHOWGUESTCHECKOUTLOGIN, showGuestCheckoutLogin);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.OVERRIDEPCLIST, JSON.stringify(overridePCList));
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISSOURCESLOADING, isSourcesLoading);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CHECKOUTREFERRER, true);
        redirectToCheckoutOrQuotePage(isEditQuoteFlow ? displayQuoteId : '');
    };

    const handleCheckoutBtnClickModal = () => {
        if (cart?.unavailableCartItems?.length > 0) {
            setIsCheckoutEnable(true);
        } else {
            handleCheckoutBtnClick();
        }
    };

    const handleCheckoutBtnClick = async () => {
        if (isAccountClosed(userAccount)) {
            dispatch({
                type: SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
                showDelinquentModal: true
            });
            return;
        }
        if (disablCheckoutOnApiError) {
            return;
        }
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress && !projectDetails?.projectAddress1) {
            setDisablCheckoutOnApiError(
                intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-credit' })
            );
            return;
        } else if (
            ((userType === USER_TYPE.CREDIT && isCreditNewAddress) || userType !== USER_TYPE.CREDIT) &&
            !viewCart?.location
        ) {
            setDisablCheckoutOnApiError(intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage' }));
            return;
        } else {
            setDisablCheckoutOnApiError('');
        }
        try {
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STARTED,
                payloadEcommerceActionAnalytics()
            );
        } catch (error) {
            logError(error, false, 'handleCheckoutBtnClick');
        }
        if (!isEmpty && !disablCheckoutOnApiError) {
            onCheckoutHandler();
        }
    };

    const handleSalesTax = salesTaxVal => {
        let salesTaxTotal = salesTaxVal;

        const transportFeeTaxToBeRemoved = orderEstimates?.miscCharges?.find(
            item => item.type.indexOf('TRANSPORTATION SURCHARGE') > -1
        );
        salesTaxTotal -=
            viewCart?.isInStorePickup && transportFeeTaxToBeRemoved?.tax ? transportFeeTaxToBeRemoved?.tax : 0;

        const fuelFeeTaxToBeRemoved = orderEstimates?.miscCharges?.find(
            item => item.type.indexOf('FUEL CONVENIENCE CHARGE') > -1
        );
        salesTaxTotal -= fuelFeeTaxToBeRemoved?.tax ? fuelFeeTaxToBeRemoved?.tax : 0;

        let totalRppTax = 0;
        orderEstimates?.itemizedCharges?.products?.forEach(product => {
            totalRppTax += product?.rppTax;
        });

        salesTaxTotal -= totalRppTax;

        const deliveryFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('DELIVERY') > -1);
        const pickupFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('PICKUP') > -1);

        if (viewCart?.isInStorePickup && !showDeliveryEstimate) {
            salesTaxTotal -= deliveryFeeObj?.tax ? deliveryFeeObj?.tax : 0;
            salesTaxTotal -= pickupFeeObj?.tax ? pickupFeeObj?.tax : 0;
        }

        return salesTaxTotal;
    };

    const handleEnvAndFuelFees = () => {
        let amountToBeSubtractedFromOtherCharges = allOtherCharges;
        const envFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('ENVIRONMENTAL') > -1);
        const { charge = 0 } = envFeeObj || {};

        amountToBeSubtractedFromOtherCharges -= charge;
        let fuelFee = fuelCharges ? fuelCharges : 0;
        amountToBeSubtractedFromOtherCharges -= optionalPlan?.isFuelChargeChecked ? 0 : fuelFee;

        const transportFeeToBeRemoved = orderEstimates?.miscCharges?.find(
            item => item.type.indexOf('TRANSPORTATION SURCHARGE') > -1
        );
        amountToBeSubtractedFromOtherCharges -=
            viewCart?.isInStorePickup && transportFeeToBeRemoved?.charge ? transportFeeToBeRemoved?.charge : 0;

        return amountToBeSubtractedFromOtherCharges;
    };

    const getSubTotalWithDatesEntered = () => {
        return startDate && endDate ? handleSalesTax(salesTax) + handleEnvAndFuelFees() : 0;
    };

    const getSubTotal = () => {
        let envFees = startDate && endDate ? charge : 0;
        let subtotal = (isEstimatesWithOwnedPc() ? cart?.prices?.grand_total?.value : rentalAmount) + envFees;
        subtotal += getSubTotalWithDatesEntered();
        if (showDeliveryEstimate && startDate && endDate) {
            if (isRatesFromTier2Radius) {
                if (userType === USER_TYPE.CREDIT) {
                    subtotal += deliveryPickUpCharges;
                }
            } else {
                subtotal += deliveryPickUpCharges;
            }
        }
        subtotal = Number(subtotal).toFixed(2);
        return subtotal;
    };

    const calculateRentalAmountWhenNoDates = () => {
        let total = 0;
        if (isEstimatesWithOwnedPc()) {
            total = cart?.prices?.grand_total?.value;
        } else {
            if (!orderEstimates) {
                return 0;
            } else if (orderEstimates?.itemizedCharges?.products) {
                orderEstimates?.itemizedCharges?.products?.forEach(product => {
                    total += product?.rentalCost;
                });
            } else {
                cart?.items?.forEach(item => {
                    total += item.prices?.row_total.value;
                });
            }
        }
        return total;
    };

    return (
        <Suspense fallback={<LoadingIndicator />}>
            {isCheckoutEnable && (
                <ProductAvailability
                    title={intl.formatMessage({ id: 'itemAvailability-modal:title' })}
                    handleOnClose={setIsCheckoutEnable}
                    cart={cart}
                    dispatch={dispatch}
                    handleCheckoutBtnClick={handleCheckoutBtnClick}
                />
            )}

            {isCCPage() && userType === USER_TYPE.CREDIT ? (
                <MiniCartHeader disablCheckoutOnApiError={disablCheckoutOnApiError}></MiniCartHeader>
            ) : (
                <AnimatedHeader />
            )}
            <Body />
            {showFooter && (
                <Footer>
                    <div
                        className={
                            userProfile?.type != EMPLOYEE
                                ? 'minicart-footer-top'
                                : 'minicart-footer-top-without-checkout'
                        }>
                        <div className="minicart-footer-prices">
                            <b data-testid={cartDataLocators.minicart_estimatedsubtotal_label}>
                                {intl.formatMessage({ id: 'cart-footer:estimated-subtotal' })}
                            </b>
                            {!isSelectedLocationEmpty() && cart?.items?.length ? (
                                <div className="minicart-footer-total">
                                    <span>
                                        <b data-testid={cartDataLocators.minicart_dynamicprice_label}>
                                            {rentalAmount !== '-'
                                                ? currencyToLocale(getSubTotal())
                                                : currencyToLocale(calculateRentalAmountWhenNoDates())}
                                        </b>
                                        {isPerDay && (rentalAmount !== '-' || calculateRentalAmountWhenNoDates()) ? (
                                            <span>/day</span>
                                        ) : null}
                                    </span>

                                    <small className="charge">
                                        {showDeliveryEstimate && deliveryPickUpCharges && !startDate && !endDate
                                            ? `+${currencyToLocale(deliveryPickUpCharges)}`
                                            : null}
                                    </small>
                                </div>
                            ) : null}
                        </div>

                        {isSelectedLocationEmpty() ? (
                            <Button
                                type="button"
                                className="button button-pb0 button-small button-left button-inlineblock"
                                onClick={handleSetLocation}
                                data-testid={cartDataLocators.minicart_setyourlocation_linktext}
                                buttonAriaLabel={`${intl.formatMessage({
                                    id: 'cart-footer:set-your-location'
                                })} ${intl.formatMessage({ id: 'cart-footer:to-view-pricing' })}`}>
                                <span className="highlight">
                                    {intl.formatMessage({ id: 'cart-footer:set-your-location' })}
                                </span>
                                <span className="minicart-footer-setlocationtext">
                                    {intl.formatMessage({ id: 'cart-footer:to-view-pricing' })}
                                </span>
                            </Button>
                        ) : null}
                    </div>
                    {userProfile?.type != EMPLOYEE && (
                        <div className="minicart-footer-actions">
                            <ViewCartFooter handleCheckoutBtnClickModal={handleCheckoutBtnClickModal} />
                        </div>
                    )}
                </Footer>
            )}
        </Suspense>
    );
};

export default React.memo(ViewCart);

ViewCart.propTypes = {
    showFooter: PropTypes.bool,
    disablCheckoutOnApiError: PropTypes.string,
    setDisablCheckoutOnApiError: PropTypes.func
};

ViewCart.defaultProps = {
    showFooter: false,
    disablCheckoutOnApiError: '',
    setDisablCheckoutOnApiError: () => {}
};
