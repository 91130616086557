/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2021 Adobe
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
import { gql } from '@apollo/client';

const MUTATION_ADD_TO_CART = gql`
    mutation addSimpleProductToCart($cartId: String!, $cartItems: [SimpleProductCartItemInput]!) {
        addSimpleProductsToCart(input: { cart_id: $cartId, cart_items: $cartItems }) {
            cart {
                total_quantity
                items {
                    __typename
                    uid
                    quantity
                    prices {
                        price {
                            currency
                            value
                        }
                        row_total {
                            currency
                            value
                        }
                    }
                    product {
                        name
                        sku
                        category_name
                        parent_category_name
                        category_path
                        ec_pc_inventory
                        thumbnail {
                            url
                        }
                    }
                }
            }
        }
    }
`;

export default MUTATION_ADD_TO_CART;
