export const locationsDatalocator = {
    save_and_continue_cta_testid: 'locationOverlay_saveAndContinue_cta',
    existing_project_cta_testid: 'locationOverlay_existingProject_cta',
    select_project_text_testid: 'accountSelection_selectProject_txt',
    project_name_testid: 'accountSelection_projName_txt',
    project_address_testid: 'accountSelection_projAdd_txt',
    location_help_message_testid: 'locationOverlay_locationHelp_txt',
    locationOverlay_address_cta: 'locationOverlay_address_cta',
    locationOverlay_searchByZip_txt: 'locationOverlay_searchByZip_txt',
    locationOverlay_sharingYourAddress_txt: 'locationOverlay_sharingYourAddress_txt',
    locationOverlay_locationfield_txtfield: 'locationOverlay_locationfield_txtfield',
    locationOverlay_location_lbl: 'locationOverlay_location_lbl',
    locationOverlay_apiSuggestions_txt: 'locationOverlay_apiSuggestions_txt',
    locationOverlay_locationerrormessage_txt: 'locationOverlay_locationerrormessage_txt',
    search_location_label: 'search_location_label',
    choose_an_account_label: 'account_search',
    accountSelector_test_id: 'accountSelector_test_id',
    select_dropdown_testid: 'select_dropdown_testid'
};
