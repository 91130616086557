import { googleApiKey } from '../../../../components/global/utils/commonUtils';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { GENERAL_EQUIPMENT_AND_TOOLS } from '../../../../components/location/constants';
import MapPinIconSelected from '../../../../resources/images/map-pin-ldp-selected.png';
import MapPinIconSelectedMobile from '../../../../resources/images/map-pin-ldp-selected-mobile.png';
import MapPinSpecialtyIconSelected from '../../../../resources/images/map-pin-specialty-ldp-selected.png';
import MapPinSpecialtyIconSelectedMobile from '../../../../resources/images/map-pin-specialty-ldp-selected-mobile.png';
var checkLocationUrl = window.location.pathname.includes('location');

document.addEventListener('readystatechange', event => {
    if (checkLocationUrl && document.readyState === 'complete') {
        var equipmentLength = document.querySelectorAll('.location-tools-row .equipment-location-details a').length;
        var locationShowLinkButton = document.querySelector('.location-show-link');
        if (equipmentLength > 12) {
            locationShowLinkButton.classList.toggle('location-show-link--show');
            locationShowLinkButton.addEventListener('click', function () {
                locationShowLinkButton.closest('.location-tools-row').classList.toggle('location-tools-row--expand');
                locationShowLinkButton.classList.toggle('location-show-link--show');
            });
        }
    }
});

if (window.location.href.indexOf('/location') > -1) {
    const specialtyType = ENV_CONFIG.SPECIALTY_TYPE?.toLowerCase();
    let mql = window.matchMedia('(max-width: 639px)');
    const specialityMapIcon = mql.matches
        ? MapPinSpecialtyIconSelectedMobile
        : MapPinSpecialtyIconSelected
    const markerMapIcon = mql.matches
        ? MapPinIconSelectedMobile
        : MapPinIconSelected
    const setMapIcon = specialtyType === GENERAL_EQUIPMENT_AND_TOOLS?.toLowerCase() ? markerMapIcon : specialityMapIcon;
    document.getElementById('location-submit')?.addEventListener('click', getDirections, false);

    function getDirections() {
        var current_address = localStorage.getItem('startingLocationForGetDirections');
        var element = document.getElementById('currentAddress');
        element.value = current_address;
    }

    window.initMap = () => {
        var MAP_RADIUS = 1000;
        var MILES_TO_METER_CONVERSION_FACTOR = 1609.344;
        var strictBounds = '';
        var latitude = document?.getElementById('destinationLatitude')?.value
            ? parseFloat(document?.getElementById('destinationLatitude')?.value)
            : 37.0902;
        var longitude = document.getElementById('destinationLongitude')?.value
            ? parseFloat(document?.getElementById('destinationLongitude')?.value)
            : -95.7129;

        //map style
        var styledMapType = new google.maps.StyledMapType([
            {
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#f5f5f5'
                    }
                ]
            },
            {
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#616161'
                    }
                ]
            },
            {
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        color: '#f5f5f5'
                    }
                ]
            },
            {
                featureType: 'administrative.land_parcel',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#bdbdbd'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#eeeeee'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#757575'
                    }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#e5e5e5'
                    }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#9e9e9e'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#ffffff'
                    }
                ]
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#757575'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#dadada'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#616161'
                    }
                ]
            },
            {
                featureType: 'road.local',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#9e9e9e'
                    }
                ]
            },
            {
                featureType: 'transit.line',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#e5e5e5'
                    }
                ]
            },
            {
                featureType: 'transit.station',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#eeeeee'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#c9c9c9'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#9e9e9e'
                    }
                ]
            }
        ]);
        var getMapBounds = map => {
            var circle = new google.maps.Circle({
                radius: MAP_RADIUS * MILES_TO_METER_CONVERSION_FACTOR,
                center: pointA
            });
            strictBounds = new google.maps.LatLngBounds(
                circle.getBounds().getSouthWest(),
                circle.getBounds().getNorthEast()
            );
        };

        var checkAllowedRadius = map => {
            if (!strictBounds) {
                getMapBounds(map);
            }
            if (strictBounds.contains(map.getCenter())) return;
            var c = map.getCenter(),
                x = c.lng(),
                y = c.lat(),
                maxX = strictBounds.getNorthEast().lng(),
                maxY = strictBounds.getNorthEast().lat(),
                minX = strictBounds.getSouthWest().lng(),
                minY = strictBounds.getSouthWest().lat();
            if (x < minX) x = minX;
            if (x > maxX) x = maxX;
            if (y < minY) y = minY;
            if (y > maxY) y = maxY;

            map.setCenter(new google.maps.LatLng(y, x));
        };
        var pointA = new google.maps.LatLng(latitude, longitude),
            myOptions = {
                zoom: 15,
                maxZoom: 15,
                minZoom: 3,
                center: pointA,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                disableDefaultUI: false,
                zoomControl: true
            },
            map = new google.maps.Map(document.getElementById('map-canvas'), myOptions),
            markerA = new google.maps.Marker({
                position: pointA,
                title: 'center',
                map: map
            });
        markerA.setIcon(setMapIcon);
        map.mapTypes.set('styled_map', styledMapType);
        map.setMapTypeId('styled_map');
        google.maps.event.addListener(map, 'drag', function () {
            checkAllowedRadius(map);
        });
    };
    const locationScript = document.createElement('script');
    locationScript.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&callback=initMap&libraries=places`
    );
    locationScript.setAttribute('async', '');
    locationScript.setAttribute('defer', '');
    document.body.appendChild(locationScript);
}