import React from 'react';
import { useIntl } from 'react-intl';
import OrderSummaryFactory from '../../../../components/molecules/orderSummaryFactory/OrderSummaryFactory';
import { FACTORYSOURCE } from '../../../../components/molecules/orderSummaryFactory/constants/orderSummaryFactoryConstants';
import ProductList from '../productList';

const MinicartBodyDotCom = ({ cart }) => {
    const intl = useIntl();

    return (
        <>
            <ProductList
                availableCartItems={cart?.availableCartItems}
                unavailableCartItems={cart?.unavailableCartItems}
            />
            <OrderSummaryFactory
                title={intl.formatMessage({ id: 'cart-order-summary:title' })}
                source={FACTORYSOURCE.MINICART}
            />
        </>
    );
};

export default MinicartBodyDotCom;
