import React from 'react';
import { useIntl } from 'react-intl';
import Close from '../../../../../resources/images/cross.svg';
import Button from '../../../Button/button';
import { headerDatalocator } from '../dataLocators';

const AnimatedHeaderP2P = ({ showHeader, showGradient, handleMiniCartClose }) => {
    const intl = useIntl();

    if (showHeader && showGradient) {
        return (
            <div className="headerroot space-between headerroot-cart">
                <p className={'header__text__small__animated'}>
                    {intl.formatMessage({ id: 'cart-header:your-cart' })}
                </p>
                <Button
                    className="button button-pb0 cartCloseBtn"
                    buttonAriaLabel={headerDatalocator.close_button}
                    title={headerDatalocator.close_button}
                    tabIndex={0}
                    onClick={handleMiniCartClose}>
                    <Close aria-hidden="true" tabIndex={'-1'} />
                </Button>
            </div>
        );
    } else if (showHeader && !showGradient) {
        return (
            <div className="root-no-gradient space-between">
                <p className={'header__text__small__animated'}>
                    {intl.formatMessage({ id: 'cart-header:your-cart' }) }
                </p>
                <Button
                    className="button button-pb0"
                    buttonAriaLabel={headerDatalocator.close_button}
                    title={headerDatalocator.close_button}
                    onClick={handleMiniCartClose}>
                    <Close aria-hidden="true" tabIndex={'-1'} />
                </Button>
            </div>
        );
    } else {
        return (
            <div className="root-no-gradient space-between">
                <h2 className={'header__text__normal'}>
                    {intl.formatMessage({ id: 'cart-header:your-cart' })}
                </h2>
                <Button
                    type="button"
                    className="button button-pb0 closeBtnFocus"
                    buttonAriaLabel={headerDatalocator.close_button}
                    title={headerDatalocator.close_button}
                    onClick={handleMiniCartClose}>
                    <Close aria-hidden="true" tabIndex={'-1'} />
                </Button>
            </div>
        );
    }
};

export default AnimatedHeaderP2P;
