import React, { useEffect, useRef, useState } from 'react';
import { instanceOf, oneOf, string, number, bool, func, object, array, oneOfType } from 'prop-types';
import { DateRangePicker } from 'react-date-range';
import useMedia from '../../../../../hooks/useMedia';
import { MEDIA_TYPE } from '../../../../../constants/screenConstants';
import './rangepicker.scss';
import { useCartState } from '../../../../../aem-core-components/components/Minicart/cartContext';
import { RESET_CLICKS, SET_CALENDAR_DATE_INTERACTION } from '../../../../../aem-core-components/actions/constants';
import { selectStartDate, selectEndDate, getHolidayList } from '../../../../../components/global/utils/commonUtils';
import { useIntl } from 'react-intl';

const RangeCalander = props => {
    const {
        setShowWarning,
        setRangeState,
        rangeState,
        classes,
        startDate,
        endDate,
        dateDisplayFormat,
        color,
        minDate,
        maxDate,
        months,
        editableDateInputs,
        setIsResetClicked,
        focusedRange,
        onFocusedRangeChange,
        checkIfDayDisabled,
        setIsEndDateEmpty,
        setIsStartDateEarly
    } = props;
    const [{ startDateClick, endDateClick, calendarDateInteraction }, dispatch] = useCartState();
    const [rangescroll, setRangescroll] = useState({ enabled: true });
    const mediaType = useMedia();
    const rangeRef = useRef(null);
    const disabledDates = getHolidayList();
    const formatter = new Intl.DateTimeFormat('en-US', { month: 'short' });

    useEffect(() => {
        rangeRef.current = rangeState;
    }, [rangeState]);

    useEffect(() => {
        removeRangeStyling(rangeState[0].startDate, rangeState[0].endDate);
        const startDate = document.querySelector('.rdrStartEdge');
        const endDate = document.querySelector('.rdrEndEdge');
        if (startDate && endDate) {
            startDate.style.background = '#f2f2f2';
            endDate.style.background = '#f2f2f2';
        }
        if (rangeState[0].startDate !== '' && rangeState[0].endDate !== '') {
            conditionalHideNextPrevBtn(
                new Date(
                    new Date(rangeState[0].startDate).getFullYear(),
                    new Date(rangeState[0].startDate).getMonth(),
                    1
                )
            );
        }
    }, [rangeState[0].startDate, rangeState[0].endDate]);

    useEffect(() => {
        if (!rangeState[0]?.startDate) {
            conditionalHideNextPrevBtn(new Date());
        }
        setTimeout(function () {
            // used ref so setTimeout will get the latest value of rangeState
            removeRangeStyling(rangeRef?.current?.[0].startDate, rangeRef?.current?.[0].endDate);
        }, 200);
        return () => {
            dispatch({ type: RESET_CLICKS });
        };
    }, []);

    useEffect(() => {
        if (
            !isNaN(new Date(rangeState[0].startDate).getTime()) &&
            (rangeState[0].endDate === '' || isNaN(new Date(rangeState[0].endDate).getTime()))
        ) {
            onFocusedRangeChange([0, 1]);
        }
    }, []);

    const removeRangeStyling = (startDate, endDate) => {
        if (
            (startDate === '' && endDate === '') ||
            (startDate && endDate === '') ||
            (startDate === '' && endDate) ||
            isNaN(new Date(startDate).getTime()) ||
            isNaN(new Date(endDate).getTime())
        ) {
            const dates = document.querySelectorAll('.rdrInRange');
            dates?.forEach(date => ((date.style.color = 'rgb(255,255,255)'), (date.style.background = 'none')));
        } else {
            const dates = document.querySelectorAll('.rdrInRange');
            dates?.forEach(date => ((date.style.color = '#f2f2f2'), (date.style.background = '#f2f2f2')));
        }
    };

    useEffect(() => {
        if (mediaType !== MEDIA_TYPE.DESKTOP) {
            setRangescroll({
                enabled: true,
                monthHeight: 285,
                longMonthHeight: 342,
                calendarWidth: window.innerWidth - 32,
                monthWidth: window.innerWidth - 32
            });
        } else {
            setRangescroll({
                enabled: false,
                monthHeight: 328,
                longMonthHeight: 256
            });
        }
    }, [mediaType]);

    const setClickedDate = (selectedRange, currentRange) => {
        let dateClicked = '';
        if (selectedRange?.endDate?.getTime() == selectedRange?.startDate?.getTime()) {
            dateClicked = selectedRange.endDate.getTime();
        } else if (currentRange[0]?.endDate === '' && !isNaN(new Date(currentRange[0]?.startDate).getTime())) {
            if (currentRange[0]?.startDate?.getTime() == selectedRange?.endDate?.getTime()) {
                dateClicked = selectedRange.startDate.getTime();
            } else if (currentRange[0]?.startDate?.getTime() == selectedRange?.startDate?.getTime()) {
                dateClicked = selectedRange.endDate.getTime();
            }
        } else if (
            !isNaN(new Date(currentRange[0]?.startDate).getTime()) &&
            !isNaN(new Date(currentRange[0]?.endDate).getTime())
        ) {
            if (currentRange[0]?.startDate?.getTime() == selectedRange?.startDate?.getTime()) {
                dateClicked = selectedRange.endDate.getTime();
            } else if (currentRange[0]?.startDate?.getTime() == selectedRange?.endDate?.getTime()) {
                dateClicked = selectedRange.startDate.getTime();
            }
        }
        return dateClicked;
    };

    const onDateClick = item => {
        dispatch({ type: SET_CALENDAR_DATE_INTERACTION });
        let selectedRange = item.selection;
        setRangeState([selectedRange]);
        let currentRange = [...rangeState];
        setIsEndDateEmpty(false);
        setIsStartDateEarly(false);

        //hide show warning
        setShowWarning(false);

        //setting clicked date
        const dateClicked = setClickedDate(selectedRange, currentRange);

        //---conditions to set range of range picker---
        //on datepicker opened or selecting end date same as start date when end date is empty
        if (currentRange[0]?.startDate === '' && currentRange[0]?.endDate === '') {
            currentRange[0].startDate = new Date(dateClicked);
            currentRange[0].endDate = '';
            setRangeState([...currentRange]);
            setIsResetClicked(false);
            return;
        }
        //if end date empty and  new date clicked before or after
        if (
            !isNaN(new Date(currentRange[0].startDate).getTime()) &&
            currentRange[0]?.endDate === '' &&
            !isNaN(dateClicked)
        ) {
            if (dateClicked < new Date(currentRange[0].startDate).getTime()) {
                currentRange[0].startDate = new Date(dateClicked);
            } else if (!calendarDateInteraction && startDateClick) {
                currentRange[0].startDate = new Date(dateClicked);
            } else if (
                dateClicked > new Date(currentRange[0].startDate).getTime() ||
                dateClicked == new Date(currentRange[0].startDate).getTime()
            ) {
                currentRange[0].endDate = new Date(dateClicked);
            }
            setRangeState([...currentRange]);
            setIsResetClicked(false);
            return;
        }

        if (calendarDateInteraction || endDateClick) {
            selectEndDate(currentRange, dateClicked, setRangeState);
        } else {
            selectStartDate(currentRange, dateClicked, setRangeState);
        }
        setIsResetClicked(false);
    };

    const conditionalHideNextPrevBtn = date => {
        const prevBtn = document.querySelector('.rdrPprevButton');
        const nextBtn = document.querySelector('.rdrNextButton');
        if (
            (minDate.getMonth() === date.getMonth() && minDate.getFullYear() === date.getFullYear()) ||
            (minDate.getMonth() === date.getMonth() + 1 && minDate.getFullYear() === date.getFullYear())
        ) {
            prevBtn.classList.add('hidden_arrow_btn');
            nextBtn.classList.remove('hidden_arrow_btn');
        } else {
            prevBtn.classList.remove('hidden_arrow_btn');
        }
        if (
            (maxDate.getMonth() === date.getMonth() && maxDate.getFullYear() === date.getFullYear()) ||
            (maxDate.getMonth() === date.getMonth() + 1 && maxDate.getFullYear() === date.getFullYear())
        ) {
            prevBtn.classList.remove('hidden_arrow_btn');
            nextBtn.classList.add('hidden_arrow_btn');
        } else {
            nextBtn.classList.remove('hidden_arrow_btn');
        }
    };
    const intl = useIntl();
    return (
        <>
            <DateRangePicker
                className={`calendarwrap ${classes}`}
                onChange={item => {
                    onDateClick(item);
                }}
                editableDateInputs={editableDateInputs}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                retainEndDateOnFirstSelection={false}
                inputRanges={[]}
                staticRanges={[]}
                dragSelectionEnabled={false}
                dateDisplayFormat={dateDisplayFormat}
                minDate={minDate}
                maxDate={maxDate}
                preventSnapRefocus={true}
                months={months}
                focusedRange={focusedRange}
                onRangeFocusChange={DateRange => onFocusedRangeChange(DateRange)}
                infiniteMonths={true}
                ranges={rangeState}
                direction={mediaType !== MEDIA_TYPE.DESKTOP ? 'vertical' : 'horizontal'}
                showMonthAndYearPickers={false}
                showDateDisplay={false}
                scroll={rangescroll}
                monthDisplayFormat="MMMM y"
                disabledDay={checkIfDayDisabled}
                onShownDateChange={(DateRange, Calendar) => {
                    conditionalHideNextPrevBtn(DateRange);
                }}
                ariaLabels={{
                    prevButton: `${intl.formatMessage({ id: 'previous-month' })}`,
                    nextButton: `${intl.formatMessage({ id: 'next-month' })}`,
                    monthPicker: 'select Month',
                    yearPicker: 'select Year'
                }}
            />
        </>
    );
};

const getMaxDate = () => {
    if (new Date().getDate() !== 1) {
        return new Date(new Date().getFullYear(), new Date().getMonth() + 12, new Date().getDate() - 1);
    } else {
        const getDaysInMonth = new Date(new Date().getYear(), new Date().getMonth(), 0).getDate();
        return new Date(new Date().getFullYear(), new Date().getMonth() + 11, getDaysInMonth);
    }
};
RangeCalander.defaultProps = {
    classes: '',
    dateDisplayFormat: 'M/d/yyyy',
    direction: 'horizontal',
    color: '#F2F2F2',
    startDate: new Date(),
    endDate: new Date(),
    minDate: undefined,
    maxDate: getMaxDate(),
    months: 12,
    editableDateInputs: true,
    checkIfDayDisabled: () => {},
    setShowWarning: () => {},
    setIsEndDateEmpty: () => {},
    setIsStartDateEarly: () => {}
};

RangeCalander.propTypes = {
    setShowWarning: func,
    rangeState: oneOfType([array, object]),
    direction: oneOf(['horizontal', 'vertical']),
    minDate: instanceOf(Date),
    dateDisplayFormat: string,
    months: number,
    maxDate: instanceOf(Date),
    startDate: instanceOf(Date),
    endDate: instanceOf(Date),
    editableDateInputs: bool,
    checkIfDayDisabled: func,
    classes: string,
    setIsEndDateEmpty: func,
    setIsStartDateEarly: func
};

export default RangeCalander;
