import { array, bool, func } from 'prop-types';
import React from 'react';
import LoadingIndicator from '../../../LoadingIndicator';
import classes from './projectListing.css';

const ProjectListing = props => {
    const {
        displaySearchResults,
        projectList,
        isProjectsLoading
    } = props;

    return (
        <>
            {isProjectsLoading ? (
                <div className={classes.jobsitesLoaderMinicart}>
                    <LoadingIndicator />
                </div>
            ) : (
                projectList?.length > 0 && (
                    <div className={`${classes.searchRoot} ${classes.searchRootMargin}`} role="radiogroup">
                        {projectList?.map((data) => (
                            <React.Fragment key={data?.rmJobsiteId}>{displaySearchResults(data)}</React.Fragment>
                        ))}
                    </div>
                )
            )
            }
        </>
    );
};

ProjectListing.propTypes = {
    displaySearchResults: func,
    projectList: array,
    isProjectsLoading: bool
};

ProjectListing.defaultProps = {
    displaySearchResults: () => { },
    projectList: [],
    isProjectsLoading: false
};

export default React.memo(ProjectListing);
