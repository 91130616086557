import React, { memo } from 'react';
import { bool, func, string, array } from 'prop-types';
import ReactModal from 'react-modal';
import { useIntl } from 'react-intl';
import useMedia from '../../../../hooks/useMedia';
import ProductStatusDetails from './productStatusDetails/ProductStatusDetails';
import SideDrawerModal from '../modals/SideDrawerModal';
import { BackHeader } from '../../../../aem-core-components/components/Minicart/headers';
import Button from '../../atoms/button/button';
import Close from '../../../../resources/images/close.svg';
import CloseIcon from '../../../../resources/images/cross_24x24.svg';
import './rentalChangeConfirmationModal.scss';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';

const RentalChangeConfirmationModal = ({
    isOpen,
    handleModalClose,
    handleConfirmation,
    handleBackNavigation,
    portalClassName,
    productList
}) => {
    const intl = useIntl();
    const mediaType = useMedia();

    const header = () => (
        <div className="rental-change-modal__header">
            {mediaType === MEDIA_TYPE.MOBILE ? (
                <h5>{intl.formatMessage({ id: 'rental-change-modal:header' })}</h5>
            ) : (
                <div className="rental-change-modal__header-headline">
                    <h6>{intl.formatMessage({ id: 'rental-change-modal:header' })}</h6>
                    <Button className="rental-change-modal__header-close" onClick={handleModalClose} customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}>
                        <Close tabIndex={-1} aria-hidden={true} />
                    </Button>
                </div>
            )}
            {mediaType !== MEDIA_TYPE.MOBILE && <hr className="divider" />}
        </div>
    );

    const content = () => {
        return (
            <div className="rental-change-modal__content">
                {productList?.map(product => (
                    <ProductStatusDetails product={product} key={product?.product?.sku} />
                ))}
            </div>
        );
    };

    const footer = () => (
        <div className="rental-change-modal__footer">
            <Button
                className="button button-primary button-block"
                type="button"
                onClick={() => handleConfirmation(true)} buttonAriaLabel={intl.formatMessage({ id: 'rental-change-modal:yes-btn' })}>
                {intl.formatMessage({ id: 'rental-change-modal:yes-btn' })}
            </Button>
            <Button
                className="button button-outline-primary button-block"
                type="button"
                onClick={() => handleConfirmation(false)} buttonAriaLabel={intl.formatMessage({ id: 'rental-change-modal:no-btn' })}>
                {intl.formatMessage({ id: 'rental-change-modal:no-btn' })}
            </Button>
        </div>
    );

    const drawerContent = () => (
        <>
            {header()}
            {content()}
        </>
    );

    const drawerHeader = () => (
        <>
            <BackHeader closeModal={handleBackNavigation} />
            <Button
                className="rental-change-modal__drawer-header__close"
                customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                onClick={handleModalClose}>
                <CloseIcon aria-hidden="true" tabIndex="-1" />
            </Button>
        </>
    );

    const renderSideDrawer = () => (
        <SideDrawerModal
            isModalOpen={isOpen}
            header={drawerHeader()}
            content={drawerContent()}
            footer={footer()}
            modalFooterClass="rental-change-modal__drawer-footer"
            modalHeaderClass="rental-change-modal__drawer-header"
            modalContentClass="rental-change-modal__drawer-content"
        />
    );

    return (
        <>
            {mediaType === MEDIA_TYPE.MOBILE ? (
                renderSideDrawer()
            ) : (
                <ReactModal
                    role="none"
                    isOpen={isOpen}
                    portalClassName={portalClassName}
                    className="rental-change-modal">
                    {header()}
                    {content()}
                    {footer()}
                </ReactModal>
            )}
        </>
    );
};

RentalChangeConfirmationModal.defaultProps = {
    productList: [],
    isOpen: false,
    handleModalClose: () => {},
    handleConfirmation: () => {},
    portalClassName: '',
    handleBackNavigation: () => {}
};

RentalChangeConfirmationModal.propTypes = {
    productList: array,
    handleModalClose: func,
    handleConfirmation: func,
    isOpen: bool,
    portalClassName: string,
    handleBackNavigation: func
};

export default memo(RentalChangeConfirmationModal);
