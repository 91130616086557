export const productTileDataLocator = {
    productTile_productImage: 'productTile_productImage',
    productTile_productFavoriteIndicator: 'productTile_productFavoriteIndicator',
    productTile_productName: 'productTile_productName',
    productTile_productPrice: 'productTile_productPrice',
    productTile_selectALocationLink: 'productTile_selectALocationLink',
    productTile_addToCartIcon: 'productTile_addToCartIcon',
    productTile_catClassInformation: 'productTile_catClassInformation',
    productTile_max_quantity_close_testid: 'productTile_max_quantity_close_testid',
    productTile_max_quantity_return_to_listing_testid: 'productTile_max_quantity_return_to_listing_testid',
    productTile_max_quantity_modal_title_testid: 'productTile_max_quantity_modal_title_testid',
    productTile_add_to_cart_CTA_testid: 'productTile_add_to_cart_CTA_testid',
    productTile_add_rental_details: 'productTile_add_rental_details',
    productTile_store_selector: 'productTile_store_selector',
    change_store_cta: 'change_store_cta',
    checkout_chooseastore_link: 'checkout_chooseastore_link',
    checkout_closetstoreneartoyou_CTA: 'checkout_closetstoreneartoyou_CTA',
    checkout_storedistance_label: 'checkout_storedistance_label',
    checkout_InstorePickUp_Address_label: 'checkout_InstorePickUp_Address_label',
    checkout_storedetails_link: 'checkout_storedetails_link',
    checkout_storephonenumber_label: 'checkout_storephonenumber_label'
};
