import React from 'react';
import { useIntl } from 'react-intl';
import Alert from '../../../../../components/global/atoms/alert/alert';
import DatePickerInput from '../../../../../components/global/modules/rangepicker/DatePickerInput';
import { CART_OVERLAYS } from '../../constants';
import { miniCartDatalocator } from '../../dataLocators';
import AccountSelector from '../Components/accountSelector';
import { miniCartHeaderDatalocator } from '../dataLocators';
import classes from '../styles.css';
import { STORAGE_CONFIG } from '../../../../../constants/storageConfig';

const MinicartHeaderDotCom = props => {
    const {
        cookieAccount,
        userAccount,
        getChooseAddress,
        handleCartOverlay,
        disablCheckoutOnApiError,
        handleCalendarOpen
    } = props;

    const intl = useIntl();

    return (
        <div className={classes.root}>
            {cookieAccount?.CurrentWynneAccount && (
                <>
                    <p className="location-autocomplete-label">
                        {intl.formatMessage({ id: 'cart-header:location-label' })}
                    </p>
                    <AccountSelector
                        projectName={userAccount?.accountName}
                        selectedProjectAddress1={getChooseAddress()}
                        accountClick={() => handleCartOverlay(CART_OVERLAYS.LOCATION)}
                        buttonAriaLabel={intl.formatMessage({ id: 'account:ac-minicart-choose-account-text' })}
                    />
                    {disablCheckoutOnApiError && (
                        <Alert
                            localStyle={`alert-withbottommargin ${classes.alertPadding}`}
                            type={'error'}
                            message={disablCheckoutOnApiError}
                            dataTestId={miniCartDatalocator.invalid_address_error}
                        />
                    )}
                </>
            )}
            <div className={`${classes.DatesContainer}`}>
                <DatePickerInput
                    titleStartDate={'click for set Start and end date'}
                    titleEndDate={'click for set Start and end date'}
                    startDate={
                        localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)
                            ? isNaN(new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getDate())
                                ? ''
                                : new Date(
                                      localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)
                                  ).toLocaleDateString('en-US', {
                                      month: 'numeric',
                                      day: 'numeric',
                                      year: '2-digit'
                                  })
                            : ''
                    }
                    endDate={
                        localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)
                            ? isNaN(new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)).getDate())
                                ? ''
                                : new Date(
                                      localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)
                                  ).toLocaleDateString('en-US', {
                                      month: 'numeric',
                                      day: 'numeric',
                                      year: '2-digit'
                                  })
                            : ''
                    }
                    handleToggle={handleCalendarOpen}
                    customClass={classes.miniCartDatepicker}
                    datePickerLabelCustomClass={'labelCustomColor'}
                />
            </div>
        </div>
    );
};

export default MinicartHeaderDotCom;
