import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ProjectSearch from '../../../../aem-core-components/components/Minicart/locations/projectListing/ProjectSearch';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import CloseCircleIcon from '../../../../resources/images/close-circle.svg';
import { JOBSITE_SEARCH_LENGTH, getProjectsFromCookie } from '../../utils/commonUtils';
import JobsiteListView from './JobsiteListView';
import './jobsitesListingModal.scss';

const AllJobsites = (props) => {
    const { isProjectsLoading, accountProjects, recommendedJobsites, isJobSiteLoaded,
        selectedValue, setSelectedValue, setSelectedJobsite, cardStyle, jobsiteListLabel, isModalOpen, selectedProjectId } = props;
    const intl = useIntl();
    const [searchQuery, setSearchQuery] = useState('');
    const [showProjectSearch, setShowProjectSearch] = useState(false);
    const [searchedJobsite, setSearchedJobsite] = useState([]);

    useEffect(() => {
        const selectedJobsiteId = selectedValue || selectedProjectId;
        const selectedjobsiteInList =
            accountProjects?.find(data => {
                return data?.jobNumber == selectedJobsiteId;
            }) || null;
        if (!selectedjobsiteInList) {
            const projectInfoCookies = getProjectsFromCookie();
            const {
                CurrentWynneAccount,
                CurrentWynneAccountCID,
                SLLat,
                SLLong,
                SLA,
                SLC,
                SLS,
                SLZ,
                CurrentJobSite,
                locationPC,
                projectName,
                projectAddress2,
                primaryContactName,
                phoneNumber,
                accessNotes,
                poNumber
            } = projectInfoCookies || {};

            const selProjectData = {
                jobNumber: CurrentJobSite,
                jobName: projectName,
                account: CurrentWynneAccount,
                companyID: CurrentWynneAccountCID,
                address1: SLA,
                address2: projectAddress2,
                city: SLC,
                state: SLS,
                zip: SLZ,
                latitude: SLLat,
                longitude: SLLong,
                isGeoCoded: true, //TODO-unavailable
                locationPC,
                salesRepId: '', //TODO-unavailable
                isActive: true,
                jobKey: CurrentJobSite,
                contactName: primaryContactName,
                contactPhone: phoneNumber,
                hasEquipment: false, //TODO-unavailable
                equipmentCount: 0, //TODO-unavailables
                deliveryInstructions: accessNotes,
                customerPO: poNumber
            };

            if (searchQuery) {
                setSearchedJobsite(accountProjects);
            } else {
                setSearchedJobsite([selProjectData, ...accountProjects]);
            }
        } else {
            let removedItem;
            let tempProjectList;

            if (selectedJobsiteId) {
                removedItem = accountProjects?.find(data => {
                    return data?.jobNumber == selectedJobsiteId;
                });

                if (removedItem) {
                    tempProjectList = accountProjects?.filter(data => {
                        return data?.jobNumber != selectedJobsiteId;
                    });
                    tempProjectList.unshift(removedItem);
                    setSearchedJobsite(tempProjectList);
                }
            }
        }
    }, [accountProjects]);

    useEffect(() => {
        if (
            accountProjects?.length > JOBSITE_SEARCH_LENGTH ||
            searchQuery ||
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.JOBSITE_SEARCH_KEYWORD)
        ) {
            setShowProjectSearch(true);
        } else {
            setShowProjectSearch(false);
        }
    }, [accountProjects, searchQuery]);

    const accountSearch = () => {
        return (
            <ProjectSearch
                searchQuery={searchQuery}
                onSearchQueryChange={setSearchQuery}
                projectList={searchedJobsite}
                placeholder={intl.formatMessage({ id: 'cap:search-jobsite' })}
                closeIcon={<CloseCircleIcon />}
                isSearchIconVisible
            />
        );
    }

    return (
        <JobsiteListView
            isProjectsLoading={isProjectsLoading}
            accountProjects={accountProjects}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchedJobsite={searchedJobsite}
            showProjectSearch={showProjectSearch}
            recommendedJobsites={recommendedJobsites}
            isJobSiteLoaded={isJobSiteLoaded}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setSelectedJobsite={setSelectedJobsite}
            cardStyle={cardStyle}
            jobsiteListLabel={jobsiteListLabel}
            isModalOpen={isModalOpen}
            jobsiteSearch={accountSearch}
        />
    )
};

export default React.memo(AllJobsites);
