import React from 'react';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { ChooseAStoreView } from './ChooseAStoreView';
import useAnalytics from '../../../../hooks/useAnalytics';
import { useFilterState } from '../../../cap';

export const ChooseAStoreViewWrapper = props => {
    const [cartState] = useCartState();
    const analyticEvents = useAnalyticsContext();
    const [{}, dispatch] = useFilterState();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    const {
        makeSelectedClicked,
        handleBackClickMobile,
        mobileCloseButton,
        onStoreDetailsClick,
        mapToggle,
        storesData,
        selectedStoreValue,
        setSelectedStoreValue,
        storeDetailData,
        mediaType,
        setIsBestMatch,
        isBestMatch,
        handleToggle,
        handleChooseThisStoreClick,
        onStoreDetailClick,
        saveStoreDetails,
        setOnStoreDetailsClick,
        handleChooseStoreFromStoreDetails,
        handleMapToggle,
        handleCloseClick,
        nextAvailableStartDate,
        currentLocation,
        pickupStoreFooterClass,
        pickupStoreHeaderClass,
        mapMobileClass,
        variant,
        pickupStoreDrawerHeading,
        pickupStoreDrawerFooter,
        isTabletView,
        showAvailableStartDate,
        showInDrawerForTablet,
        showMonochromaticMap,
        storeDetailsClassName,
        storesClassName,
        companyId,
        triggerStoreModalAnalytics
    } = props;

    return (
        <>
            <ChooseAStoreView
                makeSelectedClicked={makeSelectedClicked}
                handleBackClickMobile={handleBackClickMobile}
                mobileCloseButton={mobileCloseButton}
                onStoreDetailsClick={onStoreDetailsClick}
                mapToggle={mapToggle}
                storesData={storesData}
                selectedStoreValue={selectedStoreValue}
                setSelectedStoreValue={setSelectedStoreValue}
                storeDetailData={storeDetailData}
                mediaType={mediaType}
                setIsBestMatch={setIsBestMatch}
                isBestMatch={isBestMatch}
                handleToggle={handleToggle}
                handleChooseThisStoreClick={handleChooseThisStoreClick}
                onStoreDetailClick={onStoreDetailClick}
                saveStoreDetails={saveStoreDetails}
                setOnStoreDetailsClick={setOnStoreDetailsClick}
                handleChooseStoreFromStoreDetails={handleChooseStoreFromStoreDetails}
                handleMapToggle={handleMapToggle}
                handleCloseClick={handleCloseClick}
                nextAvailableStartDate={nextAvailableStartDate}
                cartState={cartState}
                analyticEvents={analyticEvents}
                payloadEcommerceLocationActionAnalytics={payloadEcommerceLocationActionAnalytics}
                currentLocation={currentLocation}
                pickupStoreFooterClass={pickupStoreFooterClass}
                pickupStoreHeaderClass={pickupStoreHeaderClass}
                mapMobileClass={mapMobileClass}
                variant={variant}
                pickupStoreDrawerHeading={pickupStoreDrawerHeading}
                pickupStoreDrawerFooter={pickupStoreDrawerFooter}
                useFilterState={useFilterState}
                isTabletView={isTabletView}
                showAvailableStartDate={showAvailableStartDate}
                showInDrawerForTablet={showInDrawerForTablet}
                showMonochromaticMap={showMonochromaticMap}
                storeDetailsClassName={storeDetailsClassName}
                storesClassName={storesClassName}
                companyId={companyId}
                triggerStoreModalAnalytics={triggerStoreModalAnalytics}
            />
        </>
    );
};
