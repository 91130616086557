import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Alert from '../../../../components/global/atoms/alert/alert';
import Button from '../../../../components/global/atoms/button';
import Select from '../../../../components/global/atoms/select';
import LocationAutocomplete from '../../../../components/global/modules/location-autocomplete';
import { getLocationsDetailsByPlaceId } from '../../../../components/global/modules/location-autocomplete/api/getLocations';
import {
    getStateList,
    setPrefilledAddress,
    validateCity,
    validateZip
} from '../../../../components/global/utils/commonUtils';
import { logError } from '../../../../components/global/utils/logger';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import useComputeLocation from '../../../../hooks/useComputeLocation';
import {
    SET_CART_OVERLAY,
    SET_LOCATION_DATA,
    SET_VIEW_CART_FIELDS
} from '../../../actions/constants';
import isObjectEmpty from '../../../utils/isObjectEmpty';
import Field from '../../Field';
import { useCartState } from '../cartContext';
import { CART_OVERLAYS } from '../constants';
import { BackHeader } from '../headers';
import { dataLocators } from './Constants';
import './roundTrip.scss';
import { useFilterState } from '../../../../components/cap';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';

export default function RoundTrip() {
    const [{ cart }, dispatch] = useCartState();
    const [{ viewCart }, filterDispatch] = useFilterState();
    const { sendEventsForClick } = useAnalyticsContext();
    const userType = useCheckUser();
    const { jobSiteAddr2, jobSiteZip, jobSiteCity, jobSiteState, location } = viewCart || '';
    const [streetAddress, setStreetAddress] = useState(location);
    const [placeIdData, setPlaceIdData] = useState('');
    const [selectedAddress2, setSelectedAddress2] = useState(jobSiteAddr2);
    const [selectedZip, setSelectedZip] = useState(jobSiteZip);
    const [selectedCity, setSelectedCity] = useState(jobSiteCity);
    const [selectedState, setSelectedState] = useState(jobSiteState);
    const [errorGeocodeApi, setErrorGeocodeApi] = useState(false);
    const intl = useIntl();
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const { deliveryPickUpCharges } = orderEstimates?.totals || {};
    const userCompanyID = parseInt(localStorage.getItem('companyID')) || 1;
    const [isManualAddress, setIsManualAddress] = useState(false);
    const [manualAddress, setManualAddress] = useState(streetAddress || '');
    const [addressChangedByGeocode, setAddressChangedByGeocode] = useState(false);
    const [userPrefilledAddress, setUserPrefilledAddress] = useState('');
    const [globalLocation, setGlobalLocation] = useState({
        location: viewCart?.location || '',
        lat: viewCart?.lat || '',
        long: viewCart?.long || '',
        jobSiteAddr2: viewCart?.jobSiteAddr2 || '',
        jobSiteCity: viewCart?.jobSiteCity || '',
        jobSiteState: viewCart?.jobSiteState || '',
        jobSiteZip: viewCart?.jobSiteZip || '',
        pc: viewCart?.pc || '',
        pcLat: viewCart?.pcLat || '',
        pcLong: viewCart?.pcLong || ''
    });
    const {
        cityAndZipWithoutStreetValidation,
        computeInstore,
        extractAddressComponents,
        canadaRestrictStateError,
        setCanadaRestrictStateError,
        handleManualUpdatedInstoreAddress,
        updateManualAddressInContext
    } = useComputeLocation();
    const [addressFieldsError, setAddressFieldsError] = useState({
        address1: '',
        city: '',
        zip: '',
        state: ''
    });

    useEffect(() => {
        setCanadaRestrictStateError('');
        setErrorGeocodeApi(false);
    }, [streetAddress, selectedCity, selectedState, selectedZip, selectedAddress2, manualAddress]);

    /**
     * Parse locality, state, country and postal code from response
     */
    const handleStateChangeOption = value => {
        setAddressFieldsError({ ...addressFieldsError, state: '' });
        setIsManualAddress(true);
        setSelectedState(value);
    };

    const checkAndSetAddressChange = (newValue, currentValue) => {
        if (newValue?.toLowerCase() !== currentValue?.toLowerCase()) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        setUserPrefilledAddress(setPrefilledAddress(location, jobSiteCity));
    }, [location, jobSiteCity]);

    const handleManualAddress = async () => {
        let payload = `${manualAddress}, ${selectedCity}, ${selectedState}, ${selectedZip}`;
        const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
        const localLocation = await handleManualUpdatedInstoreAddress(payload, true, companyID);
        if (!localLocation) {
            setErrorGeocodeApi(true);
            setAddressChangedByGeocode(false);
        } else {
            setErrorGeocodeApi(false);
            if (
                checkAndSetAddressChange(localLocation?.location?.split(',')[0], userPrefilledAddress) ||
                checkAndSetAddressChange(localLocation?.jobSiteCity, selectedCity) ||
                checkAndSetAddressChange(localLocation?.jobSiteState, selectedState) ||
                checkAndSetAddressChange(localLocation?.jobSiteZip, selectedZip)
            ) {
                setUserPrefilledAddress(setPrefilledAddress(localLocation?.location, localLocation?.jobSiteCity));
                setSelectedCity(localLocation?.jobSiteCity || '');
                setSelectedState(localLocation?.jobSiteState || '');
                setSelectedZip(localLocation?.jobSiteZip || '');
                setGlobalLocation({
                    location: localLocation?.location,
                    lat: localLocation?.lat,
                    long: localLocation?.long,
                    jobSiteAddr2: localLocation?.jobSiteAddr2,
                    jobSiteCity: localLocation?.jobSiteCity,
                    jobSiteState: localLocation?.jobSiteState,
                    jobSiteZip: localLocation?.jobSiteZip,
                    pc: '',
                    pcLat: '',
                    pcLong: ''
                });
                setIsManualAddress(false);
                setAddressChangedByGeocode(true);
            } else {
                const pcVal = await updateManualAddressInContext(localLocation?.lat, localLocation?.long);
                setUserPrefilledAddress(setPrefilledAddress(localLocation?.location, localLocation?.jobSiteCity));
                filterDispatch({
                    type: SET_LOCATION_DATA,
                    pc: pcVal?.pc,
                    location: localLocation?.location,
                    jobSiteCity: localLocation?.jobSiteCity,
                    jobSiteState: localLocation?.jobSiteState,
                    jobSiteZip: localLocation?.jobSiteZip,
                    lat: localLocation?.lat,
                    long: localLocation?.long,
                    jobSiteAddr2: localLocation?.jobSiteAddr2,
                    pcLat: pcVal?.pcLat,
                    pcLong: pcVal?.pcLong
                });
                filterDispatch({ type: SET_VIEW_CART_FIELDS, key: 'showDeliveryEstimate', value: true });
                dispatch({ type: SET_CART_OVERLAY, cartOverlay: CART_OVERLAYS.VIEW_CART });
            }
        }
    };

    const handleAutoSuggestedAddress = async () => {
        const localLocation = await computeInstore(placeIdData, streetAddress, '', true);

        if (!localLocation) {
            setErrorGeocodeApi(true);
        } else {
            setErrorGeocodeApi(false);
            filterDispatch({
                type: SET_LOCATION_DATA,
                pc: localLocation?.pc,
                location: localLocation?.location,
                jobSiteCity: localLocation?.jobSiteCity,
                jobSiteState: localLocation?.jobSiteState,
                jobSiteZip: localLocation?.jobSiteZip,
                lat: localLocation?.lat,
                long: localLocation?.long,
                jobSiteAddr2: selectedAddress2,
                pcLat: localLocation?.pcLat,
                pcLong: localLocation?.pcLong
            });
            filterDispatch({ type: SET_VIEW_CART_FIELDS, key: 'showDeliveryEstimate', value: true });
            dispatch({ type: SET_CART_OVERLAY, cartOverlay: CART_OVERLAYS.VIEW_CART });
        }
    };

    const handleEstimateDeliveryFee = async () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.MINI_CART,
                `${userType} ${VARIABLE_CONFIG.EVENT_ACTION.SELECT} :: ${VARIABLE_CONFIG.EVENT_ACTION.ESTIMATE_DELIVERY}`,
                VARIABLE_CONFIG.EVENT_LABEL.UPDATE_ADDRESS
            );
        } catch (error) {
            logError(error, false, 'handleEstimateDeliveryFee');
        }
        if (!validateAddressFields()) {
            return;
        }
        if (!isManualAddress && !placeIdData) {
            const pcVal = await updateManualAddressInContext(globalLocation?.lat, globalLocation?.long);
            setUserPrefilledAddress(setPrefilledAddress(globalLocation?.location, globalLocation?.jobSiteCity));
            filterDispatch({
                type: SET_LOCATION_DATA,
                pc: pcVal?.pc,
                location: globalLocation?.location,
                jobSiteCity: globalLocation?.jobSiteCity,
                jobSiteState: globalLocation?.jobSiteState,
                jobSiteZip: globalLocation?.jobSiteZip,
                lat: globalLocation?.lat,
                long: globalLocation?.long,
                jobSiteAddr2: globalLocation?.jobSiteAddr2,
                pcLat: pcVal?.pcLat,
                pcLong: pcVal?.pcLong
            });
            filterDispatch({ type: SET_VIEW_CART_FIELDS, key: 'showDeliveryEstimate', value: true });
            dispatch({ type: SET_CART_OVERLAY, cartOverlay: CART_OVERLAYS.VIEW_CART });
        } else {
            if (isManualAddress) {
                handleManualAddress();
            } else {
                if (addressChangedByGeocode) {
                    const pcVal = await updateManualAddressInContext(globalLocation?.lat, globalLocation?.long);
                    setUserPrefilledAddress(setPrefilledAddress(globalLocation?.location, globalLocation?.jobSiteCity));
                    filterDispatch({
                        type: SET_LOCATION_DATA,
                        pc: pcVal?.pc,
                        location: globalLocation?.location,
                        jobSiteCity: globalLocation?.jobSiteCity,
                        jobSiteState: globalLocation?.jobSiteState,
                        jobSiteZip: globalLocation?.jobSiteZip,
                        lat: globalLocation?.lat,
                        long: globalLocation?.long,
                        jobSiteAddr2: globalLocation?.jobSiteAddr2,
                        pcLat: pcVal?.pcLat,
                        pcLong: pcVal?.pcLong
                    });
                    filterDispatch({ type: SET_VIEW_CART_FIELDS, key: 'showDeliveryEstimate', value: true });
                    dispatch({ type: SET_CART_OVERLAY, cartOverlay: CART_OVERLAYS.VIEW_CART });
                } else {
                    handleAutoSuggestedAddress();
                }
            }
        }
    };

    const handleRemoveDelivery = () => {
        sendEventsForClick(
            VARIABLE_CONFIG.EVENT.UAEVENT,
            VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
            VARIABLE_CONFIG.EVENT_CATEGORY.MINI_CART,
            `${userType} ${VARIABLE_CONFIG.EVENT_ACTION.SELECT} :: ${VARIABLE_CONFIG.EVENT_ACTION.ESTIMATE_DELIVERY}`,
            VARIABLE_CONFIG.EVENT_LABEL.REMOVE_DELIVERY
        );
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key: 'showDeliveryEstimate', value: false });
        dispatch({ type: SET_CART_OVERLAY, cartOverlay: CART_OVERLAYS.VIEW_CART });
    };

    const handleCityChange = text => {
        setAddressFieldsError({ ...addressFieldsError, city: '' });
        setSelectedCity(text.target.value);
        setIsManualAddress(true);
        setAddressChangedByGeocode(false);
    };
    const handleZipChange = text => {
        setAddressFieldsError({ ...addressFieldsError, zip: '' });
        setSelectedZip(text.target.value);
        setIsManualAddress(true);
        setAddressChangedByGeocode(false);
    };
    const handleAddress1Change = text => {
        setAddressFieldsError({ ...addressFieldsError, address1: '' });
        setManualAddress(text);
        setIsManualAddress(true);
        setStreetAddress(text);
        setUserPrefilledAddress(text);
        setAddressChangedByGeocode(false);
    };

    const setAddressComponents = async selectedAddress => {
        const responseFromPlaceDetails = await getLocationsDetailsByPlaceId(selectedAddress);
        let addressFields = {};
        if (
            responseFromPlaceDetails?.error ||
            isObjectEmpty(responseFromPlaceDetails?.data?.result) ||
            !cityAndZipWithoutStreetValidation(responseFromPlaceDetails?.data?.result)
        ) {
            setErrorGeocodeApi(true);
        } else {
            addressFields = extractAddressComponents(responseFromPlaceDetails?.data?.result);
            setErrorGeocodeApi(false);
        }
        setSelectedZip(addressFields?.jobSiteZip || '');
        setSelectedCity(addressFields?.jobSiteCity);
        setSelectedState(addressFields?.jobSiteState);
        setAddressChangedByGeocode(false);
    };

    const setSelectedAdr = selectedAddress => {
        const errors = {
            address1: '',
            city: '',
            zip: '',
            state: ''
        };
        setErrorGeocodeApi(false);
        setAddressChangedByGeocode(false);
        setAddressFieldsError({ ...errors });
        setSelectedAddress2('');
        setPlaceIdData(selectedAddress);
        setAddressComponents(selectedAddress);
        setIsManualAddress(false);
    };

    const setSelectedAdr2 = text => {
        setSelectedAddress2(text);
        setIsManualAddress(true);
        setAddressChangedByGeocode(false);
    };

    const handleStateOrProvinceLabel = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-province' })
            : intl.formatMessage({ id: 'cart:form-state' });
    };
    const handleZipOrPostalCodeLabel = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-postalcode' })
            : intl.formatMessage({ id: 'cart:form-zipcode' });
    };
    const handleStateOrProvincePlaceholder = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-province-placeholder' })
            : intl.formatMessage({ id: 'cart:form-state-placeholder' });
    };
    const handleZipOrPostalCodePlaceholder = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-postal-placeholder' })
            : intl.formatMessage({ id: 'cart:form-zip-placeholder' });
    };

    const postalZipErrorLabelHandler = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'order-details:postal-error-label' })
            : intl.formatMessage({ id: 'order-details:zip-error-label' });
    };

    const provinceStateErrorLabelHandler = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'order-details:province-error-label' })
            : intl.formatMessage({ id: 'order-details:state-error-label' });
    };

    const validateAddressFields = () => {
        let isValid = true;
        const errors = {
            address1: '',
            city: '',
            zip: '',
            state: ''
        };

        if (!streetAddress) {
            errors.address1 = intl.formatMessage({ id: 'order-details:address-error-label' });
            isValid = false;
        } else {
            errors.address1 = '';
        }

        if (!selectedCity) {
            isValid = false;
            errors.city = intl.formatMessage({ id: 'order-details:city-error-label' });
        } else if (selectedCity) {
            if (!validateCity(selectedCity)) {
                errors.city = intl.formatMessage({ id: 'order-details:city-error-splchar-label' });
                isValid = false;
            } else {
                errors.city = '';
            }
        }

        if (!selectedZip) {
            errors.zip = postalZipErrorLabelHandler();
            isValid = false;
        } else if (selectedZip) {
            if (!validateZip(selectedZip)) {
                errors.zip = postalZipErrorLabelHandler();
                isValid = false;
            } else {
                errors.zip = '';
            }
        }

        if (!selectedState) {
            errors.state = provinceStateErrorLabelHandler();
            isValid = false;
        } else {
            errors.state = '';
        }

        setAddressFieldsError({ ...errors });
        return isValid;
    };

    const getEstimateView = () => {
        return (
            <div className="root_roundTrip">
                <BackHeader />
                <div className="roundtrip_body">
                    <div className="roundtrip_body-title">
                        <h5
                            data-testid={dataLocators.getestimate_roundtripestimate_label}
                            className="roundtrip_body-titletop">
                            {intl.formatMessage({ id: 'cart:round-trip-heading' })}
                        </h5>
                        <small data-testid={dataLocators.getestimate_availablityandfees_label}>
                            {intl.formatMessage({ id: 'cart:round-trip-subheading' })}
                        </small>
                    </div>
                    <h6
                        data-testid={dataLocators.getestimate_deliveryaddress_label}
                        className="roundtrip_body-titledelivery">
                        {intl.formatMessage({ id: 'cart:delivery-address' })}
                    </h6>
                    <div id="form paddingHeading">
                        <Field
                            data-testid={dataLocators.getestimate_address_label}
                            label={intl.formatMessage({ id: 'cart:form-address-1-placeholder' })}
                            htmlFor={dataLocators.form_address_1_label}
                            errorMsg={addressFieldsError.address1 && !canadaRestrictStateError}>
                            <LocationAutocomplete
                                inputlabel={dataLocators.form_address_1_label}
                                data-testid={dataLocators.getestimate_fulladdress_txtfield}
                                onSelectAddress={selectedAddress => setSelectedAdr(selectedAddress)}
                                handleLocationChange={handleAddress1Change}
                                selectedAddress={val => {
                                    setStreetAddress(val);
                                }}
                                prefilledAddress={userPrefilledAddress}
                                showResetBtn={false}
                                inputAriaRequired={true}
                                errorMsg={addressFieldsError.address1 && !canadaRestrictStateError}
                                isManualAddress={isManualAddress}
                            />
                            {(errorGeocodeApi || addressFieldsError.address1) && !canadaRestrictStateError ? (
                                <span className="error_input" tabindex="0" aria-live="assertive" role="alert">
                                    {intl.formatMessage({ id: 'order-details:address-error-label' })}
                                </span>
                            ) : (
                                ''
                            )}
                        </Field>
                        <Field
                            data-testid={dataLocators.getestimate_addressline2_label}
                            label={intl.formatMessage({ id: 'cart:form-address-2-placeholder' })}
                            htmlFor={dataLocators.form_address_2_label}>
                            <input
                                id={dataLocators.form_address_2_label}
                                data-testid={dataLocators.getestimate_optional_txtfield}
                                className="cmp-Field__field__input"
                                placeholder={intl.formatMessage({ id: 'optional' })}
                                field="address2"
                                onChange={text => {
                                    setSelectedAdr2(text.target.value);
                                }}
                                value={selectedAddress2}
                                aria-placeholder={intl.formatMessage({
                                    id: 'account:ac-minicart-addressline2-aria-placeholder'
                                })}
                            />
                        </Field>
                        <Field
                            data-testid={dataLocators.getestimate_city_label}
                            label={intl.formatMessage({ id: 'cart:form-city' })}
                            htmlFor={dataLocators.form_city_label}
                            errorMsg={addressFieldsError.city}>
                            <input
                                id={dataLocators.form_city_label}
                                data-testid={dataLocators.getestimate_enteracity_txtfield}
                                className={`cmp-Field__field__input ${
                                    addressFieldsError.city ? 'error_input__border' : ''
                                }`}
                                onChange={handleCityChange}
                                value={selectedCity}
                                placeholder={intl.formatMessage({ id: 'cart:form-city-placeholder' })}
                                aria-placeholder={intl.formatMessage({
                                    id: 'account:ac-minicart-city-aria-placeholder'
                                })}
                            />
                            {addressFieldsError.city ? (
                                <span className="error_input" tabindex="0" aria-live="assertive" role="alert">
                                    {addressFieldsError.city}
                                </span>
                            ) : (
                                ''
                            )}
                        </Field>
                        <div className="col-2-diff roundtripzipstate ">
                            <Field
                                data-testid={dataLocators.getestimate_state_label}
                                label={handleStateOrProvinceLabel()}
                                errorMsg={addressFieldsError.state}>
                                <Select
                                    dataTestid={dataLocators.getestimate_selectstate_txtfield}
                                    dropDownValues={getStateList()}
                                    handleChange={handleStateChangeOption}
                                    selectedValue={selectedState}
                                    placeholder={handleStateOrProvincePlaceholder()}
                                    selectAriaLabel={intl.formatMessage({
                                        id: 'account:ac-minicart-state-dropdown-aria-label'
                                    })}
                                    errorClass={addressFieldsError.state && 'error_input__border'}
                                />
                                {addressFieldsError.state ? (
                                    <span className="error_input" tabindex="0" aria-live="assertive" role="alert">
                                        {addressFieldsError.state}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </Field>
                            <Field
                                data-testid={dataLocators.getestimate_zipcode_label}
                                label={handleZipOrPostalCodeLabel()}
                                htmlFor={dataLocators.form_zipcode_label}
                                errorMsg={addressFieldsError.zip}>
                                <div className="zipcodeWrapper">
                                    <input
                                        id={dataLocators.form_zipcode_label}
                                        data-testid={dataLocators.getestimate_enterazip_txtfield}
                                        className={`cmp-Field__field__input ${
                                            addressFieldsError.zip ? 'error_input__border' : ''
                                        }`}
                                        onChange={handleZipChange}
                                        value={selectedZip}
                                        placeholder={handleZipOrPostalCodePlaceholder()}
                                        field="zipcode"
                                        aria-placeholder={intl.formatMessage({
                                            id: 'account:ac-minicart-zip-aria-placeholder'
                                        })}
                                    />
                                </div>
                                {addressFieldsError.zip ? (
                                    <span className="error_input" tabindex="0" aria-live="assertive" role="alert">
                                        {addressFieldsError.zip}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </Field>
                        </div>
                        {canadaRestrictStateError && (
                            <Alert
                                localStyle={`alert-withbottommargin`}
                                type={'error'}
                                message={canadaRestrictStateError}
                            />
                        )}
                        {addressChangedByGeocode && (
                            <Alert
                                localStyle="alert-withbottommargin"
                                type={'warning'}
                                message={intl.formatMessage({
                                    id: 'order-details:address-changed-notification-label'
                                })}
                            />
                        )}
                        <Button
                            data-testid={dataLocators.getestimate_estimatedeliveryfee_CTA}
                            type="button"
                            onClick={handleEstimateDeliveryFee}
                            className="button button-primary button-block btn-roundtrip-update"
                            buttonAriaLabel={
                                viewCart?.location && deliveryPickUpCharges && viewCart?.showDeliveryEstimate
                                    ? `${intl.formatMessage({ id: 'account:ac-minicart-update-delivery-text' })}`
                                    : `${intl.formatMessage({
                                          id: 'account:ac-minicart-estimate-delivery-fee-text'
                                      })}`
                            }>
                            {viewCart?.location && deliveryPickUpCharges && viewCart?.showDeliveryEstimate
                                ? intl.formatMessage({ id: 'account:ac-minicart-update-delivery-text' })
                                : intl.formatMessage({ id: 'account:ac-minicart-estimate-delivery-fee-text' })}
                        </Button>
                        {viewCart?.location && viewCart?.showDeliveryEstimate && deliveryPickUpCharges && (
                            <Button
                                data-testid={dataLocators.getestimate_estimatedeliveryfee_CTA}
                                type="button"
                                onClick={handleRemoveDelivery}
                                className="button button-outline-primary button-block btn-roundtrip-outlined"
                                buttonAriaLabel={`${intl.formatMessage({
                                    id: 'account:ac-minicart-remove-delivery-text'
                                })}`}>
                                {intl.formatMessage({ id: 'account:ac-minicart-remove-delivery-text' })}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return <>{getEstimateView()}</>;
}
