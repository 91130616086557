import React from 'react';

import LoadingIndicator from '../../LoadingIndicator';

import CartOptions from '../cartOptions';
import EmptyMinicartBody from '../emptyMinicartBody';
import Error from '../error';
import classes from './body.css';

import { useUserContext } from '../../../context/UserContext';
import { useCartState } from '../cartContext';
import MinicartBodyDotCom from './MinicartBodyDotCom';
import MinicartBodyP2P from './MinicartBodyP2P';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../../../../components/global/constants';

const CartLoadingIndicator = () => {
    return <LoadingIndicator />;
};

const Body = () => {
    const [{ isEditing, cart, isLoading, isCartLoading, errorMessage }] = useCartState();
    const [{ isUserProfileLoading }] = useUserContext();
    const isEmpty = cart && Object.entries(cart)?.length > 0 ? cart?.items?.length === 0 : true;
    const authorityType = useCheckAuthorityType();

    if (errorMessage) {
        return <Error />;
    }

    if (isEmpty && !isLoading && !isCartLoading) {
        return <EmptyMinicartBody />;
    }

    if (isEditing) {
        return <CartOptions />;
    }

    const showLoading = isUserProfileLoading || isLoading || isCartLoading;

    const renderBody = () => {
        switch (authorityType) {
            case AUTHORITY_TYPE.P2P:
                return <MinicartBodyP2P cart={cart} />;

            default:
                return <MinicartBodyDotCom cart={cart} />;
        }
    };

    return (
        <div className={showLoading ? `${classes.loading}` : `${classes.root}`}>
            {showLoading ? <CartLoadingIndicator /> : renderBody()}
        </div>
    );
};

export default Body;
