import axiosInstance from '../../../../api/axiosDotComInstance';
import { API_CONFIG } from '../../../../constants/apiConfig';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import isObjectWithKeys from '../../../../aem-core-components/utils/isObjectWithKeys';

const { GET_PC } = API_CONFIG;
const currentCountry = 'us'; //TODO:----

/* assets structure
 "equipmentCategory": 68,
"equipmentClass": 210,
"requestedQuantity": 1,
"sequenceNumber": 0
*/

const getStoresData = async ({
    latitude,
    longitude,
    pickupDateTime,
    returnDateTime,
    assets = [],
    isDelivery = false,
    isExpressDelivery = false,
    isLocationChanged = false
}) => {
    try {
        if (assets?.length > 0) {
            const selectedstoresDetails = JSON.parse(
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS) || '{}'
            );
            const storePayload = {
                companyId: parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                sourceSystem: 'B2B',
                isDelivery: isDelivery,
                latitude,
                longitude,
                startDateTime: pickupDateTime,
                endDateTime: returnDateTime,
                products: assets,
                sessionId: 'defaultSessionId',
                expressDelivery: isExpressDelivery
            };
            if (!isDelivery && !isLocationChanged && isObjectWithKeys(selectedstoresDetails)) {
                storePayload.latitude = selectedstoresDetails?.latitude;
                storePayload.longitude = selectedstoresDetails?.longitude;
            }

            const { data, error, status } = await axiosInstance.post(
                GET_PC,
                { ...storePayload },
                {
                    headers: {
                        companyId: parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1
                    }
                }
            );
            return { data, error, status };
        } else {
            return { error: 'items not present' };
        }
    } catch (error) {
        return { error };
    }
};

export { getStoresData };
