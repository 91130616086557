export const ADDRESS_COMPONENT_TYPE = {
    POSTAL_CODE: 'POSTAL_CODE',
    STATE: 'STATE',
    CITY: 'CITY',
    AREA_L2: 'AREA_L2',
    ROUTE: 'ROUTE',
    STREET: 'STREET',
    HOME_ADDRESS: 'HOME_ADDRESS',
    COUNTRY: 'COUNTRY',
    NEIGHBORHOOD: 'NEIGHBORHOOD',
    SUBLOCALITY: 'SUBLOCALITY'
};

export const dataLocators = {
    getestimate_roundtripestimate_label: 'getestimate_roundtripestimate_label',
    getestimate_availablityandfees_label: 'getestimate_availablityandfees_label',
    getestimate_deliveryaddress_label: 'getestimate_deliveryaddress_label',
    getestimate_address_label: 'getestimate_address_label',
    getestimate_fulladdress_txtfield: 'getestimate_fulladdress_txtfield',
    getestimate_addressline2_label: 'getestimate_addressline2_label',
    getestimate_optional_txtfield: 'getestimate_optional_txtfield',
    getestimate_city_label: 'getestimate_city_label',
    getestimate_enteracity_txtfield: 'getestimate_enteracity_txtfield',
    getestimate_state_label: 'getestimate_state_label',
    getestimate_selectstate_txtfield: 'getestimate_selectstate_txtfield',
    getestimate_zipcode_label: 'getestimate_zipcode_label',
    getestimate_enterazip_txtfield: 'getestimate_enterazip_txtfield',
    getestimate_estimatedeliveryfee_CTA: 'getestimate_estimatedeliveryfee_CTA',
    form_address_1_label: 'form_address1_label',
    form_address_2_label: 'form_address2_label',
    form_city_label: 'form_city_label',
    form_zipcode_label: 'form_zipcode_label',
    zero_dollar_delivery_label: 'zero_dollar_delivery_label'
};

export const dataLocatorsRTpDeliveryCredit = {
    getestimate_roundtripestimate_label: 'roundTripDeliveryEstimateoverlay_roundTripDeliveryEstimate_txt',
    getestimate_deliveryaddress_label: 'roundTripDeliveryEstimateoverlay_deliveryAddress_txt',
    getestimate_address_label: 'roundTripDeliveryEstimateoverlay_address_lbl',
    getestimate_fulladdress_txtfield: 'roundTripDeliveryEstimateoverlay_address_txt',
    getestimate_addressline2_label: 'roundTripDeliveryEstimateoverlay_addressLine2_lbl',
    getestimate_optional_txtfield: 'roundTripDeliveryEstimateoverlay_addressLine2_txt',
    getestimate_city_label: 'roundTripDeliveryEstimateoverlay_city_lbl',
    getestimate_enteracity_txtfield: 'roundTripDeliveryEstimateoverlay_city_txt',
    getestimate_state_label: 'roundTripDeliveryEstimateoverlay_state_lbl',
    getestimate_selectstate_txtfield: 'roundTripDeliveryEstimateoverlay_state_txt',
    getestimate_zipcode_label: 'roundTripDeliveryEstimateoverlay_zipCode_lbl',
    getestimate_enterazip_txtfield: 'roundTripDeliveryEstimateoverlay_zipCode_txt',
    getestimate_estimatedeliveryfee_CTA: 'roundTripDeliveryEstimateoverlay_estimateDeliveryFee_cta',
    getestimate_updateaddress_CTA: 'roundTripDeliveryEstimateoverlay_updateAddress_cta',
    getestimate_removedelivery_CTA: 'roundTripDeliveryEstimateoverlay_removeDelivery_cta'
};
