import { func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import AlertTriangle from '../../../../../resources/images/alert-triangle.svg';
import Alert from '../../../../global/atoms/alert/alert';
import { orderSummaryDataLocators } from '../../dataLocators';
import { STORAGE_CONFIG } from '../../../../../constants/storageConfig';
import OrderSummaryLineItem from '../orderSummaryLineItem/OrderSummaryLineItem';
import RoundTripFee from '../roundTripFee/RoundTripFee';
import '../../orderSummaryFactory.scss';

const CartSummaryView = props => {
    const {
        intl,
        orderTotals,
        source,
        deliveryChargeFlag,
        handleOpenTBDOverlay,
        handleRoundTripAndEstimateFlow,
        isRatesFromTier2Radius,
        isDeliveryFeeRequired
    } = props;

    const {
        title,
        rentalSubtotal,
        purchaseSubtotal,
        rentalProtectionPlan,
        deliveryPickUpCharges,
        prepayFuelOption,
        envFees,
        otherFees,
        taxes,
        showDeliveryEstimate,
        dispatch
    } = orderTotals;

    const [alertBanner, setAlertBanner] = useState(false);
    const inventoryCheckBannerDescription =
        document.querySelector('#inventoryCheckConfigs')?.dataset?.inventorycheckbanner ||
        intl.formatMessage({ id: 'order-summary:product-availability-error' });

    const isInStorePickup = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP) || false);

    useEffect(() => {
        setAlertBanner(isRatesFromTier2Radius);
    }, [isRatesFromTier2Radius]);

    const renderInStoreOrDeliveryFee = () => {
        if (isInStorePickup) {
            return showDeliveryEstimate && deliveryPickUpCharges ? null : (
                <OrderSummaryLineItem
                    itemLabel={intl.formatMessage({ id: 'order-summary:instore-pickup' })}
                    itemLabelDataTestId={orderSummaryDataLocators.quoteSummary_rentalSubtotal_lbl} //to be added
                    isTooltipIcon
                    tooltipIconMessage={intl.formatMessage({ id: 'order-summary:instore-pickup' })}
                    itemValue={
                        !showDeliveryEstimate ? intl.formatMessage({ id: 'order-summary:instore-pickup-value' }) : '-'
                    }
                    itemValueDataTestId={orderSummaryDataLocators.quoteSummary_rentalSubtotal_dynvalue} // to be added
                    dispatch={dispatch}
                />
            );
        }

        return (
            <RoundTripFee
                source={source}
                deliveryChargeFlag={deliveryChargeFlag}
                deliveryPickUpCharges={deliveryPickUpCharges}
                showDeliveryEstimate={showDeliveryEstimate}
                handleOpenTBDOverlay={handleOpenTBDOverlay}
                handleRoundTripAndEstimateFlow={handleRoundTripAndEstimateFlow}
                isRatesFromTier2Radius={isRatesFromTier2Radius}
                isDeliveryFeeRequired={isDeliveryFeeRequired}
                dispatch={dispatch}
            />
        );
    };

    return (
        <>
            <section className="ordersummary">
                <div className={`ordersummary-top`}>
                    <h5
                        data-testid={orderSummaryDataLocators.miniCartOverlay_orderSummary_txt}
                        className="ordersummary-top-title">
                        {title}
                    </h5>
                    <div
                        data-testid={orderSummaryDataLocators.miniCartOverlay_taxesFees_txt}
                        className="ordersummary-top-content">
                        {intl.formatMessage({ id: 'order-summary:tax-and-fees-warning-text' })}
                    </div>
                </div>

                <div className={'items group'}>
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:rental-subtotal' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_rentalSubtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={rentalSubtotal}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOverlay_rentalSubtotal_dynvalue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:purchases-subtotal' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_purchase_subtotal_lbl}
                        isTooltipIcon={false}
                        itemValue={purchaseSubtotal}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOverlay_purchase_subtotal_dynValue}
                        dispatch={dispatch}
                    />
                    {renderInStoreOrDeliveryFee()}
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:rental-protection-plan' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_rpp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={intl.formatMessage({ id: 'order-summary:rental-protection-plan' })}
                        itemValue={rentalProtectionPlan}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOVerlay_rppBlank_value}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:fuel-convenience-plan' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_fcp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={intl.formatMessage({ id: 'order-summary:fuel-convenience-plan' })}
                        itemValue={prepayFuelOption}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOVerlay_fcpBlank_value}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:environment-fee' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_srp_lbl}
                        isTooltipIcon
                        tooltipIconMessage={intl.formatMessage({ id: 'order-summary:environment-fee' })}
                        itemValue={envFees}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOverlay_srp_dynValue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:other-fee' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_srp_lbl}
                        isTooltipIcon={false}
                        itemValue={otherFees}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOverlay_srp_dynValue}
                        dispatch={dispatch}
                    />
                    <OrderSummaryLineItem
                        itemLabel={intl.formatMessage({ id: 'order-summary:taxes-text' })}
                        itemLabelDataTestId={orderSummaryDataLocators.miniCartOverlay_taxes_lbl}
                        isTooltipIcon={false}
                        itemValue={taxes}
                        itemValueDataTestId={orderSummaryDataLocators.miniCartOVerlay_taxesBlank_value}
                        dispatch={dispatch}
                    />
                </div>
                {alertBanner && (
                    <div className="ordersummary-alert-wrap">
                        <Alert
                            localStyle="ordersummary-alert"
                            icon={<AlertTriangle />}
                            type={'error'}
                            message={inventoryCheckBannerDescription}></Alert>
                    </div>
                )}
            </section>
        </>
    );
};

export default React.memo(CartSummaryView);

CartSummaryView.propTypes = {
    orderTotals: object,
    source: string,
    deliveryChargeFlag: string,
    handleOpenTBDOverlay: func,
    handleRoundTripAndEstimateFlow: func,
    isRatesFromTier2Radius: func,
    isDeliveryFeeRequired: func
};
CartSummaryView.defaultProps = {
    orderTotals: {},
    source: '',
    deliveryChargeFlag: '',
    handleOpenTBDOverlay: () => {},
    handleRoundTripAndEstimateFlow: () => {},
    isRatesFromTier2Radius: () => {},
    isDeliveryFeeRequired: () => {}
};
