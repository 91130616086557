import React from 'react';
import classes from './plainText.css';
const PlainText = props => {
    return (
        <div className={classes.plainText}>
            <p className={classes.bodyp}>{props.accountName}</p>
            <p className={classes.bodysmallp}>{props.accountNumber}</p>
        </div>
    );
};
export default PlainText;
