import { array, bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import useUserData from '../../../../../hooks/useUserData';
import { useUserContext } from '../../../../context/UserContext';
import Listing from './Listing';
import classes from './projectListing.css';
import ProjectSearch from './ProjectSearch';
import { useFilterState } from '../../../../../components/cap';

const ProjectListing = props => {
    const [{ accountProjects, isProjectsLoading }] = useUserContext();
    const {
        displaySearchResults,
        placeholder,
        notFoundErrorMessage,
        isSearchIconVisible,
        screenName,
        handleInputBlur,
        setSearchErrorText,
        source
    } = props;
    const [{ projectDetails, isJobsiteRecommendationLoading }] = useFilterState();
    const [searchQuery, setSearchQuery] = useState('');
    const [projectList, setProjectList] = useState(accountProjects || []);
    const [{ getProjectsFromCookie }] = useUserData();

    useEffect(() => {
        const selectedjobsiteInList =
            accountProjects?.find(data => {
                return data?.jobNumber == projectDetails?.selectedProjectJobId;
            }) || null;
        if (!selectedjobsiteInList) {
            const projectInfoCookies = getProjectsFromCookie();
            const {
                CurrentWynneAccount,
                CurrentWynneAccountCID,
                SLLat,
                SLLong,
                SLA,
                SLC,
                SLS,
                SLZ,
                CurrentJobSite,
                locationPC,
                projectName,
                projectAddress2,
                primaryContactName,
                phoneNumber,
                accessNotes,
                poNumber
            } = projectInfoCookies || {};

            const selProjectData = {
                jobNumber: CurrentJobSite,
                jobName: projectName,
                account: CurrentWynneAccount,
                companyID: CurrentWynneAccountCID,
                address1: SLA,
                address2: projectAddress2,
                city: SLC,
                state: SLS,
                zip: SLZ,
                latitude: SLLat,
                longitude: SLLong,
                isGeoCoded: true, //TODO-unavailable
                locationPC,
                salesRepId: '', //TODO-unavailable
                isActive: true,
                jobKey: CurrentJobSite,
                contactName: primaryContactName,
                contactPhone: phoneNumber,
                hasEquipment: false, //TODO-unavailable
                equipmentCount: 0, //TODO-unavailables
                deliveryInstructions: accessNotes,
                customerPO: poNumber
            };
            if (!searchQuery) {
                setProjectList([selProjectData, ...accountProjects]);
            } else {
                setProjectList(accountProjects);
            }
        } else {
            let removedItem;
            let tempProjectList;

            if (projectDetails?.selectedProjectJobId) {
                removedItem = accountProjects?.find(data => {
                    return data?.jobNumber == projectDetails?.selectedProjectJobId;
                });

                if (removedItem) {
                    tempProjectList = accountProjects?.filter(data => {
                        return data?.jobNumber != projectDetails?.selectedProjectJobId;
                    });
                    tempProjectList.unshift(removedItem);
                    setProjectList(tempProjectList);
                }
            }
        }
    }, [accountProjects, projectDetails?.selectedProjectJobId]);

    return (
        <div className={classes.searchRoot}>
            <ProjectSearch
                searchQuery={searchQuery}
                onSearchQueryChange={setSearchQuery}
                projectList={projectList}
                placeholder={placeholder}
                notFoundErrorMessage={notFoundErrorMessage}
                isSearchIconVisible={isSearchIconVisible}
                screenName={screenName}
                handleInputBlur={handleInputBlur}
                setSearchErrorText={setSearchErrorText}
                source={source}
            />
            <Listing
                projectList={projectList}
                isProjectsLoading={isProjectsLoading || isJobsiteRecommendationLoading}
                displaySearchResults={displaySearchResults}
            />
        </div>
    );
};

ProjectListing.propTypes = {
    accountProjects: array,
    placeholder: string.isRequired,
    displaySearchResults: func.isRequired,
    notFoundErrorMessage: string,
    handleInputBlur: bool,
    setSearchErrorText: func,
    source: string
};

ProjectListing.defaultProps = {
    screenName: '',
    handleInputBlur: false,
    setSearchErrorText: () => {},
    source: ''
};

export default React.memo(ProjectListing);
