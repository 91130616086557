export const API_CONFIG = {
    GET_LOCATIONS_URL: `web/api/v1/addresses/autosuggest?components=country:`,
    GET_LOCATIONS_URL_FOR_BOTH_COUNTRIES: `web/api/v1/addresses/autosuggest?components=country:us%7Ccountry:ca`,
    GET_PLACES_API: `web/api/v1/addresses/placedetails?language=en`,
    GET_LAT_LONG: `web/api/v1/addresses/geocode?components=country:`,
    GET_LAT_LONG_NO_COUNTRY: `web/api/v1/addresses/geocode`,
    GET_LAT_LONG_BOTH_COUNTRIES: `web/api/v1/addresses/geocode?components=country:us%7Ccountry:ca`,
    MKTO_ENDPOINT: '/web/api/v2/leads/cart',
    MKTO_LOGIN_ENDPOINT: '/web/api/v2/leads/customer',
    PROJECT_SITE_NEW_URL: '/web/api/v1/projectsites',
    CUSTOMERS_API_URL: 'web/api/v1/customers',
    VALIDATE_REFRESH_TOKEN: '/web/api/v1/okta-token/validate/token',
    GET_DATE_SLOT_AVAILABILITY_URL: `/web/api/v1/locations/windows`,
    GET_TIME_ZONE_URL: `web/api/v1/addresses/timezone?`,
    AVS_VALIDATION_URL: '/web/api/v1/addresses/verify',
    GET_PAYMENTS_URL: '/web/api/v1/customers/getpayments',
    GET_PAYMENT_SESSION_URL: '/web/api/v1/payments/session',
    GET_ADDPAYMENTS_URL: '/web/api/v1/customers/payments',
    GET_STORE_DISTANCES_URL: 'web/api/v1/locations/distance?',
    PAYMENT_TOKEN_API_URL: '/web/api/v1/payment-token/token',
    ESTIMATES_URL: '/web/api/v1/estimates',
    ESTIMATES_V2_URL: '/web/api/v2/estimates',
    GET_PC: `web/api/v1/inventory/sources`,
    GENERATE_ACCESS_TOKEN: '/api/v1/oauth2/token',
    GET_STORE_LOCATIONS: 'web/api/v1/locations',
    RATES_URL_OVERRIDEPC: '/web/api/v1/rates',
    RATES_URL_OWNED_PC: '/web/api/v1/rates_lm',
    GET_ESTIMATE_URL: '/sbr-estimates-api/api/v3/checkout/quotes',
    GET_IP_ADDRESS: 'https://www.cloudflare.com/cdn-cgi/trace',
    GET_DST_TRAINERS: 'web/api/v1/digital-assets/dstMapList',
    //P2P routes start
    GET_QUOTE_LIST: '/p2p/api/v1/quotes',
    GET_ACCOUNTS: '/p2p/api/v1/quotes/accounts',
    GET_JOBSITES: '/p2p/api/v1/quotes/jobsites',
    GET_ORDERED_BY: '/p2p/api/v1/quotes/orderedby',
    P2P_JOBSITE_URL: '/p2p/api/v1/jobsites',
    POST_STORE_DISTANCES_URL: 'web/api/v1/locations/distance',
    GET_OPTIONAL_PLANS: 'web/api/v1/customers',
    //P2P routes end
    BRANCH_LOCATOR: '/web/api/v1/locations/branchLocator?',
    GET_FILTER_SPECIALTY_DATA: '/api/v1/web-aem/getAllSpecialtiesData',
    AEM_COOKIE_CLEAR_ENDPOINT: '/services/logout',
    GET_RECOMMENDATIONS: '/api/v1/products/databricks/recommendations'
    
};
