import React, { memo } from 'react';
import { bool } from 'prop-types';
import { Minicart as Cart, CartTrigger, Portal } from '../../aem-core-components';
import MultiPortal from '../../aem-core-components/components/Portal/MultiPortal';
import DelinquentBanner from '../../aem-core-components/components/MyAccount/DelinquentBanner';
import LocationDistanceWarningBanner from '../../aem-core-components/components/MyAccount/LocationDistanceWarningBanner/LocationDistanceWarningBanner';
import { useCheckAuthorityType } from '../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../global/constants';
import UtilityBanner from '../global/modules/utilityBanner/UtilityBanner';
import MyFavourites from '../myFavourites/MyFavourites';
import PunchoutBanner from '../global/modules/punchoutBanner/PunchoutBanner';
import GetGeoLocation from '../global/atoms/GetGeoLocation/GetGeoLocation';
import GlobalAccountHeader from '../account/GlobalAccountHeader';
import GlobalSearch from '../search/globalSearch';
import partialConfig from './config';

const { mountingPoints } = partialConfig;

const Global = props => {
    const authorityType = useCheckAuthorityType();
    const isP2P = authorityType === AUTHORITY_TYPE.P2P;

    return (
        <>
            <Portal selector={mountingPoints.search}>
                <GlobalSearch />
            </Portal>
            <Portal selector={mountingPoints.cartTrigger}>
                <CartTrigger />
            </Portal>
            <Portal selector={mountingPoints.minicart}>
                <Cart />
            </Portal>
            <Portal selector={mountingPoints.pdpSelectLocation}>
                <GlobalAccountHeader isCCHeader={props?.isCCHeader} />
            </Portal>
            <Portal selector={mountingPoints.punchoutBanner}>
                <PunchoutBanner />
            </Portal>
            <Portal selector={mountingPoints.getUtilityBanner}>
                <UtilityBanner />
            </Portal>
            <Portal selector={mountingPoints.getGeoLoation}>
                <GetGeoLocation />
            </Portal>
            <Portal selector={mountingPoints.notificationBanner}>
                <DelinquentBanner />
                <LocationDistanceWarningBanner />
            </Portal>
            {isP2P && (
                <MultiPortal selector={mountingPoints.favouritesDropDown}>
                    <MyFavourites />
                </MultiPortal>
            )}
        </>
    );
};

Global.propTypes = {
    isCCHeader: bool
};
Global.defaultProps = {
    isCCHeader: false
};
export default memo(Global);
