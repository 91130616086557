import React, { memo, useEffect, useState, useRef } from 'react';
import { getProductList } from '../../../../aem-core-components/actions/product/actions';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import QUERY_PRODUCT_LIST from '../../../../aem-core-components/queries/query_product_list';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import { useAtp } from '../../../cap/hooks/useATP';
import { Tab, Tabs } from '../../atoms/tabs';
import { generateSKU, makeProductURL } from '../../utils/commonUtils';
import FreqRentals from '../freqRentals/FreqRentals';
import { FREQRENTALS_SIZE } from '../freqRentals/constants';
import RecentlyViewed from '../recentlyViewed/RecentlyViewed';
import { RECENTLY_VIEWED, RECENTLY_VIEWED_SIZE } from '../recentlyViewed/constants';
import WishList from '../wishlist/WishList';
import { useAwaitQuery } from '../../../../aem-core-components/utils/hooks';
import './rentalscombine.scss';
import {
    SET_IS_COMBINED_TAB_LOADING,
    SET_SELECTED_COMBINED_TAB_DATA
} from '../../../../aem-core-components/actions/constants';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { WISHLIST_SIZE } from '../wishlist/constants';

function RentalsCombineTab() {
    const userType = useCheckUser();
    const productListQuery = useAwaitQuery(QUERY_PRODUCT_LIST);
    const [{ wishlist, freqRentals, isProfileLoaded }] = useUserContext();
    const [{ cidPcList, isCombinedTabsLoading }, dispatch] = useCartState();
    const { handleATPCart } = useAtp();
    const [selTabIndex, setSelTabIndex] = useState(0);
    const [freqRentalsArray, setFreqRentalsArray] = useState([]);
    const [wishlistArray, setWishlistArray] = useState([]);
    const [recentlyViewedArray, setRecentlyViewedArray] = useState([]);
    const [freqRentalsDataLength, setFreqRentalsDataLength] = useState(0);
    const [wishListDataLength, setWishListDataLength] = useState(0);
    const [unavailableFreqData, setUnavailableFreqData] = useState(true);
    const [isCombinedTabsDataLoading, setIsCombinedTabsDataLoading] = useState(true);

    let recentProducts = localStorage.getItem(RECENTLY_VIEWED) ? JSON.parse(localStorage.getItem(RECENTLY_VIEWED)) : [];
    const [recentlyViewedDataLength, setRecentlyViewedDataLength] = useState(recentProducts?.length);
    const { favTitle, recentlyTitle, rentedTitle } = document.querySelector('.combined_tabs')?.dataset?.productdata
        ? JSON.parse(document.querySelector('.combined_tabs')?.dataset?.productdata || '{}')
        : {};
    const lastWishlistItemsLengthRef = useRef(wishlist?.items?.length);

    useEffect(() => {
        if (userType === USER_TYPE.CREDIT) {
            if (isCombinedTabsLoading) {
                setSelTabIndex(0);
            } else {
                if (!isCombinedTabsDataLoading) {
                    if (selTabIndex === 0) {
                        if (freqRentalsArray?.length === 0) {
                            if (wishlistArray?.length === 0) {
                                setSelTabIndex(2);
                            } else {
                                setSelTabIndex(1);
                            }
                        }
                    } else if (selTabIndex === 1) {
                        if (wishlistArray?.length === 0) {
                            if (freqRentalsArray?.length === 0) {
                                setSelTabIndex(2);
                            } else {
                                setSelTabIndex(0);
                            }
                        }
                    } else if (selTabIndex === 2) {
                        if (recentlyViewedArray?.length === 0) {
                            if (freqRentalsArray?.length === 0) {
                                setSelTabIndex(1);
                            } else {
                                setSelTabIndex(0);
                            }
                        }
                    }
                }
            }
        }
    }, [isCombinedTabsLoading, isCombinedTabsDataLoading, userType, wishlistArray?.length]);

    useEffect(() => {
        if (ENV_CONFIG.COMBINED_TABS) {
            if (!isCombinedTabsDataLoading) {
                if (!freqRentalsArray?.length && !wishlistArray?.length && !recentlyViewedArray?.length) {
                    ENV_CONFIG.COMBINED_TABS.style.display = 'none';
                } else {
                    ENV_CONFIG.COMBINED_TABS.style.display = 'block';
                }
            }
        }
    }, [isCombinedTabsDataLoading]);

    useEffect(() => {
        if (userType === USER_TYPE.CREDIT) {
            if (!unavailableFreqData) {
                if (wishlistArray?.length > 0) {
                    let splicedWishlist = wishlistArray?.slice(0, WISHLIST_SIZE);
                    dispatch({
                        type: SET_SELECTED_COMBINED_TAB_DATA,
                        value: splicedWishlist?.map(product => product?.catsku)
                    });
                    dispatch({ type: SET_IS_COMBINED_TAB_LOADING, value: false });
                } else {
                    dispatch({
                        type: SET_SELECTED_COMBINED_TAB_DATA,
                        value: recentlyViewedArray?.slice(0, RECENTLY_VIEWED_SIZE)
                    });
                    dispatch({ type: SET_IS_COMBINED_TAB_LOADING, value: false });
                }
            }
        } else {
            dispatch({
                type: SET_SELECTED_COMBINED_TAB_DATA,
                value: recentlyViewedArray?.slice(0, RECENTLY_VIEWED_SIZE)
            });
            dispatch({ type: SET_IS_COMBINED_TAB_LOADING, value: false });
        }
    }, [wishlistArray?.length, unavailableFreqData, userType]);

    useEffect(() => {
        if (!isCombinedTabsLoading) {
            if (isProfileLoaded) {
                setIsCombinedTabsDataLoading(true);
                Promise.allSettled([handleFreqRentalsListing(), handleWishListData(), handleRecentlyViewedData()])
                    .then(values => {
                        setIsCombinedTabsDataLoading(false);
                    })
                    .catch(error => {
                        setIsCombinedTabsDataLoading(false);
                    });
            }
        }
    }, [isCombinedTabsLoading, cidPcList, isProfileLoaded]);

    useEffect(() => {
        if (
            !isCombinedTabsLoading &&
            isProfileLoaded &&
            wishlist?.items?.length !== lastWishlistItemsLengthRef.current
        ) {
            setIsCombinedTabsDataLoading(true);
            handleWishListData()
                .then(result => {
                    lastWishlistItemsLengthRef.current = wishlist?.items?.length;
                    setIsCombinedTabsDataLoading(false);
                })
                .catch(error => {
                    setIsCombinedTabsDataLoading(false);
                });
        }
    }, [wishlist?.items?.length, isCombinedTabsLoading, cidPcList, isProfileLoaded]);

    const handleFreqRentalsListing = async () => {
        if (freqRentals?.length > 0) {
            const skuList = freqRentals?.map(item => generateSKU(item?.catId, item?.classId));
            const productResult = await handleProductData(skuList);
            setFreqRentalsDataLength(productResult?.length);
            dispatch({ type: SET_SELECTED_COMBINED_TAB_DATA, value: skuList });
            dispatch({ type: SET_IS_COMBINED_TAB_LOADING, value: false });

            setFreqRentalsArray(productResult.slice(0, FREQRENTALS_SIZE));
        } else {
            setFreqRentalsArray([]);
            setUnavailableFreqData(false);
        }
    };

    const handleWishListData = async () => {
        if (wishlist?.items?.length > 0) {
            let splicedWishlist = wishlist?.items;
            const productResult = await handleProductData(splicedWishlist?.map(({ product }) => product?.sku));
            setWishListDataLength(productResult?.length);
            setWishlistArray(productResult.slice(0, WISHLIST_SIZE));
        } else {
            setWishlistArray([]);
            setWishListDataLength(0);
        }
    };

    const handleRecentlyViewedData = async () => {
        if (recentProducts?.length > 0) {
            const productResult = await handleProductData(recentProducts);
            setRecentlyViewedDataLength(productResult?.length);
            setRecentlyViewedArray(productResult.slice(0, RECENTLY_VIEWED_SIZE));
        }
    };

    const handleProductData = async skuList => {
        if (skuList?.length > 0) {
            const { productDetails, error } = await getProductList({
                productListQuery,
                skuList,
                pageSize: skuList?.length,
                handleATPCart
            });
            if (productDetails?.length > 0) {
                const productResult = skuList
                    .map(product => {
                        let result = {
                            catclass: product
                        };
                        let detailsObj = productDetails.find(
                            detail => detail.sku === result.catclass && detail?.pc_availability === true
                        );
                        if (detailsObj) {
                            return {
                                ...result,
                                producttitle: detailsObj?.name,
                                productimageurl: detailsObj?.image?.url,
                                producturl: makeProductURL(detailsObj?.product_page_url),
                                categoryname: detailsObj?.category_name,
                                productcategoryname: detailsObj?.parent_category_name,
                                subcategoryname: detailsObj?.category_name,
                                category_path: detailsObj?.category_path,
                                catid: product?.catId,
                                catsku: result?.catclass,
                                showonlinecatalog: detailsObj?.showonlinecatalog,
                                disableaddtocartoption: detailsObj?.disableaddtocartoption,
                                ec_pc_inventory: detailsObj?.ec_pc_inventory,
                                pc_availability: detailsObj?.pc_availability,
                                metatitle: detailsObj?.meta_title,
                                metadescription: detailsObj?.meta_description,
                                specialtytypes: detailsObj?.specialtytypes,
                                specialtytypescode: detailsObj?.specialtytypescode,
                                analysisgroupcode: detailsObj?.analysisgroupcode
                            };
                        } else {
                            return undefined;
                        }
                    })
                    .filter(Boolean);
                return productResult;
            }
        }

        return [];
    };

    return (
        <div className="container">
            {userType === USER_TYPE.CREDIT ? (
                <Tabs selTabIndex={selTabIndex} onTabChange={setSelTabIndex} tabWrapClasses={'frequent__tabs-btn'}>
                    {freqRentalsArray?.length > 0 && (
                        <Tab title={rentedTitle || ''}>
                            <FreqRentals
                                freqRentalsArray={freqRentalsArray}
                                freqRentalsDataLength={freqRentalsDataLength}
                            />
                        </Tab>
                    )}
                    {wishlistArray?.length > 0 && (
                        <Tab title={favTitle || ''}>
                            <WishList wishlistArray={wishlistArray} wishListDataLength={wishListDataLength} />
                        </Tab>
                    )}
                    {recentlyViewedArray?.length > 0 && (
                        <Tab title={recentlyTitle || ''}>
                            <RecentlyViewed
                                recentlyViewedArray={recentlyViewedArray}
                                recentlyViewedDataLength={recentlyViewedDataLength}
                            />
                        </Tab>
                    )}
                </Tabs>
            ) : (
                <Tabs selTabIndex={selTabIndex} onTabChange={setSelTabIndex} tabWrapClasses={'frequent__tabs-btn'}>
                    {recentlyViewedArray?.length > 0 && (
                        <Tab title={recentlyTitle || ''}>
                            <RecentlyViewed recentlyViewedArray={recentlyViewedArray} />
                        </Tab>
                    )}
                </Tabs>
            )}
        </div>
    );
}

export default memo(RentalsCombineTab);
