import React from 'react';
import { useIntl } from 'react-intl';
import { useFilterState } from '../../../../components/cap';
import OrderSummaryFactory from '../../../../components/molecules/orderSummaryFactory/OrderSummaryFactory';
import { FACTORYSOURCE } from '../../../../components/molecules/orderSummaryFactory/constants/orderSummaryFactoryConstants';
import ProductList from '../productList';

const MinicartBodyP2P = ({ cart }) => {
    const intl = useIntl();
    const [{ viewCart }] = useFilterState();
    return (
        <>
            <ProductList
                availableCartItems={cart?.availableCartItems}
                unavailableCartItems={cart?.unavailableCartItems}
                isInStorePickup={viewCart?.isInStorePickup}
                // showFavoritesCTA={true} // TODO: commenting for now as this will be a part of Edit quote probably
            />
            <OrderSummaryFactory
                title={intl.formatMessage({ id: 'cart-order-summary:title' })}
                source={FACTORYSOURCE.MINICART}
            />
        </>
    );
};

export default MinicartBodyP2P;
