import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import AddToCartProductTileReact from '../../../global/modules/productTile/addToCartProductTileReact';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { FREQRENTALS_SIZE } from './constants';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { logError } from '../../utils/logger';
import './freqrentals.scss';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { usePricing } from '../../../../hooks/usePricing';
import { checkIsRequestQuote } from '../../utils/commonUtils';

const FreqRentals = ({ freqRentalsArray, freqRentalsDataLength }) => {
    const intl = useIntl();
    const [{ cidPcList }] = useCartState();
    const [{ isProfileLoaded }] = useUserContext();
    const { sendEventsForClick } = useAnalyticsContext();
    const { getProductPrices } = usePricing();
    const { isRentalDetailsAvailable } = useCheckLocationEmpty();

    const { rentedCTA, rentedLink } = document.querySelector('.combined_tabs')?.dataset?.productdata
        ? JSON.parse(document.querySelector('.combined_tabs')?.dataset?.productdata || '{}')
        : {};

    useEffect(() => {
        const getPriceForProducts = async () => {
            if (isProfileLoaded) {
                if (isRentalDetailsAvailable() && cidPcList?.length > 0 && freqRentalsArray?.length > 0) {
                    await getProductPrices(
                        freqRentalsArray?.filter(item => {
                            const { isRequestQuote } = checkIsRequestQuote(
                                item?.showonlinecatalog,
                                item?.disableaddtocartoption
                            );
                            return !isRequestQuote;
                        }),
                        VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE
                    );
                }
            }
        };

        getPriceForProducts();
    }, [isProfileLoaded, freqRentalsArray, cidPcList]);

    const itemClickFreqRentalsEvent = freqRent => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.FREQUENTLY_RENTED,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT,
                freqRent?.producttitle
            );
        } catch (error) {
            logError(error, false, 'itemClickFreqRentalsEvent');
        }
    };

    const viewAllFreqRentalsEvent = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.FREQUENTLY_RENTED,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT,
                VARIABLE_CONFIG.EVENT_LABEL.VIEW_ALL_FREQUENTLY_RENTED
            );
        } catch (error) {
            logError(error, false, 'viewAllFreqRentalsEvent');
        }
    };

    return (
        <>
            <div className="row">
                {freqRentalsArray.map(item => (
                    <div className="col-md-3 col-lg-3 col-6 frequent__item" key={item?.catclass}>
                        <AddToCartProductTileReact cardData={item} trackAnalytics={itemClickFreqRentalsEvent} />
                    </div>
                ))}
            </div>
            {rentedCTA && JSON.parse(rentedCTA) && freqRentalsDataLength > FREQRENTALS_SIZE && (
                <div className="row frequent__actions">
                    <div className="col-xl-4 col-md-6">
                        <a
                            className="button button-outline-primary button-block"
                            href={rentedLink}
                            onClick={viewAllFreqRentalsEvent}>
                            {intl.formatMessage({ id: 'Shop-all-frequently-rented' })}
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};
export default React.memo(FreqRentals);

FreqRentals.propTypes = {
    freqRentalsDataLength: PropTypes.number,
    freqRentalsArray: PropTypes.arrayOf(
        PropTypes.shape({
            catclass: PropTypes.string,
            producttitle: PropTypes.string,
            productimageurl: PropTypes.string,
            producturl: PropTypes.string,
            categoryname: PropTypes.string,
            productcategoryname: PropTypes.string,
            subcategoryname: PropTypes.string,
            category_path: PropTypes.array,
            catid: PropTypes.string,
            catsku: PropTypes.string,
            showonlinecatalog: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            disableaddtocartoption: PropTypes.bool
        })
    )
};

FreqRentals.defaultProps = {
    freqRentalsArray: [],
    freqRentalsDataLength: 0
};
