
import React from 'react';
import { useIntl } from 'react-intl';
import { func } from 'prop-types';

import LoadingIndicator from '../LoadingIndicator';

import classes from './myAccount.css';
import { useUserContext } from '../../context/UserContext';

import { SignOutLink, AccountInfoLink, AddressBookLink, ChangePasswordLink } from './AccountLinks';

const MyAccount = props => {
    const { showMenu, showChangePassword, showAccountInformation } = props;
    const [{ currentUser, inProgress }] = useUserContext();
    const intl = useIntl();

    if (inProgress) {
        return (
            <div className={classes.modal_active}>
                <LoadingIndicator>
                    {intl.formatMessage({ id: 'account:signing-in', defaultMessage: 'Signing In' })}
                </LoadingIndicator>
            </div>
        );
    }

    const accountLinks = props.children || (
        <>
            <ChangePasswordLink showChangePassword={showChangePassword} />
            <AddressBookLink />
            <AccountInfoLink showAccountInformation={showAccountInformation} />
            <SignOutLink showMenu={showMenu} />
        </>
    );

    return (
        <div className={classes.root}>
            <div className={classes.user}>
                <h2 className={classes.title}>{`${currentUser.firstname} ${currentUser.lastname}`}</h2>
                <span className={classes.subtitle}>{currentUser.email}</span>
            </div>
            <div className={classes.actions}>{accountLinks}</div>
        </div>
    );
};

MyAccount.propTypes = {
    showMenu: func,
    showAccountInformation: func.isRequired,
    showChangePassword: func.isRequired
};

export default MyAccount;
