import React, { memo } from 'react';
import BaseProductTile from '../BaseProductTile';
import { func, number, oneOfType, shape, string } from 'prop-types';

const Loading = ({ tileDetails, handleAnchorClick, handleRedirectClick, ...restProps }) => {
    const footerContent = () => {
        return <div className="producttile__loading shimmer"></div>;
    };
    return (
        <BaseProductTile
            productImg={tileDetails?.productimageurl}
            catClass={tileDetails?.catclass}
            productName={tileDetails?.producttitle}
            productUrl={tileDetails?.producturl}
            footerContent={footerContent}
            handleAnchorClick={handleAnchorClick}
            handleRedirectClick={handleRedirectClick}
            {...restProps}></BaseProductTile>
    );
};

Loading.defaultProps = {
    tileDetails: {
        catclass: '',
        producttitle: '',
        productimageurl: '',
        producturl: '#',
        showonlinecatalog: 'YES',
        disableaddtocartoption: 'NO',
        categoryname: '',
        catsku: '',
        catid: '',
        metaTitle: '',
        metaDescription: ''
    },
    handleAnchorClick: () => {},
    handleRedirectClick: () => {}
};

Loading.propTypes = {
    tileDetails: shape({
        catclass: oneOfType([number, string]),
        producttitle: string,
        productimageurl: string,
        producturl: string,
        showonlinecatalog: string,
        disableaddtocartoption: string,
        categoryname: string,
        catsku: oneOfType([number, string]),
        catid: oneOfType([number, string]),
        metaDescription: string,
        metaTitle: string
    }),
    handleAnchorClick: func,
    handleRedirectClick: func
};

export default memo(Loading);
