import { number, string } from 'prop-types';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { SIDE_DRAWER } from '../../../../aem-core-components/context/Modal/constants';
import { ModalContext } from '../../../../aem-core-components/context/ModalContext';
import { MINICART } from '../../../../constants/screenConstants';
import StarSolidIcon from '../../../../resources/images/star-solid.svg';
import StarIcon from '../../../../resources/images/star.svg';
import { pdpDataLocators } from '../../../pdp/dataLocators';
import { SET_ACTION_NAME, SET_PRODUCT_TO_WISHLIST } from '../../actionTypes/actionTypes';
import { ACTION_NAMES } from '../../constants';
import { useFavouritesState } from '../../context';

const FavouritesButton = ({ isFavorite, sku, qty, favoriteClasses, sourcePage, productName = '' }) => {
    const intl = useIntl();
    const [, favDispatch] = useFavouritesState();

    const useModalState = () => useContext(ModalContext);
    const { openModal } = useModalState();

    const clickHandler = event => {
        event.stopPropagation();
        favDispatch({ type: SET_PRODUCT_TO_WISHLIST, payload: { sku: sku, qty: qty } });
        favDispatch({ type: SET_ACTION_NAME, payload: ACTION_NAMES.SAVE });
        openModal(SIDE_DRAWER);
    };

    const getTitle = isFavorite => {
        switch (sourcePage) {
            case MINICART:
                return intl.formatMessage({ id: 'cart-body:save-cart-to-favorites' });

            default:
                return isFavorite
                    ? intl.formatMessage({ id: 'p2p-pdp-saved-favourite' })
                    : intl.formatMessage({ id: 'p2p-pdp-save-favourite' });
        }
    };

    return (
        <button
            className={`button button-outline-primary button-pb0 ${favoriteClasses}`}
            onClick={clickHandler}
            data-testid={pdpDataLocators.pdp_favoriteLink_testid}
            tabIndex={0}
            aria-label={`${
                isFavorite
                    ? intl.formatMessage({ id: 'saved-favorite-aria-label' })
                    : intl.formatMessage({ id: 'save-favorite-aria-label' })
            } ${productName}`}
            aria-pressed={Boolean(isFavorite)}>
            {isFavorite ? (
                <StarSolidIcon className="button_icon" aria-hidden={true} />
            ) : (
                <StarIcon className="button_icon" aria-hidden={true} />
            )}
            {getTitle(isFavorite)}
        </button>
    );
};

FavouritesButton.propsType = {
    sku: string,
    qty: number,
    favoriteClasses: string,
    sourcePage: string
};

FavouritesButton.defaultProps = {
    favoriteClasses: '',
    sku: '',
    qty: 0,
    sourcePage: ''
};

export default FavouritesButton;
