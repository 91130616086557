/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
import React from 'react';
import BookIcon from '../../../../resources/images/log-out.svg';

import { useIntl } from 'react-intl';

import { useConfigContext } from '../../../context/ConfigContext';
import AccountLink from '../accountLink';

const AddressBookLink = () => {
    const { pagePaths } = useConfigContext();
    const intl = useIntl();

    return (
        <AccountLink
            onClick={() => {
                window.location.href = pagePaths.addressBook;
            }}>
            <BookIcon size={18} />
            {intl.formatMessage({ id: 'account:address-book', defaultMessage: 'Address Book' })}
        </AccountLink>
    );
};

export default AddressBookLink;
