import React from 'react';
import { array, bool, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { TILE_STATES } from '../../../components/cap/constants';
import { useAtp } from '../../../components/cap/hooks/useATP';
import FavouritesButton from '../../../components/favorites/modules/favouritesButton/FavouritesButton';
import { AUTHORITY_TYPE } from '../../../components/global/constants';
import { MINICART } from '../../../constants/screenConstants';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import { miniCartDatalocator } from './dataLocators';
import Product from './product';
import classes from './productList.css';
import UnavailableProductList from './unavailableProductList';

const ProductList = props => {
    const intl = useIntl();
    const { handleATP } = useAtp();
    const authorityType = useCheckAuthorityType();
    const { availableCartItems, unavailableCartItems, showFavoritesCTA, isInStorePickup } = props;
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;

    return (
        <div className={classes.root}>
            {availableCartItems?.length > 0 && (
                <>
                    <div className={classes.bodytitleWrapper}>
                        <h6
                            data-testid={miniCartDatalocator.minicart_rental_label}
                            className={classes.bodytitle}
                            tabIndex="0"
                            aria-label={intl.formatMessage({ id: 'minicart:rentals-in-the-cart' })}>
                            {intl.formatMessage({ id: 'cart:rentals-in-the-cart' })}
                        </h6>
                        {/* TODO: productName should be implemented along with showFavoritesCTA which is part of Edit quote probably */}
                        {showFavoritesCTA && <FavouritesButton sourcePage={MINICART} productName={''} />}
                    </div>
                    <ul className={classes.rootlist}>
                        {availableCartItems?.map((item, index) => {
                            let isAvailableWithWarning = false;
                            if (isP2PUser && !isInStorePickup) {
                                const tileStatus = handleATP({
                                    catsku: item?.product?.sku,
                                    inventoryDataObj: item?.product?.ec_pc_inventory
                                });
                                isAvailableWithWarning = tileStatus === TILE_STATES.AVAILABLE_WITH_WARNING;
                            }
                            return (
                                <Product
                                    key={item?.product?.sku}
                                    item={item}
                                    index={index + 1}
                                    cartLength={availableCartItems?.length}
                                    pcAvaibility={true}
                                    isAvailableWithWarning={isAvailableWithWarning}
                                />
                            );
                        })}
                    </ul>
                </>
            )}
            {unavailableCartItems?.length > 0 && (
                <UnavailableProductList unavailableCartItems={unavailableCartItems}></UnavailableProductList>
            )}
        </div>
    );
};

ProductList.propTypes = {
    cartItems: array,
    classes: shape({
        root: string
    }),
    showFavoritesCTA: bool
};

export default ProductList;
