import React from 'react';
import { useIntl } from 'react-intl';
import { EMPLOYEE } from '../../../../components/checkout/constants';
import ArrowRight from '../../../../resources/images/arrow-right.svg';
import { useUserContext } from '../../../context/UserContext';
import classes from './accountSelector.css';
import { locationsDatalocator } from './dataLocators';

const AccountSelector = props => {
    const { buttonAriaLabel } = props;
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const intl = useIntl();
    return (
        <button
            className={`${props.screenName ? classes.modalAccountSelector : classes.AccountSelector} ${props.className}`}
            onClick={props.accountClick}
            aria-label={`${intl.formatMessage({ id: 'account:ac-common-click-here-msg' })} ${buttonAriaLabel}`} data-testid={locationsDatalocator.accountSelector_test_id}>
            <div className={classes.accountSelectorWrapper }>
                {!props?.accountSelectorTitle ? (
                    <>
                        <p
                            className={
                                userProfile?.type != EMPLOYEE
                                    ? classes.bodyp
                                    : `${classes.bodyp} ${classes.employeeAccount}`
                            }>
                            {props.accountName}
                        </p>
                        <small className={userProfile?.type != EMPLOYEE ? classes.bodysmallp : classes.employeeAccount}>
                            Account number: {props.accountNumber}
                        </small>
                    </>
                ) : (
                    props.accountSelectorTitle
                )}
            </div>
            <div className={classes.bodyIcon} >
                {userProfile?.type != EMPLOYEE && <ArrowRight />}
            </div>
        </button>
    );
};
export default AccountSelector;
