import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import Plus from '../../../../resources/images/plus.svg';
import Checkbox from '../../../global/atoms/Checkbox/Checkbox';
import Button from '../../../global/atoms/button/button';
import Shimmer from '../../../global/atoms/shimmer';
import SearchInput from '../../../global/modules/SearchInput/SearchInput';
import { SET_SELECTED_WISHLISTS } from '../../actionTypes/actionTypes';
import { logError } from '../../../global/utils/logger';
import { ACTION_NAMES, LIMIT_TO_SHOW_SEARCH_BAR, dropdownValuesConstants } from '../../constants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { useFavouritesState } from '../../context';
import { favDataLocators } from '../../dataLocators';
import useFavourites, { actions } from '../../hooks/useFavorites';
import './saveToFavorites.scss';

const SaveToFavorites = ({ handleNextScreen }) => {
    const intl = useIntl();
    const [
        {
            favourites,
            favouritesLoading,
            isSaveFavBtnClicked,
            actionName,
            currentWishlistId,
            selectedWishlists,
            favProduct
        },
        favDispatch
    ] = useFavouritesState();
    const [searchValue, setSearchValue] = useState('');
    const [wishlistNames, setWishlistNames] = useState([]);

    useEffect(() => {
        setWishlistNames(favourites);
    }, [favourites, favouritesLoading]);

    const handleCheck = favId => {
        const updatedSelectedWishlist = new Set(selectedWishlists);

        if (updatedSelectedWishlist.has(favId)) {
            updatedSelectedWishlist.delete(favId);
        } else {
            updatedSelectedWishlist.add(favId);
        }

        favDispatch({ type: SET_SELECTED_WISHLISTS, payload: [...updatedSelectedWishlist] });
    };

    useEffect(() => {
        if (isSaveFavBtnClicked) {
            onSaveFavorites();
        }
    }, [isSaveFavBtnClicked]);

    const onSaveFavorites = async () => {
        console.log('onSaveFavorites......');
        // try {
        //     const { data } = await addproductToWishlist();
        //     closeCreateWishlistModal(true);
        // } catch (error) {
        //     console.error(error);
        // }
    };

    const onClickNextSceen = () => {
        handleNextScreen();
    };

    const skipCurrentFavorite = id => {
        // It will skip to render the source wishlist name
        if ([ACTION_NAMES.COPY, ACTION_NAMES.MOVE].includes(actionName)) {
            return id === currentWishlistId;
        }
        return false;
    };

    const searchWishlists = e => {
        try {
            const value = e?.target?.value;
            setSearchValue(value);
            const searchMatchResults = favourites?.filter(({ name }) =>
                name?.toLowerCase()?.includes(value?.toLowerCase())
            );
            setWishlistNames(searchMatchResults);
        } catch (error) {
            logError(error, false, 'searchWishlists');
        }
    };

    const onClear = () => {
        setSearchValue('');
        setWishlistNames(favourites);
    };

    const getLengthOfTheWishlistNames = () => {
        if ([ACTION_NAMES.COPY, ACTION_NAMES.MOVE].includes(actionName)) {
            return favourites.length - 1; //subtracting the source wishlist, as because we are not showing sorce wishlist name for Copy and Move
        }
        return favourites.length;
    };

    return (
        <div className="save_fav">
            {getLengthOfTheWishlistNames() > LIMIT_TO_SHOW_SEARCH_BAR && (
                <div className="save_fav_searchwrapper">
                    <SearchInput
                        customClassName="search_wishlist input-group--save--search-wishlist"
                        value={searchValue}
                        inputAriaLabel={intl.formatMessage({ id: 'wishlist:search-text' })}
                        handleInputChange={searchWishlists}
                        onClear={onClear}
                        placeholder={intl.formatMessage({ id: 'p2p-save-search-input-text' })}
                        showSearchButton={false}
                    />
                </div>
            )}

            {favouritesLoading && <Shimmer heightWidthClass="shimmer_input" numberOfRows={1} />}

            <Button
                customButtonAriaLabel={intl.formatMessage({ id: 'common:empty-state-create-list' })}
                onClick={onClickNextSceen}
                className="save_fav_btn">
                <Plus />{' '}
                <span className="save_fav_btn_text">{intl.formatMessage({ id: 'p2p-favorites-create-new-list' })}</span>
            </Button>
            {favouritesLoading ? (
                <Shimmer heightWidthClass="shimmer_list_1" numberOfRows={8} />
            ) : (
                wishlistNames?.map(
                    ({ name, id }) =>
                        !skipCurrentFavorite(id) && (
                            <Checkbox
                                key={id}
                                label={name}
                                onClick={() => handleCheck(parseInt(id))}
                                isChecked={selectedWishlists?.includes(parseInt(id))}
                                dataTestId={favDataLocators.wishlist_check_box_testid}
                            />
                        )
                )
            )}
        </div>
    );
};

export default SaveToFavorites;
