import React, { memo, useEffect } from 'react';
import { bool, func } from 'prop-types';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import './EditOnlyPersistantCapHeader.scss';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';
import Button from '../../../global/atoms/button';
import GlobalModal from '../../../global/atoms/globalModal/GlobalModal';
import AlertCircle from '../../../../resources/images/alert-circle-black.svg';
import SideDrawerModal from '../../../global/modules/modals/SideDrawerModal';
import useMedia from '../../../../hooks/useMedia';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { useIntl } from 'react-intl';

const EditOnlyPersistantCapHeader = ({
    renderRentalDetailsView,
    renderRentalDetailsDrawerContent,
    renderUpdateBtn,
    toggleEditMode,
    isEditView,
    setShowPickupStoreAlert,
    showPickupStoreAlert,
    setNoLocationPC,
    setShowAlertBanner,
    showAlertBanner,
    updateRentalViewDetails,
    isCapInteracted,
    sendAnalyticsForNoStoreNearBy
}) => {
    const intl = useIntl();
    const mediaType = useMedia();
    const { sendEventsForUnavailableItem } = useAnalyticsContext();

    useEffect(() => {
        if (showPickupStoreAlert) {
            sendEventsForUnavailableItem(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_INVENTORY_ALERT_VIEWED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_UNAVAILABLE_FROM_PC
            );
            sendAnalyticsForNoStoreNearBy();
        }
    }, [showPickupStoreAlert]);

    const onChangeWarningHandler = async () => {
        setShowAlertBanner(true);
        setNoLocationPC(false);
        setShowPickupStoreAlert(false);
        await updateRentalViewDetails(false);
    };

    const onCloseNoStoresModal = () => {
        setShowPickupStoreAlert(false);
    };

    const title = () => {
        return (
            <div className="alert-title">
                <AlertCircle className="alertCircle__icon" />
                {intl.formatMessage({ id: 'cap:pickup-store-warning' })}
            </div>
        );
    };
    const content = () => {
        return (
            <>
                {intl.formatMessage({ id: 'location:info-message-desc-prefix' })}{' '}
                <a
                    href={intl.formatMessage({ id: 'cap:no-address-helpline-ref' })}
                    className="searchresult__notfound-link"
                    title={intl.formatMessage({ id: 'cap:no-store-help-text' })}>
                    {intl.formatMessage({ id: 'cap:no-address-helpline' })}
                </a>{' '}
                {intl.formatMessage({ id: 'cap:pickup-no-store-warning-text-sufix' })}
            </>
        );
    };

    const footer = () => {
        return (
            <Button
                type="button"
                className="button button-primary button-block"
                onClick={onChangeWarningHandler}
                buttonAriaLabel={intl.formatMessage({ id: 'timeout-continue-text' })}>
                {intl.formatMessage({ id: 'timeout-continue-text' })}
            </Button>
        );
    };

    const renderDesktopView = () => (
        <>
            <div className={`top-cap ${isEditView || isCapInteracted ? 'top-cap-active' : 'top-cap-inactive'}`}>
                {(isEditView || isCapInteracted) && (
                    <div
                        className={`top-cap__container ${isEditView ? 'top-cap__container--edit' : ''}  ${
                            showAlertBanner ? 'pickupstore__alert__banner' : ''
                        }`}>
                        {renderRentalDetailsView()}
                        <div className="start-renting-div">{renderUpdateBtn()}</div>
                    </div>
                )}
            </div>
        </>
    );

    const editDrawerHeader = <h5>{intl.formatMessage({ id: 'cap:rental-details' })}</h5>;

    const renderSideDrawer = () => (
        <SideDrawerModal
            isModalOpen={isEditView || isCapInteracted}
            header={editDrawerHeader}
            content={renderRentalDetailsDrawerContent()}
            footer={renderUpdateBtn()}
            showModalHeader
            modalHeaderClass="edit-drawer__header"
            modalContentClass="edit-drawer__content"
            modalFooterClass="edit-drawer__footer"
            handleModalToggle={toggleEditMode}
        />
    );

    return (
        <>
            {showPickupStoreAlert && (
                <GlobalModal
                    isOpen
                    title={title()}
                    handleClose={onCloseNoStoresModal}
                    footer={footer()}
                    content={content()}
                    className="pickupstore__alert"
                    footerClasses="pickupstore__alert__footer"
                />
            )}
            {mediaType === MEDIA_TYPE.DESKTOP ? renderDesktopView() : renderSideDrawer()}
        </>
    );
};

EditOnlyPersistantCapHeader.defaultProps = {
    renderRentalDetailsView: () => {},
    renderRentalDetailsDrawerContent: () => {},
    renderUpdateBtn: () => {},
    toggleEditMode: () => {},
    isEditView: false,
    setNoLocationPC: () => {},
    setShowAlertBanner: () => {},
    showAlertBanner: false,
    updateRentalViewDetails: () => {},
    setShowPickupStoreAlert: () => {},
    showPickupStoreAlert: false,
    isCapInteracted: false,
    sendAnalyticsForNoStoreNearBy: () => {}
};

EditOnlyPersistantCapHeader.propTypes = {
    renderRentalDetailsView: func,
    renderRentalDetailsDrawerContent: func,
    renderUpdateBtn: func,
    toggleEditMode: func,
    isEditView: bool,
    setNoLocationPC: func,
    setShowAlertBanner: func,
    showAlertBanner: bool,
    updateRentalViewDetails: func,
    setShowPickupStoreAlert: func,
    showPickupStoreAlert: bool,
    isCapInteracted: bool,
    sendAnalyticsForNoStoreNearBy: func
};

export default memo(EditOnlyPersistantCapHeader);
