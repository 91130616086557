import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { FACTORYSOURCE } from '../../constants/orderSummaryFactoryConstants';
import { orderSummaryDataLocators } from '../../dataLocators';

const GetEstimatesView = ({ source, isRatesFromTier2Radius, isDeliveryFeeRequired, deliveryPickUpCharges }) => {
    const intl = useIntl();
    const renderGetEstimatesView = () => {
        switch (source) {
            case FACTORYSOURCE.MINICART:
                return !isRatesFromTier2Radius || isDeliveryFeeRequired() ? (
                    <strong data-testid={orderSummaryDataLocators.miniCartOverlay_getEstimate_cta}>
                        {deliveryPickUpCharges}
                    </strong>
                ) : (
                    <b>{'-'}</b>
                );
            case FACTORYSOURCE.CHECKOUT:
                return !isRatesFromTier2Radius || isDeliveryFeeRequired() ? (
                    <b>{deliveryPickUpCharges}</b>
                ) : (
                    <b>{'-'}</b>
                );
            default:
                return null;
        }
    };

    return renderGetEstimatesView();
};

export default React.memo(GetEstimatesView);

GetEstimatesView.propTypes = {
    source: string,
    isRatesFromTier2Radius: bool,
    isDeliveryFeeRequired: func,
    deliveryPickUpCharges: number
};
GetEstimatesView.defaultProps = {
    source: '',
    isRatesFromTier2Radius: false,
    isDeliveryFeeRequired: () => {},
    deliveryPickUpCharges: 0
};
