export const miniCartDatalocator = {
    quantity_dropdown_testid: 'miniCartOverlay_quantity_drpdwn',
    remove_cta_testid: 'miniCartOverlay_remove_cta',
    product_name_testid: 'miniCartOverlay_productName_txt',
    product_image_testid: 'miniCartOverlay_productImage_img',
    no_location_message_testid: 'miniCartOverlay_noLocationMessage_txt',
    minicart_rental_label: 'minicart_rental_label',
    minicart_pricing_tooltip: 'minicart_pricing_tooltip',
    miniCartOverlay_startDate_clndr: 'miniCartOverlay_startDate_clndr',
    miniCartOverlay_endDate_clndr: 'miniCartOverlay_endDate_clndr',
    minicart_cartEmpty_lbl: 'minicart_cartEmpty_lbl',
    minicart_exploreVarietyTools_lbl: 'minicart_exploreVarietyTools_lbl',
    minicart_exploreRentals_CTA: 'minicart_exploreRentals_CTA',
    invalid_address_error: 'miniCartOverlay_invalidAddress_err_txt',
    toast_message_testid:'minicartOverlay_toastBeforeCheckout',
    view_similar_cta_testid: 'miniCartOverlay_view_similar_cta'
};
