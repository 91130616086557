import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import useCartOptions from '../../../../aem-core-components/components/Minicart/useCartOptions';
import MUTATION_UPDATE_CART_ITEM from '../../../../aem-core-components/queries/mutation_update_cart_item.graphql';
import CART_DETAILS_QUERY from '../../../../aem-core-components/queries/query_cart_details.graphql';
import { useAwaitQuery } from '../../../../aem-core-components/utils/hooks';
import AlertCircle from '../../../../resources/images/alert-circle-yellow.svg';
import Close from '../../../../resources/images/close.svg';
import Button from '../../atoms/button';
import './productAvailability.scss';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';

const ProductAvailability = props => {
    const intl = useIntl();
    const { title, handleOnClose, handleCheckoutBtnClick, cart } = props;
    const [updateCartItemMutation] = useMutation(MUTATION_UPDATE_CART_ITEM);
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const [{ editItem: editItemState }, { updateCartForUnavailableItems }] = useCartOptions({
        updateCartItemMutation,
        cartDetailsQuery
    });
    const [modalIsOpen, setIsOpen] = useState(true);
    const [isContinueDisabled, setIsContinueDisabled] = useState(false);

    const { sendEventsForFormCompleted, sendEventsForUnavailableCta } = useAnalyticsContext();

    useEffect(() => {
        sendEventsForFormCompleted(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_VIEWED,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ITEMS_UNAVAILABLE_FORM
        );
    }, []);

    const handleItemsUnavailableModalCtaClick = (link_name, link_type, link_destination) => {
        sendEventsForUnavailableCta(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ITEMS_UNAVAILABLE_FORM,
            link_name,
            link_type,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ITEMS_UNAVAILABLE_FORM_LINK_LOCATION,
            link_destination
        );
    };

    const closeModal = event => {
        setIsOpen(false);
        handleOnClose(false);
        if (event.target.ariaLabel === intl.formatMessage({ id: 'items-unavailability-modal:backToCart' })) {
            handleItemsUnavailableModalCtaClick(
                intl.formatMessage({ id: 'items-unavailability-modal:backToCart' }),
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_BACK_TO_CART_AND_CONTINUE_LINK_TYPE,
                window.location.href
            );
        } else {
            handleItemsUnavailableModalCtaClick(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_CLOSE,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ITEMS_UNAVAILABLE_FORM_MODAL_ICON,
                ''
            );
        }
    };

    const handleContinue = async () => {
        try {
            setIsContinueDisabled(true);
            const { unavailableCartItems } = cart || [];
            let items = unavailableCartItems?.map(item => {
                return { cart_item_uid: item?.uid, quantity: 0 };
            });
            await updateCartForUnavailableItems(items);
            handleCheckoutBtnClick();
            handleItemsUnavailableModalCtaClick(
                intl.formatMessage({ id: 'items-unavailability-modal:clickToContinue' }),
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_BACK_TO_CART_AND_CONTINUE_LINK_TYPE,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CONTINUE_LINK_DESTINATION
            );
        } catch (error) {
            setIsContinueDisabled(false);
        }
    };

    return (
        <Modal role="none" isOpen={modalIsOpen} className={'itemAvailability'}>
            <div className="itemAvailability__title-wrap">
                <div className="itemAvailability__header">
                    <AlertCircle />
                    <h6 className="itemAvailability__title" tabIndex={'0'}>
                        {title}
                    </h6>
                </div>
                <Button
                    onClick={closeModal}
                    tabIndex={'0'}
                    dataTestid={'modal-close-icon'}
                    customButtonAriaLabel={intl.formatMessage({ id: 'itemAvailability-modal:clickToClose' })}>
                    <Close aria-hidden="true" tabIndex={'-1'} />
                </Button>
            </div>
            <div className="itemAvailability__content" tabIndex={'0'}>
                {intl.formatMessage({ id: 'items-unavailability-modal-message' })}
            </div>
            {cart?.availableCartItems?.length > 0 ? (
                <div className="itemAvailability__actions">
                    <Button
                        className="button button-primary button-block itemAvailability__signin"
                        customButtonAriaLabel={intl.formatMessage({ id: 'items-unavailability-modal:clickToContinue' })}
                        tabIndex={'0'}
                        dataTestid={'modal-continue-in'}
                        isdisabled={isContinueDisabled}
                        onClick={handleContinue}>
                        {intl.formatMessage({ id: 'items-unavailability-modal:clickToContinue' })}
                    </Button>
                    <Button
                        className="button button-outline-primary button-block"
                        dataTestid={'modal-close-btn'}
                        onClick={closeModal}
                        customButtonAriaLabel={intl.formatMessage({ id: 'items-unavailability-modal:backToCart' })}>
                        {intl.formatMessage({ id: 'items-unavailability-modal:backToCart' })}
                    </Button>
                </div>
            ) : null}
        </Modal>
    );
};

export default React.memo(ProductAvailability);
