import React, { useCallback, useEffect } from 'react';
import AnimatedHeaderDotCom from './AnimatedHeaderDotCom';
import AnimatedHeaderP2P from './AnimatedHeaderP2P';
import { checkIsEditQuoteFlow } from '../../../../../components/global/utils/commonUtils';
import { AUTHORITY_TYPE } from '../../../../../components/global/constants';
import { ESCAPE_KEY, KEYDOWN_EVENT } from '../../../../../constants/screenConstants';
import { useCheckAuthorityType } from '../../../../../hooks/useCheckUser';
import { useEventListener } from '../../../../utils/hooks';
import { useCartState } from '../../cartContext';
import '../header.scss';

export default function AnimatedHeader() {
    const [
        {
            isOpen,
            animateHeader: { showHeader, showGradient }
        },
        dispatch
    ] = useCartState();
    const authorityType = useCheckAuthorityType();
    const isEditQuoteFlow = checkIsEditQuoteFlow();

    useEffect(() => {
        const title = document?.querySelector('.closeBtnFocus');
        if (isOpen) {
            setTimeout(() => {
                title?.focus();
            }, 100);
        }
    }, [isOpen]);

    const handleMiniCartClose = () => {
        dispatch({ type: 'close' });
        document.body.classList.remove('overflow-hidden');
    };

    const escapeKeyHandler = useCallback(event => {
        if (event.key === ESCAPE_KEY) {
            handleMiniCartClose();
        }
    }, []);

    useEventListener(window, KEYDOWN_EVENT, escapeKeyHandler);

    switch (authorityType) {
        case AUTHORITY_TYPE.P2P:
            if (isEditQuoteFlow) {
                return (
                    <AnimatedHeaderP2P
                        showHeader={showHeader}
                        showGradient={showGradient}
                        handleMiniCartClose={handleMiniCartClose}
                    />
                );
            }
            return (
                <AnimatedHeaderDotCom
                    showHeader={showHeader}
                    showGradient={showGradient}
                    handleMiniCartClose={handleMiniCartClose}
                />
            );

        default:
            return (
                <AnimatedHeaderDotCom
                    showHeader={showHeader}
                    showGradient={showGradient}
                    handleMiniCartClose={handleMiniCartClose}
                />
            );
    }
}
