import React, { memo, useEffect } from 'react';
import { number, object, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { checkIsEditQuoteFlow, currencyToLocale } from '../../global/utils/commonUtils';
import { useQuotesState } from '../../quotes/context';
import CartSummaryView from './components/cartSummaryView/CartSummaryView';
import CheckoutSummaryView from './components/checkoutSummaryView/CheckoutSummaryView';
import QuoteSummaryView from './components/quoteSummaryView/QuoteSummaryView';
import { FACTORYSOURCE } from './constants/orderSummaryFactoryConstants';
import useOrderSummaryFactory from './hooks/useOrderSummaryFactory';
import useQuoteSummary from './hooks/useQuoteSummary';
import { EVENT_PAGE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Page';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import useAnalytics from '../../../hooks/useAnalytics';

const OrderSummaryFactory = props => {
    const { title, source, formStep, currentStep } = props;
    const intl = useIntl();
    const [{ quoteDetailsData }] = useQuotesState();
    const { quoteSummary } = useQuoteSummary();
    const { sendEventsForP2PApplication } = useAnalyticsContext();
    const [{ payloadQuoteDetailsPageLoadActionAnalytics }] = useAnalytics();
    const {
        orderSummaryDetails,
        showPerDay,
        deliveryChargeFlag,
        showDeliveryEstimate,
        handleOpenTBDOverlay,
        handleRoundTripAndEstimateFlow,
        isRatesFromTier2Radius,
        isDeliveryFeeRequired,
        shouldShowTbdAlert,
        isInStorePickupFromSessionStorage,
        dispatch
    } = useOrderSummaryFactory(props);

    const isQuote = source === FACTORYSOURCE.QUOTE;
    const {
        checkoutSubtotal: subtotalValue,
        rentalSubtotal,
        purchaseSubtotal,
        rentalProtectionPlan,
        prepayFuelOption,
        environmentalServiceFee: envFees,
        otherFees,
        taxes,
        estimatedSubtotal,
        deliveryPickUpCharges
    } = isQuote ? quoteSummary : orderSummaryDetails;

    useEffect(() => {
        const isEditQuoteFlow = checkIsEditQuoteFlow();
        if (isQuote && estimatedSubtotal > 0 && !isEditQuoteFlow) {
            sendEventsForP2PApplication(
                EVENT_PAGE_NAMES_CONFIG.QUOTE_DETAIL_VIEWED,
                payloadQuoteDetailsPageLoadActionAnalytics()
            );
        }
    }, [quoteSummary]);

    const data = {
        title,
        rentalSubtotal: isQuote ? currencyToLocale(rentalSubtotal) : showPerDay(rentalSubtotal),
        purchaseSubtotal: isQuote ? currencyToLocale(purchaseSubtotal) : showPerDay(purchaseSubtotal),
        rentalProtectionPlan: currencyToLocale(rentalProtectionPlan),
        prepayFuelOption: currencyToLocale(prepayFuelOption),
        envFees: currencyToLocale(envFees),
        otherFees: currencyToLocale(otherFees),
        taxes: currencyToLocale(taxes),
        subtotalValue: currencyToLocale(subtotalValue),
        estimatedSubtotal: isQuote ? currencyToLocale(estimatedSubtotal) : showPerDay(estimatedSubtotal),
        deliveryPickUpCharges: currencyToLocale(deliveryPickUpCharges),
        showDeliveryEstimate,
        dispatch
    };

    const cartAndCheckoutCommonProps = {
        intl,
        orderTotals: data,
        source,
        deliveryChargeFlag,
        handleOpenTBDOverlay,
        handleRoundTripAndEstimateFlow,
        isRatesFromTier2Radius,
        isDeliveryFeeRequired
    };

    switch (source) {
        case FACTORYSOURCE.MINICART:
            return <CartSummaryView {...cartAndCheckoutCommonProps} />;
        case FACTORYSOURCE.CHECKOUT:
            return (
                <CheckoutSummaryView
                    {...cartAndCheckoutCommonProps}
                    currentStep={currentStep}
                    formStep={formStep}
                    shouldShowTbdAlert={shouldShowTbdAlert}
                    isInStorePickupFromSessionStorage={isInStorePickupFromSessionStorage}
                />
            );
        case FACTORYSOURCE.QUOTE:
            return (
                <QuoteSummaryView
                    intl={intl}
                    orderTotals={data}
                    source={source}
                    shouldShowTbdAlert={shouldShowTbdAlert}
                    quoteDetailsData={quoteDetailsData}
                />
            );
        default:
            null;
    }
};

export default memo(OrderSummaryFactory);

OrderSummaryFactory.propTypes = {
    title: string,
    source: string,
    formStep: object,
    currentStep: number
};
OrderSummaryFactory.defaultProps = {
    title: '',
    source: '',
    formStep: {},
    currentStep: 1
};
