import get from 'lodash/get';
import moment from 'moment';
import * as moment2 from 'moment-timezone';
import { createIntl, createIntlCache } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import { ADDRESS_COMPONENTS } from '../../../aem-core-components/components/Minicart/constants';
import { ADDRESS_COMPONENT_TYPE } from '../../../aem-core-components/components/Minicart/roundtrip/Constants';
import { cookieValue } from '../../../aem-core-components/utils/cookieUtils';
import config from '../../../components/App/config';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../../../constants/envConfig';
import {
    CATEGORY_PAGE_TEMPLATE_STRING,
    EQUIPMENT_RENTAL,
    HOME_PAGE,
    LOCATION,
    PDP,
    PLP,
    SEARCH,
    SEARCH_RESULTS_PAGE
} from '../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import i18Messages from '../../../i18n/en.json';
import { QTY_LIMIT, YES } from '../../pdp/constants';
import { FORCE_ITEM } from '../../checkout/constants';
import { customSearchBox } from '../../search/controllers/searchresultscontroller';
import { RECENTLY_VIEWED } from '../modules/recentlyViewed/constants';
import { isValidString, logError } from './logger';
import { AUTHORITY_TYPE } from '../constants';
import { ENV_CONFIGS } from '../constants';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import { QUOTE_DETAIL_RECORD_TYPE_PUNCHOUT } from '../../quotes/constants';
import { GENERAL_EQUIPMENT_AND_TOOLS } from '../../location/constants';
const stateJson = require('../../../constants/config.json');
const isDevelopment = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
const authorityType = useCheckAuthorityType();

export const EMAIL_REGEX = /^[A-Za-z0-9]+[\w.-]*@[A-Za-z0-9-]+\.[a-z]{2,6}$/;
export const PHONE_REGEX = /^([0-9]{3})[-]?[ ]?([0-9]{3})[-]?([0-9]{4})$/;
export const NAME_REGEX = /^[A-Za-z-]{0,50}$/;
export const LETTER_REGEX = /(?=.*[A-Za-z])/;
export const DIGIT_REGEX = /\D/g;
export const CITY_REGEX = /^.{0,50}$/;
export const consecutiveSpecialCharRegex = /\W\W/;
export const ZIP_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
export const ACCESS_NOTES_REGEX = /^[a-zA-Z0-9- ]{0,160}$/;
export const PRIMARY_CONTACT_REGEX = /^[A-Za-z- ]{0,50}$/;
export const PO_NUMBER_REGEX = /^[a-zA-Z0-9-]{0,26}$/;
export const POSTAL_REGEX = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
export const CUSTOM_FIELDS_REGEX = /[&%=]/;
export const JOBSITE_FIELDS_REGEX = /^[A-Za-z0-9& -]{0,30}$/;
export const DELAY_TIME = 200;
export const frontendConfig = ENV_CONFIG.FRONTEND_CONFIG;
export const environment = ENV_CONFIG.ENVIRONMENT;
export const endPoint = ENV_CONFIG.ENDPOINT;
export const googleApiKey = ENV_CONFIG.GOOGLE_API_KEY;
export const validStateList = ENV_CONFIG.VALID_STATE_LIST;
export const cacheItemsRatesLimit = 150;
export const isWebView = navigator.userAgent.includes('wv');
export const punchoutPath = '/procure-to-pay/punchout';
export const MIN_SEARCH_QUERY_LENGTH = 3;
export const MIN_ERROR_MSG_LENGTH = 2;
export const DEFAULT_ACCOUNT_ROW_HEIGHT = 81;
export const DEFAULT_ACCOUNT_ROW_HEIGHT_P2P = 126;
export const JOBSITE_SEARCH_LENGTH = 10;
export const SEARCH_VIEW_LENGTH = 20;

export const validateEmail = email => {
    if (consecutiveSpecialCharRegex.test(String(email))) {
        return false;
    }
    return EMAIL_REGEX.test(String(email).toLowerCase());
};

export const validatePhone = phone => PHONE_REGEX.test(String(phone));

export const validateName = name => NAME_REGEX.test(String(name)) && LETTER_REGEX.test(String(name));

export const validateJobSite = jobsite => JOBSITE_FIELDS_REGEX.test(String(jobsite));

export const validateZip = zip => {
    const companyID = parseInt(localStorage.getItem('companyID')) || 1;
    if (companyID === 2) {
        if (zip?.indexOf('000') > -1) {
            return true;
        } else {
            return POSTAL_REGEX.test(String(zip));
        }
    } else {
        return ZIP_REGEX.test(String(zip));
    }
};
export const validateCustomField = text => CUSTOM_FIELDS_REGEX.test(String(text));

export const validateCity = city => CITY_REGEX.test(String(city));

export const validateAccessNotes = accessNotes => ACCESS_NOTES_REGEX.test(String(accessNotes));

export const validatePONumber = poNumber => PO_NUMBER_REGEX.test(String(poNumber));

export const validatePrimaryContact = primaryContact => PRIMARY_CONTACT_REGEX.test(String(primaryContact));

export const formatMobileNumber = value => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const getDifferenceinHours = (pickupDateTime, returnDateTime) => {
    const date1 = new Date(pickupDateTime);
    const date2 = new Date(returnDateTime);
    return Math.abs(date2 - date1) / 36e5;
};

export const getTimeStops = (start, end) => {
    let endTime = moment(end, 'hh:mm A');
    let startTime = moment(start, 'hh:mm A');
    // if (endTime.isBefore(startTime)) {
    //     endTime.add(1, 'day');
    // }
    let timeStops = [];
    let count = 0;
    while (startTime < endTime) {
        if (count > 0) {
            startTime.add(30, 'minutes');
        }
        // const currentTime = moment().add(30, 'minutes');
        let timeAfterOffset = '';
        // if (startTime > currentTime) {
        timeStops.push(new moment(startTime).format('hh:mm A'));
        count++;
        // }
    }
    return timeStops;
};

export const makeTimeInterval = (startTime = '') => {
    const start = startTime || moment();
    const remainder = 30 - (start.minute() % 30);
    const dateTime = moment(start).add(remainder, 'minutes');
    return dateTime;
};

export const tommorrowDate = () => {
    let tommorrowDate = moment().add(1, 'days');
    tommorrowDate = moment(tommorrowDate).format('LL');
    return tommorrowDate;
};

export const convertDateToMomentDateTime = (date, selTime) => {
    let dateMoment = date ? moment(date) : moment();
    let pickupTime = '';
    let currTime = moment();
    if (selTime) {
        pickupTime = moment(selTime, 'h:mm A');
    }
    const dateAndTime = dateMoment.set({
        hour: pickupTime ? pickupTime.get('hour') : currTime.get('hour'),
        minute: pickupTime ? pickupTime.get('minute') : currTime.get('minute'),
        second: pickupTime ? pickupTime.get('second') : currTime.get('second'),
        millisecond: pickupTime ? pickupTime.get('millisecond') : currTime.get('millisecond')
    });
    // return dateAndTime.utc().format();
    let dateTimeFormatted = moment(dateAndTime?.toDate()).format('YYYY-MM-DDTHH:mm:ss[Z]');
    return dateTimeFormatted;
};

export const convertDateToMomentDateTimeNonUTC = (date, selTime) => {
    let dateMoment = date ? moment(date) : moment();
    let pickupTime = '';
    let currTime = moment();
    if (selTime) {
        pickupTime = moment(selTime, 'h:mm A');
    }
    const dateAndTime = dateMoment.set({
        hour: pickupTime ? pickupTime.get('hour') : currTime.get('hour'),
        minute: pickupTime ? pickupTime.get('minute') : currTime.get('minute'),
        second: 0,
        millisecond: 0
    });
    let dateTimeFormatted = moment(dateAndTime?.toDate()).format('YYYY-MM-DDTHH:mm:ss[Z]');
    return dateTimeFormatted;
};

export const getTimeFromCity = timezone => {
    const offset = getNormalizedUtcOffset(timezone);
    const d = new Date();
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    return new Date(utc + 3600000 * offset);
};

/**
 * Returns the UTC offset for the given timezone
 * @param timezone Example: America/New_York
 * @returns Offset value of passed timezone
 */
const getNormalizedUtcOffset = timezone => {
    const momentTimezone = moment2.tz(timezone);
    if (!momentTimezone) {
        return null;
    }
    let offset = momentTimezone.utcOffset();
    if (momentTimezone.isDST()) {
        // utcOffset will return the offset normalized by DST. If the location
        // is in daylight saving time now, it will be adjusted for that. This is
        // a NAIVE attempt to normalize that by going back 1 hour
        // TODO: Need to verify DST post API update
        // offset -= 60;
    }
    return offset / 60;
};
export const addTPlusNDays = selectedStartDate => {
    const dow = moment(selectedStartDate).day();
    let days = 4;
    if (dow === 3) {
        days = 5;
    }
    if (dow === 2) {
        days = 6;
    }
    return days;
};

export const formatCurrency = value => {
    const amount = parseInt(value);
    if (amount === 0) {
        return '$' + value;
    }
    return currencyToLocale(value, 2, 2);
};

export const currencyToLocale = (value, maxFractionDigits, minFractionDigits) => {
    if (value && value !== '-' && !isNaN(value)) {
        // If the value is negative, the -sign will be removed from the value and it will be wrapped inside parenthesis.
        // e.g input: '-1069.21' output- '($1,069.21)'
        if (Number(value) < 0) {
            return (
                '($' +
                Math.abs(value).toLocaleString('en-US', {
                    maximumFractionDigits: maxFractionDigits,
                    minimumFractionDigits: minFractionDigits
                }) +
                ')'
            );
        }
        if (Number.isInteger(Number(value))) {
            return (
                '$' +
                Number(value).toLocaleString('en-US', {
                    maximumFractionDigits: maxFractionDigits,
                    minimumFractionDigits: minFractionDigits
                })
            );
        }
        return '$' + Number(value).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    } else {
        return '-';
    }
};

/**
 * get total quantity, total price of rentals and addons in consumables
 *
 * @param {*}  selectedObj accept the selected object from context
 * @returns {}
 */
export const getTotalFromConsumables = selectedObj => {
    let totalRentalPrice = 0;
    let totalRentalQty = 0;
    let totalAddonPrice = 0;
    let totalAddonQty = 0;
    Object.keys(selectedObj).forEach(item => {
        let selectedArray = Object.keys(selectedObj[item]);
        if (selectedArray.includes('rentals')) {
            let rentalArray = Object.values(selectedObj[item]['rentals']);
            rentalArray.forEach(itemRental => {
                totalRentalQty += itemRental.qty;
                totalRentalPrice += itemRental.qty * itemRental.price;
            });
        }
        if (selectedArray.includes('addons')) {
            let addonArray = Object.values(selectedObj[item]['addons']);
            addonArray.forEach(itemAddon => {
                totalAddonQty += itemAddon.qty;
                totalAddonPrice += itemAddon.qty * itemAddon.price;
            });
        }
    });
    return { totalRentalPrice, totalRentalQty, totalAddonQty, totalAddonPrice };
};

export const getEnv = () => {
    var host = window.location.hostname;
    if (host.includes('localhost')) {
        return 'localhost';
    } else if (host.includes('dev-www')) {
        return 'development';
    } else if (host.includes('qa-www')) {
        return 'qa';
    } else if (host.includes('uat-www')) {
        return 'uat';
    } else if (host.includes('stage-www')) {
        return 'stage';
    } else if (host === 'www.sunbeltrentals.com') {
        return 'production';
    }
};

const getFallbackConfig = () => {
    const env = getEnv();
    switch (env) {
        case VARIABLE_CONFIG.DOMAIN.PRODUCTION:
            return ENV_CONFIGS[VARIABLE_CONFIG.DOMAIN.PRODUCTION];
        case VARIABLE_CONFIG.DOMAIN.STAGING:
            return ENV_CONFIGS[VARIABLE_CONFIG.DOMAIN.STAGING];
        default:
            return ENV_CONFIGS[VARIABLE_CONFIG.DOMAIN.QA];
    }
};

const fallbackConfig = getFallbackConfig();

export const getApiConfigByEnv = () => {
    const muleDomain = frontendConfig?.muledomain || fallbackConfig?.muledomain;
    const clientId = frontendConfig?.clientid || fallbackConfig?.clientId;
    const clientSecret = frontendConfig?.clientsecretcode || fallbackConfig?.clientsecretcode;
    const isP2POrPunchout = [AUTHORITY_TYPE.P2P, AUTHORITY_TYPE.PUNCHOUT].includes(authorityType);
    return {
        baseURL: muleDomain, // api domain
        timeout: 30000,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            client_secret: clientSecret,
            client_id: clientId,
            'Request-Id': '12323sunbelt',
            channel: isP2POrPunchout ? 'PUNCHOUT' : 'WEBAPP'
        }
    };
};

export const getCoveoConfig = () => ({
    organizationId: 'sunbeltdemobphillips4fa7owwx',
    accessToken: 'xxe5278697-8671-4959-bae4-5e6e9b72c09b'
});
export const getSentryConfigByEnv = () => {
    //TODO add logic for different node_envs
    return {
        dsn: 'https://e75fe20c6bdc4a97b890fb6835796de3@o102828.ingest.sentry.io/5981171'
    };
};

export const generateSKU = (catId, classId) => {
    const catRental = ('000' + catId).slice(-3);
    const classRental = ('0000' + classId).slice(-4);
    return catRental + classRental;
};

export const removeZeroPriceItems = (items, estimatesObj = {}) => {
    items?.forEach(item => {
        let rentablesLength = item?.rentables?.length;
        while (rentablesLength--) {
            const currRent = item?.rentables[rentablesLength];
            if (
                estimatesObj[`${generateSKU(currRent?.rentalCat, currRent?.rentalClass)}`] != 0 ||
                currRent.forceItem === FORCE_ITEM.YES
            ) {
                continue;
            } else {
                item?.rentables?.splice(rentablesLength, 1);
            }
        }
        item?.consumables?.forEach(obj => {
            let specGroupsLength = obj?.specGroups?.length;
            while (specGroupsLength--) {
                if (obj?.specGroups[specGroupsLength]?.maxPrice != 0) {
                    continue;
                } else {
                    obj?.specGroups?.splice(specGroupsLength, 1);
                }
            }
        });
    });
    return items;
};

export const getAvailableConsumablesConfig = items => {
    let remainingAccessories = {};
    let remainingAddOns = {};
    let totalAddOns = {};
    items?.forEach(item => {
        for (let index1 = 0; index1 < item.rentables.length; index1++) {
            const currRent = item.rentables[index1];
            if (!remainingAccessories[`${generateSKU(currRent?.rentalCat, currRent?.rentalClass)}`]) {
                remainingAccessories[`${generateSKU(currRent?.rentalCat, currRent?.rentalClass)}`] = {
                    accessoriesAvailableQuantity: parseInt(currRent.availQuantity),
                    accessoriesRemainingQuantity: parseInt(currRent.availQuantity)
                };
            } else {
                continue;
            }
        }
        for (let index2 = 0; index2 < item.consumables.length; index2++) {
            for (let index3 = 0; index3 < item.consumables[index2]?.specGroups?.length; index3++) {
                totalAddOns[`${generateSKU(item?.cat, item?.class)}`] =
                    (totalAddOns[`${generateSKU(item?.cat, item?.class)}`] || 0) + 1;
                for (let index4 = 0; index4 < item.consumables[index2].specGroups[index3].items.length; index4++) {
                    const currRent = item.consumables[index2].specGroups[index3].items[index4];
                    if (!remainingAddOns[`${currRent?.itemNumber}${currRent?.stockClass}${currRent?.oemStockNumber}`]) {
                        remainingAddOns[`${currRent?.itemNumber}${currRent?.stockClass}${currRent?.oemStockNumber}`] = {
                            addOnsAvailableQuantity: parseInt(currRent.availQuantity),
                            addOnsRemainingQuantity: parseInt(currRent.availQuantity)
                        };
                    } else {
                        continue;
                    }
                }
            }
        }
    });
    return { remainingAccessories, remainingAddOns, totalAddOns };
};

export const sortConsumables = items => {
    items.map(item =>
        item.rentables.sort((prevItem, currentItem) => {
            if (prevItem.availQuantity !== currentItem.availQuantity) {
                return currentItem.availQuantity - prevItem.availQuantity;
            } else {
                return prevItem.description.localeCompare(currentItem.description);
            }
        })
    );
    return items;
};

export const PRODUCT_TILE_MOUNT_REACT = 'product__tile__react';

export const getProductAssetsList = () => {
    let aemProducts = document.querySelectorAll(config.mountingPoints.addToCartTile);
    let reactProducts = document.querySelectorAll(`.${PRODUCT_TILE_MOUNT_REACT}`);
    let products = [...aemProducts, ...reactProducts];
    var assets = [];
    for (let i = 0; i < products.length; i++) {
        if (products[i]?.dataset?.productdata) {
            var productData = JSON.parse(products[i]?.dataset.productdata);
            assets.push(productData.catclass);
        }
    }
    return [...new Set(assets)].filter(item => item);
};

export const formatAccountProjects = (data = []) => {
    let replaceObj = { '(': '', ')': '-', ' ': '' };
    return data?.map(item => {
        let delInstructions =
            `${item?.deliveryInstructions1}${item?.deliveryInstructions2}${item?.deliveryInstructions3}${item?.deliveryInstructions4}`.replace(
                /\s+$/,
                ''
            );
        return {
            jobNumber: item?.projectId?.trim(),
            jobName: sentenceCase(item?.address[0]?.attn?.trim()),
            rmJobsiteId: item?.jobsiteId,
            account: item?.contact?.id,
            companyID: item?.companyId || 1,
            address1: sentenceCase(item?.address[0]?.line1?.trim()) || sentenceCase(item?.Location?.trim()),
            address2: item?.address[0]?.line2?.trim(),
            city: sentenceCase(item?.address[0]?.city?.trim()),
            state: item?.address[0]?.state?.trim(),
            zip: item?.address[0]?.zip?.trim(),
            latitude: item?.address[0]?.latitude,
            longitude: item?.address[0]?.longitude,
            isGeoCoded: item?.address[0]?.isGeoCoded,
            locationPC: parseInt(item?.address[0]?.locationPC),
            salesRepId: item?.salesRepId,
            isActive: item?.isActive,
            jobKey: item?.projectKey,
            contactName: item?.contact?.name,
            contactPhone: item?.contact?.phone?.replace(/[() ]/g, item => replaceObj[item]),
            hasEquipment: false,
            equipmentCount: item?.equipmentCount,
            deliveryInstructions: delInstructions,
            customerPO: item?.customerPO?.trim(),
            CJDLVY: item?.CJDLVY,
            location: item?.Location?.trim(),
            rType: item?.Rtype
        };
    });
};

export const makeProductURL = (productUrl = '') => {
    return window.location.origin + productUrl;
};

export const recentlyViewedTracker = productId => {
    const recentProducts = localStorage.getItem(RECENTLY_VIEWED);
    if (recentProducts) {
        let productList = JSON.parse(recentProducts);
        productList = productList?.filter(value => {
            if (value !== productId) {
                return value;
            }
        });
        productList.unshift(productId);
        localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(productList));
    } else {
        const productArray = [];
        productArray[0] = productId;
        localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(productArray));
    }
};

const cache = createIntlCache();
export const intl = createIntl({ locale: 'en', messages: i18Messages }, cache);

export const coveoEngineOptions = () => {
    const defaultEngineOptions = {
        configuration: {
            organizationId: 'sunbeltrentalsnonproduction1nxhhxbub',
            accessToken: 'xx21202c1b-acad-424d-ad77-277faf80780c',
            organizationEndpoints: 'https://platform.cloud.coveo.com',
            searchHub: 'Sunbelt-Commerce'
        }
    };
    return defaultEngineOptions;
};
export const MakeArrayOfObjects = arr => {
    let arr2 = [];
    arr?.forEach(ele => {
        arr2.push({
            value: `${ele ? ele : ''}`,
            label: `${ele ? ele : ''}`
        });
    });
    return arr2;
};

export const makeArrayFromObjects = Obj => {
    let arr2 = [];
    for (let i in Obj) {
        arr2.push({
            identifier: i,
            value: Obj[i].value,
            number: Obj[i].number
        });
    }
    return arr2;
};

export const getURLParams = () => {
    const url = new URL(window.location.href);

    let regex = /[?&]([^=#]+)=([^&#]*)/g,
        params = {},
        match;
    while ((match = regex?.exec(url))) {
        params[match[1]] = match[2];
    }
    return params;
};
export const isPaymentSectionVisited = () => {
    const params = getURLParams();
    if (params?.TRANSUCCESS || params?.CUSTOM2) {
        return true;
    } else {
        return false;
    }
};
export const getProjectsFromCookie = () => {
    const settingsCookie = cookieValue('Settings');
    const projectObj = {};
    if (settingsCookie) {
        const projectsInfo = settingsCookie.split('&');
        for (let i = 0; i < projectsInfo.length; i++) {
            const [key, value] = projectsInfo[i].split('=');
            projectObj[key] = decodeURIComponent(value);
        }
    }
    return projectObj;
};

export const getUserAccountFromCookies = () => {
    const projectObj = getProjectsFromCookie();
    return {
        accountName: projectObj?.CurrentWynneAccountName || '',
        accountNumber: parseInt(projectObj?.CurrentWynneAccount) || '',
        accountStatus: projectObj?.CurrentWynneAccountStatus || ''
    };
};

export const getInitialProjectStateFromCookie = () => {
    const isNewAddress = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
    const projectInfoCookies = getProjectsFromCookie();
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const selectedAccID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTED_ACCOUNT_ID);
    let setProjectFromStorage = false;
    if (projectInfoCookies?.CurrentWynneAccount && !isNewAddress) {
        if (projectInfoCookies?.CurrentWynneAccount == selectedAccID) {
            setProjectFromStorage = true;
        }
    }
    if (setProjectFromStorage) {
        return {
            locationPC: overridePC?.pc || parseInt(projectInfoCookies?.locationPC) || null,
            projectName: projectInfoCookies?.projectName || '',
            projectAddress1: projectInfoCookies?.SLA || '',
            projectAddress2: projectInfoCookies?.projectAddress2 || '',
            selectedProjectJobId: projectInfoCookies?.CurrentJobSite || '',
            selectedRMJobId: projectInfoCookies?.RMJobsiteId || '',
            selectedProjectLatititude: parseFloat(projectInfoCookies?.SLLat) || '',
            selectedProjectLongitude: parseFloat(projectInfoCookies?.SLLong) || '',
            selectedProjectState: projectInfoCookies?.SLS || '',
            selectedProjectCity: projectInfoCookies?.SLC || '',
            selectedProjectZip: projectInfoCookies?.SLZ || '',
            primaryContactName: projectInfoCookies?.primaryContactName || '',
            phoneNumber: projectInfoCookies?.phoneNumber || '',
            accessNotes: projectInfoCookies?.accessNotes || '',
            poNumber: projectInfoCookies?.poNumber || '',
            isValueUpdated: false,
            CJDLVY: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY) || ''
        };
    } else {
        return {
            locationPC: null,
            projectName: '',
            projectAddress1: '',
            projectAddress2: '',
            selectedProjectJobId: '',
            selectedRMJobId: '',
            selectedProjectLatititude: '',
            selectedProjectLongitude: '',
            selectedProjectState: '',
            selectedProjectCity: '',
            selectedProjectZip: '',
            primaryContactName: '',
            phoneNumber: '',
            accessNotes: '',
            poNumber: '',
            isValueUpdated: false,
            CJDLVY: ''
        };
    }
};

export const updateQueryParam = val => {
    const url = new URL(window.location);
    url.searchParams.set('q', val);
    window.history.replaceState({}, '', url.href);
};

export const removeQueryParam = param => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete(param);
    window.history.replaceState({}, document.title, currentUrl.toString());
};

export const redirectToQuotesPageWithQuoteId = searchStr => {
    window.location = basePathForPunchout + config.pagePaths.p2pQuoteListPage + '?q=' + searchStr;
};

export const cityAndZipValidationAddressComponent = (details, countryShortName) => {
    const addressComponents = get(details, 'addressComponents', []);
    let isCityEmpty = true;
    let isZipEmpty = true;
    let isStateValid = true;

    if (addressComponents?.length > 0) {
        addressComponents?.map(component => {
            const type = determineTypeOfAddress(component);
            switch (type) {
                case ADDRESS_COMPONENT_TYPE.CITY: {
                    isCityEmpty = false;
                    break;
                }
                case ADDRESS_COMPONENT_TYPE.SUBLOCALITY: {
                    isCityEmpty = false;
                    break;
                }
                case ADDRESS_COMPONENT_TYPE.POSTAL_CODE: {
                    isZipEmpty = false;
                    break;
                }
                case ADDRESS_COMPONENT_TYPE.STATE: {
                    isStateValid = countryShortName === 'ca' ? !isValidState(component.shortName) : false;
                    break;
                }
                default:
                    break;
            }
        });
    }
    if (isCityEmpty || isZipEmpty || isStateValid) {
        return false;
    } else {
        return true;
    }
};

export const determineTypeOfAddress = component => {
    const types = get(component, 'types', []);
    if (
        types.includes(ADDRESS_COMPONENTS.LOCALITY) ||
        types.includes(ADDRESS_COMPONENTS.AL3) ||
        types.includes(ADDRESS_COMPONENTS.AL2)
    ) {
        return ADDRESS_COMPONENT_TYPE.CITY;
    }
    if (types.includes(ADDRESS_COMPONENTS.AL1)) {
        return ADDRESS_COMPONENT_TYPE.STATE;
    }
    if (types.includes(ADDRESS_COMPONENTS.POSTAL)) {
        return ADDRESS_COMPONENT_TYPE.POSTAL_CODE;
    }
    if (types.includes(ADDRESS_COMPONENTS.STREET_NO)) {
        return ADDRESS_COMPONENT_TYPE.STREET;
    }
    if (types.includes(ADDRESS_COMPONENTS.COUNTRY)) {
        return ADDRESS_COMPONENT_TYPE.COUNTRY;
    }
    if (types.includes(ADDRESS_COMPONENTS.ROUTE)) {
        return ADDRESS_COMPONENT_TYPE.ROUTE;
    }
    if (types.includes(ADDRESS_COMPONENTS.SUBLOCALITY)) {
        return ADDRESS_COMPONENT_TYPE.SUBLOCALITY;
    }
};

export const isValidState = state => {
    if (validStateList?.includes(state)) {
        return true;
    } else {
        return false;
    }
};

export const extractAddressComponents = details => {
    let computedAddress = {
        jobSiteAddr2: '',
        jobSiteCity: '',
        jobSiteState: '',
        jobSiteZip: ''
    };
    const addressComponents = get(details, 'addressComponents', []);
    let citySelected = false;
    if (addressComponents?.length > 0) {
        addressComponents?.map(component => {
            const type = determineTypeOfAddress(component);
            switch (type) {
                case ADDRESS_COMPONENT_TYPE.CITY: {
                    computedAddress.jobSiteCity = determineCityComponent(addressComponents);
                    citySelected = true;
                    break;
                }

                case ADDRESS_COMPONENT_TYPE.STATE: {
                    computedAddress.jobSiteState = component.shortName;
                    break;
                }

                case ADDRESS_COMPONENT_TYPE.POSTAL_CODE: {
                    computedAddress.jobSiteZip = component.longName;
                    break;
                }
                case ADDRESS_COMPONENT_TYPE.SUBLOCALITY: {
                    if (!citySelected) {
                        computedAddress.jobSiteCity = component.longName;
                    }
                    break;
                }
                default:
                    break;
            }
        });
    }
    return computedAddress;
};

export const deleteCookies = (exludeCookies = []) => {
    var allCookies = document.cookie
        .split(';')
        ?.filter(cookie => !exludeCookies.includes(cookie.split('=')[0]?.trim()));
    for (var i = 0; i < allCookies.length; i++)
        document.cookie =
            allCookies[i] + '=;expires=' + new Date(0).toUTCString() + '=; path=/; SameSite=None; Secure; Max-Age=0;';
};

export const reloadOnAccountChange = userType => {
    if (userType === USER_TYPE.CREDIT && window.location.href.includes('myaccount')) {
        window.location.reload();
    }
};
export const getRentalDuration = (startDate, endDate) => {
    const rentalDuration = new Date(endDate).getTime() - new Date(startDate).getTime();
    return rentalDuration / (1000 * 3600 * 24);
};
export const getPageReferrer = () => {
    var referrerLink = document.referrer.split('/');
    if (document.referrer.includes(EQUIPMENT_RENTAL)) {
        if (isNaN(referrerLink[referrerLink.indexOf(EQUIPMENT_RENTAL) + 1])) {
            return PLP.toLowerCase();
        } else return PDP;
    } else if (document.referrer.includes(SEARCH)) {
        return SEARCH_RESULTS_PAGE.toLowerCase();
    } else if (document.referrer.includes(LOCATION)) {
        return VARIABLE_CONFIG.PAGE_TYPE.LOCATION_PAGE;
    } else if (document.referrer === '' || document.referrer === window?.location?.href) {
        return VARIABLE_CONFIG.ECOMMERCE.UNDEFINED;
    } else if (referrerLink.length <= 4) {
        return HOME_PAGE.toLowerCase();
    }
};
export const getPCfromList = pcList => {
    let cidPcList = [];
    let atpPcList = [];
    let pcDetails;
    const { pricingPcs, pickupStorePcs } = getExcludedPCfromList(pcList);

    for (let i in pricingPcs) {
        cidPcList.push(formatNearbyPC(pricingPcs[i]?.pc));
    }

    for (let i in pcList) {
        atpPcList.push(formatNearbyPC(pcList[i]?.pc));
    }

    const specialities = pcList?.find(
        item =>
            !item?.specialties ||
            item?.specialties?.length === 0 ||
            item?.specialties?.[0] === GENERAL_EQUIPMENT_AND_TOOLS
    );

    pcDetails = specialities || pcList?.[0];
    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST, JSON.stringify(cidPcList));
    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST, JSON.stringify(atpPcList));
    return { pcDetails, pickupStorePcs };
};

const getExclusionPCList = excludePcString => {
    let excludePcs = [];
    if (excludePcString?.length > 2) {
        const excludedString = excludePcString?.substring(1, excludePcString?.length - 1);
        excludePcs = excludedString?.split(',')?.map(element => element?.trim());
    }
    return excludePcs || [];
};

export const getExcludedPCfromList = pcList => {
    const excludePricePcs = getExclusionPCList(ENV_CONFIG.EXCLUDED_SPECIALTY_LIST);
    // const excludePickupPcs = getExclusionPCList(ENV_CONFIG.PICKUP_STORE_EXCLUDED_SPECIALTY_LIST);
    const filteredPricingPcs = [];
    const excludedPricingPcs = [];
    // const pickupStorePcs = [];
    for (let i in pcList) {
        const specialities = pcList[i]?.specialties;
        if (!specialities || !excludePricePcs?.includes(specialities?.[0]?.trim())) {
            filteredPricingPcs.push(pcList[i]);
        } else {
            excludedPricingPcs.push(pcList[i]);
        }
        // if (!specialities || !excludePickupPcs?.includes(specialities?.[0]?.trim())) {
        //     pickupStorePcs.push(pcList[i]);
        // }
    }
    return {
        /**  adding exclusion pcs at last, so if nearby pcs are not found from the filtered list,
         *  it will search from exclusion list as well
         * */
        pricingPcs: [...filteredPricingPcs, ...excludedPricingPcs],
        // pickupStorePcs: pickupStorePcs?.length > 0 ? pickupStorePcs : pcList
        pickupStorePcs: pcList
    };
};

// coveo

export const getProductListUrl = () => {
    let url = '';
    if (getEnv() === 'localhost') {
        //www.sunbeltrentals.com/equipment-rental/air-compressors-and-air-tools/air-compressor-aftercoolers-filters-separators-dryers/
        https: url = '/equipment-rental/air-compressors-and-air-tools/air-compressors/';
    } else if (window.location.pathname.includes('equipment-rental/')) {
        const level = document.querySelector("meta[name='categoryLevel']")?.content;
        if (level == 4) {
            let linkArr = window.location.pathname.split('/');
            if (linkArr[linkArr.length - 1] === '') {
                linkArr.pop();
            }
            linkArr.pop();
            linkArr = linkArr.join('/');
            url = `${linkArr}/`;
        } else {
            url += window.location.pathname;
        }
    } else {
        //To handle local env
        url = '/equipment-rental/air-compressors-and-air-tools/air-compressors/';
    }
    return url;
};

export const formatjobsiteZip = zipCode => {
    return (zipCode?.length === 3 ? zipCode + '000' : zipCode) || '';
};

export const handleAnonymousIdFormatting = (guid = '') => {
    if (guid === '' || !guid) {
        return undefined;
    }
    let rxGetGuidGroups = /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/;
    let formattedGUID = guid.replace(rxGetGuidGroups, '$1-$2-$3-$4-$5').toUpperCase();
    return formattedGUID;
};
export const getSignInUrl = (customRedirection = '', newAccount = false, includeReturnUrl = true) => {
    const returnUrl =
        window.location.pathname.indexOf(config.pagePaths.wiresCrossedPage) > -1 ? '/' : window.location.href;
    if (includeReturnUrl) {
        return isDevelopment
            ? `https://dev-www.sunbeltrentals.com/login/?returnUrl=${
                  customRedirection ? customRedirection : window.location.href
              }${newAccount ? '&newAccount=' + newAccount : ''}`
            : `${window.location.origin}/login/?returnUrl=${customRedirection || returnUrl}${
                  newAccount ? '&newAccount=' + newAccount : ''
              }`;
    } else {
        return isDevelopment
            ? `https://dev-www.sunbeltrentals.com/login/${newAccount ? '?newAccount=' + newAccount : ''}`
            : `${window.location.origin}/login/${newAccount ? '?newAccount=' + newAccount : ''}`;
    }
};

export const getProductId = () => {
    let { catsku } = document.querySelector('.block--pdp-hero')?.dataset || {};
    if (catsku) {
        return catsku;
    } else {
        return null;
    }
};

export const isGlobalRates = () => {
    if (window.location.pathname.includes('/search') || document.querySelector("meta[name='categoryTitle']")) {
        return false;
    }
    return true;
};

export const trapFocus = element => {
    var focusableEls = element.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), h1, h2, h3, h4, h5, h6, strong, b'
    );
    var firstFocusableEl = focusableEls[0];
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    element.addEventListener('keydown', function (e) {
        var isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
        if (!isTabPressed) {
            return;
        }
        if (e.shiftKey) {
            /* shift + tab */ if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } /* tab */ else {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    });
};

export const determineCityComponent = addressComponents => {
    for (let index in addressComponents) {
        if (addressComponents[index]?.types?.includes(ADDRESS_COMPONENTS.LOCALITY)) {
            return addressComponents[index]?.longName;
        } else if (addressComponents[index]?.types?.includes(ADDRESS_COMPONENTS.AL3)) {
            return addressComponents[index]?.longName;
        } else if (addressComponents[index]?.types?.includes(ADDRESS_COMPONENTS.AL2)) {
            return addressComponents[index]?.longName;
        }
    }
    return '';
};
export const isAccountOnHold = status => {
    return status?.toLowerCase() === 'h';
};

export const isAccountClosed = userAcc => {
    const S = userAcc?.accountStatus?.toLowerCase();
    const inactiveStatus = ['c', 'i', 's', 'h'];
    if (authorityType === AUTHORITY_TYPE.P2P) {
        return inactiveStatus.includes(S);
    }
    return inactiveStatus.includes(S) && !isValidString(userAcc?.isCorpLinkAccount);
};
export const isAccountBlocked = userAcc => {
    const S = userAcc?.accountStatus?.toLowerCase();
    const inactiveStatus = ['b', 'f'];
    if (authorityType === AUTHORITY_TYPE.P2P) {
        return inactiveStatus.includes(S);
    }
    return inactiveStatus.includes(S) && !isValidString(userAcc?.isCorpLinkAccount);
};
export const clearLocalStorage = (conserveKeys = []) => {
    const backupData = {};
    backupData[STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID] = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID);
    for (let key of conserveKeys) {
        backupData[key] = localStorage.getItem(key);
    }
    localStorage.clear();
    for (let key in backupData) {
        localStorage.setItem(key, backupData[key]);
    }
};
export const getStateList = () => {
    const companyID = parseInt(localStorage.getItem('companyID')) || '';
    const stateArray = companyID == 2 ? stateJson['STATES'][0]['CA'] : stateJson['STATES'][0]['US'];
    if (companyID == 2) {
        const validStateListArray = validStateList.split(',');
        return stateArray.filter(state => {
            if (validStateListArray.includes(state?.label) || validStateListArray.includes(state?.value)) {
                return state;
            }
        });
    }
    return stateArray;
};
export const getUserType = type => {
    if (type === 'cash') {
        return 'cash';
    } else if (type === 'credit' || type === 'employee') {
        return 'credit';
    } else {
        return 'guest';
    }
};
export const isCCPage = () => {
    const url = window.location.href?.toLowerCase();
    return (
        url.includes(config.pagePaths.myAccount) ||
        url.includes(config.pagePaths.webForms) ||
        url.includes(config.pagePaths.payment) ||
        url.includes(config.pagePaths.createAccountMain)
    );
};
export const isCheckoutPage = () => {
    try {
        // For P2P, we refer to the checkout page as the "create quote" page and the "edit quote" page.
        if (authorityType === AUTHORITY_TYPE.P2P) {
            const quotePagePaths = [config.pagePaths.p2pCreateQuote, config.pagePaths.p2pEditQuote];
            const isInQuotePagePaths = quotePagePaths.some(url => window.location.href.includes(url));
            return isInQuotePagePaths;
        }
        return window.location.href.includes(config.pagePaths.checkoutPage);
    } catch (error) {
        logError(error, false, 'isCheckoutPage');
    }
};
export const calcRQFlagStatus = flag => {
    const str = String(flag)?.toUpperCase();
    if (str === YES || str === '1') {
        return true;
    }
    return false;
};
export const checkIsRequestQuote = (showOnlineCatalog, disableAddToCart) => {
    if (calcRQFlagStatus(showOnlineCatalog) && !calcRQFlagStatus(disableAddToCart)) {
        return { showCTA: true, isRequestQuote: false };
    } else if (calcRQFlagStatus(showOnlineCatalog) && calcRQFlagStatus(disableAddToCart)) {
        return { showCTA: true, isRequestQuote: authorityType === AUTHORITY_TYPE.P2P ? false : true };
    } else if (!calcRQFlagStatus(showOnlineCatalog)) {
        return { showCTA: false, isRequestQuote: false };
    } else {
        return { showCTA: true, isRequestQuote: false };
    }
};

export const getValidQty = (cartItems, qtyToAdd, productId) => {
    let result = { error: false, quantity: qtyToAdd };
    if (cartItems && qtyToAdd && productId) {
        let productsWithQty = new Map([
            ...cartItems.map(prodObj => [prodObj?.product?.sku || prodObj?.productId, prodObj?.quantity])
        ]);
        let currentQty = productsWithQty.get(productId) || 0;
        if (currentQty >= QTY_LIMIT) {
            result = { error: true, quantity: 0 };
        } else if (currentQty + qtyToAdd > QTY_LIMIT) {
            result = { error: true, quantity: QTY_LIMIT - currentQty };
        }
    }
    return result;
};

export const redirectSignOutURL = (sessionExpired = false, returnUrl = '') => {
    let redirectURL = '';
    if (sessionExpired && returnUrl) {
        redirectURL = `${config.pagePaths.signout}?sessionExpired=${sessionExpired}&returnUrl=${returnUrl}`;
    } else if (sessionExpired && !returnUrl) {
        redirectURL = `${config.pagePaths.signout}?sessionExpired=${sessionExpired}`;
    } else if (!sessionExpired && returnUrl) {
        redirectURL = `${config.pagePaths.signout}?returnUrl=${returnUrl}`;
    } else {
        redirectURL = config.pagePaths.signout;
    }
    return redirectURL;
};
export const formatNearbyPCList = nearbyPCs => {
    const companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
    const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

    let nearbyPCList = nearbyPCs;
    if (overridePc?.pc || isValidString(ENV_CONFIG.DISABLE_INVENTORY_CHECK)) {
        nearbyPCList = [];
    }

    let formattedList = nearbyPCList?.map(element => {
        return companyID === 1 ? `01_${formatNearbyPC(element)}` : `02_${formatNearbyPC(element)}`;
    });
    return formattedList;
};
export const formatNearbyPC = (pc, id = null) => {
    const companyID = parseInt(localStorage.getItem('companyID')) || 1;
    if (id) {
        return `${id}_${nearbyPCFormat(pc)}`;
    } else {
        return companyID === 1 ? `01_${nearbyPCFormat(pc)}` : `02_${nearbyPCFormat(pc)}`;
    }
};
export const nearbyPCFormat = pc => {
    return ('000' + pc).slice(-4);
};

export const selectStartDate = (currentRange, dateClicked, setRangeState) => {
    let currentStartDate = new Date(currentRange[0].startDate).getTime();
    let currentEndDate = new Date(currentRange[0].endDate).getTime();
    if (!isNaN(currentStartDate) && !isNaN(currentEndDate) && !isNaN(dateClicked)) {
        if (dateClicked < currentEndDate) {
            currentRange[0].startDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked > currentEndDate) {
            currentRange[0].startDate = new Date(dateClicked);
            currentRange[0].endDate = '';
            setRangeState([...currentRange]);
        } else if (dateClicked == currentEndDate) {
            currentRange[0].startDate = new Date(dateClicked);
            if (dateClicked == currentStartDate) {
                currentRange[0].endDate = '';
            }
            setRangeState([...currentRange]);
        }
    } else if (!isNaN(currentStartDate) && currentRange[0]?.endDate === '' && !isNaN(dateClicked)) {
        if (dateClicked < currentStartDate) {
            currentRange[0].endDate = currentRange[0].startDate;
            currentRange[0].startDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked > currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked == currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);

            if (dateClicked == currentEndDate) {
                currentRange[0].startDate = new Date(dateClicked);
                currentRange[0].endDate = '';
            }
            setRangeState([...currentRange]);
        }
    }
};

export const selectEndDate = (currentRange, dateClicked, setRangeState) => {
    let currentStartDate = new Date(currentRange[0].startDate).getTime();
    let currentEndDate = new Date(currentRange[0].endDate).getTime();
    if (!isNaN(currentStartDate) && !isNaN(currentEndDate) && !isNaN(dateClicked)) {
        if (dateClicked > currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked < currentStartDate) {
            currentRange[0].endDate = '';
            currentRange[0].startDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked == currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        }
    } else if (!isNaN(currentStartDate) && currentRange[0]?.endDate === '' && !isNaN(dateClicked)) {
        if (dateClicked < currentStartDate) {
            currentRange[0].endDate = currentRange[0].startDate;
            currentRange[0].startDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked > currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        } else if (dateClicked == currentStartDate) {
            currentRange[0].endDate = new Date(dateClicked);
            setRangeState([...currentRange]);
        }
    }
};

export const triggerCoveoSearchAPI = () => {
    const params = new URLSearchParams(window.location.search);
    const search = params.get('q');
    if (search) {
        customSearchBox?.updateText(search);
        customSearchBox?.submit();
    } else {
        customSearchBox?.submit();
    }
};

export const isInventoryCheckRequired = () => {
    const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    if (isValidString(ENV_CONFIG.DISABLE_INVENTORY_CHECK)) {
        return false;
    } else if (isCheckoutPage()) {
        return false;
    } else if (overridePc?.pc) {
        return false;
    } else {
        return !isTier2Radius();
    }
};

export const isAtpCheckRequired = () => {
    const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

    if (isValidString(ENV_CONFIG.IS_ATP_DISABLED) || isValidString(isTier2Radius())) {
        return isInventoryCheckRequired()
            ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST))
            : [];
    } else if (overridePc?.pc) {
        return [];
    } else {
        return JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST));
    }
};

export const isNearByPcsRequired = showUnavailableItems => {
    const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const startDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) || '';
    if (isValidString(ENV_CONFIG.IS_ATP_DISABLED) || isValidString(isTier2Radius())) {
        return false;
    } else if (overridePc?.pc) {
        return true;
    } else {
        if (!isValidString(startDate)) {
            return true;
        } else if (window.location.pathname.includes('/search')) {
            return !showUnavailableItems;
        } else if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
            return false;
        } else {
            return true;
        }
    }
};
export const isTier2Radius = () => {
    const isLocationsTier2Radius = isValidString(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS)
    );
    const isSourcesTier2Radius = isValidString(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_SOURCES_TIER_2_RADIUS)
    );

    if (
        isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)) &&
        isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)) &&
        isCheckoutPage()
    ) {
        return isSourcesTier2Radius;
    }
    return isLocationsTier2Radius;
};

/* Set and remove the tier2radius flag according to the distance */
export const storeTier2Radius = distance => {
    if (distance == ENV_CONFIG.Tier1SearchRadius) {
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS);
    } else {
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS, true);
    }
};
/* Format the locations data to object, to make the iteration easy for ProductTile store modal */
export const formatStoreDataToObject = (stores = []) => {
    const allPcDetails = {};
    for (let i in stores) {
        allPcDetails[formatNearbyPC(stores[i].pc)] = stores[i];
    }
    return allPcDetails;
};

export const setFourHourRentals = (rates, productId) => {
    let fourHourRentals = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS) || '{}');

    const { daily, minimum } = rates || {};

    if (daily != minimum) {
        fourHourRentals[productId] = true;
    } else {
        fourHourRentals[productId] = false;
    }
    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS, JSON.stringify(fourHourRentals));
};

export const getHolidayList = customCompanyId => {
    let holidayList;
    let holidayListUS = document.querySelector('#holidays-us')?.dataset?.holidaylist || '';
    let holidayListCA = document.querySelector('#holidays-ca')?.dataset?.holidaylist || '';

    if (customCompanyId) {
        holidayList = customCompanyId == 2 ? holidayListCA : holidayListUS;
    } else {
        holidayList = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2 ? holidayListCA : holidayListUS;
    }
    let dates = [];
    if (holidayList) {
        const dateString = holidayList?.split(',');
        dates = dateString?.map(dateString => moment(dateString).toDate());
        return dates;
    } else {
        return dates;
    }
};
export const setPrefilledAddress = (streetAddress, selectedCity) => {
    let addrLine1 = streetAddress?.split(',')[0]?.trim();
    if (addrLine1 == selectedCity || selectedCity == '') {
        addrLine1 = '';
    }
    return addrLine1;
};

export const setPrefilledAddressForCreateProject = (streetAddress, selectedCity) => {
    let addrLine1 = streetAddress?.split(',')[0]?.trim();
    if (addrLine1 == selectedCity) {
        addrLine1 = '';
    }
    return addrLine1;
};
export const isDateDisabled = customCompanyId => {
    const isSundayEnabled = document.querySelector('#holidayConfigs')?.dataset?.enablesundayrental || 'false';
    const isSaturdayEnabled = document.querySelector('#holidayConfigs')?.dataset?.enablesaturdayrental || 'false';
    const holidays = getHolidayList(customCompanyId);
    return date => {
        const isHoliday = holidays.some(holiday => new Date(holiday).toDateString() === date.toDateString());
        if (isHoliday) {
            return true;
        }
        return (
            (date?.getDay() === 0 && !isValidString(isSundayEnabled)) ||
            (date?.getDay() === 6 && !isValidString(isSaturdayEnabled))
        );
    };
};

export const checkIsInstorePickup = () => {
    const userType = useCheckUser();
    if (checkIsEditQuoteFlow()) {
        return JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP) || false);
    } else {
        const isInstorePickupFromStorage = JSON.parse(
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP) || true
        );

        if (userType === USER_TYPE.CREDIT) {
            if (sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CREDIT_FULFILLMENT_SET)) {
                return isInstorePickupFromStorage;
            } else {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, false);
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CREDIT_FULFILLMENT_SET, true);
                return false;
            }
        }
        return isInstorePickupFromStorage;
    }
};

export const isFourHourRental = cart => {
    let items = cart?.items;
    let fourHourRentals = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS)
        ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS))
        : {};
    if (items?.length > 0) {
        return items?.find(item => {
            const { sku = '' } = item?.product;
            if (fourHourRentals[sku]) {
                return true;
            }
        });
    }
    return false;
};

export const arrayChunk = (arr, size = 3) => {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += size) {
        chunkedArray.push(arr.slice(i, i + size));
    }
    return chunkedArray;
};

export const splitArrayByIndex = arr => {
    const splitIndex = parseInt(ENV_CONFIG.OWNED_PC_RATES_LIMIT);
    const newArr = cloneDeep(arr);
    var firstHalf = newArr.slice(0, splitIndex);
    var secondHalf = newArr.slice(splitIndex);

    if (firstHalf.length > 0 && secondHalf.length > 0) {
        return [firstHalf, secondHalf];
    } else if (firstHalf.length > 0) {
        return [firstHalf];
    }

    return [];
};

export function splitArray(array, initBatchSize = 3) {
    let result = [];
    let start = 0;
    let batchSize = initBatchSize;
    while (start < array.length) {
        result.push(array.slice(start, start + batchSize));
        start += batchSize;
        batchSize += 3;
    }
    return result;
}

export const mobileResponsive = (pcAvailability = true) => {
    return {
        mobile: 2,
        smalltablet: 3,
        tablet: 4,
        smalldesktop: pcAvailability ? 3 : 2,
        desktop: pcAvailability ? 4 : 2
    };
};

export const recommendationMobileResponsive = () => {
    return {
        mobile: 2,
        smalltablet: 3,
        tablet: 4,
        smalldesktop: 6,
        desktop: 6
    };
};

export const isPunchoutPage = () => {
    return window.location.href.includes('/industries/') || window.location.pathname == punchoutPath + '/';
};

export const isAuthorMode = () => window.location.href.includes('author');
const computePunchoutBasePath = punchoutPath => {
    const path = window.location.pathname.replace(/\/$/, '');
    try {
        if (!isAuthorMode()) {
            return isDevelopment ? '/content/sunbeltrentals/us/en_US' + punchoutPath : punchoutPath;
        } else {
            return path; //ui for authored urls where rest of the route is hashed
        }
    } catch (e) {
        return punchoutPath;
    }
};

export const getAuthorHashedPath = () => {
    const hashed = window.location.hash;
    const pathWithoutHash = hashed.slice(1);
    const lastIndex = pathWithoutHash.lastIndexOf('/');
    return pathWithoutHash.substring(0, lastIndex);
};
// Determine the base path based on the value of isDevelopment
export const basePathForPunchout = computePunchoutBasePath(punchoutPath);

export const redirectToCheckoutOrQuotePage = (quoteId = '') => {
    let url = window.location.origin + config.pagePaths.checkoutPage;
    /** Need to add the condition when Create Quote or Edit Quote will be implemented */
    if (authorityType === AUTHORITY_TYPE.P2P) {
        url = window.location.origin + basePathForPunchout;
        if (quoteId) {
            url += config.pagePaths.p2pEditQuote + quoteId;
        } else {
            url += config.pagePaths.p2pCreateQuote;
        }
    }
    console.log(url, 'URL');
    window.location.href = url;
};
export const checkIsEditQuoteFlow = () => {
    // it should have a boolean value
    const showEditQuoteBanner = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SHOWEDITQUOTEBANNER));

    if (authorityType === AUTHORITY_TYPE.P2P) {
        return showEditQuoteBanner;
    }

    return false;
};

export const removeHyphens = phone => {
    return phone?.split('-').join('');
};

export const extractCatAndClass = sku => {
    const catId = ('000' + sku.slice(0, 3)).slice(-3);
    const classId = ('0000' + sku.slice(3, 7)).slice(-4);

    return {
        catId,
        classId
    };
};
export const createFullAddress = (item, showZip = false) => {
    const companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
    const country =
        companyID === 2
            ? intl.formatMessage({ id: 'location-overlay:canada' })
            : intl.formatMessage({ id: 'common:country-usa' });

    if (item?.projectAddress1) {
        return `${item?.projectAddress1}, ${item?.selectedProjectCity}, ${item?.selectedProjectState}, ${
            showZip ? item?.selectedProjectZip : country
        }`;
    }
    return '';
};

export const formatCatClass = id => `${id?.slice(0, 3)}-${id?.slice(3)}`;

/**
 * Formats a date string based on the specified format type.
 *
 * @param {string} inputDateString - The input date string to be formatted (e.g., "20230103").
 * @param {string} formatType - The format type to determine how the date should be formatted.
 *   - 'YYYY-MM-DD': Format as "2023-01-03"
 *   - 'MMM D, YYYY': Format as "Jan 3, 2023".
 * @returns {string} - The formatted date string.
 */
export const formatDateDisplay = (inputDateString, formatType) => {
    //inputDateString = "20230103"
    //formatType dateTime/ shortString
    if (!inputDateString) return '-';

    const momentObj = moment(inputDateString, 'YYYYMMDD');
    return momentObj.format(formatType);
};
export const isSBREmployee = recordType => {
    if (recordType && String(recordType).toUpperCase() === QUOTE_DETAIL_RECORD_TYPE_PUNCHOUT) {
        return false;
    }
    return true;
};
/**
 * Formats a string representation of a numeric phone number in the US format.
 * @param {string} number - The string representation of the numeric phone number to be formatted.
 * @returns {string} The formatted phone number.
 */
export const formatPhoneNumberToUSA = number => {
    // Convert the input to a string
    const numberString = String(number);

    // Validate that the input is a string and consists only of numeric characters
    if (!/^\d+$/.test(numberString)) {
        return numberString;
    }
    const formattedNumber = numberString.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    return formattedNumber;
};

export const convertDateToMomentNonUTCDateTime = dateToBeformatted => {
    let dates = new Date(dateToBeformatted);
    let dateTimeFormatted = moment(dates).format('YYYY-MM-DDTHH:mm:ss[Z]');
    return dateTimeFormatted;
};

export const isAccountNotActive = status => {
    const statusLowerCase = status?.toLowerCase();
    return statusLowerCase !== 'a';
};

export const sentenceCase = str => {
    if (isValidString(str)) {
        return String(str)
            .toLowerCase()
            .split(' ')
            .map(item => {
                return item[0]?.toUpperCase() + item?.slice(1);
            })
            .join(' ');
    }
};

export const isPasswordContainsEmail = (email, password) => {
    const partLength = 4;
    try {
        let emailParts = email.split(/[.\-,_#@]/);
        emailParts = emailParts.filter(part => part.length >= partLength);
        for (var i = 0; i < emailParts.length; i++) {
            let isMatch = password.includes(emailParts[i]);
            if (isMatch) {
                return true;
            }
        }
    } catch (error) {
        logError(error, false, 'isPasswordContainsEmail', {});
    }
    return false;
};
export const formatDate = inputDateString => {
    if (!inputDateString) {
        return undefined;
    }
    return moment(inputDateString).format('MM/DD/YYYY');
};

export const getUniqueCategories = categories => {
    let uniqueCategories = new Set();
    categories?.forEach(category => {
        category?.split('|')?.forEach(cat => {
            const cleanedCat = cat?.replace(/^"|"$/g, '').trim();
            uniqueCategories?.add(cleanedCat);
        });
    });
    return Array?.from(uniqueCategories);
};

export const getDistance = isInStorePickup => {
    let distance = '';
    const drivingDistance = JSON.parse(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDETAILS_FOR_ROUND_TRIP) || '{}'
    )?.drivingDistanceFromJobsite;
    const browseLocationOBJ = JSON.parse(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ) || '{}'
    )?.distance;
    distance = isInStorePickup ? 0 : drivingDistance || browseLocationOBJ || -1; //-1 indicating distance empty in FE
    return distance?.toString();
};

export const getNoOfItemsOnCurrentPage = pageInfo => {
    const { items_count, current_page, page_size } = pageInfo;
    return items_count ? Math.min(Math.abs((current_page - 1) * page_size - items_count), pageInfo.page_size) : 0;
};
