import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../hooks/useCheckUser';
import Button from '../../global/atoms/button';
import OktaModal from '../../global/modules/oktaModal/OktaModal';
import './capHeaderSignIn.scss';

const CapHeaderSignIn = () => {
    const intl = useIntl();
    const userType = useCheckUser();
    const [isOktaModalOpen, setIsOktaModalOpen] = useState(false);
    const linkText = ENV_CONFIG.CAP_LINK_TEXT || intl.formatMessage({ id: 'cap:sign-in' });

    const signInHandler = () => {
        setIsOktaModalOpen(!isOktaModalOpen);
    };
    const handleCloseOkta = () => {
        setIsOktaModalOpen(!isOktaModalOpen);
    };

    return userType === USER_TYPE.GUEST ? (
        <>
            <Button
                className="button sign-in-btn-cap-home"
                buttonAriaLabel={linkText}
                onClick={signInHandler}
                dataTestid="cap-sign-in">
                <span className="cap-header-aem__person" role="presentation" aria-hidden="true"></span>
                {linkText}
            </Button>
            <OktaModal
                isOpen={isOktaModalOpen}
                handleRequestClose={handleCloseOkta}
                handleCloseOkta={handleCloseOkta}
            />
        </>
    ) : null;
};

export default memo(CapHeaderSignIn);
