import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import CrossIcon from '../../../../resources/images/closecircleblack.svg';
import SearchGreen from '../../../../resources/images/searchgreen.svg';
import Button from '../../atoms/button/button';
import CardRadioButton from '../../atoms/cardRadioButton/cardRadioButton';
import Input from '../../atoms/input/Input';
import { AUTHORITY_TYPE } from '../../constants';
import {
    DEFAULT_ACCOUNT_ROW_HEIGHT,
    DEFAULT_ACCOUNT_ROW_HEIGHT_P2P,
    MIN_ERROR_MSG_LENGTH,
    SEARCH_VIEW_LENGTH
} from '../../utils/commonUtils';
import LongList from '../LongList/LongList';
import NoDataFound from '../noDataFound';
import './ChooseAccountDrawer.scss';
import { chooseAccountDrawerDataLocators } from './dataLocators';

const AccountListView = (props) => {
    const { allAccountsList, accountList, searchQuery, onSearchInputChange, onEnterKeyPressed, onResetClick, onSearchIconClick, getAccountList, selectedValue, isUserProfileLoading,
        setSelectedValue, cardStyle, updateAccountDetailsHandler, handleTextInactive, } = props;
    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState('');
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const accountListClass = isP2PUser ? (allAccountsList?.length >= SEARCH_VIEW_LENGTH ? 'accounts-list-section-with-search' : 'accounts-list-section-without-search') : '';
    const notFoundErrorMessage = intl.formatMessage({ id: 'no-account-found' });

    useEffect(() => {
        if (accountList?.length === 0 && searchQuery?.length > MIN_ERROR_MSG_LENGTH) {
            setErrorMessage(notFoundErrorMessage);
        } else {
            setErrorMessage('');
        }
    }, [accountList?.length, searchQuery]);

    const displaySearchResults = data => {
        return (
            data?.name && (
                <div key={data?.name} className="card-radio-button-wrapper">
                    <CardRadioButton
                        item={data}
                        cardStyle={cardStyle}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        onPressRadioButton={updateAccountDetailsHandler}
                        isAccount={true}
                        radioButtonAriaLabel={`${data?.name} ${intl.formatMessage({
                            id: 'account:ac-minicart-account-number-text'
                        })}${data?.account} ${handleTextInactive(data?.accountStatus)}`}
                    />
                </div>
            )
        );
    };

    return (
        <>
            {allAccountsList?.length >= SEARCH_VIEW_LENGTH ?
                <div className="search-account">
                    <Input
                        type="text"
                        name="account_search"
                        placeholder={intl.formatMessage({ id: 'search-account' })}
                        value={searchQuery}
                        dataTestId={chooseAccountDrawerDataLocators.choose_account_search_input}
                        handleInputChange={onSearchInputChange}
                        handleKeyDown={onEnterKeyPressed}
                        inputAriaLabel={`${intl.formatMessage({
                            id: 'account:ac-common-search-text'
                        })} ${intl.formatMessage({ id: 'search-account' })}`}
                    />
                    {searchQuery && (
                        <Button
                            tabIndex={0}
                            className="crossIcon"
                            onClick={onResetClick}
                            dataTestid={chooseAccountDrawerDataLocators.choose_account_cross_icon}
                            customButtonAriaLabel={intl.formatMessage({
                                id: 'pdp:search-bar-cross-icon'
                            })}>
                            <CrossIcon aria-hidden={true} tabIndex={'-1'} />
                        </Button>
                    )}
                    <Button
                        tabIndex={0}
                        className="searchIconClass input-group-append"
                        onClick={onSearchIconClick}
                        customButtonAriaLabel={intl.formatMessage({
                            id: 'search-bar-search-icon'
                        })}>
                        <SearchGreen tabIndex={'-1'} aria-hidden={'true'} />
                    </Button>
                </div>
                :
                <hr />
            }

            {(accountList?.length > 0 || searchQuery) && (
                <section className={`accounts-list-section ${accountListClass}`}>
                    {errorMessage ? (
                        <p className="no-account">{errorMessage}</p>
                    ) : (
                        <LongList
                            longList={getAccountList()}
                            rowRendererContent={displaySearchResults}
                            defaultHeight={
                                isP2PUser
                                    ? DEFAULT_ACCOUNT_ROW_HEIGHT_P2P
                                    : DEFAULT_ACCOUNT_ROW_HEIGHT
                            }
                            selectedValue={selectedValue}
                            isLongListLoading={isUserProfileLoading}
                        />
                    )}
                </section>
            )}

            {allAccountsList?.length === 0 &&
                <div className='no-results-found-container'>
                    <NoDataFound
                        heading={intl.formatMessage({ id: 'account-empty-list-heading' })}
                        subheading={intl.formatMessage({ id: 'account-empty-list-subheading' })}
                        noSearchResultsFoundIcon
                        ctaVisible={false}
                        className='favorite_no_result'
                    />
                </div>
            }
        </>
    )
};

export default React.memo(AccountListView);
