import React, { memo, useCallback, useEffect, useRef } from 'react';
import { func, object, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { MEDIA_TYPE } from '../../../../../constants/screenConstants';
import useMedia from '../../../../../hooks/useMedia';
import GreenArrowRight from '../../../../../resources/images/arrow-green-right-cap.svg';
import Button from '../../../atoms/button/button';
import { jobsiteModalOpenerLocator } from './dataLocators';
import './jobsitemodalopener.scss';

const JobsiteModalOpener = props => {
    const componentRef = useRef(null);
    const {
        value,
        autoCompleteWrapperClass,
        autoCompleteInputWrapper,
        labelContent,
        onOutsideClickOrFocus,
        inputId,
        jobAndAddressState,
        handleDrawer
    } = props;

    const intl = useIntl();
    const mediaType = useMedia();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const clickOutside = useCallback(
        e => {
            if (componentRef?.current && !componentRef.current?.contains(e.target)) {
                onOutsideClickOrFocus();
            }
        },
        [componentRef, onOutsideClickOrFocus]
    );

    const clickOutsideRef = useRef(clickOutside);
    clickOutsideRef.current = clickOutside;

    useEffect(() => {
        const handleMouseDown = e => clickOutsideRef.current(e);
        const handleFocusIn = e => clickOutsideRef.current(e);

        document.addEventListener('mousedown', handleMouseDown);
        if (mediaType === MEDIA_TYPE.DESKTOP) {
            document.addEventListener('focusin', handleFocusIn);
        }

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            if (mediaType === MEDIA_TYPE.DESKTOP) {
                document.removeEventListener('focusin', handleFocusIn);
            }
        };
    }, [value]);

    return (
        <div className={autoCompleteWrapperClass} ref={componentRef}>
            <div className={`autocomplete__input ${autoCompleteInputWrapper}`}>
                <div className={`autocomplete__input-wrap`}>
                    <Button
                        id={inputId}
                        type="button"
                        value={jobAndAddressState?.chosenJobName}
                        className="autocomplete-button"
                        onClick={handleDrawer}
                        data-testid={jobsiteModalOpenerLocator.searchBar_buttonField}
                        aria-required={true}
                        title={intl.formatMessage({ id: 'location:search-title' })}>
                        <div className={`button-text`}>
                            <span className="project-name">{jobAndAddressState?.chosenJobName}</span>{' '}
                            {jobAndAddressState?.chosenAddress}{' '}
                        </div>
                        {!jobAndAddressState?.chosenJobName && (
                            <GreenArrowRight className="green-arrow-right" tabIndex={'-1'} aria-hidden={true} />
                        )}
                    </Button>
                    {labelContent()}
                </div>
            </div>
        </div>
    );
};

JobsiteModalOpener.propTypes = {
    autoCompleteWrapperClass: string,
    autoCompleteInputWrapper: string,
    labelContent: func,
    onOutsideClickOrFocus: func,
    inputId: string,
    handleDrawer: func,
    jobAndAddressState: object,
    value: string
};

JobsiteModalOpener.defaultProps = {
    autoCompleteWrapperClass: '',
    autoCompleteInputWrapper: '',
    labelContent: () => {},
    onOutsideClickOrFocus: () => {},
    inputId: '',
    handleDrawer: () => {}
};
export default memo(JobsiteModalOpener);
