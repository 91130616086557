import { func, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import BaseProductTile from '../BaseProductTile';
import DateSelector from '../atoms/dateSelector/DateSelector';

const ChangeDatesGrt72Hrs = ({
    tileDetails,
    dailyProductRate,
    accountNumber,
    handleAnchorClick,
    handleRedirectClick,
    showAvailableDates,
    footerTileClassName,
    footerTileRef,
    ...restProps
}) => {
    const { availableDate, selectDates } = showAvailableDates || {};
    const footerContent = () => {
        return <DateSelector availabilityDate={availableDate} selectDates={selectDates} productName={tileDetails?.producttitle}></DateSelector>;
    };
    return (
        <BaseProductTile
            footerTileClassName={footerTileClassName}
            footerTileRef={footerTileRef}
            productUrl={tileDetails?.producturl}
            productImg={tileDetails?.productimageurl}
            catClass={tileDetails?.catclass}
            productName={tileDetails?.producttitle}
            dailyProductRate={dailyProductRate}
            accountNumber={accountNumber}
            footerContent={footerContent}
            handleAnchorClick={handleAnchorClick}
            handleRedirectClick={handleRedirectClick}
            {...restProps}></BaseProductTile>
    );
};

export default React.memo(ChangeDatesGrt72Hrs);

ChangeDatesGrt72Hrs.defaultProps = {
    tileDetails: {
        catclass: '',
        producttitle: '',
        productimageurl: '',
        producturl: '#',
        showonlinecatalog: 'YES',
        disableaddtocartoption: 'NO',
        categoryname: '',
        catsku: '',
        catid: '',
        metaTitle: '',
        metaDescription: ''
    },
    dailyProductRate: '',
    handleAnchorClick: () => {},
    handleRedirectClick: () => {},
    footerTileClassName: '',
    footerTileRef: () => {}
};

ChangeDatesGrt72Hrs.propTypes = {
    tileDetails: shape({
        catclass: oneOfType([number, string]),
        producttitle: string,
        productimageurl: string,
        producturl: string,
        showonlinecatalog: string,
        disableaddtocartoption: string,
        categoryname: string,
        catsku: oneOfType([number, string]),
        catid: oneOfType([number, string]),
        metaDescription: string,
        metaTitle: string
    }),
    dailyProductRate: string,
    handleAnchorClick: func,
    handleRedirectClick: func,
    footerTileClassName: string,
    footerTileRef: func
};
