import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import CloseIcon from '../../../../resources/images/close.svg';
import './animatedHeader.scss';
import Button from '../../atoms/button/button';

const AnimatedHeaderModal = props => {
    const {
        isOpen,
        renderHeader,
        renderBody,
        renderHeaderClass,
        renderBodyClass,
        setIsOpen,
        showHeader,
        showCloseIcon,
        renderModalClass
    } = props;
    const intl = useIntl();
    const [isSticky, setIsSticky] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const AnimatedHeaderModalRef = useRef(null);

    useEffect(() => {
        Modal.setAppElement('.root');
    }, []);

    useEffect(() => {
        if (modalOpen) {
            AnimatedHeaderModalRef.current.addEventListener('scroll', handleIsSticky);
        }
        return () => {
            if (AnimatedHeaderModalRef?.current) {
                AnimatedHeaderModalRef.current.removeEventListener('scroll', handleIsSticky);
            }
        };
    }, [modalOpen]);

    const handleIsSticky = e => {
        const scrollTop = AnimatedHeaderModalRef.current.scrollTop;
        scrollTop >= 10 ? setIsSticky(true) : setIsSticky(false);
    };

    function afterOpenModal() {
        setModalOpen(!modalOpen);
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={setIsOpen}
                className={'animatedheadermodal__modal'}>
                <div className={`animatedheadermodal__wrap ${renderModalClass}`} ref={AnimatedHeaderModalRef}>
                    {showHeader && (
                        <div
                            className={`animatedheadermodal__header ${renderHeaderClass} ${
                                isSticky ? 'animatedheadermodal__header-fixed' : ''
                            }`}>
                            {showCloseIcon && (
                                <Button
                                    className="animatedheadermodal__close"
                                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                                    onClick={setIsOpen}
                                    dataTestid='animated-header-modal-close'
                                    tabIndex={0}>
                                    <CloseIcon aria-hidden="true" tabIndex={'-1'} />
                                </Button>
                            )}
                            {renderHeader}
                        </div>
                    )}

                    <div className={`animatedheadermodal__body ${renderBodyClass}`}>{renderBody}</div>
                </div>
            </Modal>
        </>
    );
};

AnimatedHeaderModal.defaultProps = {
    renderHeaderClass: '',
    renderBodyClass: '',
    isOpen: false,
    setIsOpen: () => {},
    showCloseIcon: true
};

AnimatedHeaderModal.propTypes = {
    renderHeaderClass: string,
    renderBodyClass: string,
    isOpen: bool,
    setIsOpen: func,
    showCloseIcon: bool
};

export default AnimatedHeaderModal;
