import React from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../../../components/global/atoms/button/button';
import { cartDataLocators } from '../dataLocators';

const ViewCartFooterDotCom = props => {
    const { handleCheckoutBtnClickModal } = props;
    const intl = useIntl();

    return (
        <Button
            onClick={handleCheckoutBtnClickModal}
            type="button"
            className={'button button-primary button-block'}
            buttonAriaLabel={intl.formatMessage({ id: 'cart-footer:checkout' })}
            data-testid={cartDataLocators.minicart_checkout_CTA}>
            {intl.formatMessage({ id: 'cart-footer:checkout' })}
        </Button>
    );
};

export default React.memo(ViewCartFooterDotCom);
