export const locationOverlayDataLocators = {
    locationoverlay_whatsyourlocation_heading_testid: 'location_whatsyourlocation_heading',
    locationoverlay_cross_icon_testid: 'location_crossicon',
    locationoverlay_whatsyourlocation_subheading_testid: 'location_accuratepricing_label',
    locationoverlay_location_label_testid: 'location_label',
    locationoverlay_addressinput_field_testid: 'location_zippostaladdress_txtfield',
    locationoverlay_setlocation_cta_testid: 'location_setlocation_CTA',
    locationoverlay_toggleexisting_cta_testid: 'location_exitingProject_CTA',
    locationoverlay_togglenew_cta_testid: 'location_enterAddress_CTA',
    locationoverlay_account_heading_testid: 'location_account_Heading',
    locationoverlay_chooseproject_heading_testid: 'location_chooseAProject_heading',
    locationoverlay_project_title_testid: 'location_ProjectName_Title',
    locationoverlay_project_address_testid: 'location_ProjectName_Address',
    locationoverlay_project_searchbar_testid: 'location_searchBar_Porject',
    locationoverlay_accounts_heading_testid: 'location_AccountHeading',
    locationoverlay_accounts_chooseaccountheading_testid: 'location_chooseAnAccountHeading',
    locationoverlay_accounts_name_testid: 'location_accountName_Title',
    locationoverlay_accounts_number_testid: 'location_accountNumber_label',
    locationoverlay_accounts_switchaccCTA_testid: 'location_switchAccount_CTA',
    locationoverlay_enterlocation_searchlabel_testid: 'location_searchByZipAndAddress_label',
    locationoverlay_enterlocation_locationlabel_testid: 'location_location_Heading',
    locationoverlay_setyourlocation_label_testid: 'location_getYourAccuratePrice_Label',
    locationoverlay_us_toggle_label_testid: 'locationoverlay_us_toggle_label_testid',
    locationoverlay_ca_toggle_label_testid: 'locationoverlay_ca_toggle_label_testid',
    locationoverlay_guest_signin_test_id: 'locationoverlay_guest_signin_test_id'
};
