import React, { memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { bool, func, string } from 'prop-types';
import { useFilterState } from '../filterContext';
import { useCapUtils } from '../hooks/useCapUtils';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { usePageType } from '../../../hooks/usePageType';
import RadioButton from '../../global/atoms/radioButton';
import GlobalModal from '../../global/atoms/globalModal/GlobalModal';
import Button from '../../global/atoms/button';
import FulfillmentTypeSelector from '../fulfillmentTypeSelector/FulfillmentTypeSelector';
import AlertCircle from '../../../resources/images/alert-circle-black.svg';
import CarIcon from '../../../resources/images/car.svg';
import './fulfillmentStatus.scss';
import { capDataLocator } from '../dataLocator';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { EDIT_VIEW_OPEN, FULFILLMENT_TYPE } from '../constants';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';

const FulfillmentStatus = ({
    className,
    fulfillmentValue,
    setFulfillmentValue,
    showRadioOptions,
    legend,
    formInteractionStartedEventAnalytics,
    sendDeliveryInteractedEvent
}) => {
    const inputRef = useRef(null);
    const [, dispatch] = useFilterState();
    const intl = useIntl();
    const delivery_text = ENV_CONFIG.DELIVERY_TEXT || intl.formatMessage({ id: 'cap:dropdown-delivery' });
    const pickup_text = ENV_CONFIG.PICKUP_TEXT || intl.formatMessage({ id: 'cap:dropdown-pickup' });
    const { getCapUserDetails } = useCapUtils();
    const userType = useCheckUser();
    const [showPickupWarning, setShowPickupWarning] = useState(false);
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

    const pageType = usePageType();
    const isHomePage = pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE;
    const deliveryMethod = EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_DELIVERY_METHOD;

    const inputOptions = [
        {
            label: delivery_text,
            value: FULFILLMENT_TYPE.DELIVERY
        },
        {
            label: pickup_text,
            value: FULFILLMENT_TYPE.PICKUP
        }
    ];

    const fulfillmentOptions = [
        {
            value: delivery_text,
            label: FULFILLMENT_TYPE.ROUNDTRIPDELIVERY
        },
        {
            value: pickup_text,
            label: FULFILLMENT_TYPE.INSTOREPICKUP
        }
    ];

    const onChangeWarningHandler = () => {
        setShowPickupWarning(false);
    };

    const onChangeHandler = value => {
        if (userType === USER_TYPE.CREDIT) {
            if (overridePC?.pc && value === FULFILLMENT_TYPE.PICKUP) {
                setShowPickupWarning(true);
                return;
            }
        }
        setFulfillmentValue(value);
        if (isHomePage) {
            dispatch({ type: EDIT_VIEW_OPEN });
        }
        formInteractionStartedEventAnalytics(deliveryMethod);
        sendDeliveryInteractedEvent(deliveryMethod, value);
    };
    const onRadioChangeHandler = event => {
        onChangeHandler(event?.target?.value);
    };

    const onClick = () => {
        inputRef?.current?.classList?.add('viewMode-fulfillment-type--focus');
        dispatch({ type: EDIT_VIEW_OPEN });
    };

    const onSelectWrapperBlur = () => {
        inputRef?.current?.classList?.remove('viewMode-fulfillment-type--focus');
    };
    const title = () => {
        return (
            <div className="alert-title">
                <AlertCircle className="alertCircle__icon" />
                {intl.formatMessage({ id: 'fulfillment-alert-title' })}
            </div>
        );
    };
    const footer = () => {
        return (
            <Button
                type="button"
                className="button button-primary button-block"
                onClick={onChangeWarningHandler}
                buttonAriaLabel={intl.formatMessage({ id: 'continue-with-delivery' })}
                dataTestid={`${capDataLocator.continue_with_delivery}`}>
                {intl.formatMessage({ id: 'continue-with-delivery' })}
            </Button>
        );
    };

    return (
        <>
            {showPickupWarning && (
                <GlobalModal
                    isOpen
                    title={title()}
                    handleClose={onChangeWarningHandler}
                    footer={footer()}
                    content={intl.formatMessage({ id: 'fulfillment-alert-content' })}
                    className="fulfillmentType__alert"
                    footerClasses="fulfillmentType__alert__footer"
                />
            )}
            {showRadioOptions ? (
                <div className={`${className} radio-group`}>
                    {inputOptions?.map(opt => (
                        <RadioButton
                            id={opt?.value}
                            key={opt?.value}
                            value={opt?.value}
                            checked={opt?.value === fulfillmentValue}
                            label={opt?.label}
                            handleRadioChange={onRadioChangeHandler}
                            dataTestId={`${capDataLocator.cap_fulfillment_type_btn}_${opt.label}`}
                        />
                    ))}
                </div>
            ) : (
                <div className={`viewMode-fulfillment-type`} ref={inputRef} onBlur={onSelectWrapperBlur}>
                    <div className="viewMode-fulfillment-type__icon">
                        <CarIcon className="car-icon" />
                        {getCapUserDetails()?.fulfillmentText || intl.formatMessage({ id: 'cap:fulfillment-type' })}
                    </div>
                    <FulfillmentTypeSelector
                        dropDownValues={fulfillmentOptions}
                        selectedValue={fulfillmentValue}
                        selectAriaLabel={fulfillmentValue}
                        handleChange={onChangeHandler}
                        handleClick={onClick}
                        dataTestId={'drop_down_list'}
                    />
                </div>
            )}
        </>
    );
};

FulfillmentStatus.defaultProps = {
    className: '',
    fulfillmentValue: '',
    setFulfillmentValue: () => {},
    showRadioOptions: true,
    legend: '',
    formInteractionStartedEventAnalytics: () => {},
    sendDeliveryInteractedEvent: () => {}
};

FulfillmentStatus.propTypes = {
    className: string,
    fulfillmentValue: string,
    setFulfillmentValue: func.isRequired,
    showRadioOptions: bool,
    legend: string,
    formInteractionStartedEventAnalytics: func,
    sendDeliveryInteractedEvent: func
};

export default memo(FulfillmentStatus);
