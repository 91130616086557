import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import CartCounter from './cartCounter';
import CartIcon from '../../../resources/images/carticon.svg';
import CartIconFill from '../../../resources/images/carticonfill.svg';
import classes from './cartTrigger.css';
import { useCartState } from '../Minicart/cartContext';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { logError } from '../../../components/global/utils/logger';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import useAnalytics from '../../../hooks/useAnalytics';
import { EVENT_PAGE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Page';
import { useUserContext } from '../../context/UserContext';

const Trigger = () => {
    const { sendEventsForEcommercePage, sendEventsForPageUser } = useAnalyticsContext();
    const [{ userInfo }, dispatch] = useCartState();
    const [{ isUserProfileLoading }] = useUserContext();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    const { miniCart = '' } = document.querySelector('.pdp__select__location')?.dataset || {};
    let accIcon = document.querySelector('.dropdown-account-icon') || '';
    let cartQuantity = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) || 0;

    useEffect(() => {
        if (!isUserProfileLoading && userInfo?.isAuthenticatedUser && accIcon) {
            accIcon.style.background =
                "url('/content/dam/sunbeltrentals/en/media/icons/person-green.svg')  no-repeat center center";
            accIcon.style.borderRadius = '50%';
            accIcon.style.backgroundColor = '#F2FCF5';
        }
    }, [userInfo, isUserProfileLoading]);

    const sendEcommerceEvents = () => {
        try {
            sendEventsForEcommercePage(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                localStorage.getItem('companyID') == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA
            );
            sendEventsForPageUser(
                EVENT_PAGE_NAMES_CONFIG.PAGE_USER,
                payloadEcommerceLocationActionAnalytics()
            );
        } catch (error) {
            logError(error, false, 'sendEcommerceEvents');
        }
    };

    const handleCartIconClick = () => {
        dispatch({ type: 'open' });
        try {
            sendEcommerceEvents();
        } catch (error) {
            logError(error, false, 'handleCartIconClick');
        }
    };

    return (
        <button
            className={classes.root}
            data-testid={'cart-counter'}
            aria-label={`${cartQuantity} ${cartQuantity == 1 ? 'item' : 'items'} present in the cart`}
            onClick={handleCartIconClick}>
            {miniCart ? <img src={miniCart} alt="cart-icon"></img> : cartQuantity > 0 ? <CartIconFill /> : <CartIcon />}
            <CartCounter counter={cartQuantity} />
        </button>
    );
};

Trigger.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.shape({
        root: PropTypes.string
    })
};

export default Trigger;
