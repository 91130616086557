import {
    GET_DEDICATED_PC,
    SET_IS_USER_SIGNING_IN,
    SET_SHOW_SIGNIN_MODAL,
    SET_SHOW_CHOOSE_ACCOUNT_DRAWER,
    SET_SHOW_JOB_SITE_DRAWER,
    SET_CAP_ACCOUNT_SELECTED,
    SET_IS_JOBSITE_LOADED,
    SET_ACCOUNT_FAVORITE_LIST_DATA,
    SET_JOBSITE_FAVORITE_LIST_DATA,
    SET_ALL_ACCOUNT_PROJECTS,
    SET_IS_FAVORITES_LOADING
} from '../../aem-core-components/actions/constants';
import parseError from '../../aem-core-components/utils/parseError';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
export const userAction = resetUser => {
    return (state, action) => {
        switch (action.type) {
            case 'setUserDetails':
                return {
                    ...state,
                    inProgress: false,
                    currentUser: {
                        ...action.userDetails
                    }
                };
            case 'setCartId':
                return {
                    ...state,
                    cartId: action.cartId
                };
            case 'setInProgress': {
                return {
                    ...state,
                    inProgress: true
                };
            }
            case 'setToken':
                return {
                    ...state,
                    isSignedIn: true,
                    inProgress: false,
                    token: action.token,
                    signInError: null
                };
            case 'setAddressFormError':
                return {
                    ...state,
                    addressFormError: parseError(action.error)
                };
            case 'clearAddressFormError':
                return {
                    ...state,
                    addressFormError: null
                };
            case 'postCreateAddress':
                return {
                    ...state,
                    currentUser: {
                        ...state.currentUser,
                        addresses: [
                            ...[...state.currentUser.addresses].map(address => {
                                if (action.resetFields) {
                                    Object.entries(action.resetFields).forEach(([key, value]) => {
                                        address[key] = value;
                                    });
                                }
                                return address;
                            }),
                            action.address
                        ]
                    },
                    addressFormError: null,
                    isShowAddressForm: false
                };
            case 'beginEditingAddress':
                return {
                    ...state,
                    updateAddress: action.address,
                    isShowAddressForm: true
                };
            case 'endEditingAddress':
                return {
                    ...state,
                    updateAddress: null
                };
            case 'postUpdateAddress':
                return {
                    ...state,
                    currentUser: {
                        ...state.currentUser,
                        addresses: [...state.currentUser.addresses].map(address => {
                            if (action.resetFields) {
                                Object.entries(action.resetFields).forEach(([key, value]) => {
                                    address[key] = value;
                                });
                            }
                            return address.id === action.address.id ? action.address : address;
                        })
                    },
                    updateAddress: null,
                    addressFormError: null,
                    isShowAddressForm: false
                };
            case 'beginDeletingAddress':
                return {
                    ...state,
                    deleteAddress: action.address
                };
            case 'endDeletingAddress':
                return {
                    ...state,
                    deleteAddress: null
                };
            case 'postDeleteAddress':
                return {
                    ...state,
                    currentUser: {
                        ...state.currentUser,
                        addresses: [...state.currentUser.addresses].filter(address => address.id !== action.address.id)
                    },
                    deleteAddress: null,
                    deleteAddressError: null
                };
            case 'deleteAddressError':
                return {
                    ...state,
                    deleteAddressError: parseError(action.error)
                };
            case 'postCreateAccount':
                return {
                    ...state,
                    isSignedIn: false,
                    inProgress: false,
                    createAccountError: null,
                    createAccountEmail: action.accountEmail
                };
            case 'cleanupAccountCreated':
                return {
                    ...state,
                    createAccountEmail: null
                };
            case 'error': {
                return {
                    ...state,
                    inProgress: false,
                    signInError: action.error
                };
            }
            case 'createAccountError': {
                return {
                    ...state,
                    inProgress: false,
                    createAccountError: parseError(action.error)
                };
            }
            case 'signOut':
                return {
                    ...state,
                    isSignedIn: false,
                    inProgress: false,
                    token: '',
                    currentUser: {
                        firstname: '',
                        lastname: '',
                        email: '',
                        addresses: []
                    },
                    cartId: '',
                    accountDropdownView: null
                };
            case 'toggleAccountDropdown':
                return {
                    ...state,
                    isAccountDropdownOpen: action.toggle
                };
            case 'changeAccountDropdownView':
                return {
                    ...state,
                    accountDropdownView: action.view
                };
            case 'openAddressForm':
                return {
                    ...state,
                    isShowAddressForm: true
                };
            case 'closeAddressForm':
                return {
                    ...state,
                    isShowAddressForm: false
                };
            case 'setUserProfile':
                return {
                    ...state,
                    userProfile: action.userProfile
                };
            case 'setAccountProjects':
                return {
                    ...state,
                    accountProjects: action.accountProjects
                };
            case 'setUserType':
                return {
                    ...state,
                    userType: action.value
                };
            case 'setIsCheckoutLoading':
                return {
                    ...state,
                    isCheckoutLoading: action.value
                };
            case 'setIsUserProfileLoading':
                return {
                    ...state,
                    isUserProfileLoading: action.value
                };
            case 'wishlist':
                return {
                    ...state,
                    wishlist: action?.wishListData
                };
            case 'freqRentals':
                return {
                    ...state,
                    freqRentals: action?.freqRentalsData
                };
            case GET_DEDICATED_PC:
                return {
                    ...state,
                    overridePCList: action?.overridePCList
                };
            case 'resetUser':
                resetUser();
                return {
                    ...state
                };
            case 'showInactivityNotification':
                return {
                    ...state,
                    showInactivityNotification: action.value
                };
            case 'setIsOverRidePCLoading':
                return {
                    ...state,
                    isOverRidePCLoading: action.value
                };
            case 'beginProjectsLoading':
                return {
                    ...state,
                    isProjectsLoading: true
                };
            case 'endProjectsLoading':
                return {
                    ...state,
                    isProjectsLoading: false
                };
            case SET_IS_JOBSITE_LOADED:
                return {
                    ...state,
                    isJobSiteLoaded: action.payload
                };
            case 'setHasAccountChanged':
                return {
                    ...state,
                    hasAccountChanged: action.value
                };
            case 'updateSkipQtyObject':
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        skip_qty: { ...action?.skipQtyObj }
                    }
                };
            case 'setIsProfileLoaded':
                return {
                    ...state,
                    isProfileLoaded: action.value
                };
            case SET_IS_USER_SIGNING_IN:
                return {
                    ...state,
                    isUserSigningIn: action?.isUserSigningIn
                };
            case SET_SHOW_SIGNIN_MODAL:
                return {
                    ...state,
                    showSignInModal: action?.showSignInModal
                };
            case SET_SHOW_CHOOSE_ACCOUNT_DRAWER:
                return {
                    ...state,
                    showChooseAccountDrawer: action.payload
                };
            case SET_SHOW_JOB_SITE_DRAWER:
                return {
                    ...state,
                    showJobSiteDrawer: action.payload
                };
            case SET_CAP_ACCOUNT_SELECTED:
                return {
                    ...state,
                    isCapAccountSelected: action.payload
                };
            case SET_ACCOUNT_FAVORITE_LIST_DATA:
                return {
                    ...state,
                    favoriteAccountListData: action.payload
                }
            case SET_ALL_ACCOUNT_PROJECTS:
                return {
                    ...state,
                    allAccountProjects: action.allAccountProjects
                };
            case SET_JOBSITE_FAVORITE_LIST_DATA:
                return {
                    ...state,
                    favoriteJobsiteListData: action.payload
                }
            case SET_IS_FAVORITES_LOADING:
                return {
                    ...state,
                    isFavoritesLoading: action.payload
                };
            default:
                return state;
        }
    };
};
