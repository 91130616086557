import React from 'react';
import { bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { Button } from '../../../../../aem-core-components';
import { checkoutOrderSummaryDataLocators, orderSummaryDataLocators } from '../../dataLocators';
import { FACTORYSOURCE } from '../../constants/orderSummaryFactoryConstants';

const TBDDeliveryView = ({ source, showDeliveryEstimate, handleOpenTBDOverlay, handleRoundTripAndEstimateFlow }) => {
    const intl = useIntl();
    const renderTBDDeliveryView = () => {
        return (
            <b data-testid={orderSummaryDataLocators.orderSummary_tbd_cta}>
                {intl.formatMessage({ id: 'checkout:tbd-acronym' })}
            </b>
        );
    };

    return renderTBDDeliveryView();
};

export default React.memo(TBDDeliveryView);

TBDDeliveryView.propTypes = {
    source: string,
    showDeliveryEstimate: bool,
    handleOpenTBDOverlay: func,
    handleRoundTripAndEstimateFlow: func
};
TBDDeliveryView.defaultProps = {
    source: '',
    showDeliveryEstimate: false,
    handleOpenTBDOverlay: () => {},
    handleRoundTripAndEstimateFlow: () => {}
};
