import React from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../components/global/atoms/button';
import { useCartState } from './cartContext';
import { miniCartDatalocator } from './dataLocators';
import ShoppingCart from '../../../resources/images/shopping-cart-minicart.svg';
import classes from './emptyMiniCartBody.css';
import config from '../../../components/App/config';

const EmptyMinicartBody = () => {
    const [, dispatch] = useCartState();
    const intl = useIntl();

    const onExploreRentalsHandler = () => {
        if (window.location.pathname !== config.pagePaths.homePage) {
            window.location.href = config.pagePaths.homePage;
        } else {
            dispatch({ type: 'close' });
            document.body.classList.remove('overflow-hidden');
        }
    };

    return (
        <div className={classes.root} data-testid="empty-minicart">
            <div className={classes.emptyCartWrapper}>
                <div className={classes.emptyCartContentContainer}>
                    <ShoppingCart />
                    <h5 className={classes.emptyCartHeading} data-testid={miniCartDatalocator.minicart_cartEmpty_lbl}>
                        {intl.formatMessage({ id: 'cart-body-empty-msg' })}
                    </h5>
                    <span
                        className={classes.emptyCartSubtitle}
                        data-testid={miniCartDatalocator.minicart_exploreVarietyTools_lbl}>
                        {intl.formatMessage({ id: 'cart-body-empty-subtitle' })}
                    </span>
                </div>
            </div>
            <div className={classes.exploreRentalCTA}>
                <Button
                    type="button"
                    onClick={onExploreRentalsHandler}
                    buttonAriaLabel={intl.formatMessage({ id: 'cart-explore-rental-cta' })}
                    className="button button-primary button-block"
                    data-testid={miniCartDatalocator.minicart_exploreRentals_CTA}>
                    {intl.formatMessage({ id: 'cart-explore-rental-cta' })}
                </Button>
            </div>
        </div>
    );
};

export default EmptyMinicartBody;
