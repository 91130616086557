export const ALERT_TRIANGLE = 'triangle';
export const ALERT_SUCCESS = 'checkmarkcircle';
export const ALERT_ERROR = 'error';
export const HOME_PAGE_TITLE = 'Sunbelt Rentals - Equipment & Tool Rental Company';
export const EQUIPMENT_AND_TOOLS_TITLE = 'Equipment and Tools | Sunbelt Rentals';
export const FREE_DELIVERY = 'Y';
export const FREE_PICKUP = 'Y';
export const MEDIA_TYPE = {
    mobile: 'MOBILE',
    desktop: 'DESKTOP'
};
export const LIMIT_TO_SHOW_SEARCH_BAR = 20;
export const SEARCH_INPUT_LIMIT = 3;
export const SUCCESS = 'Success';
export const WARNING = 'Warning';
export const ERROR = 'Error';

export const AUTHORITY_TYPE = {
    DOTCOM: 'DOTCOM',
    P2P: 'P2P',
    PUNCHOUT: 'PUNCHOUT'
};
export const P2P_ASIDE_NAV = [
    { keyword: 'quotes', title: 'Quotes' },
    { keyword: 'purchaseorders', title: 'PO Management' }
];

// Add constants in this array in Lowercase only
export const ADDONS_SYNONYMS_ARRAY = ['merchandise', 'parts'];

export const ENV_CONFIGS = {
    production: {
        muledomain: 'https://api.sunbeltrentals.com',
        clientId: '0oa56ipgl8SAfB1kE5d7',
        clientsecretcode: '6yNzPOIbav3xJ0XMFRI9cCKjEmqcKXiPVPhQS7eo'
    },
    stage: {
        muledomain: 'https://stage-api.sunbeltrentals.com',
        clientId: '0oa6zqnsiyDYBDHSk1d7',
        clientsecretcode: '1sygtwuROKfdrBEiX-yEeypqrATE82e46lPb10n1'
    },
    qa: {
        muledomain: 'https://qa-api.sunbeltrentals.com',
        clientId: '3dfbdf465f24483d8be8e9af7dc5396c',
        clientsecretcode: '9622F5a954Dc4cfeb86993324351fFe6'
    }
};
