import React from 'react';
import TooltipIcon from '../tooltipIcon/TooltipIcon';

const OrderSummaryLineItem = props => {
    const {
        isBoldLabel,
        itemLabel,
        itemLabelDataTestId,
        itemValue,
        itemValueDataTestId,
        isTooltipIcon,
        tooltipIconMessage,
        dispatch
    } = props;

    return (
        <div className="ordersummary-item">
            <span className="ordersummary-item-key" data-testid={itemLabelDataTestId}>
                {' '}
                {isBoldLabel ? <b>{itemLabel}</b> : itemLabel}
                {isTooltipIcon && <TooltipIcon feeTypeName={tooltipIconMessage} dispatch={dispatch} />}
            </span>
            <span className="ordersummary-item-value" data-testid={itemValueDataTestId}>
                <strong>{itemValue}</strong>
            </span>
        </div>
    );
};

OrderSummaryLineItem.propTypes = {};

export default React.memo(OrderSummaryLineItem);
