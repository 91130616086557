import { bool, func, string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { SET_SHOW_DELINQUENT_ACCOUNT_MODAL } from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import ChooseAccount from '../../../../aem-core-components/components/Minicart/chooseAccount';
import LocationOverlay from '../../../../aem-core-components/components/Minicart/locations/Location-Overlay';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { SCREEN_NAME } from '../../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import useMedia from '../../../../hooks/useMedia';
import useAnalytics from '../../../../hooks/useAnalytics';
import Close from '../../../../resources/images/close.svg';
import config from '../../../App/config';
import MobileView from '../../../global/modules/mobileView';
import FixedHeaderFooterModal from '../../../global/modules/modals/fixedHeaderFooter';
import { isAccountBlocked, isAccountClosed } from '../../../global/utils/commonUtils';
import { locationOverlayDataLocators } from './dataLocators';
import Button from '../../../global/atoms/button/button';
const CreditLocationOverlay = props => {
    const {
        showLocationOverlayModal,
        showAccountOverlayModal,
        handleLocationOverlay,
        handleAccountOverlay,
        setIsCreditNewAddress,
        screenName
    } = props;
    const { sendEventsForLocationAction } = useAnalyticsContext();
    const [{ userAccount, isCreditNewAddress }, dispatch] = useCartState();
    const [userState] = useUserContext();
    const { accountProjects, userProfile } = userState;
    const intl = useIntl();
    const mediaType = useMedia();
    const currentURL = window.location.href;
    const userType = useCheckUser();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();

    const handleProjectCloseClick = () => {
        handleLocationOverlay(false);
    };
    const handleBlockedAccount = () => {
        if (
            (isAccountClosed(userAccount) || isAccountBlocked(userAccount)) &&
            window.location.pathname === config.pagePaths.checkoutPage
        ) {
            sessionStorage.setItem('isOpen', true);
            window.location.href = window.location.origin + config.pagePaths.homePage + '?showDelinquentModal=true';
            return true;
        }
        if (isAccountBlocked(userAccount)) {
            dispatch({
                type: SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
                showDelinquentModal: true
            });
            return true;
        }
        return false;
    };
    const handleCloseOverlayClick = () => {
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY, isCreditNewAddress);
        handleBlockedAccount();
        handleAccountOverlay(false);
    };
    const handleCloseIconClick = () => {
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY, isCreditNewAddress);
        handleBlockedAccount();
        handleLocationOverlay(false);
        if (userType === USER_TYPE.CREDIT && currentURL.includes('myaccount')) {
            window.location.reload();
        }
    };

    return (
        <>
            {!showAccountOverlayModal && showLocationOverlayModal ? (
                mediaType === 'MOBILE' ? (
                    <MobileView
                        isOpen={!showAccountOverlayModal && showLocationOverlayModal}
                        showHeader={false}
                        customHeaderWrapClass={'mobile-view-locationoverlay__header'}
                        rootClass={'mobile-view-locationoverlay'}
                        showFooter={true}
                        handleClose={handleCloseOverlayClick}
                        mobileBodyClass={`locationoverlay-creditmobile mobile-view-locationoverlay__body ${
                            accountProjects?.length <= 0 ? 'locationoverlay__bodynodata' : ''
                        }`}>
                        <div className="locationoverlay-credit-headingContainer">
                            <Button
                                data-testid={locationOverlayDataLocators.locationoverlay_cross_icon_testid}
                                className="locationoverlay-credit-close"
                                customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                                onClick={() => handleCloseIconClick(false)}
                                tabIndex={0}>
                                <Close aria-hidden="true" tabIndex={'-1'} />
                            </Button>
                            <h5
                                data-testid={
                                    locationOverlayDataLocators.locationoverlay_whatsyourlocation_heading_testid
                                }
                                className={
                                    screenName === SCREEN_NAME.PDP_SCREEN
                                        ? 'locationoverlay-credit-heading'
                                        : 'locationoverlay-credit-checkoutheading'
                                }
                                tabIndex={0}>
                                {screenName === SCREEN_NAME.PDP_SCREEN
                                    ? intl.formatMessage({ id: 'location-overlay:whats-your-location-heading' })
                                    : intl.formatMessage({ id: 'cart-header:location-label' })}
                            </h5>
                            {screenName === SCREEN_NAME.PDP_SCREEN && (
                                <div
                                    data-testid={
                                        locationOverlayDataLocators.locationoverlay_whatsyourlocation_subheading_testid
                                    }
                                    className="locationoverlay-credit-subheadingproject"
                                    tabIndex={0}>
                                    {intl.formatMessage({ id: 'location-overlay:whats-your-location-content' })}
                                </div>
                            )}
                        </div>
                        <LocationOverlay
                            screenName={screenName}
                            showLocationOverlayModal={showLocationOverlayModal}
                            handleLocationOverlay={handleLocationOverlay}
                            handleAccountOverlay={handleAccountOverlay}
                            setIsCreditNewAddress={setIsCreditNewAddress}
                            handleBlockedAccount={handleBlockedAccount}
                        />
                    </MobileView>
                ) : (
                    <>
                        <FixedHeaderFooterModal
                            screenName={screenName}
                            showLocationOverlayModal={showLocationOverlayModal}
                            handleLocationOverlay={handleLocationOverlay}
                            handleAccountOverlay={handleAccountOverlay}
                            setIsCreditNewAddress={setIsCreditNewAddress}
                            handleBlockedAccount={handleBlockedAccount}
                        />
                    </>
                )
            ) : mediaType === 'MOBILE' ? (
                <MobileView
                    showHeader={false}
                    isOpen={showLocationOverlayModal}
                    rootClass={'mobile-view-accountmobile'}
                    handleClose={() => handleAccountOverlay()}
                    mobileBodyClass={`mobile-view-accountmobile__body ${
                        accountProjects?.length <= 0 ? 'locationoverlay__bodynodata' : ''
                    }`}>
                    <div className="locationoverlay-accountmobile">
                        <ChooseAccount
                            source={VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY}
                            handleAccountOverlay={handleAccountOverlay}
                            screenName={screenName}
                        />
                    </div>
                </MobileView>
            ) : (
                <FixedHeaderFooterModal
                    screenName={screenName}
                    showLocationOverlayModal={showLocationOverlayModal}
                    handleLocationOverlay={handleLocationOverlay}
                    handleAccountOverlay={handleAccountOverlay}
                    setIsCreditNewAddress={setIsCreditNewAddress}
                    showAccountOverlayModal={showAccountOverlayModal}
                />
            )}
        </>
    );
};

export default CreditLocationOverlay;

CreditLocationOverlay.propTypes = {
    showLocationOverlayModal: bool,
    showAccountOverlayModal: bool,
    handleLocationOverlay: func,
    handleAccountOverlay: func,
    setIsCreditNewAddress: func,
    screenName: string
};

CreditLocationOverlay.defaultProps = {
    showLocationOverlayModal: false,
    showAccountOverlayModal: false,
    handleLocationOverlay: () => {},
    handleAccountOverlay: () => {},
    setIsCreditNewAddress: () => {},
    screenName: ''
};
