import { useMutation } from '@apollo/client';
import GET_FAVORITES from '../../../queries/query_favourites.graphql';
import ADD_TO_FAVOURITES from '../../../queries/mutation_addToFavourites.graphql';
import REMOVE_FROM_WISHLIST from '../../../queries/mutation_removeFromFavourites.graphql';
import { addFavouritesFromPdp, getPdpDetails, removeFavouritesFromPdp } from '../../../actions/pdp/actions';
import { logError } from '../../../../components/global/utils/logger';

const useFavourites = () => {
    //const [{ cart }, dispatch] = useCartState();
    const [pdpFavouritesQuery] = useMutation(GET_FAVORITES);
    const [addToFavWishlist] = useMutation(ADD_TO_FAVOURITES);
    const [removeFromFavWishlist] = useMutation(REMOVE_FROM_WISHLIST);
    const getFavourites = async accountNumber => {
        try {
            // dispatch({ type: 'beginLoading' });
            const { wishlist, error } = await getPdpDetails({ pdpFavouritesQuery, accountNumber });
            if (error) {
                logError(error, false, 'getFavourites', [accountNumber]);
            }
            return wishlist;
            // dispatch({ type: 'endLoading' });
        } catch (error) {
            if (error?.response?.status >= 500) {
                logError(error, true, 'getFavourites', [accountNumber]);
            } else {
                logError(error, false, 'getFavourites', [accountNumber]);
            }
            // dispatch({ type: 'error', error: e.toString() });
        }
    };
    const addToFavouritesWishlist = async (accountNumber, sku) => {
        try {
            // dispatch({ type: 'beginLoading' });
            const { favorites, error } = await addFavouritesFromPdp({
                addToFavWishlist,
                accountNumber,
                sku
            });
            if (error) {
                logError(error, false, 'addToFavouritesWishlist', [accountNumber, sku]);
            }
            // dispatch({ type: 'endLoading' });
            return favorites;
        } catch (error) {
            if (error?.response?.status >= 500) {
                logError(error, true, 'addToFavouritesWishlist', [accountNumber, sku]);
            } else {
                logError(error, false, 'addToFavouritesWishlist', [accountNumber, sku]);
            }
            // dispatch({ type: 'error', error: e.toString() });
        }
    };
    const removeFavouritesWishlist = async (accountNumber, favoriteID) => {
        try {
            // dispatch({ type: 'beginLoading' });
            const { data, error } = await removeFavouritesFromPdp({
                removeFromFavWishlist,
                accountNumber,
                favoriteID
            });
            if (error) {
                logError(error, false, 'removeFavouritesFromPdp', [accountNumber, favoriteID]);
            }
            // dispatch({ type: 'endLoading' });
            return { data, error };
        } catch (error) {
            if (error?.response?.status >= 500) {
                logError(error, true, 'removeFavouritesWishlist', [accountNumber, favoriteID]);
            } else {
                logError(error, false, 'removeFavouritesWishlist', [accountNumber, favoriteID]);
            }
            // dispatch({ type: 'error', error: e.toString() });
        }
    };
    const api = { getFavourites, addToFavouritesWishlist, removeFavouritesWishlist };
    return api;
};
export default useFavourites;
