import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import InfoIcon from '../../../../../resources/images/info.svg';
import Button from '../../../../global/atoms/button/button';
import { TOOLTIP_MODAL } from '../../../../../aem-core-components/context/Modal/constants';
import { summaryDetailsDatalocator } from '../../dataLocators';
import { ModalContext } from '../../../../../aem-core-components/context/ModalContext';

const TooltipIcon = ({ feeTypeName, dispatch }) => {
    const intl = useIntl();
    const useModalState = () => useContext(ModalContext);
    const { openModal } = useModalState();

    const handleIconClick = () => {
        const callDispatch = (title, content) => {
            openModal(TOOLTIP_MODAL, { isOpen: true, title: title, content: content, hideIcon: true });
        };
        
        switch (feeTypeName) {
            case intl.formatMessage({ id: 'order-summary:round-trip-delivery' }):
                callDispatch(
                    intl.formatMessage({ id: 'order-summary:round-trip-delivery' }),
                    intl.formatMessage({ id: 'order-summary:round-trip-tooltip' })
                );
                break;
            case intl.formatMessage({ id: 'order-summary:instore-pickup' }):
                callDispatch(
                    intl.formatMessage({ id: 'order-summary:instore-pickup' }),
                    intl.formatMessage({ id: 'order-summary:instore-pickup-tooltip' })
                );
                break;
            case intl.formatMessage({ id: 'order-summary:rental-protection-plan' }):
                callDispatch(
                    intl.formatMessage({ id: 'order-summary:rental-protection-plan' }),
                    intl.formatMessage({ id: 'order-summary:rental-protection-plan-tooltip' })
                );
                break;
            case intl.formatMessage({ id: 'order-summary:fuel-convenience-plan' }):
                callDispatch(
                    intl.formatMessage({ id: 'order-summary:fuel-convenience-plan' }),
                    intl.formatMessage({ id: 'order-summary:fuel-convenience-plan-tooltip' })
                );
                break;
            case intl.formatMessage({ id: 'order-summary:environment-fee' }):
                callDispatch(
                    intl.formatMessage({ id: 'order-summary:environment-fee' }),
                    intl.formatMessage({ id: 'order-summary:environment-fee-tooltip' })
                );
                break;
            default:
                break;
        }
    };
    return (
        <Button
            className="toolTipIcon"
            onClick={handleIconClick}
            data-testid={summaryDetailsDatalocator.tooltip_icon}
            tabIndex="0"
            customButtonAriaLabel={`${intl.formatMessage({
                id: 'commom:more-information'
            })} ${feeTypeName} ${intl.formatMessage({ id: 'common:graphic-clickable' })}`}>
            <InfoIcon aria-hidden="true" />
        </Button>
    );
};

export default React.memo(TooltipIcon);
