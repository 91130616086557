import React from 'react';
import { useIntl } from 'react-intl';
import { EMPLOYEE } from '../../../../components/checkout/constants';
import { isCheckoutPage } from '../../../../components/global/utils/commonUtils';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import useAnalytics from '../../../../hooks/useAnalytics';
import { SET_CART_OVERLAY } from '../../../actions/constants';
import { useUserContext } from '../../../context/UserContext';
import { useCartState } from '../cartContext';
import { CART_OVERLAYS } from '../constants';
import AccountSelector from './accountSelector';
import PlainText from './plainText';

const AccountDetails = props => {
    const intl = useIntl();
    const [, dispatch] = useCartState();
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const { sendEventsForLocationAction } = useAnalyticsContext();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();

    const navigationHandler = cartOverlay => {
        //track navigation to Accounts for credit user
        if (userProfile?.type != EMPLOYEE) {
            if (props.setShowAccountModal) {
                props.setShowAccountModal(true);
            } else if (props.handleAccountOverlay) {
                props.handleAccountOverlay(true);
            } else {
                dispatch({ type: SET_CART_OVERLAY, cartOverlay });
            }
        }
        if (JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY)) && !isCheckoutPage()) {
            sendEventsForLocationAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_NAME_ACCOUNT,
                payloadEcommerceLocationActionAnalytics()
            );
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
        }
    };
    const accountLength = props.accountLength;
    if (accountLength > 1) {
        return (
            <AccountSelector
                screenName={props.screenName}
                accountName={props.accountName}
                accountNumber={props.accountNumber}
                accountClick={() => navigationHandler(CART_OVERLAYS.ACCOUNT)}
                buttonAriaLabel={`${intl.formatMessage({ id: 'account:ac-minicart-choose-account-text' })}`}
                accountSelectorTitle={props?.accountSelectorTitle}
            />
        );
    }
    return <PlainText accountName={props.accountName} accountNumber={props.accountNumber} />;
};
export default AccountDetails;
