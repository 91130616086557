import { isValidString } from '../components/global/utils/logger';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { useCheckAuthorityType, useCheckUser } from './useCheckUser';
import { useFilterState } from '../components/cap';
import { ENV_CONFIG } from '../constants/envConfig';
import { AUTHORITY_TYPE } from '../components/global/constants';

const useCheckLocationEmpty = () => {
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const authorityType = useCheckAuthorityType();
    const isP2P = AUTHORITY_TYPE.P2P === authorityType;
    const userType = useCheckUser();
    const startDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) || '';
    const fetchLocationCoordinates = () => {
        let localLat = '';
        let localLong = '';
        const isNewAddress = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
        if (
            userType == USER_TYPE.CREDIT &&
            !isNewAddress &&
            !isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY))
        ) {
            //if isCreditnewAddress is false and if newAddress tab isnt currently open on overlay
            localLat = projectDetails?.selectedProjectLatititude;
            localLong = projectDetails?.selectedProjectLongitude;
        } else {
            localLat = viewCart?.lat;
            localLong = viewCart?.long;
        }
        return { localLat, localLong };
    };

    const isSelectedLocationEmpty = () => {
        if (projectDetails?.selectedProjectLatititude || viewCart?.lat) {
            const { localLat, localLong } = fetchLocationCoordinates();
            if ((localLat, localLong)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };
    const isRentalDetailsAvailable = () => {
        if (isSelectedLocationEmpty() || (!isValidString(startDate) && !isValidString(ENV_CONFIG.IS_ATP_DISABLED))) {
            return false;
        }
        return true;
    };

    return {
        fetchLocationCoordinates,
        isSelectedLocationEmpty,
        isRentalDetailsAvailable
    };
};
export default useCheckLocationEmpty;
