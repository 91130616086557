import { useCartState } from './cartContext';
import { updateCartItem } from '../../actions/cart';
import { SET_UPDATED_EQUIPMENT } from '../../actions/constants';
import useCartEstimate from './hooks/useCartEstimate';

const useCartOptions = ({ updateCartItemMutation, cartDetailsQuery }) => {
    const [{ editItem, cartId, editQuote }, dispatch] = useCartState();
    const [{ getEstimates}] = useCartEstimate();
    const updateCart = async newQuantity => {
        dispatch({ type: 'beginLoading' });

        await updateCartItem({
            cartDetailsQuery,
            updateCartItemMutation,
            cartId,
            cartItemUid: editItem.uid,
            itemQuantity: newQuantity,
            dispatch
        });
        dispatch({ type: 'endLoading' });
    };

    const updateCartFromMinicart = async cartItems => {
        dispatch({ type: 'beginLoading' });
        await updateCartItem({
            cartDetailsQuery,
            updateCartItemMutation,
            cartId,
            cartItems,
            dispatch
        });

        dispatch({ type: 'endLoading' });
    };

    const updateCartOnEditQuote = async () => {
        dispatch({ type: 'beginLoading' });
        await updateCartItem({
            cartDetailsQuery,
            updateCartItemMutation,
            cartId,
            cartItems: editQuote?.updatedEquipmentData,
            dispatch
        });
        dispatch({ type: SET_UPDATED_EQUIPMENT, isUpdateAvailable: false, payload: {} });
        getEstimates();
        dispatch({ type: 'endLoading' });
    };

    const updateCartForUnavailableItems = async cartItems => {
        dispatch({ type: 'beginLoading' });
        await updateCartItem({
            cartDetailsQuery,
            updateCartItemMutation,
            cartId,
            cartItems,
            dispatch
        });

        dispatch({ type: 'endLoading' });
    };

    const data = { editItem, cartId };
    const api = {
        dispatch,
        updateCartItem: updateCart,
        updateCartFromMinicart,
        updateCartForUnavailableItems,
        updateCartOnEditQuote
    };
    return [data, api];
};

export default useCartOptions;
