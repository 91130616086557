import React from 'react';
import { array } from 'prop-types';
import { useIntl } from 'react-intl';
import Product from './product';
import classes from './unavailableProductList.css';
import { ENV_CONFIG } from '../../../constants/envConfig';

const UnavailableProductList = props => {
    const intl = useIntl();
    const { unavailableCartItems } = props;

    return (
        <div>
            <h6
                className={classes.unavailableRentals}
                data-testid={'unavailable-rental-items-heading'}
                aria-label={intl.formatMessage({ id: 'minicart:product-unavailable-text' })}>
                {intl.formatMessage({ id: 'cart:product-unavailable-text' })}
            </h6>
            <div className={classes.itemsInfo}>
                {intl.formatMessage({ id: 'cart:items-unavailable-text' })}{' '}
                <a href={`tel:${ENV_CONFIG.SUPPORT_CONTACT_NUMBER}`} title={intl.formatMessage({ id: 'call-us-text' })}>
                    {ENV_CONFIG.SUPPORT_CONTACT_NUMBER}
                </a>{' '}
                {intl.formatMessage({ id: 'cart:items-information-text' })}
            </div>
            <div>
                {unavailableCartItems?.map((item, index) => (
                    <Product
                        categories={item.product.categories}
                        key={item.product.sku}
                        item={item}
                        index={index + 1}
                        cartLength={unavailableCartItems?.length}
                        pcAvaibility={false}
                    />
                ))}
            </div>
        </div>
    );
};

UnavailableProductList.propTypes = {
    unavailableCartItems: array
};

export default React.memo(UnavailableProductList);
