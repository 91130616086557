import React, { memo, useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import useAnalytics from '../../../hooks/useAnalytics';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import { usePageType } from '../../../hooks/usePageType';
import BackIcon from '../../../resources/images/arrow-ios-left.svg';
import CloseIcon from '../../../resources/images/cross_24x24.svg';
import LocationPin from '../../../resources/images/location-pin.svg';
import Button from '../../global/atoms/button/button';
import { AUTHORITY_TYPE } from '../../global/constants';
import JobsiteAddressAutoComplete from '../../global/modules/jobsitesAddressAutoComplete';
import SideDrawerModal from '../../global/modules/modals/SideDrawerModal';
import { createFullAddress } from '../../global/utils/commonUtils';
import { EDIT_VIEW_CLOSE } from '../constants';
import { capDataLocator } from '../dataLocator';
import { useFilterState } from '../filterContext';
import { useCapUtils } from '../hooks/useCapUtils';
import './drawerRentalLocation.scss';

const DrawerRentalLocation = ({
    addressText,
    userType,
    setIsCapInteracted,
    isJobsiteSelected,
    jobsiteDetails,
    isCurrentLocationDisabled,
    jobAndAddressState,
    setJobAndAddressState,
    locationContainerRef,
    isAddressChanged,
    setIsAddressChanged,
    stringSearchedForSuggestion,
    setStringSearchedForSuggestion,
    onFocusRentalLocation,
    onAddressSelect,
    onJobsiteSelect
}) => {
    const intl = useIntl();
    const [{ userAccount }] = useCartState();
    const [{ projectDetails, editViewSource }, dispatch] = useFilterState();
    const { getJobsiteWithLatLong, getCapUserDetails, getCapLocationLabel } = useCapUtils();
    const [showDrawer, setShowDrawer] = useState(false);
    const rentalLocationLabel =
        getCapUserDetails()?.rentalLocationText || intl.formatMessage({ id: 'cap:rental-location' });
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;

    const chooseAccountAndJobsiteLabel = getCapLocationLabel(rentalLocationLabel);
    const jobsiteLabel = isP2PUser ? intl.formatMessage({ id: 'cap:jobsite' }) : rentalLocationLabel;
    const labelBasedOnEmptyField =
        addressText || jobAndAddressState?.chosenJobName ? jobsiteLabel : chooseAccountAndJobsiteLabel;
    const placeholderText = isP2PUser ? '' : intl.formatMessage({ id: 'cap:location-drawer-placeholder' });
    const [showJobsitesDrawer, setShowJobsitesDrawer] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [showCustomFocus, setShowCustomFocus] = useState(false);

    useEffect(() => {
        setSelectedProjectId(projectDetails?.selectedProjectJobId);
    }, [projectDetails?.selectedProjectLatititude]);

    useEffect(() => {
        if (editViewSource === VARIABLE_CONFIG.CAP_VIEW_MODE.LOCATION_VIEW) {
            setShowCustomFocus(true);
        }
    }, [editViewSource]);

    const closeViewMode = () => {
        setIsCapInteracted(false);
        dispatch({ type: EDIT_VIEW_CLOSE });
    };

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
        setShowCustomFocus(false);
    };

    const addressSelectHandler = (address, currentLocationDisabledState, placeId) => {
        onAddressSelect(address, currentLocationDisabledState, placeId);
        setShowDrawer(false);
    };

    const jobsiteSelectHandler = async (jobsite, fullAddress, currentLocationDisabledState) => {
        onJobsiteSelect(jobsite, fullAddress, currentLocationDisabledState);
        setShowDrawer(false);
    };

    const jobsiteAddressDrawerHeading = () => {
        return (
            <>
                <Button
                    className="back-btn"
                    dataTestid={capDataLocator.back_btn_testid}
                    customButtonAriaLabel={intl.formatMessage({ id: 'account:ac-minicart-back-btn-aria-label' })}
                    onClick={toggleDrawer}>
                    <BackIcon />
                    {intl.formatMessage({ id: 'common:back' })}
                </Button>
                <Button
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    onClick={closeViewMode}>
                    <CloseIcon />
                </Button>
            </>
        );
    };

    const labelContent = () => (
        <label className="location-label" htmlFor={capDataLocator.rental_location_input_testid}>
            {labelBasedOnEmptyField}
        </label>
    );

    const renderAutoComplete = () => {
        return (
            <>
                {' '}
                {labelContent()}
                <JobsiteAddressAutoComplete
                    inputId={capDataLocator.rental_location_input_testid}
                    autocompleteWrapper="cap-location-drawer"
                    placeholderText={placeholderText}
                    prefilledAddress={addressText}
                    isLocationAvailable={userType !== USER_TYPE.GUEST}
                    userType={userType}
                    selectedAddress={addressSelectHandler}
                    onJobsiteSelect={jobsiteSelectHandler}
                    isLocationPersisted
                    focusInput
                    selectedProjectId={selectedProjectId}
                    setSelectedProjectId={setSelectedProjectId}
                    isAddressChanged={isAddressChanged}
                    setIsAddressChanged={setIsAddressChanged}
                    showJobsitesDrawer={showJobsitesDrawer}
                    setShowJobsitesDrawer={setShowJobsitesDrawer}
                    disableCurrentLocation={isCurrentLocationDisabled}
                    onLocationInputFocus={onFocusRentalLocation}
                    setIsCapInteracted={setIsCapInteracted}
                    jobAndAddressState={jobAndAddressState}
                    setJobAndAddressState={setJobAndAddressState}
                    displayJobsiteModal={isP2PUser}
                    stringSearchedForSuggestion={stringSearchedForSuggestion}
                    setStringSearchedForSuggestion={setStringSearchedForSuggestion}
                />
            </>
        );
    };

    const renderLocation = () => {
        if (!addressText) {
            return (
                <Button
                    className={`rental-input-btn placeholder-text ${showCustomFocus ? 'rental-btn-focus' : ''}`}
                    onClick={toggleDrawer}
                    customButtonAriaLabel={`${intl.formatMessage({ id: 'cap:rental-location' })} ${intl.formatMessage({
                        id: 'common:required-edit-text'
                    })}`}
                    data-testid={capDataLocator.location_input_btn_testid}>
                    {intl.formatMessage({ id: 'cap:location-drawer-placeholder' })}
                </Button>
            );
        } else if (isJobsiteSelected && jobsiteDetails?.projectName) {
            return (
                <Button
                    className={`rental-input-btn jobsite-details text-ellipsis ${
                        showCustomFocus ? 'rental-btn-focus' : ''
                    }`}
                    onClick={toggleDrawer}
                    buttonAriaLabel={intl.formatMessage({ id: 'cap:location-drawer-placeholder' })}
                    data-testid={capDataLocator.location_input_btn_testid}>
                    <span className="jobsite-name text-ellipsis">{jobsiteDetails?.projectName}</span>{' '}
                    {createFullAddress(jobsiteDetails)}
                </Button>
            );
        } else {
            return (
                <Button
                    className={`rental-input-btn text-ellipsis ${showCustomFocus ? 'rental-btn-focus' : ''}`}
                    onClick={toggleDrawer}
                    customButtonAriaLabel={`${addressText} ${intl.formatMessage({
                        id: 'common:contents-selected'
                    })} ${intl.formatMessage({ id: 'cap:rental-location' })} ${intl.formatMessage({
                        id: 'common:required-edit-text'
                    })}`}
                    data-testid={capDataLocator.location_input_btn_testid}>
                    {addressText}
                </Button>
            );
        }
    };

    return (
        <>
            <div>
                {labelContent()}
                {renderLocation()}
            </div>
            {showDrawer && (
                <SideDrawerModal
                    isModalOpen
                    handleModalToggle={toggleDrawer}
                    header={jobsiteAddressDrawerHeading()}
                    content={renderAutoComplete()}
                    modalContentClass="rental-drawer-location__content"
                    modalHeaderClass="rental-drawer-location__header"
                />
            )}
        </>
    );
};

DrawerRentalLocation.defaultProps = {
    userType: USER_TYPE.GUEST,
    addressText: '',
    setIsCapInteracted: () => {},
    isJobsiteSelected: false,
    jobsiteDetails: {},
    isCurrentLocationDisabled: false,
    locationContainerRef: () => {},
    isAddressChanged: false,
    setIsAddressChanged: () => {},
    stringSearchedForSuggestion: '',
    setStringSearchedForSuggestion: () => {},
    onFocusRentalLocation: () => {},
    onAddressSelect: () => {},
    onJobsiteSelect: () => {}
};

DrawerRentalLocation.propTypes = {
    addressText: string,
    userType: string,
    setIsCapInteracted: func,
    isJobsiteSelected: bool,
    jobsiteDetails: object,
    isCurrentLocationDisabled: bool,
    locationContainerRef: func,
    isAddressChanged: bool,
    setIsAddressChanged: func,
    stringSearchedForSuggestion: string,
    setStringSearchedForSuggestion: func,
    onFocusRentalLocation: func,
    onAddressSelect: func,
    onJobsiteSelect: func
};

export default memo(DrawerRentalLocation);
