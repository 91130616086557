import React from 'react';
import { useIntl } from 'react-intl';
import classes from './accountSelector.css';
import ArrowRight from '../../../../../resources/images/arrow-right.svg';

const AccountSelector = props => {
    const { buttonAriaLabel, customClassName, customButtonView, pickupDefaultIcon } = props;
    const intl = useIntl();

    const handleSelectorClass = () => {
        if (props?.isSelectStoreError) {
            return `${classes.AccountSelectorError}`;
        } else {
            if (customButtonView) {
                return `${classes.AccountSelectorContainer}`;
            } else {
                return `${classes.AccountSelector}`;
            }
        }
    };
    return (
        <button
            className={handleSelectorClass()}
            onClick={props.accountClick}
            data-testid={props.testid}
            aria-label={`${intl.formatMessage({ id: 'cap:pickup-store' })} ${buttonAriaLabel}`}>
            <div className={classes.accountSelectorWrapper} data-testid={props.testProjectName}>
                {props.selectedProjectAddress1 && (
                    <p className={classes.bodyp} data-testid={props.projectTestId}>
                        {props.selectedProjectAddress1}
                    </p>
                )}
                <small className={`${classes.bodysmallp} ${customClassName}`} data-testid={props.accountTestId}>
                    {props.projectName}
                </small>
            </div>
            <div
                className={
                    props.selectedProjectAddress1 === intl.formatMessage({ id: 'cap:no-store-nearby' })
                        ? 'pickupStoreInput__alert__icon'
                        : `${classes.bodyIcon} account-selector-icon`
                }>
                {pickupDefaultIcon}
            </div>
        </button>
    );
};
AccountSelector.defaultProps = {
    buttonAriaLabel: '',
    customClassName: '',
    customButtonView: '',
    pickupDefaultIcon: <ArrowRight />
};
export default AccountSelector;
