import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useFilterState } from '../filterContext';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import { usePageType } from '../../../hooks/usePageType';
import { useAtp } from './useATP';
import useComputeLocation from '../../../hooks/useComputeLocation';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import isObjectEmpty from '../../../aem-core-components/utils/isObjectEmpty';
import {
    geoCodeByLatLong,
    getLocationsDetailsByPlaceId,
    getlatLongByAddress
} from '../../global/modules/location-autocomplete/api/getLocations';
import { createFullAddress, formatNearbyPC, isCCPage, isTier2Radius } from '../../global/utils/commonUtils';
import { isValidString, logError } from '../../global/utils/logger';
import { getDateDiffInHrs } from '../utils/atputils';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { USER_ACCOUNT_TYPE, USER_TYPE } from '../../../constants/userDetailsConstants';
import { FULFILLMENT_TYPE, TILE_STATES } from '../constants';
import MUTATION_UPDATE_CART_ITEM from '../../../aem-core-components/queries/mutation_update_cart_item.graphql';
import { useMutation } from '@apollo/client';
import { useAwaitQuery } from '../../../aem-core-components/utils/hooks';
import CART_DETAILS_QUERY from '../../../aem-core-components/queries/query_cart_details.graphql';
import useCartOptions from '../../../aem-core-components/components/Minicart/useCartOptions';
import { AUTHORITY_TYPE } from '../../global/constants';
import { getCartDetails } from '../../../aem-core-components/actions/cart';
import { SET_IS_CART_LOADING } from '../../../aem-core-components/actions/constants';

export const useCapUtils = () => {
    const intl = useIntl();
    const authorityType = useCheckAuthorityType();
    const [
        { viewCart, projectDetails, startDate, endDate, selectedStoreDetails: selectedPickupStoreContext },
        filterDispatch
    ] = useFilterState();
    const [{ overridePCList, userProfile }] = useUserContext();
    const [updateCartItemMutation] = useMutation(MUTATION_UPDATE_CART_ITEM);
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const [, { updateCartForUnavailableItems }] = useCartOptions({
        updateCartItemMutation,
        cartDetailsQuery
    });
    const userType = useCheckUser();
    const [{ punchoutUserData, cart, userAccount, cartId }, cartDispatch] = useCartState();
    const pageType = usePageType();
    const { getBSRList, cityAndZipWithoutStreetValidation, extractAddressComponents, getZipFromLatLong } =
        useComputeLocation();
    const { isRentalDetailsAvailable, fetchLocationCoordinates } = useCheckLocationEmpty();

    const { handleATPCart, handleATP } = useAtp();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const isSelectedAccountIsNonCorpAccount = userProfile?.isSelectedAccountIsNonCorpAccount;
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

    const getJobsiteWithLatLong = async jobsite => {
        try {
            let projectLat = jobsite?.selectedProjectLatititude;
            let projectLong = jobsite?.selectedProjectLongitude;
            if (!projectLat || !projectLong) {
                const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                const fullAddress = createFullAddress(jobsite, true);
                if (fullAddress) {
                    const response = await getlatLongByAddress(companyID, fullAddress);
                    if (response?.data?.results?.length > 0) {
                        projectLat = response?.data?.results[0]?.geometry?.location?.lat;
                        projectLong = response?.data?.results[0]?.geometry?.location?.lng;
                    }
                }
            }
            return { ...jobsite, selectedProjectLatititude: projectLat, selectedProjectLongitude: projectLong };
        } catch (error) {
            logError(error, false, 'getJobsiteWithLatLong', [jobsite]);
        }
    };

    const distanceText = companyId => {
        let companyID = companyId ? companyId : parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID));
        if (companyID === 2) {
            return intl.formatMessage({ id: 'location:km-text' });
        }
        return intl.formatMessage({ id: 'location:miles-text' });
    };

    const updateBsrPricingPCs = async payload => {
        const {
            localLat,
            localLong,
            localStartDate = startDate,
            localIsInStorePickup = viewCart?.isInStorePickup,
            companyID,
            isSourceCallRequired = false
        } = payload || {};
        try {
            const isCreditProjectOrCashLocation =
                localLat || projectDetails?.selectedProjectLatititude || viewCart?.lat;
            if (isCCPage()) {
                // to clear always on ccpage for fresh call
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
            }
            const dateDiff = getDateDiffInHrs(localStartDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
            if (!localIsInStorePickup && dateDiff <= 24 && isCreditProjectOrCashLocation) {
                const bsrList =
                    JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST) || '[]') || [];
                if (!bsrList || !bsrList?.length) {
                    const { data } = await getBSRList({ lat: localLat, long: localLong, companyID });
                    // Create an array that combines branchCompany and branchNumber
                    if (data?.length > 0) {
                        const combinedArray = data?.map(branch =>
                            formatNearbyPC(branch?.branchNumber, branch?.branchCompany)
                        );
                        if (!isSourceCallRequired) {
                            sessionStorage.setItem(
                                STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST,
                                JSON.stringify(combinedArray)
                            );
                        }

                        return combinedArray;
                    } else {
                        if (!isSourceCallRequired) {
                            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
                        }
                        return [];
                    }
                }
                return bsrList;
            }

            return [];
        } catch (error) {
            logError(error, false, 'updateBsrPricingPCs');
        }
    };

    const fetchLocalLocationCoordinates = async (placeId, jobsiteDetails, isJobsiteSelected) => {
        try {
            let lat = '';
            let long = '';
            let addressDetails = {};
            let companyId = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
            if (isValidString(placeId) && !isJobsiteSelected) {
                const responseFromPlaceDetails = await getLocationsDetailsByPlaceId(placeId);
                if (responseFromPlaceDetails?.error || isObjectEmpty(responseFromPlaceDetails?.data?.result)) {
                    return;
                }

                let { jobSiteCity, jobSiteState, jobSiteZip, country } = extractAddressComponents(
                    responseFromPlaceDetails?.data?.result,
                    false
                );
                companyId = country;
                lat = responseFromPlaceDetails?.data?.result?.geometry?.location?.lat || '';
                long = responseFromPlaceDetails?.data?.result?.geometry?.location?.lng || '';
                if (!cityAndZipWithoutStreetValidation(responseFromPlaceDetails?.data?.result, companyId)) {
                    return { companyId, lat, long };
                }

                if (!jobSiteZip) {
                    let latlng = `${lat},${long}`;
                    let geocoderesponse = await geoCodeByLatLong(latlng);
                    jobSiteZip = getZipFromLatLong(geocoderesponse?.data?.results[0]);
                }

                addressDetails = {
                    jobSiteAddr2: '',
                    jobSiteCity,
                    jobSiteState,
                    jobSiteZip
                };
            } else {
                lat = jobsiteDetails?.selectedProjectLatititude;
                long = jobsiteDetails?.selectedProjectLongitude;
            }
            return { lat, long, companyId, addressDetails };
        } catch (error) {
            logError(error, false, 'fetchLocalLocationCoordinates');
        }
    };

    const showToggleButton = () => {
        if (
            isRentalDetailsAvailable() &&
            !isValidString(ENV_CONFIG.IS_ATP_DISABLED) &&
            !isValidString(isTier2Radius()) &&
            !overridePC?.pc
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getCapUserDetails = () => {
        try {
            const capJson = JSON.parse(ENV_CONFIG.CAP_JSON) || {};
            if (userType === USER_TYPE.GUEST || userType === USER_TYPE.CASH) {
                return capJson[userType];
            } else if (authorityType === AUTHORITY_TYPE.P2P || authorityType === AUTHORITY_TYPE.PUNCHOUT) {
                return isSelectedAccountIsNonCorpAccount
                    ? capJson[USER_ACCOUNT_TYPE.P2PNONCORP]
                    : capJson[USER_ACCOUNT_TYPE.P2PCORP];
            } else if (overridePC?.pc) {
                return capJson[USER_ACCOUNT_TYPE.OVERRIDEACCOUNT];
            } else {
                return capJson[USER_TYPE.CREDIT];
            }
        } catch (error) {
            logError(error, false, 'getCapUserDetails');
        }
    };

    const getSelectedStoreLabel = selectedPickupStore => {
        if (isObjectEmpty(selectedPickupStore)) {
            return intl.formatMessage({ id: 'cap:no-store-nearby' });
        } else {
            return (
                <>
                    {selectedPickupStore?.city} {selectedPickupStore?.state} -{' '}
                    {intl.formatMessage({ id: 'store-details:branch' })}
                    {selectedPickupStore?.pc} (
                    {Number(selectedPickupStore?.distance ?? selectedPickupStore?.drivingDistanceFromJobsite).toFixed(
                        2
                    )}{' '}
                    {distanceText(selectedPickupStore?.companyId)})
                </>
            );
        }
    };

    const getLocalEstimatesLocationDetails = ({ isJobsiteSelected, jobsiteWithLatLong, locationDetails }) => {
        try {
            if (isJobsiteSelected) {
                return {
                    lat: jobsiteWithLatLong?.selectedProjectLatititude,
                    long: jobsiteWithLatLong?.selectedProjectLongitude
                };
            } else {
                return {
                    lat: locationDetails?.lat,
                    long: locationDetails?.long
                };
            }
        } catch (error) {
            logError(error, false, 'getLocalEstimatesLocationDetails', [
                isJobsiteSelected,
                jobsiteWithLatLong,
                locationDetails
            ]);
        }
    };

    const getPayloadForCompareEstimates = async ({
        localEstimatesLocationDetails,
        filterStartDate,
        filterEndDate,
        companyID,
        fulfillmentValue,
        isSourceCallRequired
    }) => {
        try {
            let bsrData = [];

            if (!overridePCList?.length) {
                if (filterEndDate && filterStartDate) {
                    if (isSourceCallRequired) {
                        bsrData = await updateBsrPricingPCs({
                            localLat: localEstimatesLocationDetails?.lat,
                            localLong: localEstimatesLocationDetails?.long,
                            localStartDate: filterStartDate,
                            localIsInStorePickup: fulfillmentValue === FULFILLMENT_TYPE.PICKUP,
                            companyID,
                            isSourceCallRequired
                        });
                    }
                }
                return bsrData;
            }
        } catch (error) {
            logError(error, false, 'getPayloadForCompareEstimates', [localEstimatesLocationDetails]);
        }
    };

    const createUpdatedCartData = (
        filterStartDate,
        fulfillmentValue,
        selectedPickupStore,
        locationDetails,
        bsrData,
        companyID,
        localIsTier2Radius,
        localNearbyPcs,
        estimatesResponse
    ) => {
        try {
            const cartUpdatedData = [];
            estimatesResponse?.items?.forEach(data => {
                let isCICheckRequired = filterStartDate ? isValidString(ENV_CONFIG.IS_ATP_DISABLED) : true;
                let payload = {
                    catsku: data?.product?.sku,
                    inventoryDataObj: data?.product?.ec_pc_inventory,
                    localStartDate: filterStartDate,
                    localIsInStorePickup: fulfillmentValue === FULFILLMENT_TYPE.PICKUP,
                    locationPCData: selectedPickupStore,
                    isLocalLocationEmpty: !locationDetails?.lat,
                    localBsrData: bsrData,
                    localCompanyID: companyID,
                    localIsTier2Radius,
                    localNearbyPcs,
                    pcAvailability: data?.pc_availability
                };
                let tileStatus = isCICheckRequired ? handleATPCart(payload) : handleATP(payload);
                if (filterStartDate && !isValidString(ENV_CONFIG.IS_ATP_DISABLED)) {
                    if (tileStatus !== TILE_STATES.AVAILABLE) {
                        cartUpdatedData.push({ ...data, tileStatus });
                    }
                } else if (tileStatus === TILE_STATES.UNAVAILABLE) {
                    cartUpdatedData.push({ ...data, tileStatus });
                }
            });
            return cartUpdatedData;
        } catch (error) {
            logError(error, false, 'createUpdatedCartData', [estimatesResponse]);
            return [];
        }
    };

    const getCartUpdatedDetails = async cartDetailsPayload => {
        /* To take the values default values from context if details are not passed */
        try {
            let estimatesResponse = {};
            if (cart?.id) {
                estimatesResponse = cart;
            } else {
                estimatesResponse = await getCartDetails({
                    cartDetailsQuery,
                    dispatch: cartDispatch,
                    cartId,
                    filterDispatch,
                    handleATPCart
                });
                cartDispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
            }
            const NewAddress =
                JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
            const isCreditProjectAddress = userType == USER_TYPE.CREDIT && !NewAddress;
            const { localLat, localLong } = fetchLocationCoordinates();
            const {
                isJobsiteSelected = isCreditProjectAddress ? true : false,
                jobsiteDetails = projectDetails,
                locationDetails = { lat: localLat, long: localLong },
                localNearbyPcs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST) || '[]'),
                companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                selectedPickupStore = selectedPickupStoreContext,
                filterStartDate = startDate,
                filterEndDate = endDate,
                fulfillmentValue = viewCart?.isInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY,
                isSourceCallRequired = true,
                localIsTier2Radius = isValidString(isTier2Radius())
            } = cartDetailsPayload;

            let jobsiteWithLatLong = null;
            if (isJobsiteSelected) {
                jobsiteWithLatLong = await getJobsiteWithLatLong(jobsiteDetails);
            }
            const localEstimatesLocationDetails = getLocalEstimatesLocationDetails({
                isJobsiteSelected,
                jobsiteWithLatLong,
                locationDetails
            });
            const bsrData = await getPayloadForCompareEstimates({
                localEstimatesLocationDetails,
                filterStartDate,
                filterEndDate,
                companyID,
                fulfillmentValue,
                isSourceCallRequired
            });
            return createUpdatedCartData(
                filterStartDate,
                fulfillmentValue,
                selectedPickupStore,
                locationDetails,
                bsrData,
                companyID,
                localIsTier2Radius,
                localNearbyPcs,
                estimatesResponse
            );
        } catch (error) {
            logError(error, false, 'getCartUpdatedDetails', [cartDetailsPayload]);
        }
    };

    const removeUnavailableItemsFromCart = async updatedCartDetails => {
        /* Remove unavailable items form the cart*/
        const itemsToRemove = updatedCartDetails
            ?.filter(item => item?.tileStatus !== TILE_STATES.AVAILABLE)
            ?.map(item => ({ cart_item_uid: item?.uid, quantity: 0 }));
        if (itemsToRemove?.length > 0) {
            await updateCartForUnavailableItems(itemsToRemove);
        }
    };

    const getCapLocationLabel = rentalLocationLabel => {
        if (isP2PUser) {
            if (!userAccount?.accountNumber) {
                return intl.formatMessage({ id: 'cap:choose-account-and-jobsite' });
            } else {
                return intl.formatMessage({ id: 'cap:choose-a-jobsite' });
            }
        } else {
            return rentalLocationLabel;
        }
    };

    return {
        getJobsiteWithLatLong,
        distanceText,
        updateBsrPricingPCs,
        fetchLocalLocationCoordinates,
        showToggleButton,
        getCapUserDetails,
        getSelectedStoreLabel,
        getCartUpdatedDetails,
        removeUnavailableItemsFromCart,
        getCapLocationLabel
    };
};
