import { array, bool, element, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Input from '../../../../../components/global/atoms/input/Input';
import { isCheckoutPage } from '../../../../../components/global/utils/commonUtils';
import { isValidString, logError } from '../../../../../components/global/utils/logger';
import { useAnalyticsContext } from '../../../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../../../constants/analyticsConstants/Variables';
import { SCREEN_NAME } from '../../../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../../../constants/storageConfig';
import { useSunbeltLocation } from '../../../../../hooks/useSunbeltLocation';
import useAnalytics from '../../../../../hooks/useAnalytics';
import CrossIcon from '../../../../../resources/images/close.svg';
import SearchIcon from '../../../../../resources/images/search.svg';
import { useUserContext } from '../../../../context/UserContext';
import Button from '../../../Button';
import { useCartState } from '../../cartContext';
import classes from './projectListing.css';

const ProjectSearch = props => {
    const intl = useIntl();
    const sunbeltLocation = useSunbeltLocation();
    const [{}, { getAccountProjectsDotCom }] = useUserContext();
    const {
        placeholder,
        notFoundErrorMessage,
        isSearchIconVisible,
        screenName,
        handleInputBlur,
        setSearchErrorText,
        searchQuery,
        onSearchQueryChange,
        projectList,
        source,
        closeIcon
    } = props;
    const [{ userAccount }] = useCartState();
    const [errorMessage, setErrorMessage] = useState('');
    const { sendEventsForPageElementClick, sendEventsForAddressErrorAction, sendEventsForLocationAction } =
        useAnalyticsContext();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    const {
        LOCAL_STORAGE: { JOBSITE_SEARCH_KEYWORD }
    } = STORAGE_CONFIG;

    useEffect(() => {
        let debouncedFetchProjects;
        if (localStorage.getItem(JOBSITE_SEARCH_KEYWORD) || searchQuery) {
            debouncedFetchProjects = setTimeout(() => {
                filterData(searchQuery);
                localStorage.setItem(JOBSITE_SEARCH_KEYWORD, '');
            }, 100);
        }
        return () => {
            clearTimeout(debouncedFetchProjects);
            localStorage.setItem(JOBSITE_SEARCH_KEYWORD, searchQuery);
        };
    }, [searchQuery]);

    useEffect(() => {
        if (projectList?.length === 0 && searchQuery?.length > 2) {
            setSearchErrorText(searchQuery);
            setErrorMessage(notFoundErrorMessage);
        } else {
            setSearchErrorText('');
            setErrorMessage('');
        }
    }, [searchQuery, projectList]);

    useEffect(() => {
        if (
            source === VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY &&
            projectList.length === 0 &&
            searchQuery?.length > 2 &&
            !isCheckoutPage() &&
            !JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY))
        ) {
            sendEventsForAddressErrorAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ADDRESS_EVENT,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_PROJECT_SEARCH,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_PROJECT_ERROR,
                payloadEcommerceLocationActionAnalytics(true)
            );
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
        }
    }, [projectList]);

    const filterData = (query = '') => {
        if (!(query.length > 0 && query.length < 3)) {
            getAccountProjectsDotCom(userAccount?.accountNumber, query.toLowerCase());
        }
    };

    const onSearch = e => {
        const projectTimerID = isValidString(
            sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.PROJECTLOADINGTIMERID)
        );
        if (projectTimerID) {
            clearTimeout(projectTimerID);
        }
        onSearchQueryChange(e.target.value);
    };

    const onFocus = () => {
        if (
            JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY)) &&
            !isCheckoutPage()
        ) {
            sendEventsForLocationAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_NAME_PROJECT_SEARCH,
                payloadEcommerceLocationActionAnalytics()
            );
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
        }
    };

    const handleSearchEvent = () => {
        if (handleInputBlur) {
            try {
                sendEventsForPageElementClick(
                    VARIABLE_CONFIG.TYPE.FIELD,
                    VARIABLE_CONFIG.ACTION.SEARCH,
                    VARIABLE_CONFIG.STYLE.SEARCH_BAR,
                    searchQuery,
                    VARIABLE_CONFIG.PLACEMENT.CONTENT,
                    VARIABLE_CONFIG.MODAL.NONE,
                    VARIABLE_CONFIG.SELECT_METHOD.NONE,
                    sunbeltLocation
                );
            } catch (error) {
                logError(error, false, 'handleSearchEvent');
            }
        }
    };
    const onCrossIconClick = e => {
        onSearchQueryChange('');
    };

    return (
        <div className={classes.searchRoot}>
            <div
                className={
                    screenName === SCREEN_NAME.CHECKOUT_SCREEN || screenName === SCREEN_NAME.PDP_SCREEN
                        ? ''
                        : classes.searchInputBoxWrap
                }>
                <div className={classes.searchInputBox}>
                    {isSearchIconVisible && (
                        <>
                            <SearchIcon className={classes.searchInputIcon} />
                        </>
                    )}
                    <Input
                        type="text"
                        name="account_search"
                        classes={classes.searchInput}
                        placeholder={placeholder}
                        value={searchQuery}
                        dataTestId={'searchInput'}
                        handleInputChange={onSearch}
                        onFocus={onFocus}
                        handleInputBlur={handleSearchEvent}
                        inputAriaLabel={`${intl.formatMessage({
                            id: 'account:ac-common-search-text'
                        })} ${placeholder}`}
                    />
                    {searchQuery && (
                        <Button
                            buttonAriaLabel={`${intl.formatMessage({
                                id: 'projects:clear-search-bar'
                            })}`}
                            data-testid={'crossIcon'}
                            className={classes.crossIcon}
                            onClick={onCrossIconClick}>
                            {closeIcon || <CrossIcon />}
                        </Button>
                    )}
                </div>
            </div>
            {errorMessage && <span className={classes.notFoundError}>{errorMessage}</span>}
        </div>
    );
};

ProjectSearch.propTypes = {
    placeholder: string.isRequired,
    notFoundErrorMessage: string,
    searchQuery: string,
    projectList: array,
    onSearchQueryChange: func,
    handleInputBlur: bool,
    setSearchErrorText: func,
    onProjectListUpdate: func,
    source: string,
    closeIcon: element
};

ProjectSearch.defaultProps = {
    screenName: '',
    searchQuery: '',
    onSearchQueryChange: () => {},
    onProjectListUpdate: () => {},
    projectList: '',
    handleInputBlur: false,
    setSearchErrorText: () => {},
    source: '',
    notFoundErrorMessage: '',
    closeIcon: null
};

export default React.memo(ProjectSearch);
