import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classes from '../minicart.css';
import { useCartState } from '../cartContext';
import { CART_OVERLAYS } from '../constants';
import { RESET_CLICKS, SET_ANIMATED_HEADER, SET_VIEW_CART_FIELDS } from '../../../actions/constants';
import Modal from '../../../../components/global/atoms/modal';
import RangePickerOverlay from '../../../../components/global/modules/rangepicker/RangePickerOverlay';
import UnavailableRental from '../../../../components/global/modules/unavailableRental/UnavailableRental';
import { isDateDisabled } from '../../../../components/global/utils/commonUtils';
import { useFilterState } from '../../../../components/cap';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import useAnalytics from '../../../../hooks/useAnalytics';
import useMedia from '../../../../hooks/useMedia';
import { SET_END_DATE, SET_START_DATE } from '../../../../components/cap/constants';
import { getDateDiffInHrs } from '../../../../components/cap/utils/atputils';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../../../../components/global/constants';
const SCROLL_LIMIT = 230;

/**
 *
 * @returns child screen overlay on minicart
 */
export default function Container(props) {
    const [
        {
            cartStack,
            calendarDateInteraction,
            animateHeader: { showHeader, showGradient }
        },
        dispatch
    ] = useCartState();
    const [{ viewCart }, filterDispatch] = useFilterState();
    const { sendEventsForDatePickerFormInteraction } = useAnalyticsContext();
    const [{ payloadEcommerceActionAnalytics, sendAnalyticsDatePickerModalEvents }] = useAnalytics();
    const mediaType = useMedia();
    const { isOpen, setShowWarning, showWarning, children } = props;
    const rootClass = isOpen ? classes.root_open : classes.root;
    const isHolidayDate = isDateDisabled();

    const startDateStorage = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE);
    const endDateStorage = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE);
    const [selectedStartDate, setSelectedStartDate] = useState(startDateStorage || '');
    const [selectedEndDate, setSelectedEndDate] = useState(endDateStorage || '');
    const [isEndDateEmpty, setIsEndDateEmpty] = useState(false);
    const [isStartDateEarly, setIsStartDateEarly] = useState(false);
    const [rangeState, setRangeState] = useState([
        {
            key: 'selection',
            startDate: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)
                ? new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE))
                : '',
            endDate: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)
                ? new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE))
                : '',
            color: '#F2F2F2'
        }
    ]);

    const authorityType = useCheckAuthorityType();
    const isP2P = [AUTHORITY_TYPE.P2P].includes(authorityType);

    useEffect(() => {
        setSelectedStartDate(startDateStorage);
        setSelectedEndDate(endDateStorage);
    }, [startDateStorage, endDateStorage]);

    const handleScroll = e => {
        if (cartStack[cartStack.length - 1] === CART_OVERLAYS.CART) {
            if (e.target.scrollTop > 0 && e.target.scrollTop < SCROLL_LIMIT && !showHeader) {
                dispatch({ type: SET_ANIMATED_HEADER, animateHeader: { showHeader: true, showGradient: false } });
            }
        } else if (e.target.scrollTop >= SCROLL_LIMIT && !showHeader && !showGradient) {
            dispatch({ type: SET_ANIMATED_HEADER, animateHeader: { showHeader: true, showGradient: true } });
        } else if (e.target.scrollTop === 0 && showHeader) {
            dispatch({ type: SET_ANIMATED_HEADER, animateHeader: { showHeader: false, showGradient: false } });
        }
    };
    const handleCalendarClose = () => {
        if (isOpen && rangeState?.[0]?.startDate != '' && rangeState?.[0]?.endDate == '') {
            setIsEndDateEmpty(true);
            setShowWarning(true);
            return;
        }
        setShowWarning(false);
        setIsEndDateEmpty(false);
        filterDispatch({
            type: SET_VIEW_CART_FIELDS,
            key: 'isMinicartCalendarOpen',
            value: !viewCart?.isMinicartCalendarOpen
        });
    };

    const customOnModalOpen = () => {
        if (!isNaN(new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime())) {
            if (
                new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime() <
                new Date().setHours(0, 0, 0, 0)
            ) {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, '');
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, '');
                filterDispatch({ type: SET_START_DATE, startDate: '' });
                filterDispatch({ type: SET_END_DATE, endDate: '' });
                return true;
            } else {
                return false;
            }
        } else return false;
    };

    const checkIfDayDisabled = date => {
        return isHolidayDate?.(date);
    };

    const updateDateContext = (startDate, endDate) => {
        const diffInHours = getDateDiffInHrs(startDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
        if (startDate != '' && new Date(startDate).getTime() == new Date(endDate).getTime()) {
            setShowWarning(true);
            return false;
        } else if (endDate == '' || startDate == '') {
            setIsEndDateEmpty(true);
            setShowWarning(true);
            return false;
        } else if (diffInHours <= 48 && isP2P) {
            setIsStartDateEarly(true);
            setShowWarning(true);
            return false;
        } else {
            filterDispatch({
                type: SET_START_DATE,
                startDate: isNaN(new Date(startDate).getDate()) ? '' : startDate.toString()
            });
            filterDispatch({
                type: SET_END_DATE,
                endDate: isNaN(new Date(endDate).getDate()) ? '' : endDate.toString()
            });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, startDate);
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, endDate);
            return true;
            /* Dates to be updated in local storage to get the updated date in cap product tiles */
            // const localViewCart = { ...viewCart };
            // localViewCart.startDate = startDate;
            // localViewCart.endDate = endDate;
            // localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART, JSON.stringify(localViewCart));
        }
    };

    const handleCartOpen = () => {
        dispatch({ type: 'open' });
    };

    const handleResetClicked = () => {
        dispatch({ type: RESET_CLICKS });
        handleCartOpen();
    };

    const handleDateEventsInteraction = (date, fieldName) => {
        sendEventsForDatePickerFormInteraction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_INTERACTION,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
            fieldName,
            date,
            payloadEcommerceActionAnalytics(false)
        );
    };

    const sendDatePickerModalEvents = (eventName, startDate, endDate) => {
        sendAnalyticsDatePickerModalEvents(eventName, startDate, endDate);
    };

    return (
        <>
            <RangePickerOverlay
                isOpen={viewCart?.isMinicartCalendarOpen}
                showWarning={showWarning}
                setShowWarning={setShowWarning}
                setIsEndDateEmpty={setIsEndDateEmpty}
                rangeState={rangeState}
                setRangeState={setRangeState}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
                pageType={'pdp'}
                handleToggle={handleCalendarClose}
                customOnModalOpen={customOnModalOpen}
                checkIfDayDisabled={checkIfDayDisabled}
                handleSelectedDatesOnDone={updateDateContext}
                onResetClick={handleResetClicked}
                handleDateEventsInteraction={handleDateEventsInteraction}
                sendDatePickerModalEvents={sendDatePickerModalEvents}
                calendarDateInteraction={calendarDateInteraction}
                mediaType={mediaType}
                setIsStartDateEarly={setIsStartDateEarly}
                isStartDateEarly={isStartDateEarly}
                renderWarningComponent={
                    <UnavailableRental
                        showWarning={showWarning}
                        isOpen={viewCart?.isMinicartCalendarOpen}
                        handleToggle={handleCalendarClose}
                        isEndDateEmpty={isEndDateEmpty}
                        isStartDateEarly={isStartDateEarly}
                    />
                }
            />
            {/* <Modal /> */}
            <aside
                id="minicartContainer"
                data-testid="minicartContainer"
                style={{ overflow: 'scroll' }}
                onScroll={e => handleScroll(e)}
                className={rootClass}>
                {children}
            </aside>
        </>
    );
}

Container.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    showWarning: PropTypes.bool,
    setShowWarning: PropTypes.func
};

Container.defaultProps = {
    children: null,
    isOpen: false,
    showWarning: false,
    setShowWarning: () => {}
};
