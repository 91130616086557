import React, { useEffect } from 'react';
import Wires from '../../../components/global/atoms/wires/Wires';
import { ERROR_MSG } from '../../../constants/errorMappingCodes';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useUserContext } from '../../context/UserContext';
import { useCartState } from './cartContext';

const Error = () => {
    const [{ cart, errorMessage }, dispatch] = useCartState();
    const [, { resetUser, logoutUser }] = useUserContext();
    const isEmpty = cart && Object.entries(cart)?.length > 0 ? cart?.items?.length === 0 : true;
    const userTypeCookie = useCheckUser();

    const resetUserSession = () => {
        const userType = userTypeCookie;
        if (userType !== 'guest') {
            logoutUser();
        } else {
            resetUser();
            window.location.reload();
        }
    }

    useEffect(() => {
        try {
            if (errorMessage && (
                errorMessage.includes(ERROR_MSG.CART_EXPIRED)
                || errorMessage.includes(ERROR_MSG.UNAUTHORIZED_USER)
                || errorMessage.includes(ERROR_MSG.UNAUTHORIZED_CART)
                || errorMessage.includes(ERROR_MSG.CART_INACTIVE)
            )) {
                if (!isEmpty) {
                    alert('Your cart has been expired');
                }
                resetUserSession();
            }
        } catch (ex) {
            alert('Some error occurred, Your cart has been expired');
            resetUserSession();
        }
    }, [errorMessage]);

    return (
        <Wires
            wireWrapperclass={'minicart__errorwrap'}
            handleWireClick={() => {
                dispatch({ type: 'discardError' });
            }}
        />
    );
};

export default Error;
