import React, { useCallback, useEffect, useRef, useState } from 'react';
import { arrayOf, func, number, oneOfType, shape, string } from 'prop-types';
import { locationsDatalocator } from '../../../../aem-core-components/components/Minicart/locations/dataLocators';
import { useEventListener } from '../../../../aem-core-components/utils/hooks';
import { ENTER_KEY, ESCAPE_KEY, KEYUP_EVENT } from '../../../../constants/screenConstants';
import CheckMarkIcon from '../../../../resources/images/checkmark-green.svg';
import ArrowDownIcon from '../../../../resources/images/chevron-down.svg';
import './select.scss';

const Select = props => {
    const {
        dropDownValues,
        selectedValue,
        handleChange,
        className,
        label,
        placeholder = '',
        selectAriaLabel,
        errorClass,
        handleClick,
        dataTestId,
        isOpenselect,
        elementId,
        onFocus,
        labelSortByText
    } = props;
    const [isOpen, setIsOpen] = useState(isOpenselect || false);
    const dropdownMenuRef = useRef(null);
    const divRef = useRef(null);
    // logic to close dropdown during outside click
    useEffect(() => {
        if (isOpen) {
            let dropOffset = dropdownMenuRef.current.getBoundingClientRect();
            let innnerHeight = window.innerHeight;
            // if (innnerHeight - dropOffset < scrollHeight) {
            if (dropOffset.top > innnerHeight / 2) {
                dropdownMenuRef.current.classList.add('reverse');
            } else {
                dropdownMenuRef.current.classList.remove('reverse');
            }
        }
    }, [isOpen]);
    useEffect(() => {
        const handleClickOutside = e => {
            if (isOpen && divRef.current && !divRef.current.contains(e.target)) toggle();
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const toggle = () => setIsOpen(!isOpen); // toggle the dropdown
    const selectedLabel = dropDownValues.find(item => item.value === selectedValue)?.label;

    const escapeKeyHandler = useCallback(
        event => {
            if (event.key === ESCAPE_KEY && isOpen) {
                toggle();
            }
        },
        [isOpen]
    );

    useEventListener(window, KEYUP_EVENT, escapeKeyHandler);

    const onSelectOption = value => {
        handleChange(value, label);
        toggle();
    };
    const onKeyPress = (value, e) => {
        if (e.key === ENTER_KEY) onSelectOption(value);
    };
    const handleBtnClick = () => {
        toggle();
        if (handleClick) {
            handleClick();
        }
        onFocus && onFocus();
    };
    return (
        <div
            ref={divRef}
            id={elementId}
            className={`${className ? className : ''} dropdown dropdown-select ${isOpen ? 'show' : ''}`}>
            <button
                className={`button dropdown-toggle dropdown-select-button ${errorClass}`}
                type="button"
                data-toggle="dropdown"
                aria-expanded={isOpen}
                onClick={handleBtnClick}
                data-testid={dataTestId}
                aria-label={
                    selectAriaLabel
                        ? selectAriaLabel
                        : `${labelSortByText ? labelSortByText + ' ' : ''}${selectedLabel || placeholder}`
                }>
                <span
                    aria-live="polite"
                    className={`${
                        !selectedValue ? 'dropdown-select-placeholder' : ''
                    } dropdown-select-selected-option`}>
                    {selectedLabel || placeholder}
                </span>
                <ArrowDownIcon className={`dropdown-select-icon ${isOpen ? 'dropdown-select-iconopen' : ''}`} />
                {/* {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon  />} */}
            </button>
            <ul
                className={`dropdown-select-menu ${isOpen ? 'show' : ''}`}
                ref={dropdownMenuRef}
                aria-label="filterdropdown">
                {dropDownValues?.map(item => (
                    <li
                        className={`dropdown-list-item  dropdown-select-item ${
                            selectedValue === item?.value ? 'active' : ''
                        }`}
                        data-testid={locationsDatalocator.select_dropdown_testid}
                        role="option"
                        aria-selected={selectedValue === item?.value ? true : false}
                        aria-label={item?.label}
                        key={item?.value}
                        tabIndex={0}
                        onKeyPress={e => onKeyPress(item.value, e)}
                        onClick={() => onSelectOption(item.value)}>
                        {item?.label}
                        {selectedValue === item?.value && <CheckMarkIcon className={'dropdown-select-check'} />}
                    </li>
                ))}
            </ul>
        </div>
    );
};

// Prop types
Select.propTypes = {
    dropDownValues: arrayOf(
        shape({
            label: oneOfType([string, number]),
            value: oneOfType([string, number])
        })
    ),
    selectedValue: oneOfType([string, number]),
    handleChange: func.isRequired,
    selectAriaLabel: string,
    errorClass: string,
    dataTestId: string,
    label: string,
    elementId: string,
    onFocus: func
};

Select.defaultProps = {
    dropDownValues: [],
    selectedValue: -1,
    handleChange: item => {},
    selectAriaLabel: '',
    errorClass: '',
    dataTestId: '',
    label: '',
    elementId: '',
    onFocus: () => {}
};

export default Select;
