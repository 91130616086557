import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from 'react-intl';
import { getProjectsFromCookie } from '../../../../../components/global/utils/commonUtils';
import useComputeLocation from '../../../../../hooks/useComputeLocation';
import useUserData from '../../../../../hooks/useUserData';
import { SET_CART_OVERLAY, SET_VIEW_CART_FIELDS } from '../../../../actions/constants';
import { useCartState } from '../../cartContext';
import { VIEW_CART } from '../../constants';
import AnimatedHeader from '../../headers/animatedHeader/AnimatedHeader';
import MinicartHeaderDotCom from './MinicartHeaderDotCom';
import MinicartHeaderP2P from './MinicartHeaderP2P';
import { logError } from '../../../../../components/global/utils/logger';
import { useCheckAuthorityType, useCheckUser } from '../../../../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../../../../../components/global/constants';
import { useFilterState } from '../../../../../components/cap';
import { USER_TYPE } from '../../../../../constants/userDetailsConstants';
import useAnalytics from '../../../../../hooks/useAnalytics';

const MiniCartHeader = props => {
    const { disablCheckoutOnApiError, canadaRestrictStateError, isManualAddress } = props;
    const intl = useIntl();
    const userType = useCheckUser();
    const [{ sendAnalyticsDatePickerViewEvent }] = useAnalytics();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const [{ isCreditNewAddress, userAccount }, dispatch] = useCartState();
    const [selectedAddress, setSelectedAddress] = useState(viewCart.location || '');
    const cookieAccount = getProjectsFromCookie();

    const authorityType = useCheckAuthorityType();
    const isEditQuote = false;

    const handleCartOverlay = cartOverlay => {
        dispatch({ type: SET_CART_OVERLAY, cartOverlay });
    };

    const getChooseAddress = () => {
        if (userType === USER_TYPE.CREDIT && isCreditNewAddress) {
            return (
                viewCart.location || intl.formatMessage({ id: 'cart-header:location-autocomplete-empty-label-credit' })
            );
        } else {
            return (
                projectDetails.projectAddress1 ||
                intl.formatMessage({ id: 'cart-header:location-autocomplete-empty-label-credit' })
            );
        }
    };

    const handleCalendarOpen = isDateClick => {
        try {
            filterDispatch({
                type: SET_VIEW_CART_FIELDS,
                key: 'isMinicartCalendarOpen',
                value: !viewCart?.isMinicartCalendarOpen
            });
            sendAnalyticsDatePickerViewEvent(isDateClick);
        } catch (error) {
            logError(error, false, 'handleCalendarOpen');
        }
    };

    const renderHeader = () => {
        switch (authorityType) {
            case AUTHORITY_TYPE.P2P:
                if (isEditQuote) {
                    return (
                        <MinicartHeaderP2P
                            cookieAccount={cookieAccount}
                            userAccount={userAccount}
                            getChooseAddress={getChooseAddress}
                            handleCartOverlay={handleCartOverlay}
                            disablCheckoutOnApiError={disablCheckoutOnApiError}
                            setSelectedAddress={setSelectedAddress}
                            viewCart={viewCart}
                            canadaRestrictStateError={canadaRestrictStateError}
                            handleCalendarOpen={handleCalendarOpen}
                        />
                    );
                }
                return (
                    <MinicartHeaderDotCom
                        cookieAccount={cookieAccount}
                        userAccount={userAccount}
                        getChooseAddress={getChooseAddress}
                        handleCartOverlay={handleCartOverlay}
                        disablCheckoutOnApiError={disablCheckoutOnApiError}
                        setSelectedAddress={setSelectedAddress}
                        viewCart={viewCart}
                        canadaRestrictStateError={canadaRestrictStateError}
                        handleCalendarOpen={handleCalendarOpen}
                        isManualAddress={isManualAddress}
                    />
                );

            default:
                return (
                    <MinicartHeaderDotCom
                        cookieAccount={cookieAccount}
                        userAccount={userAccount}
                        getChooseAddress={getChooseAddress}
                        handleCartOverlay={handleCartOverlay}
                        disablCheckoutOnApiError={disablCheckoutOnApiError}
                        setSelectedAddress={setSelectedAddress}
                        viewCart={viewCart}
                        canadaRestrictStateError={canadaRestrictStateError}
                        handleCalendarOpen={handleCalendarOpen}
                        isManualAddress={isManualAddress}
                    />
                );
        }
    };

    return (
        <>
            <AnimatedHeader />
            {renderHeader()}
        </>
    );
};

export default React.memo(MiniCartHeader);
